/***************************************************************
* Copyright (C) 2016-2025 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#login {
  background: rgb( $backgroundColor );
  font-family: $sans;
  // display: flex;
  // flex-direction: row;
  // align-items: flex-end;
  // justify-content: flex-start;
  box-sizing: border-box;
  width: 100vw;
  padding: 2em;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  .loginWrapper {
    width: calc( 100vw - 4em );
    height: calc( 100vh - 4em );
    display: grid;
    grid-template-columns: minmax(38em, 1fr) 1fr;
    box-shadow: $boxShadow;
    border-radius: $borderRadius-input;
  }
  .formColumn {
    background: rgb( $foregroundColor );
    border-radius: $borderRadius-input 0 0 $borderRadius-input;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4em 4em 2em 4em;
    height: 100%;
    .logoWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img {
        height: 4em;
        &.logoText {
          margin-left: 1em;
        }
      }
    }
    .formWrapper {
      margin-top: 4em;
      width: 100%;
    }
    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      flex-grow: 1;
      padding-bottom: 2em;
      .loginButton {
        @include dsButton( 'minimal-primary' );
        width: 8em;
        text-align: center;
        justify-content: space-around;
        transition: all 0.3s ease-in-out;
        margin-left: 1em;
      }
      .cancelButton {
        @include dsButton( 'minimal' );
        width: 9em;
        color: rgb( $textColor-secondary );
        transition: all 0.3s ease-in-out;
      }
    }
    #login-copyright {
      justify-self: flex-end;
      p {
        margin: 0;
        color: rgb( $textColor-primary );
      }
    }
  }
  .messageColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 4em;
    padding-bottom: 16em;
    background: linear-gradient( rgba( $primary, 0.7 ), rgb( $primary ) ), url('./../../../../images/login-Page-BG.png') no-repeat left top;
    background-size: cover;
    border-radius: 0 $borderRadius-input  $borderRadius-input 0;
    h2 {
      text-align: center;
      font-size: 2.25em;
      font-weight: 600;
      margin-bottom: 1em;
      color: #fff;
    }
    p {
      text-align: center;
      font-size: 1.25em;
      font-weight: 400;
      color: #fff;
    }
    &.darkMode {
      background: linear-gradient( rgba( $backgroundColor, 0.7 ), rgb( $backgroundColor ) ), url('./../../../../images/login-Page-BG.png') no-repeat left top;
      background-size: cover;
    }
  }
  .loadingBarWrapper {
    width: 100%;
    position: relative;
    height: 4em;
    background: rgb( $dividerColor );
    display: flex;
    margin: 2em auto;
    .loadingBar {
      height: 4em;
      background: rgb( $primary );
      transition: all 0.3s ease-in-out;
      animation: pulse 5s infinite ease-in-out;
    }
  }
  .loadingContainer {
    position: relative;
    background: none;
    width: 100%;
    flex-grow: 0;
    .loadingWrapper {
      font-size: 1em;
      flex-wrap: nowrap;
      position: relative;
      width: auto;
      display: flex;
      .messageContainer {
        font-size: 1.25em;
        margin-left: 2em;
      }
    }
  }
}
