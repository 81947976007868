/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.persistentAlertWrapper {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 999999;
  width: calc( 30vw );
  cursor: move;
}

#navigationMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 5.25em;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 1003;
  padding: 1em 0em 1em 1em;
  &:hover {
    .leftNavLower {
      .expandButton {
        display: flex;
      }
    }
  }
  &:hover, &.expanded {
    width: 26em;
    #navigationMenu {
      width: 24em;
      .logoContainer {
        width: 22em;
        a {
          padding: 0 0.125em;
          .inlineSVG, img {
            display: none;
            &.logoText {
              display: block;
            }
          }
        }
      }
    }

    #lowerItems {
      width: 22em;
      padding-left: 1em;
      padding-right: 1em;
      .lowerItemWrapper {
        &.addToPlan, &.helpCenter {
          display: flex;
        }
      }
    }
  }
}

// left nav
#navigationMenu {
  width: 4.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  box-shadow: $boxShadow;
  background: rgb( $textColor-primary );
  background: -moz-linear-gradient(0deg, rgba(66,109,159,1) 0%, rgba(51,77,110,1) 33.333%);
  background: -webkit-linear-gradient(0deg, rgba(66,109,159,1) 0%, rgba(51,77,110,1) 33.333%);
  background: linear-gradient(0deg, rgba(66,109,159,1) 0%, rgba(51,77,110,1) 33.333%);
  border-radius: $borderRadius-input;
  transition: all 0.1s ease-in-out;
  .leftNavUpper, .leftNavLower {
    width: 100%;
    position: relative;
  }
  .leftNavLower {
    .expandButton {
      padding: 0;
      border: none;
      background: rgba( #fff, 0.2 );
      border-radius: $borderRadius-full;
      width: 2.25em;
      height: 2.25em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.1s ease-in-out;
      display: none;
      cursor: pointer;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: rgb( $dividerColor );
        }
      }
      &:hover {
        background: rgba( #fff, 0.4 );
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
    .logoutButton {
      padding: 0;
      border: none;
      background: rgba( #fff, 0.2 );
      border-radius: $borderRadius-full;
      width: 2.25em;
      height: 2.25em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.1s ease-in-out;
      cursor: pointer;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: rgb( $dividerColor );
        }
      }
      &:hover {
        background: rgba( #fff, 0.4 );
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .logoContainer {
    border-bottom: 1px solid rgba( #fff, 0.25 );
    width: 2.25em;
    padding-bottom: 0.5em;
    a {
      padding: 0 0.125em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .inlineSVG, img {
        width: 2em;
        display: block;
        &.logoText {
          width: calc( 2em * 6.333333 );
          height: 2em;
          display: none;
          margin-left: 1em;
          &.demoVersion {
            height: 2.75em;
            margin-top: 0.5em;
            margin-left: 0.875em;
          }
        }
      }
      img {
        margin-bottom: 1.25em;
      }
    }
  }
  #lowerItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 1em auto;
    padding-top: 2em;
    border-top: 1px solid rgba( #fff, 0.25 );
    .lowerItemWrapper {
      width: 2.5em;
      height: 2.5em;
      border-radius: $borderRadius-full;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: rgba( #fff, 0.1 );
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      position: relative;
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        opacity: 1;
        path {
          opacity: 1;
          fill: rgb( $iconColor-primary );
        }
      }
      .avatarInitials {
        width: 2.25em;
        height: 2.25em;
        line-height: 2.25em;
        cursor: pointer;
      }
      .onboardingWarning {
        border: 2px solid #fff;
        width: 1.25em;
        height: 1.25em;
        border-radius: $borderRadius-full;
        background: rgb( $critical );
        position: absolute;
        right: -0.25em;
        top: -0.25em;
        &.high {
          background: rgb( $high );
        }
        &.moderate {
          background: rgb( $moderate );
        }
        &.low {
          background: rgb( $low );
        }
        &.minimal {
          background: rgb( $minimal );
        }
      }
      .addToPlanMenuWrapper {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        .addToPlanMenuTriggerWrapper {
          .notificationCount {
            background: rgb( $primary );
          }
        }
      }
      .userMenuShade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      }
      .userMenuWrapper {
        background: rgb( $foregroundColor );
        box-shadow: $boxShadow;
        border-radius: $borderRadius-input;
        padding: 1em;
        position: fixed;
        left: 26em;
        bottom: 1em;
        width: 16.5em;
        li {
          margin: 0;
          padding: 0;
          a, .notLink {
            color: rgb( $textColor-primary );
            font-weight: 600;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0.5em 0.75em;
            border-radius: $borderRadius;
            height: unset;
            .ratingBadge {
              margin-left: 0.75em;
              font-weight: 400;
            }
            &:hover {
              background: rgb( $dividerColor );
            }
          }
        }
      }
      &.addToPlan, &.helpCenter {
        display: none;
      }
      &:hover {
        background: rgba( #fff, 0.2 );
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &.darkMode {
    background: rgb( $foregroundColor );
  }
}

#topBar {
  position: sticky;
  top: 0em;
  padding-top: 1em;
  min-height: unset;
  background: rgb( $backgroundColor );
  margin-bottom: 1em;
  margin-left: 1em;
  z-index: 1002;
  width: calc( 100% - 1em );
  transition: padding 0.1s ease-in-out;
  &.leftNavExpanded {
    padding-left: 19.75em;
  }
  .innerTopBarWrapper {
    padding: 1em;
    padding-left: 1.5em;
    box-sizing: border-box;
    background: rgb( $foregroundColor );
    background: linear-gradient(180deg, rgb( $backgroundColor ) 0%, rgb( $foregroundColor ) 4.5em);
    border: 1px solid rgba( $iconColor-primary, 0.5 );
    box-shadow: $boxShadow-light;
    border-radius: $borderRadius-input;
    &.darkMode {
      background: rgb( $foregroundColor );
    }
  }

  #globalHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .titleAndSelectorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  #pageCreateButtonContainer.left {
    .showAllUsersButton {
      @include dsButton( 'minimal-dark' );
      margin-right: 1em;
      margin-left: 0;
      .inlineSVG {
        path {
          fill: rgb( $textColor-primary );
        }
      }
    }
    .riskInsightBackButton {
      @include dsButton( 'minimal-critical' );
      margin-left: 0;
      margin-right: 1em;
      .inlineSVG {
        path {
          fill: rgb( $critical );
        }
      }
      &.darkMode {
        @include dsButton( 'critical-darkMode' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
    }
    &.high, &.High {
      .riskInsightBackButton {
        @include dsButton( 'minimal-high' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: rgb( $high );
          }
        }
        &.darkMode {
          @include dsButton( 'high-darkMode' );
          margin-left: 0;
          margin-right: 1em;
          .inlineSVG {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
      }
    }
    &.moderate, &.Moderate {
      .riskInsightBackButton {
        @include dsButton( 'minimal-moderate' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: rgb( $moderate );
          }
        }
        &.darkMode {
          @include dsButton( 'moderate-darkMode' );
          margin-left: 0;
          margin-right: 1em;
          .inlineSVG {
            path {
              fill: rgb( $textColor-secondary );
            }
          }
        }
      }
    }
    &.low, &.Low {
      .riskInsightBackButton {
        @include dsButton( 'minimal-low' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: rgb( $low );
          }
        }
        &.darkMode {
          @include dsButton( 'low-darkMode' );
          margin-left: 0;
          margin-right: 1em;
          .inlineSVG {
            path {
              fill: rgb( $textColor-secondary );
            }
          }
        }
      }
    }
    &.minimal, &.Minimal {
      .riskInsightBackButton {
        @include dsButton( 'minimal-minimal' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: rgb( $low );
          }
        }
        &.darkMode {
          @include dsButton( 'minimal-darkMode' );
          margin-left: 0;
          margin-right: 1em;
          .inlineSVG {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
      }
    }
    &.unknown, &.Unknown {
      .riskInsightBackButton {
        @include dsButton( 'minimal-dark' );
        margin-left: 0;
        margin-right: 1em;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
    }
  }
  h1 {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    font-family: $sans;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color: rgb( $textColor-primary );
    margin-right: 0.5em;
    white-space: nowrap;
    margin-right: 1em;
    .inlineSVG {
      margin-right: 0.75em;
      path {
        fill: rgb( $iconColor-primary );
        &.gearIcon {
          fill: rgb( $primary );
        }
      }
    }
    .ratingBadge.beta {
      font-size: 0.625em;
    }
  }
  .rightHeaderContent {
    min-width: 20em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  #pageCreateButtonContainer {
    button, .dropdownButtonTrigger, .dropdownTrigger {
      @include dsButton( 'minimal-primary' );
      padding: 0.625em 1.25em;
      margin-left: 1em;
      font-weight: 600;
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        path {
          fill: rgb( $primary );
        }
      }
    }
    .dropdownMenu.visible {
      top: 3.75em;
      button {
        @include dsButton( 'minimal-primary' );
        padding: 0.375em 1em;
        margin: 1em;
      }
    }
    .pageSettingsButton {
      margin: 0;
      padding: 0.5em;
      opacity: 0.6;
      border: none;
      background: none;
      cursor: pointer;
      display: block;
      transition: all 0.3s ease-in-out;
      margin-left: 0.5em;
      border-radius: $borderRadius-full;
      width: 2.5em;
      height: 2.5em;
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
      }
      &:hover {
        opacity: 1;
        background: rgb( $dividerColor );
      }
    }
  }
  #pageAlertsContainer {
    button {
      &.showNotificationsButton {
        width: 2.5em;
        height: 2.5em;
        border-radius: $borderRadius-full;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        transition: all 0.3s ease-in-out;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        background: rgb( $backgroundColor );
        opacity: 1;
        margin: 0 1em;
        position: relative;
        .inlineSVG {
          flex-shrink: 0;
          path {
            fill: rgb( $textColor-primary );
            transition: all 0.3s ease-in-out;
          }
        }
        .notificationsCount {
          background: rgb( $status-alert );
          top: -0.75em;
          right: -0.75em;
          width: 1.75em;
          height: 1.75em;
          line-height: 1.75em;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: $borderRadius-full;
          font-size: 0.875em;
          font-weight: 600;
          white-space: nowrap;
          margin: 0;
          margin-left: 0;
          color: #fff;
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        }
        &:hover {
          background: rgb( $dividerColor );
          .inlineSVG {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
      }
    }
    .HeaderConfigureButton {
      @include dsButton( 'minimal-dark' );
      padding: 0.625em 1.25em;
      margin-left: 1em;
    }
  }
  #pageHeaderContainer {
    border-top: 1px solid rgb( $dividerColor );
    padding-top: 1em;
    margin-top: 1em;
  }
}