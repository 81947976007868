/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.fieldWrapper.recipients {
  .addRecipientsButton {
    @include dsButton('minimal-primary');
    text-align: center;
    justify-content: space-around;
  }
  .emptyState {
    padding: 1em;
    font-size: 1.125em;
  }
  .summaryWrapper {
    padding: 0.5em;
    background: rgb( $backgroundColor );
    border: 1px solid rgb( $dividerColor );
    border-radius: $borderRadius;
    margin-bottom: 0.5em;
    strong {
      margin-bottom: 0.5em;
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
};

.modalContainer.emailRecipientsModal {
  .modalBody {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: calc( 80vh - 2em );
    .column {
      height: 100%;
      width: 100%;
      h2 {
        margin-bottom: 1em;
      }
      &:nth-of-type(2) {
        border-left: 1px solid rgb( $iconColor-primary );
        padding-right: 0;
        padding-left: 1em;
      }
      ul {
        max-height: calc( 80vh - 10.5em );
        overflow: auto;
        li {
          transition: all 0.3s ease-in-out;
          border: 1px solid transparent;
          border-bottom: 1px solid rgb( $dividerColor );
          padding: 0.5em 0;
          position: relative;
          height: 3.25em;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          .avatarInitials {
            margin-right: 1em;
          }
          h3 {
            padding: 0;
            padding-right: 0.5em;
            margin: 0;
            font-weight: 400;
            font-size: 1em;
            width: 100%;
          }
          button {
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            opacity: 0.4;
            transition: opacity 0.3s ease-in-out;
            .inlineSVG {
              width: 1.5em;
              height: 1.5em;
              display: block;
            }
            &:hover {
              opacity: 1;
            }
          }
          &.isSelected {
            padding-left: 2em;
            padding-right: 0.875em;
            border-radius: $borderRadius;
            background: rgba($textColor-primary, 0.05);
            border: 1px solid rgb( $iconColor-primary );
            height: auto;
            margin-bottom: 0.5em;
            &:before {
              content: "";
              position: absolute;
              height: 0.75em;
              width: 0.75em;
              border-radius: $borderRadius-full;
              background: rgb( $primary );
              top: 50%;
              margin-top: -0.375em;
              left: 0.75em;
            }
          }
        }
      }
      &:nth-of-type(1) {
        ul {
          padding-right: 1em;
        }
      }
    }
    .emptyState {
      padding: 1em;
      font-size: 1.125em;
    }
  }
}