/***************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

 .activityItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgb( $foregroundColor );
  border-radius: $borderRadius-input;
  border: 1px solid rgb( $dividerColor );
  padding: 0.5em 0.75em;
  color: rgb( $textColor-primary );
  margin-bottom: 0.5em;
  transition: all 0.3s ease-in-out;
  .contentWrapper {
    flex-grow: 1;
    padding-right: 1em;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    .itemIconWrapper {
      background: rgb( $high--10 );
      width: 2em;
      height: 2em;
      border-radius: $borderRadius-full;
      align-items: center;
      justify-content: space-around;
      margin-right: 1em;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: rgb( $high );
        }
      }
    }
    .main {
      flex-grow: 1;
      h2 {
        font-size: 1em;
        margin: 0;
        padding: 0;
        font-weight: 400;
        margin-bottom: 0.5em;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        strong {
          font-weight: 600;
          margin-right: 0.25em;
        }
        span {
          color: rgb( $textColor-primary );
        }
        &:hover {
          color: rgb( $primary );
          text-decoration: underline;
          span {
            color: rgb( $primary );
          }
        }
      }
      span {
        color: rgb( $textColor-secondary );
      }
    }
    .additional {
      margin-left: 1em;
      padding-left: 1em;
      border-left: 1px solid rgb( $dividerColor );
      align-items: center;
      width: 20em;
      label {
        color: rgb( $textColor-primary );
        margin-bottom: 0.5em;
        text-align: center;
        font-weight: 600;
      }
      span {
        color: rgb( $textColor-secondary );
        text-align: center;
      }
    }
    .timestamp {
      margin-left: 1em;
      color: rgb( $textColor-primary );
    }
  }
  .actionsWrapper {
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    button {
      margin-left: 1em;
      border: none;
      background: none;
      cursor: pointer;
      border-radius: $borderRadius-full;
      height: 2em;
      width: 2em;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        transition: all 0.3s ease-in-out;
        path {
          transition: all 0.3s ease-in-out;
          fill: rgb( $iconColor-primary );
        }
      }
      &:hover {
        background: rgb( $dividerColor );
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
      &.viewItemButton {
        &:hover {
          background: rgb( $textColor-primary );
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  &.medium {
    .itemIconWrapper {
      background: rgb( $moderate--10 );
      .inlineSVG {
        path {
          fill: rgb( $moderate );
        }
      }
    }
  }
  &.low {
    .itemIconWrapper {
      background: rgb( $low--10 );
      .inlineSVG {
        path {
          fill: rgb( $low );
        }
      }
    }
  }
  &.scanLog {
    .itemIconWrapper {
      background: rgb( $dividerColor );
      .inlineSVG {
        width: 1.5em;
        height: 1.5em;
        path {
          fill: rgb( $textColor-primary );
        }
      }
    }
  }
  &:hover {
    border: 1px solid rgb( $iconColor-primary );
    box-shadow: $boxShadow-light;
    .actionsWrapper {
      button.viewItemButton {
        background: rgb( $textColor-primary );
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
      }
    }
  }
 }