/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.setupPageContainer {
  ul.setupRecordsList {
    margin: 0;
    padding: 0;
    font-family: $sans;
    // padding: 1em 0;
    position: relative;
    &.isDraggable {
      // margin: 1em -1em -1em -1em;
      // padding: 1em;
      border-radius: $borderRadius;
      border: 1px solid transparent;
      &.isDragging {
        border: 1px solid rgb( $dividerColor );
        background: rgba($textColor-primary, 0.03);
        li.setupRecordItem {
          border-bottom: 1px solid rgb( $dividerColor );
        }
      }
    }
  }
}