/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.scanningStatusIndicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-radius: $borderRadius-full;
  padding: 0.5em 1.25em 0.5em 0.75em;
  background: rgb( $backgroundColor );
  .labelWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
      path {
        fill: rgb( $textColor-secondary );
      }
    }
    strong {
      color: rgb( $textColor-primary );
      margin-right: 0.25em;
    }
    span {
      color: rgb( $textColor-primary );
      font-weight: 400;
    }
  }
  .timeago {
    font-weight: 600;
    color: rgb( $textColor-secondary );
  }
  &.status--red {
    background: rgb( $status-alert--10 );
    .labelWrapper {
      .inlineSVG {
        path {
          fill: rgb( $status-alert );
        }
      }
    }
    .timeago {
      color: $status-alert
    }
  }
  &.status--orange {
    background: rgb( $status-moderate--10 );
    .labelWrapper {
      .inlineSVG {
        path {
          fill: rgb( $status-moderate );
        }
      }
    }
    .timeago {
      color: $status-moderate
    }
  }
  &.status--yellow {
    background: rgb( $status-warning--10 );
    .labelWrapper {
      .inlineSVG {
        path {
          fill: rgb( $status-warning );
        }
      }
    }
    .timeago {
      color: $status-warning
    }
  }
  &.status--green {
    background: rgb( $status-success--10 );
    .labelWrapper {
      .inlineSVG {
        path {
          fill: rgb( $status-success );
        }
      }
    }
    .timeago {
      color: $status-success
    }
  }
  &.status--blue {
    background: rgb( $status-info--10 );
    .labelWrapper {
      .inlineSVG {
        path {
          fill: rgb( $status-info );
        }
      }
    }
    .timeago {
      color: $status-info
    }
  }
  &.darkMode {
    background: none;
    border: 1px solid rgb( $dividerColor );
    color: rgb( $dividerColor );
    .labelWrapper {
      .inlineSVG {
        path {
          fill: rgb( $dividerColor );
        }
      }
      strong {
        color: rgb( $dividerColor );
      }
      span {
        color: rgb( $dividerColor );
      }
    }
    .timeago {
      color: rgb( $dividerColor );
    }
    &.status--red {
      border: 1px solid rgb( $status-alert );
      .labelWrapper {
        .inlineSVG {
          path {
            fill: rgb( $status-alert );
          }
        }
        strong {
          color: rgb( $status-alert );
        }
        span {
          color: rgb( $status-alert );
        }
      }
      .timeago {
        color: rgb( $status-alert );
      }
    }
    &.status--orange {
      border: 1px solid rgb( $status-moderate );
      .labelWrapper {
        .inlineSVG {
          path {
            fill: rgb( $status-moderate );
          }
        }
        strong {
          color: rgb( $status-moderate );
        }
        span {
          color: rgb( $status-moderate );
        }
      }
      .timeago {
        color: rgb( $status-moderate );
      }
    }
    &.status--yellow {
      border: 1px solid rgb( $status-warning );
      .labelWrapper {
        .inlineSVG {
          path {
            fill: rgb( $status-warning );
          }
        }
        strong {
          color: rgb( $status-warning );
        }
        span {
          color: rgb( $status-warning );
        }
      }
      .timeago {
        color: rgb( $status-warning );
      }
    }
    &.status--green {
      border: 1px solid rgb( $status-success );
      .labelWrapper {
        .inlineSVG {
          path {
            fill: rgb( $status-success );
          }
        }
        strong {
          color: rgb( $status-success );
        }
        span {
          color: rgb( $status-success );
        }
      }
      .timeago {
        color: rgb( $status-success );
      }
    }
    &.status--blue {
      border: 1px solid rgb( $status-info );
      .labelWrapper {
        .inlineSVG {
          path {
            fill: rgb( $status-info );
          }
        }
        strong {
          color: rgb( $status-info );
        }
        span {
          color: rgb( $status-info );
        }
      }
      .timeago {
        color: rgb( $status-info );
      }
    }
  }
}