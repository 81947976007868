/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.vulnerabilityRecordCardContentWrapper {
  .cvssCategoriesBreakdownWrapper, .CVSSBreakdown, .instancesBreakdownWrapper {
    border-bottom: 1px solid rgb( $dividerColor );
  }
  p {
    margin-top: 1em;
    max-height: 7.5em;
    overflow: hidden;
  }
  .recordDetails__InformationPanel__Body_Section.statistics {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  // .CVSSBreakdown {
  //   padding-bottom: 1em;
  // }
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1em;
    label {
      font-weight: 600;
      margin-bottom: 0.5em;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    span {
      line-height: 1.5em;
    }
  }
  .inlineLink.newTabLink {
    margin-bottom: 0.5em;
    &.mitreLink {
      background: rgb( $moderate--10 );
      color: rgb( $textColor-primary );
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .cveLogo {
        height: 1em;
        width: 3em;
        margin-right: 0.5em;
      }
      .inlineSVG {
        path {
          fill: rgb( $moderate );
        }
      }
      &:hover {
        background: rgb( $moderate--10 );
      }
      &.darkMode {
        background: none;
        border: 1px solid rgb( $status-moderate );
        color: rgb( $status-moderate );
        &:hover {
          background: rgb( $status-moderate );
          color: rgb( $textColor-secondary );
        }
      }
    }
  }
}
// .recordCardContainer.recordCardType_vulnerability {
//   .contentGridWrapper {
//     .mainContent {
//       .recordCardContent {
//         .cvssCategoriesBreakdownWrapper, .CVSSBreakdown, .instancesBreakdownWrapper {
//           border-bottom: 1px solid rgb( $dividerColor );
//         }
//         p {
//           margin-top: 1em;
//         }
//         .recordDetails__InformationPanel__Body_Section.statistics {
//           display: grid;
//           grid-template-columns: 1fr 1fr;
//           padding-top: 2em;
//         }
//         .CVSSBreakdown {
//           padding-top: 1em;
//         }
//       }
//     }
//   }
// }