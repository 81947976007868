/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.setupPageContainer.tasksPage {
  background: rgb( $backgroundColor );
  height: calc(100vh - 8.5em);
  overflow: auto;
}
