/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.alert, .alert-item {
  margin-bottom: 1em;
  padding: 0.5em 1em;
  border-radius: $borderRadius;
  color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  background: rgb( $high );
  .inlineSVG {
    margin-right: 0.5em;
    height: 1.25em;
  }
  p {
    line-height: 1.5em;
    margin: 0;
    padding: 0;
  }
  a {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  &.warning {
    background: rgb( $status-moderate );
  }
}

.notification {
  border-radius: $borderRadius;
  border: 1px solid rgb( $status-alert );
  background: rgb( $status-alert--25 );
  color: rgb( $status-alert );
  font-weight: bold;
  font-family: $sans;
  position: relative;
  margin: 1em 0;
  .notificationHeader {
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: $borderRadius $borderRadius 0 0;
    box-sizing: border-box;
    padding: 0.5em;
    width: calc(100% + 2px);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    color: #fff;
    text-transform: uppercase;
    background: rgb( $status-alert );
    font-size: 0.875em;
    .inlineSVG {
      height: 1.25em;
      width: 1.25em;
      margin-right: .5em;
    }
    button {
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: 0.375em;
      right: 1.25em;
      padding: 0;
      margin: 0;
      width: 1em;
      height: 1em;
      .inlineSVG {
        height: 1.25em;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .notificationBody {
    padding: 3em 1em 1em 1em;
    display: block;
    p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0em;
      }
    }
    a {
      color: rgb( $textColor-primary );
      display: inline;
      opacity: 1;
    }
    ul {
      margin-top: 0.5em;
      background: none;
      margin-left: 1em;
      max-height: 12em;
      overflow: auto;
      li {
        background: none;
        padding: 0;
        list-style: disc;
        margin-bottom: 0.5em;
        a {
          background: none !important;
          padding: 0 !important;
          color: rgb( $status-alert ) !important;
          opacity: 1;
        }
      }
    }
    .additionalContent {
      margin-top: 1em;
      a {
        margin-left: 0.25em;
      }
    }
  }
  &.success {
    background: rgb( $status-success--25 );
    border: 1px solid rgb( $status-success );
    .notificationHeader {
      background: rgb( $status-success );
    }
    .notificationBody {
      color: rgb( $status-success );
      ul {
        li {
          a {
            color: rgb( $status-success ) !important;
          }
        }
      }
    }
  }
  &.warning {
    background: rgb( $status-warning--25 );
    border: 1px solid rgb( $status-warning );
    .notificationHeader {
      background: rgb( $status-warning );
    }
    .notificationBody {
      color: rgb( $status-warning );
      ul {
        li {
          a {
            color: rgb( $status-warning ) !important;
          }
        }
      }
    }
  }
  &.info {
    background: rgb( $status-info--25 );
    border: 1px solid rgb( $textColor-primary );
    .notificationHeader {
      background: rgb( $textColor-primary );
    }
    .notificationBody {
      color: rgb( $textColor-primary );
      ul {
        li {
          a {
            color: rgb( $textColor-primary ) !important;
          }
        }
      }
    }
  }


  &.flash {
    margin: 0;
    opacity: 0;
    box-shadow: $boxShadow-heavy;
    z-index: 9999999;
    position: fixed;
    bottom: 6em;
    right: -28em;
    transition: all 0.15s ease-in-out;
    max-width: 50vw;
    &.visible {
      right: 1em;
      opacity: 1;
    }
    &.top {
      bottom: unset;
      top: 7em;
    }
  }
}
