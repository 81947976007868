/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.setupRecordItem.accepted_risk_plan.item.alternateLayout {
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  position: relative;
  section.mainDetailsSection {
    .column {
      h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          margin-right: 0.5em;
          path {
            fill: rgb( $iconColor-primary );
          }
        }
      }
      span {
        color: rgb( $textColor-primary );
        font-weight: 400;
        font-size: 1em;
        strong {
          font-weight: 600;
        }
      }
    }
  }
  .itemActions {
    align-items: flex-start !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    height: 2.5em;
  }
  .expandingItemsContainer {
    position: relative;
    grid-column: 1 / 5;
    .showItemsButton {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      margin: 0;
      cursor: pointer;
      opacity: 0.6;
      background: none;
      border: none;
      transition: all 0.3s ease-in-out;
      font-size: 0.875em;
      color: rgb( $textColor-primary );
      z-index: 1;
      margin-top: -1.25em;
      .inlineSVG {
        margin-left: 0.5em;
        width: 1.25em;
        height: 1.25em;
      }
      &:hover {
        opacity: 1;
      }
    }
    section {
      border-top: 1px solid rgb( $dividerColor );
      padding-top: 1em;
      padding-left: 0;
      border-left: none;
      h2 {
        margin-bottom: 1em;
        font-size: 1em;
        color: rgb( $textColor-primary );
      }
      ul {
        margin: 0;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0.5em;
      }
    }
    &.expanded {
      section {
        margin-top: 1em;
      }
      .showItemsButton {
        .inlineSVG {
          transform: rotate(180deg);
        }
      }
    }
    .emptyState {
      font-size: 1.125em;
      padding: 1.125em;
    }
  }
}