/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.accordionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1em;
  .accordionHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 1em 0;
    cursor: pointer;
    width: 100%;
    h3 {
      font-size: 1em;
      font-weight: 600;
      margin: 0;
      padding: 0;
      transition: all 0.3s ease-in-out;
    }
    button {
      border: 1px solid rgb( $primary );
      background: rgb( $foregroundColor );
      transition: all 0.3s ease-in-out;
      color: rgb( $primary );
      border-radius: 0.25em;
      padding: 0.25em 0.75em;
      font-size: 0.875em;
      text-align: center;
      cursor: pointer;
      margin-right: 1em;
    }
  }
  .accordionBody {
    height: auto;
    opacity: 1;
  }
  &.collapsed {
    .accordionBody {
      transition: all 0.3s ease-in-out;
      height: 0;
      opacity: 0;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      position: relative;
    }
  }
  &.alternate {
    border: none;
    border-radius: 0;
    padding: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgb( $iconColor-primary );
    button {
      .inlineSVG {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.collapsed {
      border: none;
      .accordionHeader {
        button {
          transform: rotate(180deg);
        }
      }
      .accordionBody {
        padding: 0;
      }
    }
    &:first-of-type {
      border-radius: $borderRadius $borderRadius 0 0;
    }
    .accordionHeader {
      padding: 1em;
      border: none;
      border-bottom: 1px solid rgb( $iconColor-primary );
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img {
          height: 1.5em;
          margin-right: 0.5em;
        }
      }
      button {
        transition: all 0.3s ease-in-out;
        border: none;
        padding: 0;
        margin: 0;
        background: none;
        cursor: pointer;

      }
    }
    .accordionBody {
      width: 100%;
      padding: 1em;
      background: rgb( $backgroundColor );
      strong {
        display: block;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        &:first-of-type {
          margin-top: 0;
        }
      }
      ul {
        margin-left: 1em;
        li {
          list-style: disc;
        }
        &.twoColumn {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.5em;
          li {
            width: 85%;
            margin: 0;
            span {
              width: 100%;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
