/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;
.fieldWrapper.schedule {
  label {
    select {
      padding-right: 2.5em;
    }
    .timezoneInfo {
      text-transform: none;
      display: block;
      font-size: 1em;
      font-weight: 400;
      color: rgb( $textColor-primary );
      margin-bottom: 0.5em;
      &.disabled {
        color: rgb( $iconColor-primary );
      }
    }
    .occurrenceWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      span {
        text-transform: none;
      }
      span, input, .selectFieldWrapper {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 0.5em;
        width: auto;
        margin-bottom: 0.25em;
      }
      &.allowMultiple {
        // background: rgb( $backgroundColor );
        position: relative;
        // padding: 0 1.5em .5em 0.5em;
        margin-bottom: 0.5em;
        // border-radius: $borderRadius;
        padding-bottom: 1em;
        border-bottom: 1px solid rgb( $dividerColor );
        select, input {
          margin-top: 0.5em;
        }
        button {
          position: absolute;
          right: 0em;
          top: 0.75em;
          border: none;
          background: none;
          cursor: pointer;
          opacity: 0.5;
          transition: 0.3s ease-in-out;
          .inlineSVG {
            width: 1.75em;
          }
          &:focus {
            outline: none;
          }
          &:hover {
            opacity: 1;
          }
          &.disabled {
            cursor: default;
            pointer-events: none;
          }
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      flex-direction: row;
      margin-top: 1em;
      .addNewOccurrenceButton {
        @include dsButton('minimal-primary');
        border-radius: $borderRadius;
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
        &.disabled {
          color: rgb( $primary );
          opacity: 0.4;
        }
      }
    }
  }
}