/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.notFoundWrapper {
  width: 100%;
  font-family: $sans;
  text-align: center;
  padding: 4em;
  color: rgb( $textColor-primary );
  h1 {
    font-size: 3em;
    margin-bottom: 1em;
  }
  p {
    font-size: 1.5em;
  }
  a {
    @include dsButton('primary');
    color: #fff !important;
    width: 20em;
    margin: 0 auto;
  }
}