/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

ul.setupRecordsList {
  li.setupRecordItem.alternateLayout.subordinate_scanner.item {
    grid-template-columns: 30em calc( 100% - 10em - 30em ) 10em !important;
    background: rgb( $foregroundColor );
    section.mainDetailsSection {
      .column {
        span {
          margin-top: 0.5em;
          color: rgb( $textColor-secondary );
          strong {
            color: rgb( $textColor-primary );
          }
          button {
            @include dsButton( 'minimal-primary' );
            display: inline-block;
          }
          .online {
            color: rgb( $status-success );
          }
          .offline {
            color: rgb( $iconColor-primary );
          }
        }
        h2 {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            margin: 0;
            margin-right: 0.25em;
            &.offline {
              opacity: 0.3;
            }
          }
          button {
            @include dsButton( 'minimal-primary');
            font-size: 0.625em;
            margin-left: 0.75em;
          }
        }
      }
    }
  }
}