/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  width rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;
#navigationMenu {
  // default collapsed state
  .menutItemWrapperV2 {
    cursor: pointer;
    border-radius: $borderRadius-input;
    margin-top: 1.5em;
    width: 100%;
    transition: all 0.15s ease-in-out;
    .menuItemHeader {
      color: #fff;
      padding: 0.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      transition: all 0.15s ease-in-out;
      .labelWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        transition: all 0.15s ease-in-out;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          flex-shrink: 0;
          path {
            fill: rgb( $iconColor-primary );
          }
        }
        h4 {
          visibility: hidden;
          height: 0;
          overflow: hidden;
          opacity: 0;
          transition: none;
          flex-shrink: 1;
          margin-left: 0.5em;
          margin: 0;
          margin-left: 0.5em;
          padding: 0;
          font-size: 1em;
          line-height: 1.5em;
        }
        .ratingBadge {
          display: none;
        }
      }
      .addToPlanMenuWrapper {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        .addToPlanMenuTriggerWrapper {
          width: 22em;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            opacity: 1;
            path {
              opacity: 1;
              fill: rgb( $iconColor-primary );
            }
          }
          .notificationCount {
            display: none !important;
          }
        }
      }
      .userMenuInitialsWrapper {
        position: relative;
        .userDetailsWrapper {
          margin-left: -0.6em;
        }
        .onboardingWarning {
          border: 2px solid #fff;
          width: 1.25em;
          height: 1.25em;
          border-radius: $borderRadius-full;
          background: rgb( $critical );
          position: absolute;
          right: -0.25em;
          top: -0.25em;
          &.high {
            background: rgb( $high );
          }
          &.moderate {
            background: rgb( $moderate );
          }
          &.low {
            background: rgb( $low );
          }
          &.minimal {
            background: rgb( $minimal );
          }
        }
      }
      button {
        background: rgba( $textColor-primary, 0.1 );
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: $borderRadius-full;
        padding: 0;
        border: none;
        cursor: pointer;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: none;
        .inlineSVG {
          width: 0.75em;
          height: 0.75em;

          path {
            fill: rgb( $iconColor-primary );
          }
        }
      }
    }
    .menuItemSubNavigation {
      visibility: hidden;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: none;
      max-height: 45vh;
      overflow: auto;
      li {
        margin-bottom: 0.25em;
        padding: 0;
        a, .notLink {
          color: #fff;
          font-weight: 600;
          text-decoration: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 0.5em 0.75em;
          border-radius: $borderRadius;
          height: unset;
          .ratingBadge {
            margin-left: 0.75em;
            font-weight: 400;
            &.beta, &.new {
              color: #fff;
            }
            &.new {
              background: rgb( $status-success );
            }
            &.beta {
              background: rgb( $primary );
            }
          }
          &:hover {
            background: rgba( $textColor-primary, 0.8 );
          }
        }
        .notLink {
          background: rgba( $textColor-primary, 0.8 );
          position: relative;
          padding-left: 2em;
          &:before {
            content: '';
            position: absolute;
            top: calc( 50% - ( 0.75em / 2 ) );
            left: 0.75em;
            width: 0.75em;
            height: 0.75em;
            background: rgb( $primary );
            border-radius: $borderRadius-full;
          }
        }
        &.divider {
          margin-bottom: 0;
          padding: 0.75em 0.5em;
          label {
            text-transform: uppercase;
            color: rgba( #fff, 0.6 );
            font-size: 0.75em;
          }
          &:first-child {
            margin-top: 0;
            border-top: none;
          }
        }
        &.indented {
          padding-left: 0.5em;
        }
      }
      .routeNavigationItemsWrapperV2 {
        &.border--top {
          margin-top: 0.25em;
          border-top: 1px solid rgba( #fff, 0.2 );
        }
        &.border--bottom {
          border-bottom: 1px solid rgba( #fff, 0.2 );
          margin-bottom: 0.25em;
        }
        &.border--both {
          margin-bottom: 0.25em;
          margin-top: 0.25em;
          border-top: 1px solid rgba( #fff, 0.2 );
          border-bottom: 1px solid rgba( #fff, 0.2 );
        }
      }
    }
    &.isCurrent {
      background: rgba( #fff, 0.3 );
      .menuItemHeader {
        .labelWrapper {
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        }
        button {
          background: rgb( $iconColor-primary );
          .inlineSVG {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
      }
      .menuItemSubNavigation {
        border-bottom: none;
      }
    }
    &.isCollapsed {
      background: unset;
      &.isCurrent {
        background: rgba( #fff, 0.3 );
      }
    }
    &:hover {
      background: rgba( #fff, 0.3 );
      .menuItemSubNavigation {
        .labelWrapper {
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
    &.darkMode {
      &.isCurrent {
        background: rgba( $primary, 0.15 );
        &:hover {
          background: rgba( $primary, 0.15 );
        }
      }
      .menuItemSubNavigation {
        li {
          a, .notLink {
            &:hover {
              background: rgba( $primary, 0.15 );
            }
          }
          .notLink {
            background: rgba( $primary, 0.15 );
          }
        }
      }
    }
  }
}
// full width state of entire menu
#navigationMenuWrapper {
  &:hover, &.expanded {
    .menutItemWrapperV2 {
      background: rgba( #fff, 0.3 );
      .menuItemHeader {
        .labelWrapper {
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
          }
          h4 {
            visibility: visible;
            height: unset;
            opacity: 1;
            transition: 0.25s ease-in-out;
          }
          .ratingBadge {
            display: inline-flex;
          }
        }
        button {
          visibility: visible;
          height: unset;
          opacity: 1;
          transition: 0.25s ease-in-out;
          height: 1.25em;
          width: 1.25em;
          overflow: unset;
        }
        .userMenuInitialsWrapper {
          position: relative;
          .userDetailsWrapper {
            margin-left: 0em;
          }
        }
      }
      .menuItemSubNavigation {
        opacity: 1;
        // overflow: unset;
        padding: 0.5em;
        height: unset;
        visibility: visible;
        transition: 0.25s ease-in-out;
      }
      &.isCollapsed {
        background: unset;
        .menuItemHeader {
          button {
            transform: rotate( 180deg );
          }
        }
        .menuItemSubNavigation {
          opacity: 0;
          width: 0;
          height: 0;
          overflow: hidden;
        }
        &.isCurrent {
          background: rgba( #fff, 0.3 );
        }
      }
      &.isCurrent {
        height: auto;
        transition: 0.25s ease-in-out;
        .menuItemHeader {
          .labelWrapper {
            .inlineSVG {
              path {
                fill: rgba( #fff, 0.7 );
              }
            }
          }
        }
        &.isCollapsed {
          height: 2.25em;
          transition: 0.25s ease-in-out;
        }
      }
      &:hover {
        background: rgba( #fff, 0.3 );
      }
    }
  }
}
