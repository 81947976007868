/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;
.appliedFilterWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: rgb( $backgroundColor );
  padding: 0;
  padding-left: 1em;
  border-radius: $borderRadius-input;
  color: rgb( $textColor-primary );
  cursor: default;
  margin: calc( 0.5em + 1px ) 0.5em 0 0;
  transition: all 0.3s ease-in-out;
  .filterValueWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
      display: block;
    }
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      path {
        fill: rgb( $textColor-secondary );
      }
    }
    .filterAttribute {
      margin-right: 0.25em;
      flex-shrink: 0;
    }
    .filterComparator {
      margin-right: 0.25em;
    }
    .filterValue {
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .vulnScannerInstanceNameWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      strong {
        margin-right: 0.25em;
      }
    }
    .allValuesShade {
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
    }
    .allValuesWrapper {
      position: fixed;
      color: rgb( $textColor-primary );
      font-family: $sans;
      z-index: 99999999;
      padding: 0.5em;
      border: 1px solid rgba( $iconColor-primary, 0.75 );
      border-radius: $borderRadius-input;
      color: rgb( $textColor-primary );
      box-shadow: $boxShadow;
      background: rgb( $foregroundColor );
      max-height: 50vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-height: 50vh;
      overflow: auto;
      .headerLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 0.5em;
        font-weight: 400;
        padding: 0em 0.5em;
        .roundGlyphButton {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          .inlineSVG {
            padding: 0;
            margin: 0;
          }
        }
      }
      .multiValueItemWrapper {
        margin: 0;
        margin-bottom: 0.5em;
        width: 100%;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .selectionOverflow {
      padding-left: 0.5em;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-top: 0.125em;
      &:hover {
        color: rgb( $primary );
        text-decoration: underline;
      }
    }
  }
  button {
    padding: 0.5em;
    border-radius: 0 $borderRadius-input $borderRadius-input 0;
    background: rgb( $iconColor-primary );
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    margin-left: 0.75em;
    transition: all 0.3s ease-in-out;
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin: 0;
      path {
        fill: #fff;
      }
    }
  }
  &:hover {
    background: rgb( $dividerColor );
    button {
      background: rgb( $textColor-primary );
    }
  }
  &.appliedFilterClass--Included {
    background: rgb( $status-success--10 );
    color: rgb( $status-success );
    button {
      background: rgb( $status-success--50 );
    }
    &.multipleValues {
      border: 1px solid rgb( $status-success )--50;
      .multiValueItemWrapper {
        background: rgb( $status-success--10 );
      }
      &:hover {
        background: rgb( $status-success--10 );
        border: 1px solid rgb( $status-success );
      }
    }
    &:hover {
      button {
        background: rgb( $status-success );
      }
    }
  }
  &.appliedFilterClass--Excluded {
    background: rgb( $status-alert--10 );
    color: rgb( $status-alert );
    button {
      background: rgb( $status-alert--60 );
    }
    &.multipleValues {
      border: 1px solid rgb( $status-alert )--60;
      .multiValueItemWrapper {
        background: rgb( $status-alert--10 );
      }
      &:hover {
        background: rgb( $status-alert--10 );
        border: 1px solid rgb( $status-alert );
      }
    }
    &:hover {
      button {
        background: rgb( $status-alert );
      }
    }
  }
  &.multipleValues {
    background: rgb( $foregroundColor );
    border: 1px solid rgb( $dividerColor );
    .filterValue {
      margin-bottom: -0.25em;
    }
    .multiValueItemWrapper {
      margin-left: 0.25em;
      padding: 0.125em 0.5em;
      border-radius: $borderRadius;
      background: rgb( $dividerColor );
      margin-bottom: 0.25em;
    }
    button {
      border-radius: 0 $borderRadius $borderRadius 0;
    }
    &:hover {
      background: rgb( $backgroundColor );
      border: 1px solid rgb( $textColor-primary );
    }
  }
  &.darkMode {
    background: rgba( #fff, 0.2 );
    button {
      background: rgba( $backgroundColor, 0.8 );
      &:hover {
        background:rgb( $backgroundColor );
      }
    }
    &.multipleValues {
      border: 1px solid rgba( $backgroundColor, 0.8 );
      .multiValueItemWrapper {
        &.default {
          background: rgba( $backgroundColor, 0.5 );
        }
      }
    }
  }
}