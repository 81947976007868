/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.vulnerabilityInstancesWidgetTableWrapper {
  width: 100%;
  position: relative;
  z-index: 1;
  .dataTable {
    .tableRow {
      position: relative;
      grid-template-columns:
        12em
        2fr
        5em
        1fr
        1fr
        11em
        6em;
        &.tableHeader {
          position: -webkit-sticky;
          position: sticky;
        }
      .tableCell {
        &.headerCell_last_scanned,
        &.headerCell_description,
        &.tableCell_addresses {
          min-width: 12em;
          .expandingCellWrapper,
          .expandingCellHiddenContent {
            min-width: 12em;
          }
          pre {
            flex-grow: 1;
            padding: 0;
            margin: 0;
            background: none;
            font-family: 'Monospace', monospace;
            font-weight: 600;
          }
        }
        &.tableCell_risk {
          // width: 14em;
          .rowRiskWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .riskReduction {
              font-weight: 600;
              color: rgb( $critical );
              margin-left: 0.5em;
            }
            &.high {
              .riskReduction {
                color: rgb( $high );
              }
            }
            &.moderate {
              .riskReduction {
                color: rgb( $moderate );
              }
            }
            &.low {
              .riskReduction {
                color: rgb( $low );
              }
            }
            &.minimal {
              .riskReduction {
                color: rgb( $low );
              }
            }
          }
        }
        &.tableCell_name {
          font-weight: 600;
          span.missingItemName {
            color: rgb( $textColor-secondary );
          }
        }
        &.tableCell_scanner_signature {
          .relatedSignatureButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border: none;
            cursor: default;
            pointer-events: none;
            width: 100%;
            transition: all 0.3s ease-in-out;
            background: none;
            .recordTypeHeaderIcon {
              transition: all 0.3s ease-in-out;
              width: 1.5em;
              height: 1.5em;
              margin-right: 0.5em;
            }
            .name {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: left;
            }
            &:hover {
              background: rgb( $primary--10 );
            }
          }
        }
        &.tableCell_last_scanned {
          color: rgb( $textColor-secondary );
        }
        &.tableCell_sensitive_assets {
          .sensitiveAssetsCount {
            &.hasAssets {
              color: rgb( $high );
              font-weight: 600;
            }
          }
        }
        &.tableCell_patches,
        &.tableCell_vulnerabilities,
        &.tableCell_sensitive_assets,
        &.tableCell_vulns,
        &.tableCell_hosts,
        &.tableCell_CVSS,
        &.tableCell_exploit_status,
        &.headerCell_patches,
        &.headerCell_vulnerabilities,
        &.headerCell_sensitive_assets,
        &.headerCell_vulns,
        &.headerCell_hosts,
        &.headerCell_CVSS {
          text-align: center;
          align-items: center;
          justify-content: space-around;
        }
      }
    }
  }
  &.host {
    .dataTable {
      .tableRow {
        .tableCell {
          &.headerCell_last_scanned {
            border-right: none;
          }
        }
      }
    }
  }
  &.patch {
    .dataTable {
      .tableRow {
        grid-template-columns:
          12em
          1fr
          11em
          11em
          6em;
        .tableCell {
          &.headerCell_vulns {
            border-right: none;
          }
        }
      }
    }
  }
  &.vulnerability {
    .dataTable {
      .tableRow {
        grid-template-columns:
          12em
          1fr
          8em
          8em
          8em
          6em;
        .tableCell {
          &.headerCell_hosts {
            border-right: none;
          }
        }
      }
    }
  }
  &.signature {
    .dataTable {
      .tableRow {
        grid-template-columns:
          12em
          1fr
          2fr
          8em
          8em;
        .tableCell {
          &.headerCell_vulns {
            border-right: none;
          }
        }
      }
    }
  }
}