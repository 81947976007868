/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.modalShade {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($textColor-primary, 0.6);
  visibility: hidden;
  z-index: 9999996;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.modalContainer {
  max-height: 80vh;
  position: fixed;
  top: 10vh;
  width: 80vw;
  left: 10vw;
  background: rgb( $foregroundColor );
  border-radius: $borderRadius;
  box-shadow: $boxShadow-heavy;
  visibility: hidden;
  z-index: 9999997;
  box-sizing: border-box;
  font-family: $sans;
  color: rgb( $textColor-primary );
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 4.5em;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
  &.small {
    width: 48em;
    left: calc( 50vw - 22em);
  }
  &.large, &.scanGroupModal, &.tag, &.sensitive_asset_policy {
    width: 90vw;
    left: 5vw;
    // top: 5vh;
    height: 95vh;
    max-height: 95vh;
    transition: all 0.3s ease-in-out;
    top: 105vh;
    opacity: 1;
    &.visible {
      top: 5vh
    }
    .modalBody {
      max-height: 95vh;
    }
    .modalActions {
      padding: 1em 2em;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      box-shadow: $boxShadow-heavy;
      border-top: 1px solid rgb( $iconColor-primary );
    }
  }
  &.fullscreen {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    left: 0;
    top: 0;
    border-radius: 0;
    box-shadow: none;
    .closeButton {
      width: 2.5em;
      height: 2.5em;
      border-radius: $borderRadius-full;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      transition: all 0.3s ease-in-out;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      background: rgb( $backgroundColor );
      opacity: 1;
      .inlineSVG {
        flex-shrink: 0;
        path {
          fill: rgb( $textColor-primary );
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        background: rgb( $dividerColor );
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
    }
  }
  &.noHeader {
    padding-top: 2em;
  }
  .closeButton.noHeader {
    width: 2.5em;
    height: 2.5em;
    border-radius: $borderRadius-full;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    transition: all 0.3s ease-in-out;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    background: rgb( $backgroundColor );
    opacity: 1;
    .inlineSVG {
      flex-shrink: 0;
      path {
        fill: rgb( $textColor-primary );
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover {
      background: rgb( $dividerColor );
      .inlineSVG {
        path {
          fill: rgb( $textColor-primary );
        }
      }
    }
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1;
  }
  .modalHeader {
    box-sizing: border-box;
    padding: 0.75em 0.75em 0.75em 1.5em;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    background: none;
    h2 {
      font-weight: 600;
      text-align: left;
      width: 100%;
      font-size: 1.125em;
      color: rgb( $textColor-primary );
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .modalHeaderIconWrapper {
        background: rgb( $backgroundColor );
        width: 2em;
        height: 2em;
        border-radius: $borderRadius-full;
        align-items: center;
        justify-content: space-around;
        margin-right: 0.75em;
        .modalHeaderIcon {
          width: 1.125em;
          height: 1.125em;
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }

    }
    .closeButton {
      width: 2.5em;
      height: 2.5em;
      border-radius: $borderRadius-full;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      transition: all 0.3s ease-in-out;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      background: rgb( $backgroundColor );
      opacity: 1;
      .inlineSVG {
        flex-shrink: 0;
        path {
          fill: rgb( $textColor-primary );
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        background: rgb( $dividerColor );
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
    }
  }
  .modalBody {
    // height: 1em;
    display: block;
    overflow: auto;
    box-sizing: border-box;
    padding: 1em 3em 5em 3em;
    max-height: calc(80vh - 4em);
    flex-grow: 1;
    width: 100%;
    p.directions {
      line-height: 1.5em;
      margin-bottom: 2em;
    }
    pre {
      margin-bottom: 1em;
    }
    .validFormWrapper {
      padding-bottom: 5em;
    }
  }
  .modalContent, .modalActions {
    width: 100%;
  }
  .modalContent {
    padding-bottom: 5em;
  }
  .modalActions {
    border-top: 1px solid rgb( $dividerColor );
    // box-shadow: $boxShadow;
    padding: 1em 2em;
    border-radius: 0 0 $borderRadius $borderRadius;
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    background: rgb( $foregroundColor );
    align-items: center;
    box-sizing: border-box;
    flex-direction: row-reverse;
    width: 100%;
    button {
      @include dsButton('minimal-primary');
      border-radius: $borderRadius;
      .inlineSVG {
        path {
          fill: rgb( $primary );
        }
      }
      margin-left: 1em;
      &.cancelButton {
        @include dsButton('minimal');
        border-radius: $borderRadius;
        &.asPrimary {
          @include dsButton('minimal-primary');
          border-radius: $borderRadius;
          opacity: 1;
          .inlineSVG {
            path {
              fill: rgb( $primary );
            }
          }
        }
      }
    }
  }
}
