/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.userDetailsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .userNameWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .ratingBadge {
      margin-left: 1em;
      width: unset;
      padding: 0 1.5em;
      &.Admin {
        background: rgb( $status-info--10 );
        color: rgb( $status-info );
        border: 1px solid rgb( $status-info );
      }
      &.Report {
        &.Manager {
          background: rgb( $status-success--10 );
          color: rgb( $status-success );
          border: 1px solid rgb( $status-success );
        }
      }
    }
  }
  .userDetailsSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1em;
    .integrationLabel {
      font-size: 0.875em;
      color: rgb( $textColor-primary );
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5em;
      .inlineSVG {
        height: 1.125em;
        width: 1.125em;
      }
      span{
        strong {
          text-transform: unset;
          margin: 0 0.25em;
          font-size: unset;
        }
      }
    }
    h3 {
      font-size: 1.25em;
      margin: 0;
      padding: 0;
    }
    span {
      margin-top: 0.25em;
      color: rgb( $textColor-secondary );
    }
  }

  .avatarInitials {
    @include dsButton('secondary');
    padding: 0;
    height: 2.5em;
    width: 2.5em;
    text-transform: uppercase;
    color: #fff;
    font-family: $sans;
    text-align: center;
    line-height: 2.5em;
    border-radius:  $borderRadius-full;
    font-weight: 600;
    border: 2px solid #fff;
    cursor: default;
    &.small {
      flex-shrink: 0;
      cursor: default;
      box-shadow: none;
      border: none;
      font-size: 0.75em;
      line-height: 0.75em;
      display: flex;
      align-items: center;
      justify-content: space-around;
      opacity: 1;
      &:hover {
        opacity: 1;
      }
    }
    &.avatar {
      display: block;
    }
  }
}


