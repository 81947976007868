/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.ipConnectivityModal {
  .modalBody {
    display: flex;
    flex-direction: row;
    padding-top: 1em;
    padding-bottom: 7em;
    .formWrapper {
      width: 30%;
      padding-right: 2em;
      .fieldGroupWrapper {
        width: 100%;
        .fieldWrapper {
          width: 100%;
          // &:nth-of-type(1) {
          //   order: 2;
          // }
          // &:nth-of-type(2) {
          //   order: 1;
          // }
          // &:nth-of-type(3) {
          //   order: 3;
          // }
        }
      }
    }
    .ipConnectivityTestResultsContainer {
      width: 70%;
      min-height: 10em;
      border: 1px solid rgb( $dividerColor );
      padding: 1em;
      border-radius: $borderRadius;
      background: rgb( $backgroundColor );
      font-family: $fixed;
      white-space: pre-wrap;
      position: relative;
      overflow: auto;
      .emptyState {
        font-size: 1.25em;
        font-family: $sans;
      }
      .notification {
        font-family: $sans;
      }
      span {
        margin-bottom: 0.5em;
        padding: 0.25em;
        line-height: 1.625em;
        &.logLevel-50 {
          background: rgb( $status-alert--25 );
          color: rgb( $textColor-primary );
          font-weight: 600;
        }
        &.logLevel-70 {
          background: rgb( $status-alert--25 );
          color: rgb( $textColor-primary );
          font-weight: 600;
        }
        &.logLevel-90 {
          background: rgb( $status-alert--25 );
          color: rgb( $textColor-primary );
          font-weight: 600;
        }
      }
      .loadingContainer {
        background: none;
        .loadingWrapper {
          .messageContainer {
            font-family: $sans;
            font-size: 1.5em;
            color: rgb( $textColor-secondary );
          }
        }
      }
    }
  }
}
