/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.reactSetupModal.integration {
  .modalBody {
    .userMappingContainer {
      margin: -1em;
      h2 {
        font-size: 1.25em;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-bottom: 1em;
      }
      ul {
        width: 100%;
        li {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          padding-bottom: 1em;
          border-bottom: 1px solid rgb( $dividerColor );
          transition: all 0.3s ease-in-out;
          .userDetailsWrapper {
            width: calc( 100% - 20em );
            .avatarInitials {
              &:hover {
                opacity: 1;
              }
            }
            h3 {
              .inlineSVG {
                display: none;
              }
            }
          }
          .formWrapper {
            position: unset;
            width: unset;
            .fieldGroupWrapper {
              width: unset;
              margin: 0;
              padding: 0;
              position: unset;
              margin-left: -2em;
              margin-right: -2em;
              padding-right: 2em;
              padding-left: 2em;
              .fieldWrapper {
                margin: 0;
                padding: 0;
              }
              .fieldWrapper.checkbox {
                width: 1.5em;
                position: absolute;
                left: 0;
                top: 0.875em;
              }
              .fieldWrapper.searchResults {
                width: 20em;
                right: 0;
                top: 0;
              }
            }
          }
          &.isMapped {
            padding-left: 1.5em;
            &:before {
              content: "";
              position: absolute;
              height: 0.75em;
              width: 0.75em;
              border-radius: $borderRadius-full;
              background: rgb( $status-warning );
              top: 1.125em;
              left: 0em;
            }
          }
        }
      }
    }
  }
}
