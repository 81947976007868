/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetails__MainPanel__Body_ThirdPartyScanning {
  .recordDetails__MainPanel_BodyRow {
    &.scanningStatusSummary {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
    }
    &.currentSignatureDetails {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      color: rgb( $textColor-primary );
      .scannerSignatureSelector {
        position: relative;
        .scannerSignatureSelectorTrigger {
          padding: 0.5em 1em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          background: rgb( $backgroundColor-secondary );
          border: 1px solid rgb( $iconColor-primary );
          border-radius: $borderRadius-input;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            margin-left: 1em;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            background: rgb( $dividerColor );
          }
          &.open {
            .inlineSVG {
              transform: rotate( 180deg );
            }
          }
        }
        .scannerSignatureSelectorShade {
          width: 100vw;
          height: 100vh;
          position: fixed;
          left: 0;
          top: 0;
          z-index: 1006;
        }
        .scannerSignatureSelectorOptions {
          position: absolute;
          right: 0;
          top: 3.5em;
          z-index: 1007;
          border: 1px solid rgb( $dividerColor );
          border-radius: $borderRadius-input;
          box-shadow: $boxShadow-light;
          padding: 1em;
          width: 52em;
          max-width: 60vw;
          background: rgb( $foregroundColor );
          max-height: 32em;
          overflow: auto;
          .scannerSignatureSelectorOption {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0.375em 0.75em;
            border-radius: $borderRadius;
            background: rgb( $foregroundColor );
            margin-bottom: 0.25em;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            .label {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              font-weight: 400;
              strong {
                font-weight: 600;
                margin-right: 0.25em;
              }
            }
            .timestamp {
              color: rgb( $textColor-secondary );
              font-weight: 400;
            }
            img {
              width: 1.5em;
              height: 1.5em;
              margin-right: 0.5em;
              display: block;
            }
            &:hover {
              background: rgb( $dividerColor );
            }
          }
        }
      }
      .scannerSignatureContentWrapper {
        h4 {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 1em;
          font-weight: 400;
          margin: 0;
          padding: 0;
          margin-top: 1em;
          margin-bottom: 2em;
          .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-weight: 400;
            img {
              width: 1.75em;
              height: 1.75em;
              margin-right: 0.5em;
              display: block;
            }
            strong {
              font-weight: 600;
              margin-right: 0.25em;
            }
          }
          .ratingBadge {
            margin-left: 1em;
          }
          .timestamp {
            color: rgb( $textColor-secondary );
            font-weight: 400;
          }
        }
        .markdownWrapper {
          margin: 1em 0;
        }
        pre, .markdownWrapper {
          max-height: 35vh;
          overflow: auto;
        }
        .scannerRating {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 0.5em;
          .ratingBadge {
            margin-left: 0.5em;
          }
        }
        .infoSection {
          label {
            margin-bottom: 1em;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
          ul {
            li {
              margin-bottom: 0.5em;
              font-weight: 600;
              label {
                font-weight: 400;
                display: inline;
                margin: 0;
              }
            }
          }
        }
        .collapsibleSectionBody {
          ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1em;
          }
        }
      }
    }
  }
}