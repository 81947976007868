/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.dialogMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 9999998;
  &.visible {
    display: block;
  }
}

.dialogContainer {
  font-family: $sans;
  position: fixed;
  width: 40vw;
  max-height: 20em;
  top: 10vh;
  left: 30vw;
  background: rgb( $foregroundColor );
  border-radius: $borderRadius-input;
  padding: 2em;
  box-shadow: $boxShadow-heavy;
  display: none;
  z-index: 9999999;
  &.visible {
    display: block;
  }
  .dialogContentWrapper {
    font-size: 1.125em;
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .inlineSVG {
      margin-right: 1em;
      height: 2.5em;
      width: 2.5em;
      flex-shrink: 0;
      path {
        fill: rgb( $textColor-primary );
      }
    }
  }
  .dialogActions {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .cancelButton {
      @include dsButton( 'minimal' );
      margin-right: 1em;
    }
    .confirmButton {
      @include dsButton( 'minimal-primary' );
    }
  }
}