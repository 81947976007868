/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.modalContainer.selectListFieldModal {
  .modalBody {
    .emptyState {
      width: 100%;
      font-size: 1.25em;
    }
    .selectedItems {
      width: 100%;
      order: 1;
    }
    .newItemWrapper {
      width: 100%;
      order: 2;
    }
  }
}

.fieldWrapper.selectList, .modalContainer.selectListFieldModal {
  .selectListContainer {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    .labelWrapper {
      width: 100%;
      margin-bottom: 1em;
    }
    .selectedItems {
      margin: 0;
      padding-left: 1em;
      width: 50%;
      flex-grow: 0;
    }
    label {
      width: 50%;
      flex-direction: row;
    }
    &.asVertical {
      flex-direction: column;
      label {
        width: 100%;
      }
      .emptyState {
        order: 2;
        font-size: 1em;
        width: 100%;
      }
      .selectedItems {
        order: 3;
        width: 100%;
        margin: 0;
        padding: 0;
        margin-bottom: 0.5em;
      }
      .newItemWrapper {
        order: 1;
        width: 100%;
      }
    }
    &.asModal {
      .asModalSummary, .asModalTrigger {
        width: 100%;
      }
      .asModalSummary {
        text-align: center;
        color: rgb( $textColor-secondary );
        padding: 1em 0;
      }
      .asModalTrigger {
        @include dsButton('primary');
        margin-top: 0.5em;
      }
      .emptyState {
        width: 100%;
        padding: 1em;
        font-size: 1.25em;
        a {
          display: inline-block;
          margin-left: 0.25em;
        }
      }
      .newItemWrapper, .selectedItems {
        width: 50%;
      }
    }
  }
  span.labelWrapper {
    font-weight: 500;
    span.required {
      color: rgb( $status-alert );
    }
    .helpTrigger {
      height: 1em;
    }
  }
  .newItemWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    order: 1;
    margin-bottom: 0.5em;
    .selectFieldWrapper {
      width: 100%;
      select {
        margin-top: 0;
        width: 100%;
        border-radius: $borderRadius-input;
      }
    }
  }
  .multiSelectFieldWrapper {
    margin-right: 1em;
    width: calc( 50% - 2em );
    button {
      @include dsButton('minimal-default');
      border-radius: $borderRadius;
      .inlineSVG {
        max-width: 1.25em;
        max-height: 1.25em;
        margin-left: 1em;
      }
    }
  }
  .selectedItems {
    order: 2;
    flex-grow: 1;
    width: calc( 50% - 2em );
    h2 {
      color: rgb( $textColor-primary );
      padding: 0;
      margin-bottom: 1em;
      font-size: 1em;
    }
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: rgb( $textColor-primary );
      text-transform: none;
      padding: 0.5em 1.5em 0.5em 0.5em;
      position: relative;
      // border-radius: $borderRadius;
      // background: rgb( $backgroundColor );
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgb( $dividerColor );
      span {
        margin-right: 1em;
        &.itemLabel {
          margin-right: 0.5em;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc( 100% - 3.5em);
        }
      }
      button {
        position: absolute;
        top: 50%;
        right: 0em;
        padding: 0;
        margin: 0;
        margin-top: calc( 1.25em / -2 );
        border: none;
        background: none;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        .inlineSVG {
          height: 1.25em;
          width: 1.25em;
          opacity: 0.4;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
        &.removeButton {
          right: 1.5em;
        }
      }
      .draggableHandle {
        margin-left: 1em;
        opacity: 0.5em;
        transition: 0.3s ease-in-out;
        cursor: grab;
      }
      &.isDraggable:hover {
        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        cursor: move;
        .draggableHandle {
          opacity: 1;
        }
      }
      &.isDragging {
        border: 1px solid rgb( $textColor-secondary );
        box-shadow: $boxShadow-heavy;
        border-radius: $borderRadius;
        background: rgb( $foregroundColor );
        .draggableHandle {
          opacity: 1;
        }
      }
      &.disabled {
        span {
          color: rgb( $iconColor-primary );
        }
        button {
          &.removeButton {
            pointer-events: none;
            cursor: default;
          }
        }
        .draggableHandle {
          cursor: default;
        }
      }
    }
    ul {
      padding: 0.5em;
      border-radius: $borderRadius;
      &.isDragging {
        border: 1px solid rgb( $dividerColor );
        background: rgba($textColor-primary, 0.03);
      }
    }
  }
  .mulitSelect {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: none;
    padding: 0.5em;
    height: 16em;
    border: 1px solid rgb( $iconColor-primary );
    border-radius: $borderRadius;
    &:focus {
      outline: none;
    }
    option {
      padding: 0.5em 1em;
      cursor: pointer;
      &:focus,
      &:active,
      &:checked {
        background-image: $backgroundColor;
      }
      &:first-child {
        border-radius: $borderRadius $borderRadius 0 0;
      }
      &:last-child {
        border-radius: 0 0 $borderRadius $borderRadius;
      }
    }
  }
  .emptyState {
    order: 3;
    width: 50%;
  }
}
