/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@import "/appliance/share/webroot/third-party/google-fonts/Open-Sans.css";

/***************************************************************
* fonts *
***************************************************************/
$sans: "Open Sans";
$fixed: "Courier New", monospace;

/***************************************************************
* colors *
***************************************************************/

:root {
  --primary: 0, 170, 233;
  --primary--80: 51, 187, 237;
  --primary--75: 64, 191, 239;
  --primary--60: 102, 204, 242;
  --primary--50: 128, 213, 244;
  --primary--40: 153, 221, 246;
  --primary--25: 191, 234, 250;
  --primary--20: 204, 238, 251;
  --primary--10: 230, 247, 253;

  --text-color-primary: 51, 77, 110;
  --text-color-secondary: 144, 160, 183;
  --background-color: 247, 247, 250;
  --background-color-secondary: 252, 252, 253;
  --divider-color: 235, 239, 242;
  --border-color: 235, 239, 242;
  --foreground-color: 255, 255, 255;
  --table-secondary: 249, 251, 252;
  --icon-color-primary: 194, 207, 224;
  --icon-color-secondary: 235, 239, 242;
  --link-color: 0, 170, 233;

  --border-radius: 0.375em;
  --border-radius-input: 0.5em;
  --border-radius-full: 9999em;
  --border-radius-button: 0.375em;
  --box-shadow: 0px 0px 4px 0px rgba(0,0,0, .16);
  --box-shadow-input: 1px 2px 8px 1px rgba(0,0,0, .05);
  --box-shadow-heavy: 0px 8px 32px 0px rgba(0,0,0, .12);
  --box-shadow-light: 1px 2px 8px 1px rgba(0,0,0, .05);

  --status-info: 45, 156, 219;
  --status-info--80: 87, 176, 226;
  --status-info--75: 98, 181, 228;
  --status-info--60: 129, 196, 233;
  --status-info--50: 150, 206, 237;
  --status-info--40: 171, 215, 241;
  --status-info--25: 203, 230, 246;
  --status-info--20: 213, 235, 248;
  --status-info--10: 234, 245, 251;

  --status-success: 111, 207, 151;
  --status-success--80: 140, 217, 172;
  --status-success--75: 147, 219, 177;
  --status-success--60: 169, 226, 193;
  --status-success--50: 183, 231, 203;
  --status-success--40: 197, 236, 213;
  --status-success--25: 219, 243, 229;
  --status-success--20: 226, 245, 234;
  --status-success--10: 241, 250, 245;

  --status-warning: 249, 205, 73;
  --status-warning--80: 250, 215, 109;
  --status-warning--75: 251, 218, 119;
  --status-warning--60: 251, 225, 146;
  --status-warning--50: 252, 230, 164;
  --status-warning--40: 253, 235, 182;
  --status-warning--25: 254, 243, 210;
  --status-warning--20: 254, 245, 219;
  --status-warning--10: 254, 250, 237;

  --status-moderate: 242, 153, 74;
  --status-moderate--80: 245, 173, 110;
  --status-moderate--75: 245, 179, 119;
  --status-moderate--60: 247, 194, 146;
  --status-moderate--50: 249, 204, 165;
  --status-moderate--40: 250, 214, 183;
  --status-moderate--25: 252, 230, 210;
  --status-moderate--20: 252, 235, 219;
  --status-moderate--10: 254, 245, 237;

  --status-alert: 247, 104, 91;
  --status-alert--80: 249, 134, 124;
  --status-alert--75: 249, 142, 132;
  --status-alert--60: 250, 164, 157;
  --status-alert--50: 251, 180, 173;
  --status-alert--40: 252, 195, 189;
  --status-alert--25: 253, 217, 214;
  --status-alert--20: 253, 225, 222;
  --status-alert--10: 254, 240, 239;

  --critical: 195, 57, 55;
  --critical--80: 207, 97, 95;
  --critical--75: 210, 107, 105;
  --critical--60: 219, 136, 135;
  --critical--50: 225, 156, 155;
  --critical--40: 231, 176, 175;
  --critical--25: 240, 206, 205;
  --critical--20: 243, 215, 215;
  --critical--10: 249, 235, 235;

  --high: 234, 94, 80;
  --high--80: 238, 126, 115;
  --high--75: 239, 134, 124;
  --high--60: 242, 158, 150;
  --high--50: 245, 175, 168;
  --high--40: 247, 191, 185;
  --high--25: 250, 215, 211;
  --high--20: 251, 223, 220;
  --high--10: 253, 239, 238;

  --moderate: 255, 150, 26;
  --moderate--80: 255, 171, 72;
  --moderate--75: 255, 176, 83;
  --moderate--60: 255, 192, 118;
  --moderate--50: 255, 203, 141;
  --moderate--40: 255, 213, 163;
  --moderate--25: 255, 229, 198;
  --moderate--20: 255, 234, 209;
  --moderate--10: 255, 245, 232;

  --low: 255, 223, 13;
  --low--80: 255, 229, 61;
  --low--75: 255, 231, 74;
  --low--60: 255, 236, 110;
  --low--50: 255, 239, 134;
  --low--40: 255, 242, 158;
  --low--25: 255, 247, 195;
  --low--20: 255, 249, 207;
  --low--10: 255, 252, 231;

  --minimal: 67, 217, 136;
  --minimal--80: 105, 225, 160;
  --minimal--75: 114, 227, 166;
  --minimal--60: 142, 232, 184;
  --minimal--50: 161, 236, 196;
  --minimal--40: 180, 240, 207;
  --minimal--25: 208, 246, 225;
  --minimal--20: 217, 247, 231;
  --minimal--10: 236, 251, 243;

  --gradientColor1: 0, 170, 233;
  --gradientColor2: 32, 114, 159;
  --gradient-primary: linear-gradient(90deg, #00AAE9, #20729F);
  --gradient-secondary: linear-gradient(90deg, #ddeef4, #e0e8ed);

  // specific attack scenario colors that are not affected by theming
  --attackScenario-lpe: 0, 51, 51;
  --attackScenario-lpe--30: 194, 206, 206;
  --attackScenario-clientSideAttack: 65, 121, 106;
  --attackScenario-clientSideAttack--30: 210, 223, 219;
  --attackScenario-rce: 130, 166, 125;
  --attackScenario-rce--30: 225, 234, 224;
  --attackScenario-mim: 212, 170, 87;
  --attackScenario-mim--30: 245, 235, 215;
  --attackScenario-credentialTheft: 255, 140, 0;
  --attackScenario-credentialTheft--30: 255, 228, 194;
  --attackScenario-exploitedVulnerabilities: 247, 104, 91;
  --attackScenario-exploitedVulnerabilities--30: 253, 219, 216;

  // specific filter colors that are not affected by theming
  --filter-teal: 26, 188, 156;
  --filter-blue: 52, 152, 219;
  --filter-purple: 155, 89, 182;
  --filter-red: 231, 76, 60;
}

$primary: var(--primary);
$textColor-primary: var(--text-color-primary);
$textColor-secondary: var(--text-color-secondary);

$gradientColor1: var(--gradientColor1);
$gradientColor2: var(--gradientColor2);
$gradient-primary: var(--gradient-primary);
$gradient-secondary: var(--gradient-secondary);

$backgroundColor: var(--background-color);
$backgroundColor-secondary: var(--background-color-secondary);
$dividerColor: var(--divider-color);
$foregroundColor: var(--foreground-color);
$table-primary: var(--foreground-color);
$table-secondary: var(--table-secondary);
$iconColor-primary: var(--icon-color-primary);
$iconColor-secondary: var(--icon-color-secondary);
$linkColor: var(--link-color);

$borderRadius: var(--border-radius);
$borderRadius-input: var(--border-radius-input);
$borderRadius-full: var(--border-radius-full);
$borderRadius-button: var(--border-radius-button);

$boxShadow: var(--box-shadow);
$boxShadow-input: var(--box-shadow-input);
$boxShadow-heavy: var(--box-shadow-heavy);
$boxShadow-light: var(--box-shadow-light);

$status-info: var(--status-info);
$status-success: var(--status-success);
$status-warning: var(--status-warning);
$status-moderate: var(--status-moderate);
$status-alert: var(--status-alert);

$critical: var(--critical);
$high: var(--high);
$moderate: var(--moderate);
$low: var(--low);
$minimal: var(--minimal);

// tint variants of status colors
$status-info--80: var(--status-info--80);
$status-info--75: var(--status-info--75);
$status-info--60: var(--status-info--60);
$status-info--50: var(--status-info--50);
$status-info--40: var(--status-info--40);
$status-info--25: var(--status-info--25);
$status-info--20: var(--status-info--20);
$status-info--10: var(--status-info--10);

$status-success--80: var(--status-success--80);
$status-success--75: var(--status-success--75);
$status-success--60: var(--status-success--60);
$status-success--50: var(--status-success--50);
$status-success--40: var(--status-success--40);
$status-success--25: var(--status-success--25);
$status-success--20: var(--status-success--20);
$status-success--10: var(--status-success--10);

$status-warning--80: var(--status-warning--80);
$status-warning--75: var(--status-warning--75);
$status-warning--60: var(--status-warning--60);
$status-warning--50: var(--status-warning--50);
$status-warning--40: var(--status-warning--40);
$status-warning--25: var(--status-warning--25);
$status-warning--20: var(--status-warning--20);
$status-warning--10: var(--status-warning--10);

$status-moderate--80: var(--status-moderate--80);
$status-moderate--75: var(--status-moderate--75);
$status-moderate--60: var(--status-moderate--60);
$status-moderate--50: var(--status-moderate--50);
$status-moderate--40: var(--status-moderate--40);
$status-moderate--25: var(--status-moderate--25);
$status-moderate--20: var(--status-moderate--20);
$status-moderate--10: var(--status-moderate--10);

$status-alert--80: var(--status-alert--80);
$status-alert--75: var(--status-alert--75);
$status-alert--60: var(--status-alert--60);
$status-alert--50: var(--status-alert--50);
$status-alert--40: var(--status-alert--40);
$status-alert--25: var(--status-alert--25);
$status-alert--20: var(--status-alert--20);
$status-alert--10: var(--status-alert--10);

// tint variants of risk colors
$critical--80: var(--critical--80);
$critical--75: var(--critical--75);
$critical--60: var(--critical--60);
$critical--100: var(--critical--50);
$critical--40: var(--critical--40);
$critical--25: var(--critical--25);
$critical--20: var(--critical--20);
$critical--10: var(--critical--10);

$high--80: var(--high--80);
$high--75: var(--high--75);
$high--60: var(--high--60);
$high--100: var(--high--50);
$high--40: var(--high--40);
$high--25: var(--high--25);
$high--20: var(--high--20);
$high--10: var(--high--10);

$moderate--80: var(--moderate--80);
$moderate--75: var(--moderate--75);
$moderate--60: var(--moderate--60);
$moderate--100: var(--moderate--50);
$moderate--40: var(--moderate--40);
$moderate--25: var(--moderate--25);
$moderate--20: var(--moderate--20);
$moderate--10: var(--moderate--10);

$low--80: var(--low--80);
$low--75: var(--low--75);
$low--60: var(--low--60);
$low--100: var(--low--50);
$low--40: var(--low--40);
$low--25: var(--low--25);
$low--20: var(--low--20);
$low--10: var(--low--10);

$minimal--80: var(--minimal--80);
$minimal--75: var(--minimal--75);
$minimal--60: var(--minimal--60);
$minimal--100: var(--minimal--50);
$minimal--40: var(--minimal--40);
$minimal--25: var(--minimal--25);
$minimal--20: var(--minimal--20);
$minimal--10: var(--minimal--10);

// tint variants of primary
$primary--80: var(--primary--80);
$primary--75: var(--primary--75);
$primary--60: var(--primary--60);
$primary--100: var(--primary--50);
$primary--40: var(--primary--40);
$primary--25: var(--primary--25);
$primary--20: var(--primary--20);
$primary--10: var(--primary--10);

// specific attack scenario colors that are not affected by theming
$attackScenario-lpe: var(--attackScenario-lpe);
$attackScenario-lpe--30: var(--attackScenario-lpe--30);
$attackScenario-clientSideAttack: var(--attackScenario-clientSideAttack);
$attackScenario-clientSideAttack--30: var(--attackScenario-clientSideAttack--30);
$attackScenario-rce: var(--attackScenario-rce);
$attackScenario-rce--30: var(--attackScenario-rce--30);
$attackScenario-mim: var(--attackScenario-mim);
$attackScenario-mim--30: var(--attackScenario-mim--30);
$attackScenario-credentialTheft: var(--attackScenario-credentialTheft);
$attackScenario-credentialTheft--30: var(--attackScenario-credentialTheft--30);
$attackScenario-exploitedVulnerabilities: var(--attackScenario-exploitedVulnerabilities);
$attackScenario-exploitedVulnerabilities--30: var(--attackScenario-exploitedVulnerabilities--30);

// specific filter colors that are not affected by theming
$filter-teal: var(--filter-teal);
$filter-blue: var(--filter-blue);
$filter-purple: var(--filter-purple);
$filter-red: var(--filter-red);

// // specific attack scenario colors that are not affected by theming
// $attackScenario-lpe: 0, 51, 51;
// $attackScenario-lpe--30: 194, 206, 206;
// $attackScenario-clientSideAttack: 65, 121, 106;
// $attackScenario-clientSideAttack--30: 210, 223, 219;
// $attackScenario-rce: 130, 166, 125;
// $attackScenario-rce--30: 225, 234, 224;
// $attackScenario-mim: 212, 170, 87;
// $attackScenario-mim--30: 245, 235, 215;
// $attackScenario-credentialTheft: 255, 140, 0;
// $attackScenario-credentialTheft--30: 255, 228, 194;
// $attackScenario-exploitedVulnerabilities: 247, 104, 91;
// $attackScenario-exploitedVulnerabilities--30: 253, 219, 216;

// // specific filter colors that are not affected by theming
// $filter-teal: 26, 188, 156;
// $filter-blue: 52, 152, 219;
// $filter-purple: 155, 89, 182;
// $filter-red: 231, 76, 60;

.inlineLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: $borderRadius-button;
  padding: 0.375em 0.75em;
  background: rgb( $primary--10 );
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  color: rgb( $primary );
  transition: all 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
    background: rgb( $primary--25 );
  }
  &.newTabLink {
    justify-content: space-between;
    .inlineSVG {
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      margin-left: 0.5em;
      path {
        fill: rgb( $primary );
      }
    }
  }
  &.inline {
    display: inline;
    justify-content: flex-start;
    width: unset;
    padding: 0.375em 0;
    margin: 0.25em;
    background: none;
    .inlineSVG {
      margin-left: 0.25em;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
    }
  }
  &.darkMode {
    background: none;
    border: 1px solid rgb( $primary );
    color: rgb( $primary );
    &:hover {
      background: rgb( $primary );
      color: rgb( $textColor-secondary );
    }
  }
}

@mixin tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: $borderRadius;
  background: rgb( $dividerColor );
  padding: 0.5em 1em;
  border: 1px solid rgb( $textColor-secondary );
  color: rgb( $textColor-secondary );
}

// mixins
@mixin dsButton($type: 'none') {
  line-height: 1.5em;
  display: flex;
  color: #fff;
  text-align: center;
  padding: .375em 1em;
  border-radius: $borderRadius-input;
  margin: 0;
  border: none;
  cursor: pointer;
  // transition: all 0.3s ease-in-out;
  font-weight: 600;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
  .inlineSVG {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.25em;
  }
  span {
    line-height: 1.5em;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  @if $type == primary {
    background: rgb( $primary );
    opacity: 1;
    line-height: 1.5em;
    &:hover {
      background: rgb( $primary--10 );
    }
    &:disabled, &.disabled {
      background: rgb( $primary );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == primary-darkMode {
    background: rgba( $primary, 0.85 );
    line-height: 1.5em;
    color: rgb( $textColor-secondary );
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgba( $primary, 0.7 );
    }
    &:disabled, &.disabled {
      background:  rgba( $primary, 0.4 );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-primary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == critical-darkMode {
    background: rgba( $critical, 0.85 );
    line-height: 1.5em;
    color: rgb( $textColor-primary );
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgba( $critical, 0.7 );
    }
    &:disabled, &.disabled {
      background:  rgba( $critical, 0.4 );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-primary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == high-darkMode {
    background: rgba( $high, 0.85 );
    line-height: 1.5em;
    color: rgb( $textColor-primary );
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgba( $high, 0.7 );
    }
    &:disabled, &.disabled {
      background:  rgba( $high, 0.4 );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-primary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == moderate-darkMode {
    background: rgba( $moderate, 0.85 );
    line-height: 1.5em;
    color: rgb( $textColor-secondary );
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgba( $moderate, 0.7 );
    }
    &:disabled, &.disabled {
      background:  rgba( $moderate, 0.4 );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-secondary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == low-darkMode {
    background: rgba( $low, 0.85 );
    line-height: 1.5em;
    color: rgb( $textColor-secondary );
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgba( $low, 0.7 );
    }
    &:disabled, &.disabled {
      background:  rgba( $low, 0.4 );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-secondary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-darkMode {
    background: rgba( $minimal, 0.85 );
    line-height: 1.5em;
    color: rgb( $textColor-primary );
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgba( $minimal, 0.7 );
    }
    &:disabled, &.disabled {
      background:  rgba( $minimal, 0.4 );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-primary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == secondary {
    background: rgb( $textColor-secondary );
    line-height: 1.5em;
    color: #fff;
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgb( $iconColor-primary );
    }
    &:disabled, &.disabled {
      background: rgb( $textColor-secondary );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal {
    background: none;
    border: none;
    color: rgb( $textColor-primary );
    padding: 0.375em 1em;
    opacity: 0.7;
    line-height: 1.5em;
    &:hover {
      opacity: 1;
      background: rgb( $dividerColor );
      box-shadow: none;
      color: rgb( $textColor-primary );
    }
    &:focus {
      opacity: 1;
      background: rgb( $dividerColor );
      box-shadow: none;
      color: rgb( $textColor-primary );
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.2;
      cursor: default;
      pointer-events: none;
      color: rgb( $textColor-secondary );
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-primary {
    color: rgb( $primary );
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $primary--10 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    line-height: 1.5em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $primary );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgb( $primary--25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $primary--10 );
      cursor: default;
      pointer-events: none;
      color: rgb( $primary );
      opacity: 0.4;
      &:hover {
        background: rgb( $primary--10 );
        box-shadow: none;
      }
    }
  } @else if $type == minimal-high {
    line-height: 1.5em;
    color: rgb( $high );
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $high--10 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $high );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgb( $high--25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $high--10 );
      cursor: default;
      pointer-events: none;
      color: rgb( $high );
      opacity: 0.4;
      &:hover {
        background: rgb( $high--10 );
        box-shadow: none;
      }
    }
  } @else if $type == minimal-critical {
    line-height: 1.5em;
    color: rgb( $critical );
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $critical--10 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $critical );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgb( $critical--25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $critical--10 );
      cursor: default;
      pointer-events: none;
      color: rgb( $critical );
      opacity: 0.4;
      &:hover {
        background: rgb( $critical--10 );
        box-shadow: none;
      }
    }
  } @else if $type == minimal-moderate {
    line-height: 1.5em;
    color: rgb( $moderate );
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $moderate--10 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $moderate );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgb( $moderate--25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $moderate--10 );
      cursor: default;
      pointer-events: none;
      color: rgb( $moderate );
      opacity: 0.4;
      &:hover {
        background: rgb( $moderate--10 );
        box-shadow: none;
      }
    }
  } @else if $type == minimal-low {
    line-height: 1.5em;
    color: rgb( $low );
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $low--10 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $low );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgb( $low--25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $low--10 );
      cursor: default;
      pointer-events: none;
      color: rgb( $low );
      opacity: 0.4;
      &:hover {
        background: rgb( $low--10 );
        box-shadow: none;
      }
    }
  } @else if $type == minimal-minimal {
    line-height: 1.5em;
    color: rgb( $minimal );
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $minimal--10 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $minimal );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgb( $minimal--25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $minimal--10 );
      cursor: default;
      pointer-events: none;
      color: rgb( $minimal );
      opacity: 0.4;
      &:hover {
        background: rgb( $minimal--10 );
        box-shadow: none;
      }
    }
  } @else if $type == minimal-default {
    line-height: 1.5em;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgb( $textColor-primary );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: #fff;
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba($textColor-primary, 0.6);
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $dividerColor );
      cursor: default;
      pointer-events: none;
      color: #fff;
      &.next {
        margin-left: 1em !important;
      }
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == minimal-dark {
    color: rgb( $textColor-primary );
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    background: rgba( $textColor-primary, 0.1 );
    transition: all 0.3s ease-in-out;
    padding: 0.375em 1em;
    .inlineSVG {
      width: 1.125em;
      height: 1.125em;
      path {
        fill: rgb( $textColor-primary );
      }
    }
    span {
      display: block;
      margin: 0 0.25em;
    }
    &:hover {
      background: rgba( $textColor-primary, 0.25 );
    }
    &:focus {
      outline: none;
    }
    &:disabled, &.disabled {
      background: rgb( $dividerColor );
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == outline-dark {
    background: none;
    border: 1px solid rgb( $textColor-primary );
    color: rgb( $textColor-primary );
    &:hover {
      color: rgb( $textColor-primary );
      opacity: 0.7;
    }
    &:focus {
      color: rgb( $textColor-primary );
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == outline-add {
    background: none;
    border: 1px solid rgb( $status-success );
    color: rgb( $status-success );
    .inlineSVG {
      path {
        fill: rgb( $status-success );
      }
    }
    &:hover {
      color: rgb( $status-success );
      opacity: 0.7;
    }
    &:focus {
      color: rgb( $status-success );
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == outline-remove {
    background: none;
    border: 1px solid rgb( $status-alert );
    color: rgb( $status-alert );
    .inlineSVG {
      path {
        fill: rgb( $status-alert );
      }
    }
    &:hover {
      color: rgb( $status-alert );
      opacity: 0.7;
    }
    &:focus {
      color: rgb( $status-alert );
    }
    &:disabled, &.disabled {
      background: none;
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == destructive {
    background: rgb( $textColor-secondary );
    color: #fff;
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      background: rgb( $iconColor-primary );
    }
    &:disabled, &.disabled {
      background: rgb( $textColor-secondary );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == status-success {
    background: rgb( $status-success );
    &:hover {
      background: rgb( $status-success--50 );
    }
    &:focus {
      background: rgb( $status-success );
    }
    &:disabled, &.disabled {
      background: rgb( $status-success );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == status-alert {
    background: rgb( $status-alert );
    &:hover {
      background: rgb( $status-alert--40 );
    }
    &:focus {
      background: rgb( $status-alert );
    }
    &:disabled, &.disabled {
      background: rgb( $status-alert );
      opacity: 0.15;
      cursor: default;
      pointer-events: none;
      color: #fff;
      &:hover {
        box-shadow: none;
      }
    }
  } @else if $type == none {
    background: rgb( $foregroundColor );
    color: rgb( $textColor-primary );
    &:hover {
      box-shadow: 0px 0px 0px 3px rgba($primary, 0.4);
    }
  } @else if $type == disabled {
    background: rgb( $primary );
    opacity: 0.15;
    cursor: default;
    pointer-events: none;
    color: #fff;
    &:hover {
      box-shadow: none;
    }
  }
}

.toggleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: $borderRadius-input;
  background: rgb( $dividerColor );
  padding: 0.25em;
  // border: 1px solid rgb( $iconColor-primary );
  button {
    background: none;
    color: rgb( $textColor-primary );
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em 1.5em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    border: none;
    color: rgb( $textColor-primary );
    margin: 0em;
    border-radius: $borderRadius;
    margin-right: 0.25em;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      transition: all 0.3s ease-in-out;
      path {
        transition: all 0.3s ease-in-out;
        fill: rgb( $iconColor-primary );
      }
    }
    &:hover {
      background: rgb( $foregroundColor );
      color: rgb( $textColor-primary );
      .inlineSVG {
        path {
          fill: rgb( $primary );
        }
      }
    }
    &.toggled {
      background: rgb( $textColor-primary );
      color: #fff;
      box-shadow: $boxShadow-input;
      z-index: 1;
      .inlineSVG {
        path {
          fill: rgb( $primary );
        }
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &.darkMode {
    background: rgba( #fff, 0.2 );
    button {
      &.toggled {
        background: rgba( $primary, 0.85 );
        color: rgb( $textColor-secondary );
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }
    }
  }
 }
