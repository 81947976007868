/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetails__MainPanel__Body_PathAnalysis.path {
  .pathDetailVisual {
    position: relative;
    .fullScreenVisualToggleButton {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      z-index: 1;
    }
  }
  .pathSVGContainer {
    padding: 1em;
    background: rgb( $backgroundColor );
    border-radius: $borderRadius;
    svg {
      max-height: 14em;
      &.svgHeight-1 {
        max-height: 8em;
      }
      .graphActions {
        right: 1em;
        bottom: 1em;
      }
    }
  }
  .pathAnalysisContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2em;
    background: rgb( $foregroundColor );
    border-radius: $borderRadius;
    h3 {
      font-size: 1em;
      columns: $textColor-primary;
      margin-bottom: 1em;
    }
    .description {
      padding: 0.5em 1em;
      background: rgb( $backgroundColor );
      border-radius: $borderRadius;
      width: 100%;
    }
    .edgeDescriptionList {
      width: 100%;
      .edgeDescriptionItem {
        cursor: default;
        background: rgb( $foregroundColor );
        padding: 0.75em 1em;
        border-radius: $borderRadius;
        margin-bottom: 0em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        &:hover {
          background: rgb( $backgroundColor );
          &.risk-nofix {
            h2 {
              .inlineSVG {
                path {
                  fill: rgb( $textColor-primary );
                }
              }
            }
          }
          .timelineColumn {
            svg, .inlineSVG {
              opacity: 1;
            }
          }
          .contentColumn {
            &:before {
              opacity: 1;
            }
          }
        }
        .timelineColumn {
          z-index: 2;
          .nodeIconWrapper {
            width: 2.5em;
            height: 2.5em;
            border-radius: 9999em;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background: rgb( $backgroundColor );
            margin: 0;
            padding: 0;
            margin-top: -0.625em;
            .recordTypeHeaderIcon {
              width: 1.5em;
              height: 1.5em;
            }
            &.adversary {
              background: rgb( $status-alert--10 );
              .recordTypeHeaderIcon {
                width: 1.75em;
                height: 1.75em;
              }
            }
          }
        }
        .contentColumn {
          padding-left: 1em;
          position: relative;
          width: 100%;
          z-index: 1;
          padding-bottom: 1em;
          h2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-weight: 400;
            font-size: 1em;
            color: rgb( $textColor-primary );
            margin-bottom: 0.75em;
            strong {
              margin-right: 0.25em;
            }
          }
          p {
            color: rgb( $textColor-primary );
            font-size: 1em;
            // margin-bottom: 0;
            line-height: 1.5em;
            background: rgb( $backgroundColor-secondary );
            border: 1px solid rgb( $iconColor-primary );
            border-radius: $borderRadius;
            margin-bottom: 1em;
            padding: 0.75em 1em;
            display: block;
            width: 100%;
            strong {
              &.vulnDescription {
                text-transform: lowercase;
              }
            }
            a {
              &.risk-critical {
                @include dsButton( 'minimal-critical' );
                display: inline-flex;
                margin-left: 0.5em;
                width: unset;
                .inlineSVG {
                  path {
                    fill: rgb( $critical );
                  }
                }
              }
              &.risk-high {
                @include dsButton( 'minimal-high' );
                display: inline-flex;
                margin-left: 0.5em;
                width: unset;
                .inlineSVG {
                  path {
                    fill: rgb( $high );
                  }
                }
              }
              &.risk-moderate {
                @include dsButton( 'minimal-moderate' );
                display: inline-flex;
                margin-left: 0.5em;
                width: unset;
                .inlineSVG {
                  path {
                    fill: rgb( $moderate );
                  }
                }
              }
              &.risk-low {
                @include dsButton( 'minimal-low' );
                display: inline-flex;
                margin-left: 0.5em;
                width: unset;
                .inlineSVG {
                  path {
                    fill: rgb( $low );
                  }
                }
              }
              &.risk-minimal {
                @include dsButton( 'minimal-minimal' );
                display: inline-flex;
                margin-left: 0.5em;
                width: unset;
                .inlineSVG {
                  path {
                    fill: rgb( $low );
                  }
                }
              }
            }
          }
          &:before {
            content: "";
            position: absolute;
            left: calc( ( 1.25em / -1 ) - 1.5px );
            top: 1.25em;
            height: calc( 100% + 0.25em );
            width: 3px;
            background: rgb( $textColor-secondary );
            opacity: 0.4;
          }
          .alertIcon {
            position: absolute;
            left: -4.5em;
            top: -0.25em;
            path {
              fill: rgb( $status-alert );
            }
          }
        }
        // the first item in the list has an extra icon for the attacker
        &:first-child {
          .contentColumn {
            position: relative;
            .attackerIcon {
              position: absolute;
              top: 0;
              left: -1.375em;
              width: 1.5em;
              height: 1.5em;
            }
          }
        }
        // the pretend last item, really the second to last item
        &.lastItem {
          .timelineColumn {
            position: relative;
            .nodeTypeIcon {
              width: 1.5em;
              height: 1.5em;
            }
            .warningIcon {
              position: absolute;
              width: 1.5em;
              height: 1.5em;
              right: -0.75em;
              top: -0.75em;
              opacity: 1;
              path {
                fill: rgb( $status-alert );
              }
            }
          }
        }
        // the actual last item that just has a label, no description
        &.risk-critical {
          // .timelineColumn {
          //   .inlineSVG {
          //     path {
          //       fill: rgb( $critical );
          //     }
          //   }
          // }
          .contentColumn {
            &:before {
              background: rgb( $critical );
            }
          }
        }
        &.risk-high {
          // .timelineColumn {
          //   .inlineSVG {
          //     path {
          //       fill: rgb( $high );
          //     }
          //   }
          // }
          .contentColumn {
            &:before {
              background: rgb( $high );
            }
          }
        }
        &.risk-moderate {
          // .timelineColumn {
          //   .inlineSVG {
          //     path {
          //       fill: rgb( $moderate );
          //     }
          //   }
          // }
          .contentColumn {
            &:before {
              background: rgb( $moderate );
            }
          }
        }
        &.risk-low {
          // .timelineColumn {
          //   .inlineSVG {
          //     path {
          //       fill: rgb( $low );
          //     }
          //   }
          // }
          .contentColumn {
            &:before {
              background: rgb( $low );
            }
          }
        }
        &.risk-minimal {
          // .timelineColumn {
          //   .inlineSVG {
          //     path {
          //       fill: rgb( $low );
          //     }
          //   }
          // }
          .contentColumn {
            &:before {
              background: rgb( $low );
            }
          }
        }
        &.risk-primary {
          // .timelineColumn {
          //   .inlineSVG {
          //     path {
          //       fill: rgb( $primary );
          //     }
          //   }
          // }
          .contentColumn {
            &:before {
              background: rgb( $primary );
            }
          }
        }
        &:last-child {
          .contentColumn {
            &:before {
              display: none;
            }
            p {
              background: rgb( $status-alert--10 );
              border: 1px solid rgb( $status-alert );
            }
          }
        }
      }
    }
  }
}
