/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.setupRecordItem.cloud_scanner.item.alternateLayout {
  grid-template-columns: 4fr 1fr !important;
  section.mainDetailsSection {
    .column {
      border-left: 1px solid rgb( $dividerColor );
      padding-left: 1em;
      span {
        color: rgb( $textColor-secondary );
      }
    }
  }
}