/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.acsURL {
  .URLWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgb( $backgroundColor );
    border-radius: $borderRadius-input;
    padding: 0.5em;
    width: 100%;
    pre {
      padding: 0;
      border: none;
      background: none;
      color: rgb( $textColor-primary );
      margin: 0;
      white-space: nowrap;
    }
    input[type="text"] {
      background: rgb( $dividerColor );
      border: 1px solid rgb( $iconColor-primary );
      border-radius: $borderRadius-input;
      color: rgb( $textColor-primary );
      width: auto;
      margin: 0 0.25em;
      padding: 0.25em 0.75em;
      margin-top: 0;
    }
  }
}
