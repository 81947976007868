/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;
ul.setupRecordsList {
  .historyItemStatusWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 1em;
    border: 1px solid rgb( $textColor-secondary );
    background: rgb( $backgroundColor );
    border-radius: $borderRadius-full;
    padding: 0.25em 0.75em 0.25em 0.5em;
    span {
      color: rgb( $textColor-secondary ) !important;
    }
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
    }
    button {
      padding: 0;
      margin: 0;
      background: 0;
      font-weight: 600;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-decoration: underline;
    }
    &.running {
      border: 1px solid rgb( $status-info );
      background: rgb( $status-info--10 );
      span {
        color: rgb( $status-info ) !important;
      }
      .inlineSVG {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        path {
          fill: rgb( $status-info );
        }
      }
    }
    &.queued {
      .inlineSVG {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
    }
    &.completed {
      border: 1px solid rgb( $status-success );
      background: rgb( $status-success--10 );
      span {
        color: rgb( $status-success ) !important;
      }
      .inlineSVG {
        path {
          fill: rgb( $status-success );
        }
      }
      &.withWarnings {
        border: 1px solid rgb( $status-alert );
        background: rgb( $status-alert--10 );
        span {
          color: rgb( $status-alert ) !important;
        }
        .inlineSVG {
          path {
            fill: rgb( $status-alert );
          }
        }
        button {
          color: rgb( $status-alert );
        }
      }
    }
    &.incomplete {
      border: 1px solid rgb( $status-alert );
      background: rgb( $status-alert--10 );
      span {
        color: rgb( $status-alert ) !important;
      }
      .inlineSVG {
        path {
          fill: rgb( $status-alert );
        }
      }
      &.withWarnings {
        .inlineSVG {
          path {
            fill: rgb( $status-alert );
          }
        }
        button {
          color: rgb( $status-alert );
        }
      }
    }
  }
  li.setupRecordItem.task_item.alternateLayout {
    grid-template-columns: 1fr 1fr 1fr 11em;
    section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1em;
      label {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 1em;
      }
      strong, span {
        font-size: 1em;
        color: rgb( $textColor-primary );
        margin: 0;
      }
      strong {
        font-weight: 600;
      }
      span {
        font-weight: 400;
        &.sectionDetails {
          margin: 0;
          strong {
            margin-right: 0.25em;
          }
          span {
            color: rgb( $textColor-secondary );
          }
          li {
            margin-bottom: 0.5em;
          }
        }
      }
      .sectionDetails {
        ul {
          li {
            &.taskStatus {
              display: flex;
              flex-direction: row;
              align-items: center;
              strong {
                margin-right: 0.5em;
              }
            }
          }
        }
      }
    }
    h2 {
      font-size: 1.125em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .helpTrigger {
        margin: 0;
        margin-left: 0.25em;
        width: 1em;
        height: 1em;
        .inlineSVG {
          width: 1em;
          height: 1em;
        }
      }
    }
    .actionAndDuration {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 1em;
      span {
        strong {
          margin-right: 0.25em;
        }
        span {
          color: rgb( $textColor-secondary );
        }
      }
    }
    .jobHistoryButton {
      @include dsButton( 'minimal' );
      font-size: 0.875em;
      align-self: flex-end;
    }
    .jobHistorySection {
      grid-column: 1 / 5;
      padding: 0;
      margin: 0;
      border: none;

      margin: 2em -1em 0 -1em;
      border-top: 1px solid rgb( $dividerColor );

      .dataTable {
        .tableRow {
          grid-template-columns: 17em 1fr 16em 7em 10em;
          .tableCell {
            .historyItemStatusWrapper {
              width: 100%;
              padding: 0;
              border: none;
              background: none;
              span {
                color: rgb( $textColor-primary ) !important;
              }
            }
            li {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              margin-right: 0.5em;
              margin-bottom: 0.25em;
              strong {
                margin-right: 0.25em;
              }
              &:after {
                content: ',';
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
            .recordTypeHeaderIcon {
              display: block;
              width: 1.25em;
              height: 1.25em;
              margin-right: 0.25em;
            }
            .showWarningsButton {
              @include dsButton( 'minimal-high' );
            }
          }
        }
      }
    }
  }
  .itemHistoryWarningShade {
    width: 100vw;
    height: 100vh;
    z-index: 999998;
    background: rgba( $textColor-primary, 0.1);
    top: 0;
    left: 0;
    position: fixed;
  }
  .itemHistoryWarningsWrapper {
    position: fixed;
    padding: 1em;
    border-radius: $borderRadius-input;
    box-shadow: $boxShadow;
    background: rgb( $foregroundColor );
    z-index: 999999;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        .inlineSVG {
          margin-right: 0.5em;
          width: 1.25em;
          height: 1.25em;
          path {
            fill: rgb( $status-alert );
          }
        }
      }
    }
    li {
      font-size: 1em;
      line-height: 1.25em;
    }
  }
}
