/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.exportMenuTrigger {
  @include dsButton( 'minimal-dark' );
  margin-right: 3.5em;
  span {
    margin: 0;
    margin-left: 0.5em;
  }
  .inlineSVG {
    width: 1.25em;
    height: 1.25em;
    path {
      fill: rgb( $textColor-primary );
    }
  }
  &:disabled {
    background: rgb( $backgroundColor ) !important;
    color: rgb( $textColor-secondary ) !important;
    .inlineSVG {
      path {
        fill: rgb( $textColor-secondary ) !important;
      }
    }
  }
}

.riskInsightTableAndVisualWrapper {
  flex-grow: 1;
  border-radius: $borderRadius-input;
  background: rgb( $foregroundColor );
  .riskInsightTableHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border-bottom: 1px solid rgb( $dividerColor );
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      .recordIconWrapper {
        width: 2.25em;
        height: 2.25em;
        background: rgb( $backgroundColor );
        border-radius: $borderRadius-full;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-right: 0.5em;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
      h2 {
        font-size: 1.125em;
        font-weight: 400;
        padding: 0;
        margin: 0;
        margin-right: 0.5em;
        color: rgb( $textColor-primary );
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        strong {
          margin-left: 0.25em;
          font-weight: 600;
        }
      }
    }
    .right {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .toggleWrapper, .fieldWrapper, button {
        flex-shrink: 0;
      }
      .fieldWrapper {
        margin-bottom: 0;
        flex-shrink: 0  ;
      }
      .selectV2Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-right: 1em;
        flex-shrink: 0;
        span.labelWrapper {
          flex-shrink: 0;
          color: rgb( $textColor-primary );
          margin-right: 0.5em;
          width: unset;
          margin-bottom: 0;
        }
      }
      .tableOptionsShade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99999999;
      }
      .tableOptionsMenu {
        position: absolute;
        right: 0em;
        top: 3em;
        color: rgb( $textColor-primary );
        font-family: $sans;
        z-index: 99999999;
        padding: 0.5em;
        border: 1px solid rgba( $iconColor-primary, 0.75 );
        border-radius: $borderRadius-input;
        color: rgb( $textColor-primary );
        box-shadow: $boxShadow;
        background: rgb( $foregroundColor );
        max-height: 50vh;
        overflow: auto;
        width: 18em;
        label {
          font-size: 1em;
          color: rgb( $textColor-primary );
          margin-bottom: 1em;
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            padding: 0.375em 0.5em;
            cursor: pointer;
            transition: all 200ms ease-in-out;
            margin-bottom: 0.375em;
            border-radius: $borderRadius;
            border: 1px solid transparent;
            label {
              cursor: pointer;
              margin: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              .labelWrapper {
                cursor: pointer;
                margin-left: 0.5em;
              }
            }
            &.disabled {
              cursor: not-allowed;
              opacity: 0.5;
              pointer-events: none;
              &:hover {
                background: none;
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            &:hover {
              background: rgb( $backgroundColor );
            }
          }
        }
        &.ordering {
          right: 2.5em;
          width: 26em;
          .formWrapper {
            padding-left: 0.5em;
            .fieldGroupWrapper {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
            }
            .fieldWrapper {
              width: 50%;
              &.select2 {
                margin-bottom: 1em;
                .selectV2Wrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  .labelWrapper {
                    margin-bottom: 0.5em;
                    label {
                      margin-bottom: 0;
                    }
                  }
                  .selectV2Trigger {
                    min-width: unset;
                    width: 100%;
                  }
                }
              }
            }
          }
          button {
            @include dsButton( 'minimal-primary' );
            border-radius: $borderRadius;
            text-align: center;
            justify-content: space-around;
          }
        }
      }
    }
  }
}

.host_riskInsightDataTable {
  // columns:
  // 1. remedation (optional) = 3em
  // 2. risk (optional) = 12.5em
  // 3. name = minmax( 20em, 1fr )
  // 4. sensitive assets = 8em
  // 5. product name (optional) = 14em
  // 6. addresses (optional) = 12.5em
  // 7. all patches = 7em
  // 8. unsuperseded patches = 12em
  // 9. vulnerabilities = 8em
  // 10. last scanned = minmax( 21em, 27em )
  // 11. Agent (optional) = 12em
  // 12a. actions (w/o filter button) = 8em
  // 12b. actions (w/ filter button) = 10em
  .tableRow {
    position: relative;
    grid-template-columns:
      minmax( 20em, 1fr )
      8em
      7em
      12em
      8em
      minmax( 21em, 27em )
      8em;
    &.instanceActionsVersion {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        7em
        12em
        8em
        minmax( 21em, 27em )
        10em;
    }
    .tableCell {
      &.tableCell_name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .assetTagsModalAndListWrapper {
          margin-left: 0.5em;
          ul {
            li {
              padding: 0.375em 1.25em !important;
              line-height: 1.25em !important;
            }
          }
        }
      }
      &.headerCell_sensitive_assets,
      &.headerCell_all_patches,
      &.headerCell_unsuperseded_patches,
      &.headerCell_vulnerabilities,
      &.tableCell_sensitive_assets,
      &.tableCell_all_patches,
      &.tableCell_unsuperseded_patches,
      &.tableCell_vulnerabilities {
        text-align: center;
        span {
          width: 100%;
          display: block;
        }
      }
      &.tableCell_Last, &.headerCell_Last {
        text-align: center;
        justify-content: space-around;
      }
      &.tableCell_actions {
        button {
          flex-shrink: 0;
          &.addFilterButton,
          &.removeFilterButton {
            background: none;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-radius: $borderRadius-full;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-shrink: 0;
            margin-right: 0.5em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
              flex-shrink: 0;
              path {
                fill: rgb( $status-alert );
              }
            }
            &:hover {
              background: rgb( $dividerColor );
            }
          }
          &.addFilterButton {
            .inlineSVG {
              path {
                fill: rgb( $status-success );
              }
            }
          }
        }
      }
    }
    &.tableHeader {
      position: sticky !important;
      position: -webkit-sticky !important;
      .headerCell {
        position: sticky !important;
        position: -webkit-sticky !important;
      }
      .headerCell_DeepSurface {
        border-right: 1px solid #fff;
      }
    }
    // several different classes to account for
    &.includedColumns--risk {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
    }
    &.includedColumns--risk-product_name {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        14em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
    }
    &.includedColumns--risk-product_name-ip_addresses {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        14em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
    }
    &.includedColumns--risk-product_name-ip_addresses-agent_version {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        14em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--risk-product_name-agent_version {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        14em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--risk-ip_addresses-agent_version {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--risk-agent_version {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--product_name-ip_addresses-agent_version {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        14em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--product_name-agent_version {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        14em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--ip_addresses-agent_version {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--agent_version {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        7em
        12em
        8em
        minmax( 21em, 27em )
        12em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          10em;
      }
    }
    &.includedColumns--risk-ip_addresses {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
    }
    &.includedColumns--product_name {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        14em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
    }
    &.includedColumns--product_name-ip_addresses {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        14em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
    }
    &.includedColumns--ip_addresses {
      grid-template-columns:
        minmax( 20em, 1fr )
        8em
        12em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em;
      }
    }
  }
  // order by risk, no risk, include remediation
  &.hasRemediation {
    .tableRow {
      grid-template-columns:
        3em
        minmax( 20em, 1fr )
        8em
        7em
        12em
        8em
        minmax( 21em, 27em )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          10em;
      }
      &.includedColumns--risk {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--risk-product_name {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            14em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--risk-product_name-ip_addresses {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            14em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--risk-ip_addresses {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--product_name {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            14em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--product_name-ip_addresses {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            14em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--ip_addresses {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            10em;
        }
      }
      &.includedColumns--risk-product_name-ip_addresses-agent_version {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            14em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--risk-product_name-agent_version {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            14em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--risk-ip_addresses-agent_version {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--risk-agent_version {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            8em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--product_name-ip_addresses-agent_version {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          14em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            14em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--product_name-agent_version {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          14em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            14em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--ip_addresses-agent_version {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          12em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            12em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
      &.includedColumns--agent_version {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          8em
          7em
          12em
          8em
          minmax( 21em, 27em )
          12em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            8em
            7em
            12em
            8em
            minmax( 21em, 27em )
            12em
            10em;
        }
      }
    }
  }
}

.patch_riskInsightDataTable {
  // columns:
  // 1. remediation (optional) = 3em
  // 2. risk (optional) = 12.5em
  // 3. name = minmax( 20em, 1fr )
  // 4. superseded patches = 10em
  // 5. affected hosts (optional) = 8em
  // 6. vulnerabilities (optional) = 8em
  // 7. description = minmax( 20em, 1fr )
  // 8a. actions (w/o filter button) = 8em
  // 8b. actions (w/ filter button) = 10em
  .tableRow {
    grid-template-columns:
      minmax( 20em, 1fr )
      10em
      8em
      8em
      8em;
    &.instanceActionsVersion {
      grid-template-columns:
        minmax( 20em, 1fr )
        10em
        8em
        8em
        8em
        10em;
    }
    .tableCell {
      &.tableCell_description {
        pre {
          flex-grow: 1;
          padding: 0;
          margin: 0;
          background: none;
          font-family: 'Monospace', monospace;
          font-weight: 600;
        }
      }
      &.headerCell_superseded_patches,
      &.headerCell_affected_hosts,
      &.headerCell_vulnerabilities,
      &.tableCell_superseded_patches,
      &.tableCell_affected_hosts,
      &.tableCell_vulnerabilities {
        text-align: center;
        span {
          width: 100%;
          display: block;
        }
      }
      &.tableCell_actions {
        button {
          flex-shrink: 0;
          &.addFilterButton,
          &.removeFilterButton {
            background: none;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-radius: $borderRadius-full;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-shrink: 0;
            margin-right: 0.5em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
              flex-shrink: 0;
              path {
                fill: rgb( $status-alert );
              }
            }
            &:hover {
              background: rgb( $dividerColor );
            }
          }
          &.addFilterButton {
            .inlineSVG {
              path {
                fill: rgb( $status-success );
              }
            }
          }
        }
      }
    }
    &.tableHeader {
      position: sticky;
      position: -webkit-sticky;
      .headerCell {
        position: sticky;
        position: -webkit-sticky;
      }
      .headerCell_vulnerabilities {
        border-right: 1px solid #fff;
      }
    }
    &.includedColumns--risk {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        10em
        8em
        8em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          10em
          8em
          8em
          10em;
      }
    }
    &.includedColumns--risk-description {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        10em
        8em
        8em
        minmax( 20em, 1fr )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          10em
          8em
          8em
          minmax( 20em, 1fr )
          10em;
      }
    }
    &.includedColumns--description {
      grid-template-columns:
        minmax( 20em, 1fr )
        10em
        8em
        8em
        minmax( 20em, 1fr )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          10em
          8em
          8em
          minmax( 20em, 1fr )
          10em;
      }
    }
  }

  &.hasRemediation {
    .tableRow {
      grid-template-columns:
        3em
        minmax( 20em, 1fr )
        10em
        8em
        8em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          10em
          8em
          8em
          8em
          10em;
      }
      &.includedColumns--risk {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          10em
          8em
          8em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            10em
            8em
            8em
            10em;
        }
      }
      &.includedColumns--risk-description {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          10em
          8em
          8em
          minmax( 20em, 1fr )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            10em
            8em
            8em
            minmax( 20em, 1fr )
            10em;
        }
      }
      &.includedColumns--description {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          10em
          8em
          8em
          minmax( 20em, 1fr )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            10em
            8em
            8em
            minmax( 20em, 1fr )
            10em;
        }
      }
    }
  }
}

.user_riskInsightDataTable {
  // columns:
  // 1. risk = 12.5em
  // 2. name = minmax( 20em, 1fr )
  // 3. recently accessed hosts = 11em
  // 4. domain groups = 8em
  // 5. actions = 4em

  .tableRow {
    position: relative;
    grid-template-columns:
      minmax( 20em, 1fr )
      11em
      8em
      4em;
    .tableCell {
      &.headerCell_recently_accessed_hosts,
      &.headerCell_domain_groups,
      &.tableCell_recently_accessed_hosts,
      &.tableCell_domain_groups {
        text-align: center;
        span {
          width: 100%;
          display: block;
        }
      }
    }
    &.tableHeader {
      position: sticky;
      position: -webkit-sticky;
      .headerCell {
        position: sticky;
        position: -webkit-sticky;
      }
      .headerCell_domain_groups {
        border-right: 1px solid #fff;
      }
    }
    &.includedColumns--risk {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        11em
        8em
        4em;
    }
  }
}

.vulnerability_riskInsightDataTable {
  // columns:
  // 1. risk = 12.5em
  // 2. name = minmax( 20em, 1fr )
  // 3. exploit status = 9em
  // 4. CVSS = 10em
  // 5. affected hosts = 8em
  // 6. all patches = 7em
  // 7. unsuperseded patches = 11em
  // 8. description = minmax( 20em, 1fr )
  // 9a. actions (w/o filter button) = 8em
  // 9b. actions (w/ filter button) = 10em
  .tableRow {
    grid-template-columns:
      minmax( 20em, 1fr )
      9em
      10em
      8em
      7em
      11em
      8em;
    &.instanceActionsVersion {
      grid-template-columns:
        minmax( 20em, 1fr )
        9em
        10em
        8em
        7em
        11em
        10em;
    }
    .tableCell {
      &.tableCell_description {
        pre {
          flex-grow: 1;
          padding: 0;
          margin: 0;
          background: none;
          font-family: 'Monospace', monospace;
          font-weight: 600;
        }
      }
      &.headerCell_affected_hosts,
      &.headerCell_all_patches,
      &.headerCell_unsuperseded_patches,
      &.tableCell_affected_hosts,
      &.tableCell_all_patches,
      &.tableCell_unsuperseded_patches,
      &.headerCell_CVSS
      &.tableCell_CVSS,
      &.tableCell_exploit_status {
        text-align: center;
        span {
          width: 100%;
          display: block;
        }
      }
      &.tableCell_actions {
        button {
          flex-shrink: 0;
          &.addFilterButton,
          &.removeFilterButton {
            background: none;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-radius: $borderRadius-full;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-shrink: 0;
            margin-right: 0.5em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
              flex-shrink: 0;
              path {
                fill: rgb( $status-alert );
              }
            }
            &:hover {
              background: rgb( $dividerColor );
            }
          }
          &.addFilterButton {
            .inlineSVG {
              path {
                fill: rgb( $status-success );
              }
            }
          }
        }
      }
    }
    &.tableHeader {
      position: sticky;
      position: -webkit-sticky;
      .headerCell {
        position: sticky;
        position: -webkit-sticky;
      }
      .headerCell_unsuperseded_patches {
        border-right: 1px solid transparent;
      }
    }
    &.includedColumns--risk {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        9em
        10em
        8em
        7em
        11em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          10em;
      }
    }
    &.includedColumns--risk-description {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        9em
        10em
        8em
        7em
        11em
        minmax( 20em, 1fr )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          12.5em
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          minmax( 20em, 1fr )
          10em;
      }
    }
    &.includedColumns--description {
      grid-template-columns:
        minmax( 20em, 1fr )
        9em
        10em
        8em
        7em
        11em
        minmax( 20em, 1fr )
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          minmax( 20em, 1fr )
          10em;
      }
    }
  }
  &.hasRemediation {
    .tableRow {
      grid-template-columns:
        3em
        minmax( 20em, 1fr )
        9em
        10em
        8em
        7em
        11em
        8em;
      &.instanceActionsVersion {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          10em;
      }
      &.includedColumns--risk {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            9em
            10em
            8em
            7em
            11em
            10em;
        }
      }
      &.includedColumns--risk-description {
        grid-template-columns:
          3em
          12.5em
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          minmax( 20em, 1fr )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            12.5em
            minmax( 20em, 1fr )
            9em
            10em
            8em
            7em
            11em
            minmax( 20em, 1fr )
            10em;
        }
      }
      &.includedColumns--description {
        grid-template-columns:
          3em
          minmax( 20em, 1fr )
          9em
          10em
          8em
          7em
          11em
          minmax( 20em, 1fr )
          8em;
        &.instanceActionsVersion {
          grid-template-columns:
            3em
            minmax( 20em, 1fr )
            9em
            10em
            8em
            7em
            11em
            minmax( 20em, 1fr )
            10em;
        }
      }
    }
  }
}

.path_riskInsightDataTable {
  // columns:
  // 1. risk = 12.5em
  // 2. name = minmax( 20em, 1fr )
  // 3. steps = 8em
  // 4. actions = 4em
  .tableRow {
    position: relative;
    grid-template-columns:
      1fr
      8em
      4em;

    &.tableHeader {
      position: sticky;
      position: -webkit-sticky;
      .headerCell {
        position: sticky;
        position: -webkit-sticky;
      }
      .headerCell_steps {
        border-right: 1px solid #fff;
      }
    }
    .headerCell_steps, .tableCell_steps {
      align-items: center;
      text-align: center;
      span {
        display: block;
        width: 100%;
        align-items: center;
        text-align: center;
      }
    }
    &.includedColumns--risk {
      grid-template-columns:
        12.5em
        minmax( 20em, 1fr )
        8em
        4em;
    }
  }
}

.signature_riskInsightDataTable {
  // columns:
  // 1. risk = 12.5em
  // 2. scanner signature = 1fr
  // 3. name = 2fr
  // 4. hosts = 6em
  // 5. vulns = 6em
  // 6. actions = 7.5em

  .tableRow {
    grid-template-columns:
      1fr
      1.25fr
      6em
      6em
      7.5em;
    &.includedColumns--risk {
      grid-template-columns:
        12.5em
        1fr
        1.25fr
        6em
        6em
        7.5em;
    }
    .tableCell {
      &.tableCell_actions {
        button {
          flex-shrink: 0;
          &.addFilterButton,
          &.removeFilterButton {
            background: none;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-radius: $borderRadius-full;
            width: 2em;
            height: 2em;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-shrink: 0;
            margin-right: 0.5em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
              flex-shrink: 0;
              path {
                fill: rgb( $status-alert );
              }
            }
            &:hover {
              background: rgb( $dividerColor );
            }
          }
          &.addFilterButton {
            .inlineSVG {
              path {
                fill: rgb( $status-success );
              }
            }
          }
        }
      }
    }
  }
}

.paginationContainer.indeterminantPagination.riskInsightPagination {
  border-left: none;
  padding-left: 0;
  margin-left: 0;
  padding: 1em;
  // justify-content: flex-start;
  border-radius: 0 0 $borderRadius $borderRadius;
  border-top: 1px solid rgb( $dividerColor );
  .selectFieldWrapper {
    margin-right: 1em;
  }
  .nextPreviousButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    button {
      margin-left: 1em;
    }
  }
}

.riskInsightTableWrapper {
  width: 100%;
  flex-grow: 1;
  transition: all 0.15s ease-in-out;
  height: 1em;
  position: relative;
  z-index: 1;
  .fullScreenVisualToggleButton {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 50;
  }
  .dataTable.riskInsightDataTable {
    .tableRow {
      .tableCell_risk {
        // width: 14em;
        .rowRiskWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
      .tableCell_name {
        font-weight: 600;
        span.missingItemName {
          color: rgb( $textColor-secondary );
        }
      }
      .tableCell_scanner_signature {
        .relatedSignatureButton {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          border-radius: $borderRadius-input;
          padding: 0.5em 0.75em;
          border: 1px solid rgb( $dividerColor );
          background: rgb( $foregroundColor );
          cursor: pointer;
          width: 100%;
          transition: all 0.3s ease-in-out;
          .recordTypeHeaderIcon {
            transition: all 0.3s ease-in-out;
            width: 1.5em;
            height: 1.5em;
            margin-right: 0.5em;
          }
          .name {
            width: 100%;
            overflow: hidden;
            // white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
          }
          &:hover {
            background: rgb( $backgroundColor );
            border: 1px solid rgb( $iconColor-primary );
          }
        }
      }
      .tableCell_description {
        max-height: 5em;
        overflow: hidden;
        align-items: flex-start;
      }
      .tableCell_last_scanned {
        color: rgb( $textColor-secondary );
      }
      .tableCell_sensitive_assets {
        .sensitiveAssetsCount {
          &.hasAssets {
            color: rgb( $high );
            font-weight: 600;
          }
        }
      }
      .selectCell > button {
        background: none;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: $borderRadius-full;
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        opacity: 0.4;
        flex-shrink: 0;
        span {
          display: none;
        }
        .inlineSVG {
          width: 1.125em;
          height: 1.125em;
          flex-shrink: 0;
          path {
            fill: rgb( $textColor-primary );
          }
        }
        &:hover {
          opacity: 1;
          background: rgb( $dividerColor );
          .inlineSVG {
            path {
              fill: rgb( $primary );
            }
          }
        }
      }
      &.tableHeader {
        position: sticky;
        position: -webkit-sticky;
        .headerCell {
          position: sticky;
          position: -webkit-sticky;
        }
      }
    }
    .tableCell_patches,
    .tableCell_vulnerabilities,
    .tableCell_sensitive_assets,
    .headerCell_patches,
    .headerCell_vulnerabilities,
    .headerCell_sensitive_assets {
      text-align: center;
    }
  }
}
