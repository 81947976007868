/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.riskScoreWidgetWrapper {
  background: rgb( $primary--10 );
  border-radius: $borderRadius;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 3em;
  height: 100%;
  .outerRiskCircle {
    border: 2em solid rgba( $primary, 0.3);
    border-radius: $borderRadius-full;
    width: 16em;
    height: 16em;
    .innerRiskCircle {
      border: 2px solid rgb( $primary );
      background: rgb( $foregroundColor );
      border-radius: $borderRadius-full;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .scoreAndLabelWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        color: rgb( $primary );
        strong {
          font-size: 2em;
          font-weight: 600;
        }
        span {
          color: rgb( $textColor-primary );
          font-size: 1.25em;
          margin-top: 0.5em;
          font-weight: 400;
        }
      }
    }
  }
  &.risk--critical {
    background: rgb( $critical--10 );
    .outerRiskCircle {
      border-color: rgba( $critical, 0.3);
      .innerRiskCircle {
        border-color: rgb( $critical );
        .scoreAndLabelWrapper {
          color: rgb( $critical );
        }
      }
    }
  }
  &.risk--high {
    background: rgb( $high--10 );
    .outerRiskCircle {
      border-color: rgba( $high, 0.3);
      .innerRiskCircle {
        border-color: rgb( $high );
        .scoreAndLabelWrapper {
          color: rgb( $high );
        }
      }
    }
  }
  &.risk--moderate {
    background: rgb( $moderate--10 );
    .outerRiskCircle {
      border-color: rgba( $moderate, 0.3);
      .innerRiskCircle {
        border-color: rgb( $moderate );
        .scoreAndLabelWrapper {
          color: rgb( $moderate );
        }
      }
    }
  }
  &.risk--low {
    background: rgb( $low--10 );
    .outerRiskCircle {
      border-color: rgba( $low, 0.3);
      .innerRiskCircle {
        border-color: rgb( $low );
        .scoreAndLabelWrapper {
          color: rgb( $low );
        }
      }
    }
  }
  &.risk--minimal {
    background: rgb( $minimal--10 );
    .outerRiskCircle {
      border-color: rgba( $low, 0.3);
      .innerRiskCircle {
        border-color: rgb( $low );
        .scoreAndLabelWrapper {
          color: rgb( $low );
        }
      }
    }
  }
  &.risk--default {
    background: rgba( $textColor-primary, 0.1 );
    .outerRiskCircle {
      border-color: rgba( $textColor-primary, 0.3);
      .innerRiskCircle {
        border-color: rgb( $textColor-primary );
        .scoreAndLabelWrapper {
          color: rgb( $textColor-primary );
        }
      }
    }
  }
}

.dashboardWidgetWrapper.risk_score,
.dashboardWidgetWrapper.risk_target {
  padding: 0;
  overflow: hidden;
  border: none;
  &.widgetHeight-4 {
    .widgetContent {
      height: 17em;
      .riskScoreWidgetWrapper {
        .outerRiskCircle {
          width: 12em;
          height: 12em;
        }
        .innerRiskCircle {
          .scoreAndLabelWrapper {
            strong {
              font-size: 1.5em;
            }
            span {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      height: 21em;
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      height: 25em;
    }
  }
}
