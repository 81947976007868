/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.objectAccessItemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .checkboxesWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 16em;
    .checkButton {
      margin-right: 1em;
    }
  }
  .labelWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1em;
    font-weight: 500;
    color: rgb( $textColor-primary );
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
    }
  }
}