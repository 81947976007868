/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.vulnerability_instances_vulnerability_age_breakdown {
  .widgetContent {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 14em;
    grid-column-gap: 1em;
    height: unset;
    .svgBarWrapper {
      border-bottom: 2px solid rgb( $iconColor-primary );
      padding-bottom: 0.5em;
      margin-left: -0.5em;
      padding-left: 0.5em;
    }
    .chartWrapper {
      min-height: 14em;
      max-height: 14em;
    }
    .cvssBarChartWrapper {
      height: 10em;
    }
    .axisContainer.yAxis {
      height: calc( 10.5em + 1px );
    }
    .svgBarWrapper {
      height: 10em;
    }
    .axisContainer.xAxis {
      margin-left: 4.5em;
      width: calc( 100% - 3.5em );
    }
  }
  .xLabel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-column: 2 / 3;
    padding-top: 0.5em;
    padding-right: 0.5em;
    span {
      font-weight: 600;
      font-size: 0.875em;
    }
  }
  &.widgetHeight-4 {
    .widgetContent {
      .chartWrapper {
        min-height: 9em;
        max-height: 9em;
      }
      .cvssBarChartWrapper {
        height: 9em;
      }
      .axisContainer.yAxis {
        height: 9em;
      }
      .svgBarWrapper {
        height: 9em;
      }
    }
    &.withDescription {
      .widgetContent {
        .chartWrapper {
          min-height: 6.5em;
          max-height: 6.5em;
        }
        .cvssBarChartWrapper {
          height: 6.5em;
        }
        .axisContainer.yAxis {
          height: 6.5em;
        }
        .svgBarWrapper {
          height: 6.5em;
        }
      }
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      .chartWrapper {
        min-height: 13.5em;
        max-height: 13.5em;
      }
      .cvssBarChartWrapper {
        height: 13.5em;
      }
      .axisContainer.yAxis {
        height: 13.5em;
      }
      .svgBarWrapper {
        height: 13.5em;
      }
    }
    &.withDescription {
      .widgetContent {
        .chartWrapper {
          min-height: 11em;
          max-height: 11em;
        }
        .cvssBarChartWrapper {
          height: 11em;
        }
        .axisContainer.yAxis {
          height: 11em;
        }
        .svgBarWrapper {
          height: 11em;
        }
      }
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      .chartWrapper {
        min-height: 18em;
        max-height: 18em;
      }
      .cvssBarChartWrapper {
        height: 18em;
      }
      .axisContainer.yAxis {
        height: 18em;
      }
      .svgBarWrapper {
        height: 18em;
      }
    }
    &.withDescription {
      .widgetContent {
        .chartWrapper {
          min-height: 15em;
          max-height: 15em;
        }
        .cvssBarChartWrapper {
          height: 15em;
        }
        .axisContainer.yAxis {
          height: 15em;
        }
        .svgBarWrapper {
          height: 15em;
        }
      }
    }
  }
  &.widgetHeight-7 {
    .widgetContent {
      .chartWrapper {
        min-height: 22.5em;
        max-height: 22.5em;
      }
      .cvssBarChartWrapper {
        height: 22.5em;
      }
      .axisContainer.yAxis {
        height: 22.5em;
      }
      .svgBarWrapper {
        height: 22.5em;
      }
    }
    &.withDescription {
      .widgetContent {
        .chartWrapper {
          min-height: 18.5em;
          max-height: 18.5em;
        }
        .cvssBarChartWrapper {
          height: 18.5em;
        }
        .axisContainer.yAxis {
          height: 18.5em;
        }
        .svgBarWrapper {
          height: 18.5em;
        }
      }
    }
  }
  &.widgetHeight-8 {
    .widgetContent {
      .chartWrapper {
        min-height: 26.5em;
        max-height: 26.5em;
      }
      .cvssBarChartWrapper {
        height: 26.5em;
      }
      .axisContainer.yAxis {
        height: 26.5em;
      }
      .svgBarWrapper {
        height: 26.5em;
      }
    }
    &.withDescription {
      .widgetContent {
        .chartWrapper {
          min-height: 22.5em;
          max-height: 22.5em;
        }
        .cvssBarChartWrapper {
          height: 22.5em;
        }
        .axisContainer.yAxis {
          height: 22.5em;
        }
        .svgBarWrapper {
          height: 22.5em;
        }
      }
    }
  }
}