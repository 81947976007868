/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.risk_peer_percentile_over_time {
  .widgetContent {
    .chartPlusAxis {
      display: grid;
      grid-template-columns: 6em 1fr;
      height: calc( 100% - 2em );
      padding-top: 0.5em;
      position: relative;
      .areaPlusBarWrapper {
        width: calc( 100% - 3em );
        height: 100%;
        padding-left: 0.5em;
        border-bottom: 2px solid rgb( $iconColor-primary );
        margin-left: -0.5em;
        z-index: 2;
      }
    }
    .axisLinesContainer {
      &.yAxis, &.xAxis {
        position: absolute;
        z-index: 1;
        padding-left: 6.5em;
        padding-right: 3.5em;
        padding-top: 0.5em;
      }
      &.xAxis {
        height: calc( 100% - 0.5em );
      }
    }
    .axisContainer {
      &.yAxis {
        padding-bottom: 0;
        height: 100%;
      }
      &.xAxis {
        width: calc( 100% - 3em );
        padding-top: 0.5em;
        padding-left: 6.5em;
      }
    }
  }
}