/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.globalRiskTriggerWrapper {
  font-family: $sans;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: $borderRadius-input;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0 1em;
  .riskButtonSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: rgb( $textColor-primary );
    font-size: 1em;
    background: rgb( $backgroundColor );
    padding: 0.625em 1em;
    border-radius: $borderRadius-input;
    .iconWrapper {
      width: 1.5em;
      height: 1.5em;
      background: rgba( #fff, 0.85 );
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-right: 0.5em;
      border-radius: $borderRadius-full;
      .inlineSVG {
        flex-shrink: 0;
        width: 1em;
        height: 1em;
      }
    }
  }
  &.risk--critical {
    .riskButtonSection {
      color: #fff;
      background: rgb( $critical );
      .iconWrapper {
        .inlineSVG {
          path {
            fill: rgb( $critical );
          }
        }
      }
    }
  }
  &.risk--high {
    .riskButtonSection {
      color: #fff;
      background: rgb( $high );
      .iconWrapper {
        .inlineSVG {
          path {
            fill: rgb( $high );
          }
        }
      }
    }
  }
  &.risk--moderate {
    .riskButtonSection {
      color: #fff;
      background: rgb( $moderate );
      .iconWrapper {
        .inlineSVG {
          path {
            fill: rgb( $moderate );
          }
        }
      }
    }
  }
  &.risk--low {
    .riskButtonSection {
      color: #fff;
      background: rgb( $low );
      .iconWrapper {
        .inlineSVG {
          path {
            fill: rgb( $low );
          }
        }
      }
    }
  }
  &.risk--minimal {
    .riskButtonSection {
      color: #fff;
      background: rgb( $minimal );
      .iconWrapper {
        .inlineSVG {
          path {
            fill: rgb( $minimal );
          }
        }
      }
    }
  }
  &.risk--primary {
    .riskButtonSection {
      color: #fff;
      background: rgb( $primary );
      .iconWrapper {
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
  }
  &.displayModesLength--2 {
    .riskButtonSection {
      border-radius: $borderRadius-input 0 0 $borderRadius-input;
      &:nth-of-type( 2 ) {
        border-radius: 0 $borderRadius-input $borderRadius-input 0;
      }
    }
    &.risk--critical {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $critical );
          background: rgb( $critical--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: rgb( $critical );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
          }
        }
      }
    }
    &.risk--high {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $high );
          background: rgb( $high--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: rgb( $high );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
          }
        }
      }
    }
    &.risk--moderate {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $moderate );
          background: rgb( $moderate--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: rgb( $moderate );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
          }
        }
      }
    }
    &.risk--low {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $low );
          background: rgb( $low--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: rgb( $low );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
          }
        }
      }
    }
    &.risk--minimal {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $minimal );
          background: rgb( $minimal--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: rgb( $minimal );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
          }
        }
      }
    }
    &.risk--primary {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $primary );
          background: rgb( $primary--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $primary );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: #fff;
          background: rgb( $primary );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $primary );
              }
            }
          }
        }
      }
    }
  }
  &.displayModesLength--3 {
    .riskButtonSection {
      border-radius: $borderRadius-input 0 0 $borderRadius-input;
      &:nth-of-type( 2 ) {
        border-radius: 0;
      }
      &:nth-of-type( 3 ) {
        border-radius: 0 $borderRadius-input $borderRadius-input 0;
      }
    }
    &.risk--critical {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $critical );
          background: rgb( $critical--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: rgb( $critical );
          background: rgb( $critical--25 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: rgb( $critical );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
          }
        }
      }
    }
    &.risk--high {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $high );
          background: rgb( $high--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: rgb( $high );
          background: rgb( $high--25 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: rgb( $high );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
          }
        }
      }
    }
    &.risk--moderate {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $moderate );
          background: rgb( $moderate--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: rgb( $moderate );
          background: rgb( $moderate--25 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: rgb( $moderate );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
          }
        }
      }
    }
    &.risk--low {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $low );
          background: rgb( $low--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: rgb( $low );
          background: rgb( $low--25 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: rgb( $low );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
          }
        }
      }
    }
    &.risk--minimal {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $minimal );
          background: rgb( $minimal--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: rgb( $minimal );
          background: rgb( $minimal--25 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: rgb( $minimal );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
          }
        }
      }
    }
    &.risk--primary {
      .riskButtonSection {
        &:nth-of-type( 1 ) {
          color: rgb( $primary );
          background: rgb( $primary--10 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $primary );
              }
            }
          }
        }
        &:nth-of-type( 2 ) {
          color: rgb( $primary );
          background: rgb( $primary--25 );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $primary );
              }
            }
          }
        }
        &:nth-of-type( 3 ) {
          color: #fff;
          background: rgb( $primary );
          .iconWrapper {
            .inlineSVG {
              path {
                fill: rgb( $primary );
              }
            }
          }
        }
      }
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.globalRiskDisplay {
  background: rgb( $critical );
  opacity: 0.8;
  padding: 1em 1.5em;
  border-radius: 0.5em;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1em auto;
  label {
    color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.25em;
    .inlineSVG {
      margin-right: 0.5em;
      width: 1.5em;
      height: 1.5em;
      path {
        fill: #fff;
      }
    }
  }
  strong {
    font-size: 4em;
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  &.high {
    background: rgb( $high );
  }
  &.moderate {
    background: rgb( $moderate );
  }
  &.low {
    background: rgb( $low );
  }
  &.minimal {
    background: rgb( $low );
  }
}

.topBarRiskScoreModal {
  width: 72em !important;
  left: calc( 50vw - 36em ) !important;
  .modalBody {
    .riskDisplayModesWrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 1em;
      margin: 2em 0;
      &.displayModesLength--2 {
        grid-template-columns: 1fr 1fr;
      }
      &.displayModesLength--3 {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .optionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 36em;
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 2em;
        .labelWrapper {
          width: unset;
          margin: 0;
          margin-left: 0.5em;
        }
      }
    }
    .labelWrapper {
      width: 100%;
      margin-bottom: .5em;
      // font-size: .875em;
      font-weight: 500;
      // text-transform: uppercase;
      color: rgb( $textColor-primary );
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      line-height: 1.5em;
    }
    .formWrapper {
      .selectFieldWrapper.percentCurrentRisk {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .labelWrapper {
          width: unset;
          margin-right: 0.5em;
        }
        select {
          width: 6em;
          margin-right: 0.5em;
        }
      }
    }
  }
}