/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.exploreModelexploreItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: rgb( $textColor-primary );
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  margin: 0;
  cursor: pointer;
  padding: 0.25em 0.75em;
  border-radius: $borderRadius;
  margin-bottom: 0.25em;
  .name {
    width: 10em;
    flex-grow: 1;
    overflow: hidden;
    // text-overflow: ellipsis;
    margin-right: 0;
    flex-shrink: 0;
    // white-space: nowrap;
    strong {
      font-weight: 600;
    }
  }
  .riskPercentageBarWrapper {
    margin-right: 0.5em;
  }
  .riskReduction {
    flex-shrink: 0;
    width: 3.625em;
    text-align: right;
    margin-right: 0.5em;
  }
  .addRemoveIcon {
    flex-shrink: 0;
    margin-left: 0.5em;
    width: 1.25em;
    height: 1.25em;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
    path {
      transition: all 0.3s ease-in-out;
      fill: rgb( $textColor-secondary );
    }
  }
  &:hover {
    background: rgb( $critical--10 );
    .addRemoveIcon {
      opacity: 1;
      path {
        fill: rgb( $critical );
      }
    }
    &.risk-critical {
      background: rgb( $critical--10 );
      .addRemoveIcon {
        path {
          fill: rgb( $critical );
        }
      }
    }
    &.risk-high {
      background: rgb( $high--10 );
      .addRemoveIcon {
        path {
          fill: rgb( $high );
        }
      }
    }
    &.risk-moderate {
      background: rgb( $moderate--10 );
      .addRemoveIcon {
        path {
          fill: rgb( $moderate );
        }
      }
    }
    &.risk-low {
      background: rgb( $low--10 );
      .addRemoveIcon {
        path {
          fill: rgb( $low );
        }
      }
    }
    &.risk-minimal {
      background: rgb( $minimal--10 );
      .addRemoveIcon {
        path {
          fill: rgb( $low );
        }
      }
    }
    &.risk-negligible {
      background: rgb( $primary--10 );
      .addRemoveIcon {
        path {
          fill: rgb( $primary );
        }
      }
    }
    &.risk-none {
      background: rgb( $dividerColor );
      .addRemoveIcon {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
    }
    &.path {
      background: rgb( $textColor-primary );
      color: #fff;
    }
  }

  &.alreadySelected, &.selected, &.standalone {
    background: rgb( $backgroundColor );
    margin-bottom: 0.5em;
    .itemTypeIcon {
      flex-shrink: 0;
      margin-right: 0.5em;
      width: 1em;
      height: 1em;
      path {
        fill: rgb( $textColor-secondary );
      }
    }
    .name {
      width: 11.5em;
    }
  }
  &.standalone {
    .name {
      width: 26em;
    }
  }
  &.isSelectedPath {
    background: rgb( $textColor-primary );
    color: #fff;
  }
}