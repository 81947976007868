/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "globals" as *;

.button-bar, #eula-buttons {
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 0;
  background: rgb( $foregroundColor );
  button, span {
    @include dsButton('primary');
    width: 120px;
    flex-grow: 0;
    font-family: $sans;
    font-weight: bold;
    height: 2em;
    line-height: 2em;
    &:nth-of-type(1) {
      @include dsButton('destructive');
      height: 2em;
      line-height: 2em;
    }
    &:last-of-type {
      @include dsButton('primary');
    }
  }
}

#modalCloseButton, .popup-close {
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  cursor: pointer;
  opacity: 0.6;
  width: 1.25em;
  height: 1.25em;
  border: none;
  padding: 0;
  margin: 0;
  img {
    width: 1.25em;
  }
  &:hover {
    opacity: 1;
  }
}

#pageContent, #topBar, .modalContainer, .recordCardContainer {
  &.darkMode {
    .recordIconWrapper, .copyDashboardButton, .modalHeaderIconWrapper, .closeButton {
      background: rgba( #fff, 0.2 ) !important;
      &:disabled {
        opacity: 0.5;
      }
    }
    .sensitiveAssetItemWrapper.standAlone {
      .left {
        .labelAndType {
          .type {
            color: rgba( #fff, 0.5 );
          }
        }
      }
    }
    .pathSVGContainer {
      background: none;
    }
    p.description, .tableDescription {
      background: rgba( #fff, 0.2 ) !important;
    }
    .contentColumn p {
      background: rgba( #fff, 0.2 );
    }
    .riskExplanationTriggerButton {
      color: rgba( #fff, 0.5 );
    }
    #pageCreateButtonContainer button, #pageCreateButtonContainer .dropdownButtonTrigger, #pageCreateButtonContainer .dropdownTrigger {
      @include dsButton('primary-darkMode');
      .inlineSVG {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
    }
    pre {
      background: rgba( #fff, 0.2 );
    }
    .roundGlyphButton, .recordItemAction, .roundGlyphButton.light {
      flex-shrink: 0;
      background: rgba( #fff, 0.1 );
      .inlineSVG {
        path {
          fill: rgba( #fff, 0.5 ) !important;
        }
      }
      &:hover {
        background: rgba( #fff, 0.3 );
      }
    }
    .emptyState {
      color: rgba( #fff, 0.5 ) !important;
    }
    #pageCreateButtonContainer {
      button {
        @include dsButton('primary-darkMode');
        color : rgb( $textColor-secondary );
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }
      .dashboardSelector {
        .dropdownTrigger {
          @include dsButton('primary-darkMode');
          color : rgb( $textColor-secondary );
          margin-left: 1em;
          .inlineSVG {
            path {
              fill: rgb( $textColor-secondary );
            }
          }
        }
      }
    }
    .widgetDescriptionContainer, .itemPercentageBarWrapper {
      background: rgba( #fff, 0.2 );
    }
    .bigNumberWrapper {
      h3 {
        color: rgb( $textColor-primary );
      }
    }
    .fullBarWrapper {
      rect.barContainer {
        fill:rgba( #fff, 0.1 );
        &:hover {
          fill:rgba( #fff, 0.25 );
        }
      }
    }
    .fieldWrapper {
      &.select {
        .asSelect {
          .dropdownTrigger.asSelect {
            background: rgba( #fff, 0.1 );
          }
        }
      }
    }
    .logoWrapper {
      background: rgba( #fff, 0.2 );
    }
    .pathsWidgetWrapper {
      background: none;
    }
    .riskScoreWidgetWrapper {
      background: none !important;
    }
    .axisLabel {
      .mainLabel {
        color: rgba( #fff, 0.5 );
      }
    }
    .reportingDashboardPageHeader {
      h2 {
        .inlineSVG.dsLogo {
          display: none;
        }
      }
    }
    .exportMenuTrigger {
      background: rgba( #fff, 0.4 ) !important;
      color: #fff !important;
      span {
        color: #fff !important;
      }
    }
    .remediationPlanProgress .completionWrapper .completionBarWrapper {
      background: rgba( #fff, 0.1 );
    }
    .helpTrigger {
      .inlineSVG {
        path {
          fill: rgba( #fff, 0.5 ) !important;
        }
      }
    }
    .riskContributionWrapper {
      .description {
        background: rgba( #fff, 0.2 );
      }
    }
    ul.setupRecordsList li.setupRecordItem.alternateLayout.subordinate_scanner.item section.mainDetailsSection .column span,
    .activityItem .contentWrapper .main span,
    .activityItem .contentWrapper .additional span,
    ul.setupRecordsList li.setupRecordItem.alternateLayout section.mainDetailsSection .orderContainer strong,
    ul.setupRecordsList li.setupRecordItem.alternateLayout.scan_credential.item section.mainDetailsSection .column span,
    .scanningDashboardWidgetsWrapper .dashboardWidgetWrapper.donut .widgetContent .chartAndLabelWrapper h3,
    .userDetailsWrapper .userDetailsSection span,
    .setupRecordItem.authentication_provider.item.alternateLayout .mainDetailsSection .column span,
    .setupRecordItem.certificate.alternateLayout.item .mainDetailsSection .column span,
    .fieldWrapper.checkbox label .labelWrapper {
      color: rgba( #fff, 0.5 );
    }
  }
}

#dropdownMenuItemsPortal {
  .dropdownMenu {
    &.darkMode {
      background: rgb( $backgroundColor );
      li {
        .selectIemWrapper {
          background: rgb( $backgroundColor );
          color: rgb( $textColor-primary ) !important;
          .logoWrapper {
            background: rgba( #fff, 0.2 );
          }
          span {
            color: rgb( $textColor-primary ) !important;
          }
        }
        &:hover {
          .selectIemWrapper {
            background: rgba( #fff, 0.2 );
            &.asDivider {
              background: rgb( $backgroundColor );
            }
          }
        }
      }
    }
  }
}

// stupid safari, stupid overrides
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {

    .modalContainer.tag {
      overflow: visible;
    }
    #navigationMenu.collapsed ul#menuItems {
      width: 4.25em;
    }
    button {
      // display: block !important;
      // img {
      //   margin-bottom: -1px !important;
      // }
      &.taskRunnerButton {
        display: flex !important;
      }
    }
    #report-options-row,
    #page-content,
    #dataContainer,
    #chartLegend,
    #react-setup-container,
    #react-about-container,
    #reactAboutPage,
    .tabContent,
    .formActions,
    .modalActions,
    .button-bar {
      min-height: unset;
    }
    .linkContainer,
    #globalRiskWrapper,
    .tableActions,
    .setupFormContainer,
    .filterForm,
    .validFormWrapper,
    .formGroupSection {
      min-height: min-content;
    }
    #globalRiskWrapper {
      display: flex !important;
    }
    .insightListHeader,
    .setupPageContainer .pageHeader,
    .filterTestButton,
    .savedFiltersWrapper .saveButtonWrapper {
      min-height: 3em;
    }
    .panelTabs {
      min-height: 2em;
    }
    .config-help {
      height: calc( 100vh - 26em);
    }
    .explore-item {
      flex-shrink: 0;
    }
    .filterWrapper label input[type="date"] {
      width: 90%;
    }
    #report-listing-row {
      #report-listing {
        .report-item, .report-item-selected {
          flex-shrink: 0;
        }
      }
    }
    .report-item-column {
      div {
        flex-shrink: 0;
        &.report-item-list {
          flex-shrink: 1;
        }
      }
    }
}}

div {
  min-height: unset;
}


// for printing
@media print {
  body {
    width: 100%;
    padding: 0;
    margin: 0;
    background: none;
  }
  #reactDialogPortal,
  #reactModalPortal,
  #reactHelpPortal,
  #reactOnboardingPortal,
  #addToMenuPortal,
  #reactToolTipPortal,
  #userMenuTriggerWrapper,
  #topBar,
  #navigationMenu,
  #reactFlashMessageQueuePortal,
  #icicleChartCategoryLabelPortal {
    display: none;
    &.printingFullScreenVisual {
      display: block !important;
      .fullScreenVisualModal {
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 !important;
        .modalBody {
          padding: 0 !important;
        }
        .closeButton,
        .fullScreenVisualToggleButton,
        .fullScreenVisualPrintButton {
          display: none;
        }
        .vulnerabilityInstancesVisualWrapper {
          .instancesBreakdown {
            height: 10em;
          }
          .legendsContainer {
            font-size: 0.625em;
          }
        }
        .dashboardWidget.vulnerability_instances_cvss_breakdown.barchart {
          height: 5.5in;
          .chartWrapper {
            max-height: unset;
            .cvssBarChartWrapper {
              min-height: unset;
              max-height: unset;
            }
          }
        }
      }
    }
  }
  #page-content {
    width: 100%;
    height: unset;
    overflow: visible;
    padding: 0.125in;
    margin: 0;
    background: none;
  }
}
