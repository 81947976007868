/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

// default browser input style overrides
.textFieldWrapper {
  &.withGenerate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    input[type="text"] {
      flex-grow: 1;
    }
    button {
      @include dsButton('minimal-dark');
      border-radius: $borderRadius-input;
      margin-left: 1em;
    }
  }
}
