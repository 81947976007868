/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.setupPageVisualizationWrapper {
  background: rgb( $foregroundColor );
  padding: 1em;
  font-family: $sans;
  color: rgb( $textColor-primary );
  .riskOverTimeChartWrapper {
    position: relative;
    width: 100%;
    h3 {
      font-size: 1.25em;
      text-indent: 4.8em;
    }
    .yAxis {
      position: absolute;
      top: 0;
      left: 0;
      width: 8em;
      height: 100%;
      font-size: 0.75em;
      text-align: left;
      display: grid;
      grid-template-rows: repeat( 3, 33.3333% );
      padding-top: 4em;
      padding-bottom: 3em;
      span {
        text-align: right;
        padding-right: 1em;
      }
    }
    .xAxis {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.2em;
      font-size: 0.75em;
      text-align: center;
      display: grid;
      grid-template-columns: repeat( 6, 16.66667% );
      padding-left: 8em;
      span {
        &:nth-of-type(1) {
          text-align: left;
        }
        &:nth-of-type(6) {
          text-align: right;
        }
      }
    }
    .svgAreaWrapper {
      margin-top: 1em;
      margin-left: 6em;
      margin-bottom: 2em;
    }
  }

}