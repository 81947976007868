/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.CVSSBreakdown {
  width: 100%;
  position: relative;
  .vectorString, .deepSurfaceNotes {
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
    strong {
      width: 100%;
      margin-bottom: 0.5em;
      font-size: 0.875em;
    }
    .scoreWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5em;
    }
    span.labelSpan {
      color: rgb( $textColor-primary );
      font-weight: 400;
    }
    span.cvssScore {
      font-weight: 600;
      color: rgb( $textColor-primary );
      text-transform: uppercase;
      font-size: 1em !important;
      margin-left: 0.5em;
      &.risk--critical {
        color: rgb( $critical );
      }
      &.risk--high {
        color: rgb( $high );
      }
      &.risk--moderate {
        color: rgb( $moderate );
      }
      &.risk--low {
        color: rgb( $low );
      }
      &.risk--minimal {
        color: rgb( $low );
      }
    }
    .vectorStringWrapper {
      width: 100%;
      .stringWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        // position: relative;
        flex-wrap: wrap;
        cursor: default;
        font-weight: 600;
        span.stringContainer {
          margin-left: 0.25em;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
          // position: relative;
          flex-wrap: wrap;
          cursor: default;
          font-weight: 600;
          flex-grow: 1;
        }
      }
      .vectorStringSection {
        // padding-bottom: 1em;
        // position: relative;
        a {
          color: rgb( $textColor-primary );
        }
        .sectionInfo {
          position: absolute;
          width: 100%;
          top: 3.5em;
          left: 0em;
          display: none;
          padding: 0.5em 1em;
          border-radius: $borderRadius;
          box-shadow: $boxShadow-light;
          background: rgb( $backgroundColor-secondary );
          border: 1px solid rgb( $dividerColor );
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          p, a, span, strong {
            margin: 0;
            padding: 0;
            color: rgb( $textColor-primary );
            font-weight: 400;
          }
          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            label {
              flex-shrink: 0;
              width: unset;
              margin: 0;
              font-weight: 600;
              margin-left: 0.25em;
              font-size: 1em;
            }
            strong {
              flex-shrink: 0;
              font-weight: 400;
            }
          }
          strong {
            padding-left: 0.25em;
          }
          a {
            color: rgb( $primary );
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            span {
              margin-right: 0.25em;
              color: rgb( $primary );
            }
            .inlineSVG {
              height: 1em;
              width: 1em;
            }
          }
        }
        &:hover {
          text-decoration: underline;
          // background: rgb( $foregroundColor );
          z-index: 1;
          border-radius: $borderRadius $borderRadius 0 0;
          a {
            color: rgb( $primary );
          }
          .sectionInfo {
            display: flex;
          }
        }
      }
      .emptyState {
        font-size: 1em;
        // background: rgb( $foregroundColor );
        padding: 0em;
        padding-top: 0.5em;
      }
    }
  }
}