/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
 @use "../../../../styles/globals.scss" as *;

.flashMessageQueueWrapper {
  z-index: 9999999;
  position: fixed;
  top: 6em;
  right: 1em;
  width: 30em;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  &.loginVersion {
    right: unset;
    left: 1em;
    top: unset;
    bottom: 1em;
  }
}