/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.helpContainer {
  display: none;
  position: fixed;
  top: 5em;
  right: 2em;
  box-shadow: $boxShadow-heavy;
  background: rgb( $foregroundColor );
  border-radius: $borderRadius;
  width: 30em;
  z-index: 9999999;
  font-family: $sans;
  color: rgb( $textColor-primary );
  .helpHeader {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: move;
    padding: 0.725em 1em;
    font-size: 1em;
    transition: all 0.3s ease-in-out;
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
      path {
        fill: rgb( $iconColor-primary );
      }
    }
    h2 {
      font-size: 1em;
      margin: 0;
      padding: 0;
    }
    button {
      transition: all 0.3s ease-in-out;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      width: unset;
      &:focus {
        outline: none;
      }
    }
    &:hover {
      background: rgb( $backgroundColor );
      .inlineSVG {
        opacity: 1;
        &.draggableIcon {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
    .titleWrapper, .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .actions {
      .inlineSVG {
        margin-right: 0;
      }
      button {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-left: 1em;
      }
    }
  }
  .helpBody {
    max-height: 40vh;
    overflow: auto;
    padding: 0.5em;
    .helpTopicsCount {
      text-transform: uppercase;
      font-weight: 600;
      color: rgb( $iconColor-primary );
      font-size: 0.75em;
      padding: 0.5em 1em;
      strong {
        color: rgb( $textColor-primary );
        margin-left: 0.5em;
      }
    }
    .allItemsMenu {
      margin: 0;
      padding: 0;
      li {
        padding: 0;
        margin-bottom: 0;
        .helpItemDescription {
          width: 100%;
          padding: 0.5em 1em;
          border-radius: none;
          background: rgb( $foregroundColor );
          text-decoration: none;
          color: rgb( $textColor-primary );
          text-align: left;
          padding: 0.5em 1em;
          border-radius: $borderRadius;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          transition: all 0.3s ease-in-out;
          overflow: hidden;
          cursor: pointer;
          h4 {
            font-size: 1em;
            padding: 0;
            margin: 0;
            margin-bottom: 0.25em;
          }
          p {
            color: rgb( $textColor-secondary );
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            padding: 0;
            font-weight: 400;
            display: none;
            &:first-of-type {
              display: block;
            }
          }
          ul, ol, pre {
            display: none;
          }
          &:hover {
            background: rgb( $dividerColor );
          }
        }
      }
    }
    .helpItemDescriptionDetail {
      padding: 1em;
      h4 {
        margin-top: 0;
      }
    }
    .backButton {
      font-size: 0.875em;;
      color: rgb( $textColor-primary );
      width: 6em;
      margin: 0 1em;
      justify-content: space-around;
      padding: 0;
      border: none;
      background: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      opacity: 0.3;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      span {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
      .inlineSVG {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        transition: all 0.3s ease-in-out;
        path {
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        color: rgb( $primary );
        opacity: 1;
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
  }
  .helpFooter {
    border-top: 1px solid rgb( $dividerColor );
    padding: 1em;
    .openHelpCenter {
      @include dsButton( 'minimal-primary' );
      justify-content: space-around;
      span {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .inlineSVG {
          margin-left: 0.5em;
        }
      }
    }
  }
  &.visible {
    display: block;
  }
}

.helpTrigger {
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  margin-left: 0.25em;
  display: inline-block;
  .inlineSVG {
    opacity: 0.7;
    display: block;
    display: block;
    height: 1.25em;
    width: 1.25em;
  }
  &:hover {
    .inlineSVG {
      opacity: 1;
    }
  }
}
