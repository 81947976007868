/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.modalContainer.reactSetupModal.scanGroupModal {
  padding-bottom: 4em;
  background: none;
  box-shadow: none;
  .modalHeader {
    padding: 0;
    position: relative;
    h2 {
      display: none;
    }
    button {
      position: absolute;
      top: 4.25em;
      right: 2em;
    }
  }
  .modalBody {
    padding: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    overflow: hidden;
    .tabWrapper {
      padding: 2em;
      height: 100%;
      background: rgb( $foregroundColor );
      margin: 0;
      border-radius: 0 $borderRadius 0 0;
      border-top: none;
      h2, .sectionHeader {
        font-size: 1.125em;
        padding-bottom: 0;
        margin-bottom: 1.5em;
        grid-column: 1 / 3;
        width: 100%;
      }
      .stepDirections {
        background: rgb( $backgroundColor );
        padding: 1em 1.5em;
        border-radius: $borderRadius;
        grid-column: 1 / 3;
        margin-bottom: 1.75em;
      }
      &.general {
        .fieldGroupWrapper {
          &.basic {
            display: flex;
          }
          &.settings, &.advanced {
            display: none;
          }
        }
      }
      &.settings {
        .fieldGroupWrapper {
          &.basic {
            display: none;
          }
          &.settings, &.advanced {
            display: flex;
          }
        }
      }
    }
    .formWrapper {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      .fieldGroupWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        border: none;
        padding: 0;
        margin: 0;
        &.basic {
          flex-wrap: wrap;
          .fieldWrapper {
            &.select {
              width: 15em;
              .selectFieldWrapper {
                width: 100%;
                border-radius: $borderRadius;
              }
            }
            &.text {
              width: calc(100% - 15em);
              padding-right: 1em;
            }
            &.textarea {
              padding-bottom: 0;
              margin-bottom: 0;
              width: 50%;
              textarea {
                height: calc( 95vh - 25.5em );
                resize: vertical;
              }
              &:last-of-type {
                padding-left: 1em;
              }
            }
          }
        }
        &.settings {
          width: 50%;
          flex-direction: column;
          padding-left: 2em;
          h2 {
            font-size: 1em;
            color: rgb( $textColor-secondary );
            text-transform: uppercase;
            margin-bottom: 1.5em;
          }
          .fieldWrapper {
            width: 100%;
          }
        }
        &.advanced {
          flex-wrap: wrap;
          width: 50%;
          border-right: 1px solid rgb( $iconColor-primary );
          padding-right: 2em;
          .fieldWrapper {
            width: 100%;
          }
        }
      }
    }
  }
}

ul.setupRecordsList {
  li.setupRecordItem.alternateLayout.scan_group.item {
    grid-template-columns: 20em calc( 100% - 20em - 25em ) 25em !important;
    background: rgb( $foregroundColor );
    section.mainDetailsSection {
      .column {
        width: 100%;
        span {
          margin-top: 0.5em;
          color: rgb( $textColor-primary );
          width: 100%;
          font-weight: 400;
          strong {
            color: rgb( $textColor-primary );
            font-weight: 600;
          }
          ul {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 0.25em;
            flex-wrap: wrap;
            width: 100%;
            li {
              margin-right: 0.25em;
              margin-bottom: 0.25em;
              strong {
                font-size: 0.875em;
              }
              &:after {
                content: ", ";
              }
              &:last-child {
                margin-right: 0em;
                &:after {
                  content: "";
                }
              }
              &.remainder {
                margin-top: 0.125em;
                font-size: 0.875em;
                color: rgb( $textColor-secondary );
              }
            }
          }
        }
      }
    }
    .itemActions {
      .taskRunnerButton {
        margin-left: 1em;
      }
    }
  }
}