/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.cvssBarChartWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 7em 1fr;
  margin-top: 1em;
  .svgBarWrapper {
    .barChart {
      .barContainer {
        transition: all 0.3s ease-in-out;
        &:hover, &.isHovered {
          fill: rgb( $dividerColor );
        }
      }
      .barSegment {
        transition: all 0.3s ease-in-out;
        &:hover, &.isHovered {
          fill-opacity: 0.75;
        }
      }
    }
  }
  .axisContainer {
    &.xAxis {
      margin-left: 6.5em;
      width: calc( 100% - 6.5em );
    }
  }
}