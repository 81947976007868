/***************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.routeNavigationMenuHoverPolygon {
  position: absolute;
  left: 0;
  width: 1em;
  rect, polygon {
    cursor: pointer;
  }
}

.routeNavigationMenu {
  position: fixed;
  color: rgb( $textColor-primary );
  font-family: $sans;
  background: rgb( $foregroundColor );
  border: 1px solid rgb( $iconColor-primary );
  border-radius: $borderRadius-input;
  box-shadow: $boxShadow-heavy;
  display: none;
  width: 22em;
  z-index: 1008;
  .routeNavigationHeader {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgb( $iconColor-primary );
    color: rgb( $textColor-primary );
    background: rgb( $backgroundColor-secondary );
    border-radius: 0.5em 0.5em 0 0;
    a {
      width: 100% !important;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start !important;
      color: rgb( $textColor-primary );
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      border-radius: 0.5em 0.5em 0 0 !important;
      padding: 0.625em 1em !important;
      height: unset !important;
      &:hover {
        color: rgb( $textColor-primary ) !important;
        text-decoration: none;
        background: rgb( $dividerColor ) !important;
        .inlineSVG {
          path {
            fill: rgb( $primary ) !important;
          }
        }
      }
    }
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
      path {
        fill: rgb( $iconColor-primary );
      }
    }
    h2 {
      font-size: 1em;
      margin: 0;
      padding: 0;
    }
    &.current {
      .inlineSVG {
        path {
          fill: rgb( $primary );
        }
      }
      a:hover {
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
    .userDetailsWrapper {
      .avatarInitials {
        width: 2em;
        height: 2em;
        margin-right: 01em;
        font-weight: 400;
        color: #fff !important;
        border-color: #fff !important;
        line-height: 1.5em;
        font-size: 0.75em;
      }
    }
  }
  &.visible {
    display: block;
  }
  ul {
    padding: 0.5em;
  }
  li.divider {
    margin-bottom: 0;
    padding: 0.5em 1em;
    label {
      text-transform: uppercase;
      // // font-weight: 600;
      color: rgb( $textColor-secondary );
      font-size: 0.75em;
    }
    &:first-child {
      margin-top: 0;
      border-top: none;
    }
  }
  .routeNavigationItemsWrapper {
    &.border--top {
      border-top: 1px solid rgb( $dividerColor );
    }
    &.border--bottom {
      border-bottom: 1px solid rgb( $dividerColor );
    }
    &.border--both {
      border-top: 1px solid rgb( $dividerColor );
      border-bottom: 1px solid rgb( $dividerColor );
    }
  }
  .routeNavActionButton {
    @include dsButton( 'minimal-primary' );
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 0.5em;
    margin-bottom: 1em;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .inlineSVG {
        margin: 0;
        margin-right: 0.5em;
        path {
          fill: rgb( $primary );
        }
      }
    }
  }
  .routeNavigationItem {
    width: 100% !important;
    margin-bottom: 0;
    a, .notLink {
      width: 100% !important;
      padding: 0.5em 1em 0.5em 2em !important;
      border-radius: none !important;
      background: rgb( $foregroundColor ) !important;
      text-decoration: none !important;
      color: rgb( $textColor-primary ) !important;
      text-align: left !important;
      border-radius: $borderRadius !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start !important;
      transition: all 0.3s ease-in-out;
      height: unset !important;
    }
    a {
      &:hover {
        background: rgb( $dividerColor ) !important;
      }
    }
    .notLink {
      background-color: rgb( $textColor-primary ) !important;
      color: #fff !important;
      position: relative;
      padding-left: 2em;
      &:before {
        content: '';
        position: absolute;
        top: calc( 50% - ( 0.75em / 2 ) );
        left: 0.75em;
        width: 0.75em;
        height: 0.75em;
        background: rgb( $primary );
        border-radius: $borderRadius-full;
      }
      .ratingBadge.beta {
        background: rgb( $primary );
        color: #fff;
      }
      .ratingBadge.new {
        background: rgb( $status-success );
        color: #fff;
      }
      &.withAction {
        background: none !important;
        color: rgb( $textColor-primary ) !important;
        padding-left: 1em !important;
        cursor: pointer !important;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
        .ratingBadge {
          margin-left: 1em;
        }
        &:before {
          display: none;
        }
        &:hover {
          background: rgb( $dividerColor ) !important;
        }
      }
    }
    &.rootLevel {
      a {
        padding-left: 1em !important;
      }
    }
    &.border--top {
      border-top: 1px solid rgb( $dividerColor );
    }
    &.border--bottom {
      border-bottom: 1px solid rgb( $dividerColor );
    }
    &.border--both {
      border-top: 1px solid rgb( $dividerColor );
      border-bottom: 1px solid rgb( $dividerColor );
    }
  }
  &.darkMode {
    .routeNavigationItem {
      .notLink {
        background-color: rgba( $primary, 0.3 ) !important;
      }
    }
  }
}