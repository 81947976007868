/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#react-setup-container {
  width: 100%;
}

.subordinateScannerRegistration {
  .modalActions {
    a {
      @include dsButton('primary');
    }
  }
}

.reactSetupModal {
  .formDescription {
    a {
      margin: 0 0.25em;
      color: rgb( $primary );
    }
    line-height: 1.5;
  }
}

.setupPageContainer {
  width: 100%;
  position: relative;
  .listActions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .listCollapseToggle {
    @include dsButton( 'minimal-primary' );
    background: none;
    margin: 0 1em 1em;
    .inlineSVG {
      margin-left: 0.5em;
    }
  }
  .setupPageOptions {
    font-family: $sans;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-right: 2em;
    border-right: 1px solid rgb( $dividerColor );
    label {
      span {
        margin-right: 0.5em;
        text-transform: uppercase;
        color: rgb( $textColor-secondary );
        font-size: 0.75em;
        font-weight: 600;
        &.betaFlag {
          padding: 0.25em 0.75em;
          border-radius: $borderRadius;
          background: rgb( $primary );
          color: #fff;
          text-transform: none;
        }
      }
      .selectFieldWrapper {
        margin-top: 0.5em;
        select {
          padding: 0.25em 2.5em 0.25em 0.75em;
          border: 1px solid rgb( $textColor-secondary );
          border-radius: $borderRadius;
          font-size: 0.875em;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  &.connectivity_clique {
    .pageHeader {
      position: static;
      box-shadow: none;
      justify-content: space-between;
      border-bottom: none;
    }
  }
}

.statusViewer {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgb( $textColor-secondary );
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  span {
    margin-bottom: 1em;
  }
}
.certifcateViewer {
  font-family: $fixed;
  display: block;
  white-space: pre-wrap;
  text-align: center;
}

.setupFormContainer {
  color: rgb( $textColor-primary );
  padding-bottom: 3em;
  .formWrapper {
    width: 100%;
    .fieldGroupWrapper {
      width: 100%;
      background: rgb( $foregroundColor );
      border-radius: $borderRadius;
      padding: 1em 2em;
      .sectionHeader {
        font-size: 1.125em;
      }
    }
  }
}

.formActionsContainer {
  background: rgb( $backgroundColor );
  padding: 0 1em 1em 1em;
  position: fixed;
  bottom: 0em;
  right: 0em;
  width: calc( 100vw - 5.25em );
  z-index: 2;
  .formActions {
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background: rgb( $foregroundColor );
    z-index: 2;
    border: 1px solid rgb( $dividerColor );
    box-shadow: $boxShadow-light;
    border-radius: $borderRadius-input;
    button {
      @include dsButton('minimal-primary');
      .inlineSVG {
        path {
          fill: rgb( $primary );
        }
      }
      &:first-of-type {
        @include dsButton('minimal-default');
        margin-right: 1em;
        &.disabled, &:disabled {
          color: rgb( $iconColor-primary ) !important;
        }
      }
    }
    &.agentFormActions {
      justify-content: flex-end;
      button {
        @include dsButton('primary');
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
    &.singleButton {
      justify-content: flex-end;
      button {
        @include dsButton('primary');
        border-radius: $borderRadius;
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
  }
}


.modalContainer.reactSetupModal.twoColumn {
  width: 80vw;
  left: 10vw;
  .modalBody {
    .validFormWrapper {
      flex-direction: row;
      .formGroupSection {
        width: 50%;
        box-sizing: border-box;
        padding-right: 2em;
        &:last-of-type {
          padding-right: 0;
          padding-left: 2em;
          border-left: 1px solid rgb( $dividerColor );
        }
      }
    }
  }
  &.scan_credential {
    .modalBody {
      .validFormWrapper {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 50% 50%;
        grid-template-areas: "A B" "A C";
        .formGroupSection {
          width: 100%;
          padding: 0;
          border: none;
        }
        .formGroupSection.left {
          grid-area: A;
          padding-right: 2em;
          border-right: 1px solid rgb( $dividerColor );
        }
        .formGroupSection.topRight {
          grid-area: C;
          padding-left: 2em;
        }
        .formGroupSection.right {
          grid-area: B;
          padding-left: 2em;
        }
      }
    }
  }
}

.modalContainer.reactSetupModal.authentication_provider {
  width: 56em;
  left: calc( 50vw - 28em);
}

// agent specific setup styles
.agentSetupModal {
  .modalBody {
    .validFormWrapper {
      .inputWrapper.weeklySchedule {
        .fieldsWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          .timePickerWrapper, .daysWrapper {
            width: calc( 50% - 1em)
          }
          .daysWrapper {
            border-left: 1px solid rgb( $dividerColor );
            padding-left: 2em;
          }
        }
      }
    }
  }
}

.IPinfoModal {
  .modalBody {
    display: flex;
    flex-direction: row;
    .itemAttribute {
      width: 50%;
      padding: 1em;
      overflow: auto;
      p {
        margin: 1em auto;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: 0.5em;
        &.targetHostsList {
          grid-template-columns: 100%;
        }
        li {
          margin: 0;
          a {
            display: inline-block;
            padding: 0.25em 2.125em 0.25em 0.5em;
            border: 1px solid rgb( $primary );
            border-radius: 0.25em;
            background-image: url('./../../../../images/icons/primary/link.svg');
            background-position: calc( 100% - 0.5em ) center;
            background-size: 1em;
            background-repeat: no-repeat;
            width: 100%;
            &:hover {
              text-decoration: none;
              background: rgb( $primary--10 );
            }
          }
          &.targetHost {
            border: 1px solid rgb( $iconColor-primary );
            border-radius: $borderRadius;
            padding: 0.375em;
            a {
              border: none;
              background: rgb( $backgroundColor );
              cursor: pointer;
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 0.875em;
              color: rgb( $textColor-primary );
              margin-bottom: 0.5em;
              .inlineSVG {
                width: 1.25em;
                height: 1.25em;
                margin-right: 0.5em;
                flex-shrink: 0;
              }
              strong {
                margin-right: 0.5em;
              }
              &.logLink {
                margin-bottom: 0;
              }
              &:hover {
                background: rgb( $primary--10 );
              }
            }
          }
        }
      }
    }
  }
}

.setupTabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  // margin-bottom: 2em;
  // margin-top: 1em;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    background: rgb( $dividerColor );
    border: 1px solid transparent;
    // border-bottom: 1px solid rgb( $iconColor-primary );
    box-sizing: border-box;
    text-transform: uppercase;
    padding: 0.75em 1.25em;
    text-align: center;
    cursor: pointer;
    margin: 0;
    opacity: 0.4;
    font-weight: 600;
    border-radius: 0.125em 0.125em 0 0;
    .tabStep {
      background: rgb( $textColor-primary );
      color: #fff;
      font-size: 1.25;
      font-weight: 600;
      border-radius: $borderRadius-full;
      padding: 0.5em;
      display: block;
      width: 2em;
      height: 2em;
      text-align: center;
      line-height: 1em;
      margin-right: 1em;
    }
    &:hover {
      opacity: 1;
    }
    &.active {
      border: 1px solid rgb( $iconColor-primary );
      border-bottom: 1px solid transparent;
      opacity: 1;
      background: rgb( $foregroundColor );
      cursor: default;
      pointer-events: none;
      margin-bottom: -2px;
    }
  }
}

.tabWrapper {
  border-top: 1px solid rgb( $iconColor-primary );
  padding: 2em 3em;
  margin: 0 -3em;
}
