/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.dashboardEditorV2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  .formWrapper {
    flex-grow: 1;
    padding-right: 1em;
  }
  .addWidgetsWrapper {
    min-width: 22em;
    h2 {
      font-size: 1em;
      margin-bottom: 1em;
    }
    .optionsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-direction: start;
      flex-wrap: wrap;
      .widgetOption {
        font-family: $sans;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: rgb( $textColor-primary );
        transition: all 0.3s ease-in-out;
        padding: 1em;
        border-radius: $borderRadius;
        border: none;
        opacity: 0.75;
        background: none;
        cursor: pointer;
        width: 10em;
        margin-right: 1em;
        margin-bottom: 1em;
        background: rgb( $dividerColor );
        label {
          text-align: center;
          margin-top: 1em;
          transition: all 0.3s ease-in-out;
        }
        .inlineSVG {
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          opacity: 1;
          background: rgb( $textColor-primary );
          label {
            color: #fff;
          }
          .inlineSVG {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .editorActionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px solid rgb( $dividerColor );
    width: 100%;
    flex-shrink: 0;
    button {
      @include dsButton( 'primary' );
      &.cancelButton {
        @include dsButton( 'minimal' );
        margin-right: 1em;
      }
      &.deleteButton {
        @include dsButton( 'status-alert' );
      }
    }
    .buttonWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}