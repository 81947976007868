/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.setupRecordItem.tag.alternateLayout {
  grid-template-columns: 1fr 6em !important;
  .mainDetailsSection {
    display: grid !important;
    grid-template-columns: 20em 1fr 1fr;
    align-items: flex-start !important;
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 1em;
      flex-shrink: 0;
      h2 {
        padding: 0.25em 1.5em;
        display: block;
        border-radius: $borderRadius-full;
        text-align: center;
      }
      span {
        color: rgb( $textColor-primary ) !important;
        font-size: 1em;
        font-weight: 400;
        strong, label {
          font-weight: 600;
          margin-right: 0.25em;
        }
      }
    }
    section {
      flex-shrink: 1;
      align-items: flex-start;
      .sectionLabel {
        display: block;
        margin-bottom: 0.5em;
        font-weight: 600;
        columns: $textColor-primary;
        font-size: 1em;
      }
      span.emptyString {
        color: rgb( $textColor-primary ) !important;
        font-weight: 400;
      }
    }
    .column, section {
      height: 100%;
    }
    .tagStringWrapper {
      color: rgb( $textColor-primary ) !important;
      font-size: 1em;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      span {
        color: rgb( $textColor-primary ) !important;
        font-weight: 400 !important;
        font-size: 1em;
        padding: 0.125em 0.5em;
        border-radius: $borderRadius;
        display: block;
        margin-right: 0.25em;
        margin-bottom: 0.25em;
        margin-top: 0;
        code {
          font-weight: 600 !important;
        }
        &.included {
          border: 1px solid rgba( $status-success, 0.5 );
          background: rgba( $status-success, 0.05 ) !important;
        }
        &.excluded {
          border: 1px solid rgba( $status-alert, 0.5 );
          background: rgba( $status-alert, 0.05 ) !important;
        }
      }
    }
  }
};