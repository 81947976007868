/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.inputWrapper.checkbox {
  label {
    flex-wrap: nowrap;
    align-items: center;
    text-transform: none;
    .labelWrapper {
      flex-grow: 1;
      margin-left: 1em;
      text-transform: none;
      color: rgb( $textColor-secondary );
      font-size: 1em;
      input[type="text"], input[type="number"] {
        background: rgb( $dividerColor );
        border: 1px solid rgb( $iconColor-primary );
        border-radius: 0.25em;
        color: rgb( $textColor-primary );
        width: 4em;
        margin-left: 1em;
        margin-top: 0;
        padding: 0.25em 0.5em;
      }
    }
    input[type='checkbox'] {
      margin: 0;
      width: auto;
      transform: scale(1);
    }
  }
}

.checkboxFieldWrapper {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  background-image: url('./../../../../../images/icons/input/checkbox.svg');
  background-repeat: no-repeat;
  background-size: contain;
  &.checked {
    background-image: url('./../../../../../images/icons/input/checkbox-checked.svg');
  }
  &.disabled {
    pointer-events: none;
    background-image: url('./../../../../../images/icons/input/checkbox-disabled.svg');
    &.checked {
      background-image: url('./../../../../../images/icons/input/checkbox-checked-disabled.svg');
    }
  }
  input {
    width: 0;
    height: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    visibility: hidden;
  }
  &.asToggle {
    border-radius: $borderRadius-full;
    background: rgb( $dividerColor );
    padding: 2px;
    width: auto;
    height: auto;
    background-image: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    flex-shrink: 0;
    font-size: 0.875em;
    .labelWrapper {
      transition: all 0.15s ease-in-out;
      padding: 0.375em 0.75em;
      width: auto;
      background: rgb( $foregroundColor );
      margin-right: 2em;
      margin-left: 0;
      border-radius: $borderRadius-full;
      // text-transform: none;
    }
    &:hover {
      background: rgb( $iconColor-primary );
      .labelWrapper {
        box-shadow: $boxShadow;
      }
    }
    &.checked {
      background: rgb( $primary );
      .labelWrapper {
        margin-right: 0;
        margin-left: 2em;
        color: rgb( $primary );
      }
      &:hover {
        background: rgb( $primary );
        .labelWrapper {
          color: rgb( $primary );
        }
      }
    }
  }
}
