/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.modalContainer.large.reactSetupModal.tag {
  .modalBody {
    padding: 1em 2em 4em 2em;
    padding-bottom: 4em;
    // required form fields (upper)
    .nameAndColorLabel {
      display: block;
      line-height: 1.5em;
      font-size: 1em;
      margin-bottom: 0.5em;
      font-weight: 500;
      color: rgb( $textColor-primary );
    }
    .formWrapper {
      &.requiredFieldsFormWrapper {
        display: grid;
        grid-template-columns: 1fr 24em;
        grid-column-gap: 1.5em;
        .fieldGroupWrapper {
          padding: 0;
          margin: 0;
          &.name {
            display: grid;
            grid-template-columns: 1fr 12em;
            grid-column-gap: 0.5em;
            align-items: center;
            border-radius: $borderRadius-input;
            box-shadow: $boxShadow-input;
            border: 1px solid rgb( $iconColor-primary );
            padding: 0.375em;
            height: 3.375em;
            .fieldWrapper {
              &.text, &.colorSelect {
                margin-bottom: 0;
                label {
                  .labelWrapper {
                    display: none;
                  }
                }
              }
              &.text {
                flex-grow: 1;
                input[type="text"] {
                  padding: 0.375em 1em;
                  border: none;
                  box-shadow: none;
                  border-radius: $borderRadius-input;
                }
              }
              &.colorSelect {
                .dropdownTrigger {
                  border: none;
                  box-shadow: none;
                  border-radius: 0 $borderRadius-input $borderRadius-input 0;
                  border-left: 1px solid rgb( $dividerColor );
                  padding-left: 0.5em;
                }
              }
            }
          }
          &.owner {
            padding: 0;
            margin: 0;
            margin-top: -2em;
            .fieldWrapper {
              margin-bottom: 0;
              select {
                padding: 0.875em 2em 0.875em 1.25em;
                background-position: calc(100% - 1.25em) 50%;
              }
            }
          }
        }
      }

    }
    .tagDefinitionWrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      h2 {
        font-weight: 600;
        font-size: 1em;
        margin: 1em 0;
        padding: 0;
        width: 100%;
      }
      .toggleDefinitionModalButton {
        @include dsButton( 'minimal-dark' );
        border: 1px dashed rgb( $textColor-secondary );
        background: rgb( $backgroundColor );
        width: 10em;
        text-align: center;
        justify-content: space-around;
      }
      .testDefinitionButton {
        @include dsButton( 'primary' );
        background: rgb( $textColor-primary );
        position: absolute;
        right: 0;
        top: 3.5em;
        &:hover {
          background: rgba( $textColor-primary, 0.6 );
        }
        &.disabled, &:disabled {
          background: rgba( $textColor-primary, 0.5 );
        }
      }
      .appliedFilters {
        margin: 0;
        width: calc( 100% - 22em );
        padding: 0 1em;
        .appliedFilterButton {
          margin: 0 0.5em 0.5em 0;
          button {
            .inlineSVG {
              width: 1.375em;
              height: 1.375em;
            }
          }
        }
        .clearFiltersButton {
          margin-top: 0;
          margin-bottom: 0.375em;
        }
      }
      .definitionFormModalShade {
        background: rgba( $textColor-primary, 0.2 );
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        &.visible {
          visibility: visible;
          height: 100vh;
          width: 100vw;
          overflow: unset;
        }
      }
      .definitionFormModal {
        position: absolute;
        visibility: hidden;
        height: 0;
        width: 0;
        overflow: hidden;
        top: 6.5em;
        left: 0;
        padding: 0;
        background: rgb( $foregroundColor );
        border-radius: $borderRadius-input;
        .definitionFormHeader {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.5em;
        }
        .formWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1em;
          .fieldGroupWrapper {
            padding: 0;
            margin: 0;
            padding: 1em;
            border: 1px solid rgb( $status-success )--50;
            background: rgb( $status-success--10 );
            border-radius: $borderRadius;
            &.to_exclude {
              border: 1px solid rgb( $status-alert )--40;
              background: rgb( $status-alert--10 );
            }
            .fieldWrapper {
              textarea {
                resize: none;
                height: 11em;
              }
              &.select {
                .multiSelectWrapper {
                  .multiSelectOptions {
                    max-height: 26em;
                    overflow: auto;
                    top: unset;
                    bottom: 4em;
                  }
                }
              }
            }
          }
        }
        .definitionFormFooter {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding-top: 1em;
          button {
            @include dsButton( 'minimal-primary' );
          }
        }
        &.visible {
          visibility: visible;
          height: unset;
          width: 50vw;
          min-width: 60em;
          overflow: unset;
          padding: 1em;
          border: 1px solid rgb( $iconColor-primary );
          box-shadow: $boxShadow;
        }
      }
    }
    .tagTestTableDescription {
      width: 100%;
      font-size: 1em;
      color: rgb( $textColor-primary );
      padding: 1em 1.5em;
      background: rgb( $backgroundColor );
      margin: 1em 0;
      border-radius: $borderRadius;
    }
    .tagTestTableHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .indeterminantPagination.tagTestPagination {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .tagTestTableWrapper {
      margin: 0 -2em;
      .dataTable {
        border-top: 1px solid rgb( $dividerColor );
        margin-top: 1em;
        .tableRow {
          padding: 0 1em;
          grid-template-columns: 24em 1fr 25em 27em 4em;
          .tableCell {
            &.headerCell_DeepSurface {
              border-right: none;
            }
            &.tableCell_name {
              a {
                font-weight: 600;
                color: rgb( $textColor-primary );
                transition: all 0.3s ease-in-out;
                &:hover {
                  text-decoration: underline;
                  color: rgb( $primary );
                }
              }
            }
          }
          &.tableHeader {
            position: static;
            z-index: unset;
            .headerCell {
              position: static;
              z-index: unset;
            }
          }
        }
      }
    }
  }
}