/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordList {
  li {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &.vulnerabilities {
    .recordListItem {
      a {
        .itemContent {
          grid-template-columns: 9em 1fr 4em;
        }
      }
    }
    &.orderBy-exploit_status {
      a {
        .itemContent {
          grid-template-columns: 9em 1fr 12em;
        }
      }
    }
    &.orderBy-cvss_base_score {
      a {
        .itemContent {
          grid-template-columns: 9em 1fr 2em;
        }
      }
    }
  }
  &.products, &.descendants, &.domain_groups, &.software {
    background: rgb( $backgroundColor );
    border-radius: $borderRadius;
    padding: 1em;
    .unformatted {
      display: block;
      margin-bottom: 0.75em;
      font-family: monospace, monospace;
    }
    .recordListItem {
      &:last-child {
        margin-bottom: 0;
        .unformatted {
          margin-bottom: 0;
        }
      }
    }
  }
  &.orderBy-exploit_status {
    li {
      .exploitStatusIcons {
        .inlineSVG {
          transition: all 0.3s ease-in-out;
          width: 1.25em;
          height: 1.25em;
          opacity: 1;
          margin-right: 0.5em;
        }
      }
      a, .notLink {
        &:hover {
          .exploitStatusIcons {
            .inlineSVG {
              path {
                fill: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}