/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.widgetHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgb( $textColor-primary );
  font-weight: 400;
  margin-bottom: 1em;
  font-size: 1.125em;
  color: rgba( $textColor-primary, 0.8 );
  width: 100%;
  flex-wrap: wrap;
  strong {
    font-weight: 600;
    color: rgb( $textColor-primary );
    &.widgetHeaderLabelcolon {
      margin-right: 0.25em;
    }
  }
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}