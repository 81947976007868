/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.filterBuilderWrapper {
  z-index: 999999999;
  position: relative;
  width: calc( 100% - 15em );
  margin-right: 14em;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: row;
  .filterBuilderTrigger {
    // @include dsButton('minimal-primary');
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    max-height: 2.5em;
    padding: 3px;
    padding-right: 1.25em;
    border-radius: $borderRadius-input;
    border: none;
    background: rgba( $primary, 0.2 );
    color: rgb( $primary );
    transition: all 300ms ease-in-out;
    cursor: pointer;
    .iconWrapper {
      background: rgba( #fff, 0.9 );
      border-radius: $borderRadius;
      padding: 0.375em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-right: 0.75em;
      flex-shrink: 0;
      font-weight: 600;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        path {
          fill: rgba( $primary, 0.9 );
        }
      }
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .inlineSVG {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        path {
          fill: rgb( $primary );
        }
      }
    }
    &:hover {
      background: rgba( $primary, 0.3 );
    }
  }
  .filterBuilderShade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba( $textColor-primary, 0.2 );
    z-index: 999998;
  }
  .filterBuilderForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em;
    border: 1px solid rgb( $dividerColor );
    border-radius: $borderRadius-input;
    background: rgb( $foregroundColor );
    box-shadow: $boxShadow-light;
    flex-shrink: 0;
    position: absolute;
    top: -0.5em;
    left: 0em;
    z-index: 999999;
    max-width: calc( 100% + 14em );
    .fieldWrapper {
      z-index: 2;
      margin-bottom: 0;
      .labelWrapper {
        display: none;
      }
      input {
        border-radius: $borderRadius;
      }
    }
    .filterAttributeWrapper {
      position: relative;
      flex-shrink: 0;
      input.filterAttributeInput {
        border: none;
        padding: 0.5em 1em;
        background: rgb( $backgroundColor );
        box-shadow: none;
        width: auto;
        padding: 0.5em 1em;
        border-radius: $borderRadius;
      }
      &.isFocused {
        input.filterAttributeInput {
          padding: 0.5em 1em;
          margin: 0;
          transition: all 0.3s ease-in-out;
          background: rgb( $foregroundColor );
          border: 1px solid rgba( $iconColor-primary, 0.75 );
          color: rgba( $textColor-primary, 0.85 );
          box-shadow: $boxShadow-input;
          border-radius: $borderRadius;
          &:focus {
            outline: none;
            color: rgb( $textColor-primary );
          }
        }
      }
      &.isValid {
        input.filterAttributeInput {
          background: rgb( $backgroundColor );
          color: rgb( $textColor-primary );
        }
      }
    }
    .filterComparatorWrapper {
      margin: 0 0.75em;
      flex-shrink: 0;
      .singleComparatorWrapper {
        padding: 0.5em 1em;
        background: rgb( $backgroundColor );
        box-shadow: none;
        border-radius: $borderRadius-input;
        font-weight: 600;
        color: rgba( $textColor-primary, 0.85);
        border: 1px solid rgb( $backgroundColor );
        box-sizing: border-box;
      }
      &.isValid {
        .singleComparatorWrapper {
          background: rgb( $backgroundColor );
          color: rgb( $textColor-primary );
          border: 1px solid rgb( $status-success )--10;
        }
      }
    }
    .autoCompleteOptionsWrapper {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      width: calc( 100% + 1em );
      top: 3em;
      left: -0.5em;
      padding: 0.5em;
      padding: 0.5em;
      border: 1px solid rgba( $iconColor-primary, 0.75 );
      border-radius: $borderRadius-input;
      color: rgb( $textColor-primary );
      box-shadow: $boxShadow;
      background: rgb( $foregroundColor );
      max-height: 50vh;
      overflow: auto;
      li {
        padding: 0;
        margin: 0;
        width: 100%;
        margin-bottom: 0.375em;
        button {
          @include dsButton('minimal');
          padding: 0.375em 0.75em;
          text-align: left;
          justify-content: flex-start;
          transition: all 300ms ease-in-out;
          width: 100% !important;
          border-radius: $borderRadius;
          color: rgb( $textColor-primary );
          font-weight: 400;
          opacity: 1;
          border: 1px dashed transparent;
          &.current {
            background: rgb( $backgroundColor );
            border: 1px dashed rgb( $iconColor-primary);
          }
        }
      }
    }
    .fieldWrapper {
      &.select2 {
        .selectV2Wrapper {
          .selectV2Trigger.isMultiSelect {
            flex-wrap: wrap;
            .selectionsWrapper {
              flex-wrap: wrap;
              margin-bottom: -0.25em;
              .selectionWrapper {
                margin-bottom: 0.25em;
              }
            }
          }
        }
        .selectionOverflow {
          flex-shrink: 0;
        }
      }
      &.textarea {
        textarea {
          padding: 0.375em 1em;
          margin: 0;
          min-width: 40em;
          height: 2.5em;
        }
      }
      &.date {
        input[ type="date" ] {
          margin-top: 0;
        }
      }
    }
    .finishFilterButton {
      @include dsButton( 'minimal-minimal' );
      padding: 0.5em 1em;
      margin-left: 0.5em;
      flex-shrink: 0;
      .inlineSVG {
        path {
          fill: rgb( $status-success );
        }
      }
    }
  }
  &.darkMode {
    .filterBuilderTrigger {
      background: rgba( $primary, 0.85 );
      color: rgb( $textColor-secondary );
      .iconWrapper {
        background: rgba( #fff, 0.5 );
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }
      span {
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }
    }
    .filterBuilderForm {
      .filterAttributeWrapper {
        input.filterAttributeInput {
          background: rgba( #fff, 0.1 );
        }
      }
      .filterComparatorWrapper {
        .singleComparatorWrapper {
          background: rgba( #fff, 0.1 );
        }
      }
      .finishFilterButton {
        @include dsButton( 'minimal-darkMode' );
        background: rgb( $status-success );
        color: rgb( $textColor-secondary );
        padding: 0.5em 1em;
        margin-left: 0.5em;
        flex-shrink: 0;
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }
    }
  }
}