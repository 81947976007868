/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.assetTagsModalAndListWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .showAllTagsButton {
    background: none;
    padding: 0;
    margin: 0;
    margin-left: 0.5em;
    color: rgb( $iconColor-primary );
    border: none;
    cursor: pointer;
    font-size: 0.875em;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: rgb( $primary );
      text-decoration: underline;
    }
  }
  .allAssetTagsMask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99998;
  }
  .allAssetTagsModal {
    position: absolute;
    z-index: 99999;
    right: -9em;
    padding: 0.5em;
    border-radius: $borderRadius-input;
    box-shadow: $boxShadow-heavy;
    background: rgb( $foregroundColor );
    width: unset;
    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1em !important;
      margin: 0 !important;
      padding: 0 !important;
      margin-bottom: 0.5em !important;
      button {
        font-size: 0.75em;
      }
    }
    ul {
      display: flex;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      padding-right: 0 !important;
      li {
        margin-right: 0 !important;
        margin-bottom: 0.75em !important;
        font-weight: 600 !important;
        font-size: 0.75em !important;
        padding: 0.375em 1.25em !important;
        border-radius: $borderRadius-full;
      }
    }
  }
}

.assetTagListWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0 !important;
  .assetTagListItem {
    font-weight: 600;
    margin-right: 0.75em !important;
    margin-bottom: 0;
    font-size: 0.75em;
    &.emptyTag {
      color: rgb( $textColor-secondary );
      background: rgb( $backgroundColor );
    }
    &.darkMode {
      background: none !important;
    }
  }
  &.vertical {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    .assetTagListItem {
      margin-right: 0 !important;
      margin-bottom: 0.5em !important;
    }
  }
}