/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../../styles/globals.scss" as *;

.modalContainer.remediation_plan {
  .tabWrapper.select {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    padding: 2em !important;
    padding-bottom: 4.5em !important;
    height: calc( 95vh - 3em ) !important;
    align-items: flex-start;
    .panel {
      padding: 0;
      max-height: unset;
      height: unset;
      padding-right: 2em;
      border-right: 1px solid rgb( $dividerColor );
      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }
      &.searchResults {
        position: relative;
        height: calc( 95vh - 17.5em );
        .recordList {
          overflow: auto;

        }
      }
      &.selectedItems {
        overflow: auto;
      }
    }
    .formWrapper {
      padding: 0;
      margin: 0;
      margin-bottom: 1.75em;
      .fieldGroupWrapper {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: rgb( $foregroundColor );
        border: 1px solid rgba( $iconColor-primary, 0.75 );
        border-radius: $borderRadius-input;
        color: rgba( $textColor-primary, 0.85 );
        box-shadow: $boxShadow-input;
        padding: 0.375em;
        margin: 0;
        .fieldWrapper {
          margin: 0;
          padding: 0;
          &:nth-of-type( 2 ) {
            flex-grow: 1;
          }
          label {
            .labelWrapper {
              display: none;
            }
          }
          .selectFieldWrapper {
            border: none;
            box-shadow: none;
            border-radius: none;
            select {
              border: none;
              box-shadow: none;
              border-radius: 0;
              padding: 0.375em 2.5em 0.375em 1em;
              appearance: unset;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              border-right: 1px solid rgb( $dividerColor );
              &:hover {
                background-color: rgb( $backgroundColor );
                border-radius: $borderRadius;
                border-right: 1px solid transparent;
              }
              &:focus {
                outline: $primary;
                background-color: rgb( $backgroundColor );
                border-radius: $borderRadius;
                border-right: 1px solid transparent;
              }
            }
          }
          input[type="text"] {
            border: none;
            box-shadow: none;
            border-radius: $borderRadius;
            flex-grow: 1;
            padding: 0;
            padding: 0.375em 1em;
            margin-left: 0.5em;
          }
          label {
            &:nth-of-type(2) {
              flex-grow: 1;
            }
          }
          .debouncedTextFieldWrapper {
            flex-grow: 1;
          }
        }
      }
    }
    &.isActive {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .stepDirections {
        width: 100%;
      }
      .panel {
        width: 100%;
      }
    }
  }
}