/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.override {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  label {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .labelWrapper {
      width: auto;
    }
    &.inline {
      flex-wrap: nowrap;
      flex-grow: 1;
      .labelWrapper {
        margin-bottom: 0;
      }
    }
    input,
    .checkboxFieldWrapper {
      margin: 0;
      &.asToggle {
        margin-right: 1em;
      }
    }

    .checkboxFieldWrapper {
      width: 1.75em;
      height: 1.75em;
      background-position: right center;
      margin-left: 0.25em;
      .labelWrapper {
        padding-top: 0.125em;
        padding-right: 2em;
      }
      &.asToggle {
        width: unset;
        height: unset;
        input[type="checkbox"] {
          width: 0;
          height: 0;
          border: none;
        }
        .labelWrapper {
          width: auto;
          padding-right: 0;
          padding-left: 0;
          padding-top: 0;
          padding: 0.375em 0.75em;
          margin-bottom: 0;
          line-height: unset;
        }
      }
    }
    input[type='number'] {
      width: 5em;
      padding: 0.5em;
      outline: none;
      background: rgb( $dividerColor );
      border: 1px solid rgb( $iconColor-primary );
      border-radius: $borderRadius-input;
      color: rgb( $textColor-primary );
      margin-left: 1em;
      &:disabled {
        opacity: 0.4;
      }
    }
    .selectFieldWrapper {
      select {
        margin-top: 0;
        margin-left: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 2.5em;
        border-radius: $borderRadius-input;
      }
    }
    .ratingBadge {
      height: unset;
    }
  }
  &.updated, &.invalid {
    label {
      padding-left: 0em;
      &:before {
        display: none;
      }
      .labelWrapper {
        padding-left: 0;
      }
      &.inline {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}
