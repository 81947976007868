/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.ratingBadge {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 2em;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  width: 8em;
  border: 1px solid rgb( $textColor-secondary );
  background: rgb( $backgroundColor );
  color: rgb( $textColor-secondary );
  border-radius: $borderRadius-full;
  font-family: $sans;
  &.success, &.active, &.green {
    background: rgb( $status-success--10 );
    color: rgb( $status-success );
    border-color: rgb( $status-success );
  }
  &.red, &.alert {
    background: rgb( $status-alert--10 );
    color: rgb( $status-alert );
    border-color: rgb( $status-alert );
  }
  &.critical, &.Critical {
    background: rgb( $critical--10 );
    color: rgb( $critical );
    border-color: rgb( $critical );
  }
  &.high, &.High, &.important, &.Important {
    background: rgb( $high--10 );
    color: rgb( $high );
    border-color: rgb( $high );
  }
  &.moderate, &.Moderate, &.medium, &.Medium {
    background: rgb( $moderate--10 );
    color: rgb( $moderate );
    border-color: rgb( $moderate );
  }
  &.low, &.Low {
    background: rgb( $low--10 );
    color: rgb( $low );
    border-color: rgb( $low );
  }
  &.minimal, &.Minimal {
    background: rgb( $minimal--10 );
    color: rgb( $minimal );
    border-color: rgb( $minimal );
  }
  &.negligible {
    background: rgb( $primary--10 );
    color: rgb( $primary );
    border-color: rgb( $primary );
  }
  &.unknown {
    background: rgb( $backgroundColor );
    color: rgb( $textColor-secondary );
    border-color: rgb( $textColor-secondary );
  }
  &.altVersion {
    border: 1px solid rgb( $textColor-secondary );
    background: rgb( $textColor-secondary );
    color: #fff;
    &.success, &.active, &.green {
      background: rgb( $status-success );
      color: #fff;
      border-color: rgb( $status-success );
    }
    &.red, &.alert {
      background: rgb( $status-alert );
      color: #fff;
      border-color: rgb( $status-alert );
    }
    &.critical, &.Critical {
      background: rgb( $critical );
      color: #fff;
      border-color: rgb( $critical );
    }
    &.high, &.High, &.important, &.Important {
      background: rgb( $high );
      color: #fff;
      border-color: rgb( $high );
    }
    &.moderate, &.Moderate, &.medium, &.Medium {
      background: rgb( $moderate );
      color: #fff;
      border-color: rgb( $moderate );
    }
    &.low, &.Low {
      background: rgb( $low );
      color: #fff;
      border-color: rgb( $low );
    }
    &.minimal, &.Minimal {
      background: rgb( $minimal );
      color: #fff;
      border-color: rgb( $minimal );
    }
    &.negligible {
      background: rgb( $primary );
      color: #fff;
      border-color: rgb( $primary );
    }
    &.unknown {
      background: rgb( $textColor-secondary );
      color: #fff;
      border-color: rgb( $textColor-secondary );
    }
  }
  &.beta {
    background: rgb( $primary--10 );
    color: rgb( $primary );
    border-color: rgb( $primary );
    text-transform: capitalize;
    height: 1.75em;
    font-size: 0.75em;
    width: 4em;
    display: inline-flex;
    margin-left: 1em;
    &.altVersion {
      background: rgb( $primary );
      color: #fff;
    }
  }
  &.new {
    background: rgb( $status-success--10 );
    color: rgb( $status-success );
    border-color: rgb( $status-success );
    text-transform: capitalize;
    height: 1.75em;
    font-size: 0.75em;
    width: 4em;
    display: inline-flex;
    margin-left: 1em;
    &.altVersion {
      background: rgb( $status-success );
      color: #fff;
    }
  }
  &.autoprovisioning {
    width: 13em;
  }
  &.cvssRatingBadge {
    width: 11em;
  }
  &.darkMode {
    background: none;
    &.success, &.active, &.green {
      background: none;
    }
    &.red, &.alert {
      background: none;
    }
    &.critical, &.Critical {
      background: none;
    }
    &.high, &.High, &.important, &.Important {
      background: none;
    }
    &.moderate, &.Moderate, &.medium, &.Medium {
      background: none;
    }
    &.low, &.Low {
      background: none;
    }
    &.minimal, &.Minimal {
      background: none;
    }
    &.negligible {
      background: none;
    }
    &.unknown {
      background: none;
    }
    &.altVersion {
      background: unset;
      &.success, &.active, &.green {
        background: rgb( $status-success );
      }
      &.red, &.alert {
        background: rgb( $status-alert );
      }
      &.critical, &.Critical {
        background: rgb( $critical );
      }
      &.high, &.High, &.important, &.Important {
        background: rgb( $high );
      }
      &.moderate, &.Moderate, &.medium, &.Medium {
        background: rgb( $moderate );
      }
      &.low, &.Low {
        background: rgb( $low );
      }
      &.minimal, &.Minimal {
        background: rgb( $minimal );
      }
      &.negligible {
        background: rgb( $primary );
      }
      &.unknown {
        background: rgb( $textColor-secondary );
      }
    }
    &.beta {
      background: none;
      &.altVersion {
        background: rgb( $primary );
        color: rgb( $textColor-secondary );
      }
    }
    &.new {
      background: none;
      &.altVersion {
        background: rgb( $status-success );
        color: rgb( $textColor-secondary );
      }
    }
  }
}