/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/

@use "../../../../../styles/globals.scss" as *;
ul.setupRecordsList {
  li.setupRecordItem.export_item.alternateLayout {
    grid-template-columns: 2fr 1fr 24em;
    &.focus {
      border: 2px solid rgb( $primary );
      box-shadow: $boxShadow-heavy;
    }
    section {
      &.mainDetailsSection {
        h2 {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            margin: 0;
            margin-right: 0.25em;
            &.running {
              -webkit-animation: spin 2s linear infinite;
              -moz-animation: spin 2s linear infinite;
              animation: spin 2s linear infinite;
            }
          }
        }
        .column {
          // width: 100%;
          .reportDetails {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0.5em 0.75em;
            border-radius: $borderRadius;
            background: rgb( $backgroundColor );
            margin-top: 0.5em;
            margin-bottom: 0.25em;
            span {
              margin: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              color: rgb( $textColor-primary );
              &:first-of-type {
                margin-right: 1.5em;
              }
            }
            .inlineSVG {
              width: 1em;
              height: 1em;
              margin-right: 0.5em;
            }
          }
          span {
            color: rgb( $textColor-secondary );
            font-weight: 400;
            strong {
              font-weight: 600;
              color: rgb( $textColor-primary );
              text-transform: uppercase;
              font-size: 0.875em;
            }
          }
        }
      }
      &.doubleSection {
        strong {
          &:last-of-type {
            margin-top: 2em;
          }
        }
      }
    }
    .taskRunnerButton {
      margin-left: 1em;
    }
  }
}
