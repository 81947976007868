/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetailsMainPanel {
  width: 100%;
  .recordDetails__MainPanel__Body {
    padding: 2em;
    background: rgb( $foregroundColor );
    border-radius: 0 $borderRadius $borderRadius $borderRadius;
    min-height: 50vh;
    position: relative;
    .recordDetails__MainPanel_BodyRow {
      padding: 2em 0;
      border-bottom: 1px solid rgb( $dividerColor );
      &.riskAssessment {
        padding-top: 0;
        .recordDetails__InformationPanel__Body_Section.statistics {
          &.patch, &.user {
            padding: 0 4em;
          }
        }
        &.host {
          padding-top: 0.5em;
          .recordDetails__InformationPanel__Body_Section.statistics {
            justify-content: flex-end;
            flex-wrap: wrap;
            .recordStatisticWrapper {
              margin-left: 1.5em;
              margin-bottom: 1.5em;
              margin-top: 1.5em;
            }
          }
        }
      }
      &.pathsAndAssets {
        .criticalPathsWrapper {
          padding: 1em;
          border-radius: $borderRadius;
          background: rgb( $backgroundColor );
          position: relative;
          .emptyState {
            padding: 1em;
            font-size: 1.25em;
            background: rgb( $backgroundColor );
            border-radius: $borderRadius;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
          }
          svg {
            max-height: 29em;
          }
          &.pathCount_5 {
            svg {
              max-height: 38em;
            }
          }
          .fullScreenVisualToggleButton {
            position: absolute;
            bottom: 0.5em;
            right: 0.5em;
          }
          &.darkMode {
            background: none;
          }
        }
        &.host {
          display: grid;
          grid-template-columns: 28em 1fr;
          grid-column-gap: 1em;
        }
      }
      &.recordLists {
        .collapsibleSectionsWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1em;
        }
        padding-bottom: 0;
        border-bottom: none;
      }
      .mainPanel__SectionDescription {
        margin-top: -1em;
        grid-column: 1 / 3;
        color: rgb( $textColor-primary );
      }
      h2, h3 {
        grid-column: 1 / 3;
        font-size: 1em;
        font-weight: 600;
        margin: 0;
        padding: 0;
        color: rgb( $textColor-primary );
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
        .remediationInstructionsButton {
          @include dsButton( 'minimal-primary' );
          margin-left: 1em;
          &.darkMode {
            @include dsButton( 'primary-darkMode' );
            margin-left: 1em;
          }
        }
        .addToPlanMenuWrapper {
          position: relative;
          z-index: unset;
          top: unset;
          left: unset;
          right: unset;
          .addToPlanMenuTriggerWrapper {
            .addToPlanMenuTrigger {
              @include dsButton( 'minimal-critical');
              .inlineSVG {
                opacity: 1;
                path {
                  fill: rgb( $critical );
                }
              }
              &.high {
                @include dsButton( 'minimal-high');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: rgb( $high );
                  }
                }
              }
              &.moderate {
                @include dsButton( 'minimal-moderate');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: rgb( $moderate );
                  }
                }
              }
              &.low {
                @include dsButton( 'minimal-low');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: rgb( $low );
                  }
                }
              }
              &.minimal {
                @include dsButton( 'minimal-minimal');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: rgb( $low );
                  }
                }
              }
              &.unknown {
                @include dsButton( 'minimal-dark' );
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: rgb( $textColor-primary );
                  }
                }
              }

            }
            &.darkMode {
              .addToPlanMenuTrigger {
                @include dsButton( 'critical-darkMode');
                .inlineSVG {
                  opacity: 1;
                  path {
                    fill: rgb( $textColor-primary );
                  }
                }
                &.high {
                  @include dsButton( 'high-darkMode');
                  .inlineSVG {
                    opacity: 1;
                    path {
                      fill: rgb( $textColor-primary );
                    }
                  }
                }
                &.moderate {
                  @include dsButton( 'moderate-darkMode');
                  .inlineSVG {
                    opacity: 1;
                    path {
                      fill: rgb( $textColor-secondary );
                    }
                  }
                }
                &.low {
                  @include dsButton( 'low-darkMode');
                  .inlineSVG {
                    opacity: 1;
                    path {
                      fill: rgb( $textColor-secondary );
                    }
                  }
                }
                &.minimal {
                  @include dsButton( 'minimal-darkMode');
                  .inlineSVG {
                    opacity: 1;
                    path {
                      fill: rgb( $textColor-primary );
                    }
                  }
                }
                &.unknown {
                  @include dsButton( 'primary-darkMode' );
                  .inlineSVG {
                    opacity: 1;
                    path {
                      fill: rgb( $textColor-secondary );
                    }
                  }
                }
              }
            }
          }
        }
      }
      .bodySectionItem {
        margin-bottom: 1em;
        label {
          margin-bottom: 0.5em;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: rgb( $textColor-primary );
        }
        .bodySectionDescription {
          padding: 0.5em 1em;
          background: rgb( $backgroundColor );
          border-radius: $borderRadius;
          margin-bottom: 1em;
          color: rgb( $textColor-primary );
          &.darkMode {
            background: rgba( #fff, 0.2 );
          }
        }
        ul {
          color: rgb( $textColor-primary );
          li {
            color: rgb( $textColor-primary );
            &.uniqueScanner {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 0.375em 0.5em 0.375em 0.75em;
              border-radius: $borderRadius-full;
              margin-bottom: 0.625em;
              border: 1px solid rgba( $iconColor-primary, 0.5 );
              color: rgb( $textColor-primary );
              .logoAndLabel {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding-right: 1em;
              }
              img {
                width: 1.5em;
                height: 1.5em;
                margin-right: 0.5em;
              }
            }
          }
        }
      }
    }
    .recordDetails__InformationPanel__Body_Section {
      &.statistics {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .recordStatisticWrapper {
          margin-bottom: 0;
          .statisticLabel {
            font-size: 1em;
          }
        }
      }
    }
  }
}