/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.modalContainer.twoColumn.reactSetupModal.scan_credential {
  width: 90vw;
  left: 5vw;
  max-height: 90vh;
  top: 5vh;
  padding-bottom: 4.5em;

  .modalHeader {
    box-shadow: $boxShadow;
    border-bottom: 1px solid rgb( $iconColor-primary );
  }
  .modalBody {
    max-height: calc( 90vh - 8em);
    overflow: hidden;
    padding: 0;
    .scanCredentialsModalWrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 22em calc( 100% - 22em );
      .column {
        .columnHeader {
          width: calc( 100% + 3em );
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background: rgba( $textColor-primary, 0.05 );
          padding: 0.75em 1.5em;
          margin: 0 -1.5em 1em -1.5em;
          border-bottom: 1px solid rgb( $dividerColor );
          .stepNumber {
            flex-shrink: 0;
            width: 2em;
            height: 2em;
            border-radius: $borderRadius-full;
            color: #fff;
            font-weight: 600;
            background: rgb( $textColor-secondary );
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
          }
          h3 {
            margin: 0;
            padding: 0;
            padding-left: 0.75em;
            font-weight: 400;
            strong {
              font-weight: 600;
            }
          }
        }
        .formWrapper {
          .fieldGroupWrapper {
            padding: 0;
            margin: 0;
            border: none;
          }
        }
        // left column, selection pain
        &:first-of-type {
          background: rgb( $backgroundColor );
          padding: 1.5em;
          padding-top: 0;
          overflow: hidden;
        }
        // right column, full form
        &.fullForm {
          padding: 1.5em;
          padding-top: 0;
          box-shadow: $boxShadow;
          max-height: calc( 90vh - 8.2em);
          overflow: auto;
          .formWrapper {
            display: block;
            .fieldGroupWrapper {
              .sectionHeader {
                font-size: 1.125em;
              }
              .fieldWrapper {
                label {
                  .labelWrapper {
                    font-size: 0.875em;
                  }
                }
                &#ports_textarea_fieldWrapper {
                  textarea {
                    resize: none;
                    height: 2.75em;
                    padding: 0.75em 1em;
                    line-height: 1em;
                  }
                }
              }
            }
            &.withPAM {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              .fieldGroupWrapper {
                width: 100%;
                order: 2;
                &.pam {
                  order: 1;
                  padding: 1em;
                  padding-bottom: 0;
                  border-radius: $borderRadius;
                  background: rgba( $primary--10, 0.5 );
                  border: 1px solid rgba( $textColor-primary, 0.25 );
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: 2em;
                  margin-bottom: 1em;
                  .sectionHeader {
                    grid-column: 1 / 3;
                  }
                }
                &.common {
                  padding-left: 0em;
                }
              }
              &.onlyPAM {
                .fieldGroupWrapper.pam {
                  padding: 0;
                  margin: 0;
                  border: none;
                  border-radius: 0;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and ( min-width : 1100px ) {
  .modalContainer.twoColumn.reactSetupModal.scan_credential {
    .modalBody {
      .scanCredentialsModalWrapper {
        .column.fullForm {
          .formWrapper.withPAM {
            display: grid;
            grid-template-columns: 2fr 1fr;
            .fieldGroupWrapper {
              &.pam {
                margin-right: 1em;
                margin-bottom: 0;
              }
              &.common {
                padding-left: 1em;
              }
            }
            &.onlyPAM {
              grid-template-columns: 1fr;
              .fieldGroupWrapper {
                &.pam {
                  margin-right: 0;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}