/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.graphModelNodeGroup {

  .svgNodeIconOutline {
    fill: #fff;
  }
  .draggingMask, .pathDraggingMask {
    cursor: pointer;
  }
  text {
    opacity: 0.8;
    &::selection {
      background: none;
    }
  }
  &.default {
    .nodeIconCircle {
      fill: rgb( $textColor-secondary );
    }
    .nodeHoverCircle {
      fill: rgb( $textColor-secondary );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $textColor-secondary );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $textColor-secondary );
      }
      .nodeHoverCircle {
        fill: rgb( $textColor-secondary );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $textColor-secondary );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.high {
    .nodeIconCircle {
      fill: rgb( $high--100 );
    }
    .nodeHoverCircle {
      fill: rgb( $high--100 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $high--100 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $high--75 );
      }
      .nodeHoverCircle {
        fill: rgb( $high--75 );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $high--75 );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.moderate {
    .nodeIconCircle {
      fill: rgb( $moderate--100 );
    }
    .nodeHoverCircle {
      fill: rgb( $moderate--100 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $moderate--100 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $moderate--75 );
      }
      .nodeHoverCircle {
        fill: rgb( $moderate--75 );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $moderate--75 );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.low {
    .nodeIconCircle {
      fill: rgb( $low--100 );
    }
    .nodeHoverCircle {
      fill: rgb( $low--100 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $low--100 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $low--75 );
      }
      .nodeHoverCircle {
        fill: rgb( $low--75 );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $low--75 );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.minimal {
    .nodeIconCircle {
      fill: rgb( $minimal--100 );
    }
    .nodeHoverCircle {
      fill: rgb( $minimal--100 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $minimal--100 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $minimal--75 );
      }
      .nodeHoverCircle {
        fill: rgb( $minimal--75 );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $minimal--75 );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.primary {
    .nodeIconCircle {
      fill: rgb( $primary--100 );
    }
    .nodeHoverCircle {
      fill: rgb( $primary--100 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $primary--100 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $primary--75 );
      }
      .nodeHoverCircle {
        fill: rgb( $primary--75 );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $primary--75 );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.sensitive {
    .nodeIndicatorDot {
      fill: rgb( $status-alert );
    }
    .nodeIconCircle {
      fill: rgb( $primary--75 );
    }
    .nodeHoverCircle {
      fill: rgb( $primary--75 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $primary--75 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $primary );
      }
      .nodeHoverCircle {
        fill: rgb( $primary );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $primary );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
  &.isAdversary {
    .nodeIconCircle {
      fill: rgb( $high--100 );
    }
    .nodeHoverCircle {
      fill: rgb( $high--100 );
      fill-opacity: 0.2;
    }
    svg {
      path {
        fill: rgb( $high--100 );
        &.svgNodeIconOutline {
          fill: #fff
        }
      }
    }
    &.highlight {
      .nodeIconCircle {
        fill: rgb( $high--75 );
      }
      .nodeHoverCircle {
        fill: rgb( $high--75 );
        fill-opacity: 0.2;
      }
      svg {
        path {
          fill: rgb( $high--75 );
          &.svgNodeIconOutline {
            fill: #fff
          }
        }
      }
    }
  }
}