/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.instancesWidgetsWrapper {
  background: none;
  display: grid;
  grid-template-columns: 20em 1fr 1fr;
  grid-gap: 2em;
  width: 100%;
  .bigNumber {
    color: rgb( $textColor-primary );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .bigNumberWrapper {
      margin-top: 4em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      h3 {
        font-weight: 400;
        color: rgb( $textColor-secondary );
      }
      h2 {
        font-size: 3.5em;
        margin-bottom: 0.25em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .inlineSVG {
          margin-right: 0.25em;
          height: 0.75em;
          width: 0.75em;
          path {
            fill: rgb( $iconColor-primary );
          }
        }
      }
      span {
        font-size: 1.125em;
        text-align: center;
      }
    }
  }
  .dashboardWidgetWrapper {
    height: 17em;
    .chartLegend {
      font-size: 1em;
      align-self: flex-start;
      margin-top: 2em;
    }
    h2 {
      font-size: 1.125em;
      font-weight: 600;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    &.bar {
      display: grid;
      grid-template-columns: 1fr 16em;
      grid-column-gap: 2em;
      .svgBarWrapper {
        max-height: 10.5em;
      }
    }
    &.donut {
      .widgetContent {
        display: grid;
        grid-template-columns: 1fr 22em;
        grid-column-gap: 1em;
        .svgDonutWrapper {
          svg {
            max-height: 15em;
          }
        }
      }
    }
  }
}