/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.fullScreenVisualToggleButton,
.fullScreenVisualPrintButton {
  width: 2.5em;
  height: 2.5em;
  border-radius: $borderRadius-full;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s ease-in-out;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  background: rgb( $backgroundColor );
  opacity: 1;
  z-index: 1;
  .inlineSVG {
    flex-shrink: 0;
    path {
      fill: rgb( $textColor-primary );
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    background: rgb( $dividerColor );
    .inlineSVG {
      path {
        fill: rgb( $textColor-primary );
      }
    }
  }
};

.modalContainer.fullscreen.fullScreenVisualModal {
  padding: 2em !important;
  .modalBody {
    max-height: unset;
    height: unset;
    padding: 1em 2em;
  }
  .fullScreenVisualContentWrapper {
    &.riskInsightIndex {
      .widgetContent {
        width: 100%;
        display: grid;
        grid-template-columns: 5em 1fr 12em;
        height: 50vh;
        min-height: 30em;
        margin-top: 15vh;
        .chartAxis {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
          height: 100%;
          .tic {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: -0.375em;
            color: rgb( $textColor-primary );
            font-weight: 600;
            &:after {
              content: "";
              width: 0.5em;
              height: 2px;
              background: rgb( $dividerColor );
              margin-left: 0.375em;
              margin-top: 0.373em;
            }
          }
        }
        .svgBarWrapper {
          height: calc( 50vh - 0.5em );
          min-height: 29.5em;
          border-bottom: 2px solid rgb( $dividerColor );
          border-left: 2px solid rgb( $dividerColor );
          padding-bottom: 0.5em;
          padding-left: 0.5em;
          svg {
            height: 100%;
            width: 100%;
          }
        }
        .chartLegend {
          padding-left: 2em;
          align-self: center;
        }
      }
    }
    &.riskInsightTable {
      .rowRiskWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .ratingBadge {
          margin-right: 0.5em;
        }
      }
      .relatedSignatureButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-radius: $borderRadius-input;
        padding: 0.5em 0.75em;
        background: rgb( $primary--10 );
        border: none;
        cursor: pointer;
        width: 100%;
        transition: all 0.3s ease-in-out;
        .recordTypeHeaderIcon {
          transition: all 0.3s ease-in-out;
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
        }
        .name {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
        &:hover {
          background: rgb( $primary--10 );
        }
      }
      &.host {
        .host_riskInsightDataTable {
          .tableRow {
            position: relative;
            grid-template-columns:
              12em
              minmax(12em, 1fr)
              11em
              7em
              6em
              10em
              7em
              4em;
            .tableCell {
              &.tableCell_name {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .assetTagsModalAndListWrapper {
                  ul {
                    li {
                      padding: 0.375em 0.625em !important;
                      line-height: 1.25em !important;
                      margin-bottom: 0 !important;
                    }
                  }
                }
              }
              &.headerCell_sensitive_assets,
              &.headerCell_all_patches,
              &.headerCell_unsuperseded_patches,
              &.headerCell_vulnerabilities,
              &.tableCell_sensitive_assets,
              &.tableCell_all_patches,
              &.tableCell_unsuperseded_patches,
              &.tableCell_vulnerabilities {
                text-align: center;
                span {
                  width: 100%;
                  display: block;
                }
              }
            }
            &.tableHeader {
              position: sticky !important;
              position: -webkit-sticky !important;
              .headerCell {
                position: sticky !important;
                position: -webkit-sticky !important;
              }
              .headerCell_vulnerabilities {
                border-right: 1px solid #fff;
              }
            }
          }
          &.hasRemediation {
            .tableRow {
              position: relative;
              grid-template-columns:
                4em
                12em
                minmax(12em, 1fr)
                11em
                7em
                6em
                10em
                7em
                4em;
            }
          }
        }
      }
      &.patch {
        .patch_riskInsightDataTable {
          .tableRow {
            position: relative;
            grid-template-columns:
              12em
              minmax(6em, 12fr)
              9em
              8em
              7em
              4em;
            .tableCell {
              &.headerCell_superseded_patches,
              &.headerCell_affected_hosts,
              &.headerCell_vulnerabilities,
              &.tableCell_superseded_patches,
              &.tableCell_affected_hosts,
              &.tableCell_vulnerabilities {
                text-align: center;
                span {
                  width: 100%;
                  display: block;
                }
              }
            }
            &.tableHeader {
              position: sticky;
              position: -webkit-sticky;
              .headerCell {
                position: sticky;
                position: -webkit-sticky;
              }
              .headerCell_vulnerabilities {
                border-right: 1px solid #fff;
              }
            }
          }
          &.hasRemediation {
            .tableRow {
              grid-template-columns:
                4em
                12em
                minmax(6em, 12fr)
                9em
                8em
                7em
                4em;
            }
          }
        }
      }
      &.user {
        .user_riskInsightDataTable {
          .tableRow {
            position: relative;
            grid-template-columns:
              12em
              minmax(6em, 12fr)
              11em
              8em
              4em;
            .tableCell {
              &.headerCell_recently_accessed_hosts,
              &.headerCell_domain_groups,
              &.tableCell_recently_accessed_hosts,
              &.tableCell_domain_groups {
                text-align: center;
                span {
                  width: 100%;
                  display: block;
                }
              }
            }
            &.tableHeader {
              position: sticky;
              position: -webkit-sticky;
              .headerCell {
                position: sticky;
                position: -webkit-sticky;
              }
              .headerCell_domain_groups {
                border-right: 1px solid #fff;
              }
            }
          }
        }
      }
      &.vulnerability {
        .vulnerability_riskInsightDataTable {
          .tableRow {
            position: relative;
            grid-template-columns:
              12em
              minmax(6em, 12fr)
              9em
              4.5em
              8em
              6em
              10em
              4em;
            .tableCell {
              &.headerCell_affected_hosts,
              &.headerCell_all_patches,
              &.headerCell_unsuperseded_patches,
              &.tableCell_affected_hosts,
              &.tableCell_all_patches,
              &.tableCell_unsuperseded_patches,
              &.headerCell_CVSS
              &.tableCell_CVSS,
              &.tableCell_exploit_status {
                text-align: center;
                span {
                  width: 100%;
                  display: block;
                }
              }
            }
            &.tableHeader {
              position: sticky;
              position: -webkit-sticky;
              .headerCell {
                position: sticky;
                position: -webkit-sticky;
              }
              .headerCell_unsuperseded_patches {
                border-right: 1px solid #fff;
              }
            }
          }
          &.hasRemediation {
            .tableRow {
              grid-template-columns:
                4em
                12em
                minmax(6em, 12fr)
                9em
                4.5em
                8em
                6em
                10em
                4em;
            }
          }
        }
      }
      &.instance {
        .instance_riskInsightDataTable {
          .tableRow {
            position: relative;
            grid-template-columns:
              12em
              minmax(12em, 1fr)
              8em
              18em
              18em
              11em
              6.5em;
              &.tableHeader {
                position: -webkit-sticky;
                position: sticky;
              }
            .tableCell {
              .VulnerabilityDescriptionContainer {
                display: flex;
                flex-direction: row;
                span {
                  color: rgb( $primary );
                  cursor: pointer;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
              &.headerCell_last_scanned,
              &.headerCell_description,
              &.headerCell_vulns {
                border-right: 1px solid #fff;
              }
              &.tableCell_description {
                pre {
                  flex-grow: 1;
                  padding: 0;
                  margin: 0;
                  background: none;
                  font-family: 'Monospace', monospace;
                  font-weight: 600;
                }
              }
              &.tableCell_addresses {
                min-width: 12em;
                .expandingCellWrapper,
                .expandingCellHiddenContent {
                  min-width: 12em;
                }
                pre {
                  flex-grow: 1;
                  padding: 0;
                  margin: 0;
                  background: none;
                  font-family: 'Monospace', monospace;
                  font-weight: 600;
                }
              }
              &.tableCell_actions {
                button {
                  &.addFilterButton,
                  &.removeFilterButton {
                    background: none;
                    border: none;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: $borderRadius-full;
                    width: 2em;
                    height: 2em;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-shrink: 0;
                    margin-right: 0.5em;
                    .inlineSVG {
                      width: 1.25em;
                      height: 1.25em;
                      flex-shrink: 0;
                      path {
                        fill: rgb( $status-alert );
                      }
                    }
                    &:hover {
                      background: rgb( $dividerColor );
                    }
                  }
                  &.addFilterButton {
                    .inlineSVG {
                      path {
                        fill: rgb( $status-success );
                      }
                    }
                  }
                }
              }
              &.tableCell_vulns,
              &.headerCell_vulns,
              &.tableCell_hosts,
              &.headerCell_hosts {
                text-align: center;
                justify-content: space-around;
              }
            }
          }
          &.host_groupBy.hasRemediation {
            .tableRow {
              grid-template-columns:
                4em
                12em
                minmax(12em, 1fr)
                8em
                18em
                18em
                11em
                6.5em;
            }
          }
          &.patch_groupBy {
            .tableRow {
              grid-template-columns:
                12em
                minmax(4em, 16em)
                8em
                8em
                minmax(6em, 12fr)
                6.5em;
            }
            &.hasRemediation {
              .tableRow {
                grid-template-columns:
                  4em
                  12em
                  minmax(4em, 16em)
                  8em
                  8em
                  minmax(6em, 12fr)
                  6.5em;
              }
            }
          }
          &.vulnerability_groupBy {
            .tableRow {
              grid-template-columns:
                12em
                minmax(4em, 16em)
                9em
                6em
                5em
                minmax(6em, 12fr)
                6.5em;
            }
            &.hasRemediation {
              .tableRow {
                grid-template-columns:
                  4em
                  12em
                  minmax(4em, 16em)
                  9em
                  6em
                  5em
                  minmax(6em, 12fr)
                  6.5em;
              }
            }
          }
          &.signature_groupBy {
            .tableRow {
              grid-template-columns:
                12em
                20em
                1fr
                5em
                5em
                3em;
            }
            .tableCell.tableCell_name {
              p {
                width: unset;
                white-space: normal;
                cursor: default;
                margin: 0;
              }
            }
            .tableCell.tableCell_description {
              min-width: 20em;
            }
            .tableCell.tableCell_rating {
              text-align: center;
            }
          }
        }
      }
    }
    &.riskInsightInstances {
      padding-top: 15vh;
      &.categories {
        .vulnerabilityInstancesVisualWrapper {
          .instancesBreakdown {
            height: 14em;
          }
        }
      }
      &.widgets {
        .instancesWidgetsWrapper {
          .dashboardWidgetWrapper {
            height: 26em;
            .axisContainer {
              &.xAxis {
                margin-left: 4.5em;
                width: calc( 100% - 4.5em );
              }
            }
            &.bar {
              .cvssBarChartWrapper {
                .svgBarWrapper {
                  max-height: 20em;
                }
              }
            }
            &.donut {
              .widgetContent {
                .svgDonutWrapper {
                  .donutChart {
                    max-height: 24em;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.dashboardWidget {
      &.hosts_specific,
      &.patches_specific,
      &.vulnerabilities_specific {
        .recordCardContainer  {
          position: relative;
          border-radius: none;
          box-shadow: none;
          width: 50vw;
          min-width: 40em;
          margin: 15vh auto;
        }
      }

      &.hosts_priority,
      &.patches_priority,
      &.vulnerabilities_priority,
      &.users_priority {
        .widgetContent {
          overflow: auto;
        }
        // bar chart version
        .priorityChartAndAxisWrapper {
          display: grid;
          grid-template-columns: 5em 1fr;
          .axisContainer.yAxis {
            margin-right: 0;
            height: calc(100% + 0.5em );
          }
          .svgBarWrapper {
            height: calc(100% + 0.5em );
            padding-left: 0.5em;
            padding-bottom: 0.5em;
            border-bottom: 2px solid rgb( $dividerColor );
          }
        }
        // table version
        .tableWidgetWrapper {
          margin-top: 1em;
          .tableRow {
            margin: 0;
            padding: 0;
            width: 100%;
            &.tableHeader {
              border-left: 1px solid rgb( $dividerColor );
              border-right: 1px solid rgb( $dividerColor );
              border-top: 1px solid rgb( $dividerColor );
              .headerCell:first-of-type {
                margin: 0;
              }
            }
            .tableCell {
              padding: 0.5em 1em;
              &.tableCell_description,
              &.tableCell_name,
              &.tableCell_product_name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
              }
              &.tableCell_risk {
                .ratingBadge {
                  margin-right: 0.5em;
                }
              }
              &.tableCell_name {
                a {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  color: rgb( $textColor-primary );
                  transition: all 0.3s ease-in-out;
                  cursor: pointer;
                  text-decoration: none;
                  font-weight: 600;
                  .inlineSVG {
                    width: 1em;
                    height: 1em;
                    margin-left: 0.5em;
                    flex-shrink: 0;
                    path {
                      transition: all 0.3s ease-in-out;
                      fill: $iconColor-primary
                    }
                  }
                  &:hover {
                    color: rgb( $primary );
                    text-decoration: underline;
                    .inlineSVG {
                      path {
                        fill: rgb( $primary );
                      }
                    }
                  }
                }
              }
            }
          }
          &.hosts {
            .tableRow {
              grid-template-columns: 13em 1fr 1fr 10em 10em;
              .tableCell {
                &.headerCell_host_type,
                &.headerCell_vulnerabilities,
                &.tableCell_host_type,
                &.tableCell_vulnerabilities {
                  text-align: center;
                  justify-content: space-around;
                }
                &.headerCell_vulnerabilities {
                  border-right: none;
                }
              }
            }
            &.riskClass--no_risk {
              .tableRow {
                grid-template-columns: 1fr 1fr 10em 10em;
              }
            }
          }
          &.patches {
            .tableRow {
              grid-template-columns: 13em 1fr 8em 8em 2fr;
              .tableCell {
                &.headerCell_hosts,
                &.headerCell_vulnerabilities,
                &.tableCell_hosts,
                &.tableCell_vulnerabilities {
                  text-align: center;
                  justify-content: space-around;
                }
                &.headerCell_description {
                  border-right: none;
                }
              }
            }
            &.riskClass--no_risk {
              .tableRow {
                grid-template-columns: 1fr 8em 8em 2fr;
              }
            }
          }
          &.vulnerabilities {
            .tableRow {
              grid-template-columns: 13em 1fr 8em 8em 2fr;
              .tableCell {
                &.headerCell_hosts,
                &.tableCell_hosts {
                  text-align: center;
                  justify-content: space-around;
                }
                &.headerCell_description {
                  border-right: none;
                }
              }
            }
            &.cvssClass--has_cvss {
              .tableRow {
                grid-template-columns: 13em 13em 1fr 8em 8em 2fr;
              }
              &.riskClass--no_risk {
                .tableRow {
                  grid-template-columns: 13em 1fr 8em 8em 2fr;
                }
              }
            }
            &.riskClass--no_risk {
              .tableRow {
                grid-template-columns: 1fr 8em 8em 2fr;
              }
              &.cvssClass--has_cvss {
                .tableRow {
                  grid-template-columns: 13em 1fr 8em 8em 2fr;
                }
              }
            }
          }
        }
        &.barchart {
          width: 100%;
          min-height: 30em;
          margin-top: 15vh;
          .axisContainer.yAxis {
            border-right: none;
          }
          .svgBarWrapper {
            height: calc( 50vh - 0.5em );
            min-height: 29.5em;
            border-bottom: 2px solid rgb( $dividerColor );
            border-left: 2px solid rgb( $dividerColor );
            padding-bottom: 0.5em;
            padding-left: 0.5em;
            svg {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      &.hosts_comparison,
      &.patches_comparison,
      &.vulnerabilities_comparison,
      &.vulnerability_instances_comparison,
      &.risk_comparison {
        color: rgb( $textColor-primary );
        width: calc( 100vw - 8em );
        overflow: hidden;
        &.number {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 50vh;
          margin: auto auto;
          .widgetHeaderContainer {
            display: none;
          }
          .widgetContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
          }
          .overtimeNumberWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            h2 {
              font-size: 1.125em;
              font-weight: 400;
              margin-bottom: 1em;
              color: rgb( $textColor-primary );
              strong {
                font-weight: 600;
              }
            }
            .countWrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 1em;
              .inlineSVG {
                width: 2.5em;
                height: 2.5em;
                margin-right: 0.75em;
                path {
                  fill: rgb( $iconColor-primary );
                }
              }
              strong {
                font-size: 3.5em;
              }
              &.risk {
                border-radius: 1em;
                background: rgb( $backgroundColor );
                padding: 1.5em 2.5em;
                strong {
                  font-weight: 500;
                }
                &.riskClass--critical {
                  background: rgb( $critical--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $critical );
                    }
                  }
                  strong {
                    color: rgb( $critical );
                  }
                }
                &.riskClass--high {
                  background: rgb( $high--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $high );
                    }
                  }
                  strong {
                    color: rgb( $high );
                  }
                }
                &.riskClass--moderate {
                  background: rgb( $moderate--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $moderate );
                    }
                  }
                  strong {
                    color: rgb( $moderate );
                  }
                }
                &.riskClass--low {
                  background: rgb( $low--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $low );
                    }
                  }
                  strong {
                    color: rgb( $low );
                  }
                }
                &.riskClass--minimal {
                  background: rgb( $minimal--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $minimal );
                    }
                  }
                  strong {
                    color: rgb( $minimal );
                  }
                }
                &.riskClass--primary {
                  background: rgb( $primary--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $primary );
                    }
                  }
                  strong {
                    color: rgb( $primary );
                  }
                }
              }
            }
            .trendWrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 1em;
              .trendIconWrapper {
                width: 1.5em;
                height: 1.5em;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                background: rgb( $backgroundColor );
                border-radius: $borderRadius-full;
                margin-right: 0.5em;
                .inlineSVG {
                  width: 1em;
                  height: 1em;
                  path {
                    fill: rgb( $textColor-secondary );
                  }

                }
                span {
                  font-weight: 600;
                  color: rgb( $textColor-secondary );
                  line-height: 1.25em;
                }
                &.up {
                  background: rgb( $status-alert--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $status-alert );
                    }
                  }
                }
                &.down {
                  background: rgb( $status-success--10 );
                  .inlineSVG {
                    path {
                      fill: rgb( $status-success );
                    }
                  }
                }
              }
            }
          }
        }
        .overtimeBarWrapper, .overtimeMultiBarWrapper {
          padding-top: 0.5em;
          display: grid;
          grid-template-columns: 6em 1fr 16em;
          height: 100%;
          max-height: 100%;
          .axisContainer {
            &.yAxis {
              height: 100%;
              max-height: 100%;
            }
          }
          .svgBarWrapper, .svgMultiBarWrapper {
            padding-bottom: 0.5em;
            padding-left: 0.5em;
            border-bottom: 2px solid rgb( $iconColor-primary );
            margin-left: -0.5em;
            max-height: 100%;
          }
          .chartLegend {
            padding-left: 2em;
          }
          .svgMultiBarWrapper {
            height: 100%;
            max-height: 100%;
            .multiBarChart {
              width: 100%;
              height: 100%;
              max-height: 100%;
            }
          }
        }
        .emptyState {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
      }

      &.hosts_over_time,
      &.patches_over_time,
      &.vulnerabilities_over_time,
      &.vulnerability_instances_over_time,
      &.risk_simplified_over_time {
        color: rgb( $textColor-primary );
        width: calc( 100vw - 8em );
        height: calc( 100vh - 8em );
        overflow: hidden;
        .overtimeMultiAreaWrapper, .overtimeAreaWrapper {
          padding-top: 0.5em;
          display: grid;
          grid-template-columns: 9em 1fr;
          height: 100%;
          position: relative;
          .axisLinesContainer {
            &.yAxis, &.xAxis {
              position: absolute;
              z-index: 1;
              padding-top: 0.5em;
              padding-left: 9.5em;
              padding-right: 3.5em;
            }
          }
          .axisContainer {
            &.yAxis {
              padding-bottom: 0;
              height: 100%;
            }
          }
          // the svg
          .multiAreaPlusBarWrapper, .areaPlusBarWrapper {
            // padding-bottom: 0.5em;
            padding-left: 0.5em;
            border-bottom: 2px solid rgb( $iconColor-primary );
            margin-left: -0.5em;
            height: 100%;
            width: calc( 100% - 3em );
            z-index: 2;
          }
          .chartLegend {
            padding-left: 2em;
          }
          &.withLegend {
            grid-template-columns: 9em 1fr 14em;
            .multiAreaPlusBarWrapper, .areaPlusBarWrapper {
              width: 100%;
            }
            .axisLinesContainer {
              &.yAxis, &.xAxis {
                padding-right: 14.5em;
              }
            }
          }
        }
        .axisContainer {
          &.xAxis {
            width: calc( 100% - 14.5em );
            padding-top: 0.5em;
            padding-left: 9.5em;
            &.fullWidth {
              width: calc( 100% - 3em );
            }
          }
        }
        .emptyState {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
      }
      &.hosts_global,
      &.patches_global,
      &.vulnerabilities_global,
      &.risk_global,
      &.vulnerability_instances_os_family_breakdown,
      &.vulnerability_instances_tag_breakdown,
      &.vulnerability_instances_exploit_status_breakdown,
      &.vulnerability_instances_cvss_breakdown.donut,
      &.scanning_scan_group_breakdown,
      &.scanning_agent_version_breakdown,
      &.scanning_age_breakdown {
        display: grid;
        grid-column-gap: 1em;
        grid-template-columns: 1fr 25em;
        padding-top: 10vh;
        .svgDonutWrapper {
          padding-top: 0;
          min-height: unset;
          max-height: 70vh;
        }
        .chartLegend {
          align-self: center;
          font-size: 1.5em;
        }
      }

      &.hosts_top_details,
      &.patches_top_details,
      &.vulnerabilities_top_details {
        .topRecordDetailsWrapper {
          display: grid;
          grid-gap: 1em;
          padding: 1em;
          font-size: 1.125em;
          .recordCardContainer {
            position: relative;
            border: 1px solid rgb( $dividerColor );
            box-shadow: $boxShadow-light;
            z-index: unset;
          }
          &.vertical {
            grid-template-rows: 1fr 1fr 1fr;
            &.itemCount--5 {
              grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
            }
          }
          &.horizontal {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            .recordsCountHeader {
              grid-column: 1 / 4;
            }
            &.itemCount--5 {
              .recordsCountHeader {
                grid-column: 1 / 6;
              }
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
          }
        }
      }

      &.scanning_age_breakdown {
        p {
          font-size: 1.5em;
          grid-column: 1 / 3;
          width: 100%;
          padding: 0.5em 1em;
          border-radius: $borderRadius;
          background: rgb( $backgroundColor );
          text-align: center;
        }
      }
      &.hosts_total,
      &.patches_total,
      &.vulnerabilities_total,
      &.vulnerability_instances_total {
        color: rgb( $textColor-primary );
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .bigNumberWrapper {
          margin-top: 20vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          h2 {
            font-size: 5em;
            margin-bottom: 0.25em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .inlineSVG {
              margin-right: 0.25em;
              height: 0.75em;
              width: 0.75em;
              path {
                fill: rgb( $iconColor-primary );
              }
            }
          }
          span {
            font-size: 2em;
            text-align: center;
          }
        }
      }

      &.scanning_total {
        color: rgb( $textColor-primary );
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .totalWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          margin-top: 20vh;
          h2 {
            font-size: 5em;
            margin-bottom: 0.25em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .inlineSVG {
              margin-right: 0.25em;
              height: 0.75em;
              width: 0.75em;
              path {
                fill: rgb( $iconColor-primary );
              }
            }
          }
          span {
            font-size: 2em;
            text-align: center;
          }
        }
        .bigNumberWrapper.twoNumbers {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .totalWrapper {
            padding: 1em;
          }
        }
      }
      &.vulnerability_instances_vulnerability_age_breakdown,
      &.vulnerability_instances_cvss_breakdown.barchart {
        display: grid;
        grid-template-columns: 1fr 16em;
        .widgetHeaderContainer {
          grid-column: 1 / 3;
        }
        .axisContainer.xAxis {
          margin-left: 6.5em;
          width: calc( 100% - 6.5em );
        }
        .chartWrapper {
          padding-top: 0.5em;
          max-height: 30em;
          .cvssBarChartWrapper {
            min-height: calc( 30em );
            max-height: calc( 30em );
            .svgBarWrapper {
              height: 30em;
              svg {
                height: 30em;
              }
            }
          }
        }
        .chartLegend {
          align-self: center;
        }
        &.withDescription {
          display: grid;
          grid-template-columns: 1fr 20em;
          grid-column-gap: 1em;
          height: unset;
          margin-top: 15vh;
          .chartWrapper {
            padding-top: 0.5em;
            max-height: 26em;
          }
          .cvssBarChartWrapper {
            min-height: calc( 26em );
            max-height: calc( 26em );
            .svgBarWrapper {
              height: 26em;
              svg {
                height: 26em;
              }
            }
          }
          .axisContainer.xAxis {
            margin-left: 6.5em;
            width: calc( 100% - 6.5em );
            height: 3.5em;
            .axisLabel {
              font-size: 1.125em;
            }
          }
          .chartLegend {
            align-self: center;
            font-size: 1.5em;
          }
        }
      }
      &.vulnerability_instances_vulnerability_age_breakdown {
        margin-top: 0;
        .svgBarWrapper {
          border-bottom: 2px solid rgb( $iconColor-primary );
          padding-bottom: 0.5em;
          margin-left: -0.5em;
          padding-left: 0.5em;
        }
        .xLabel {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          grid-column: 2 / 3;
          padding-top: 0.5em;
          padding-right: 0.5em;
          span {
            font-weight: 600;
            font-size: 0.875em;
          }
        }
      }
      &.vulnerability_instances_category {
        color: rgb( $textColor-primary );
        padding-top: 15vh;
        .svgDonutWrapper {
          padding-top: 0;
          min-height: unset;
          max-height: 50vh;
        }
        .categoryDescription {
          margin: 0;
          margin-top: 2em;
          font-size: 2em;
          text-align: center;
        }
      }
      &.vulnerability_instances_global {
        padding-top: 15vh;
        .instancesBreakdown {
          height: 14em;
        }
      }
      &.risk_target,
      &.risk_score {
        padding-top: 15vh;
        .riskScoreWidgetWrapper {
          background: rgb( $primary--10 );
          border-radius: $borderRadius;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          padding: 6em;
          height: 100%;
          .outerRiskCircle {
            border: 4em solid rgba( $primary, 0.3);
            border-radius: $borderRadius-full;
            width: 26em;
            height: 26em;
            .innerRiskCircle {
              border: 3px solid rgb( $primary );
              background: rgb( $foregroundColor );
              border-radius: $borderRadius-full;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              .scoreAndLabelWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                color: rgb( $primary );
                strong {
                  font-size: 3em;
                  font-weight: 600;
                }
                span {
                  color: rgb( $textColor-primary );
                  font-size: 2em;
                  margin-top: 0.5em;
                  font-weight: 400;
                }
              }
            }
          }
          &.risk--critical {
            background: rgb( $critical--10 );
            .outerRiskCircle {
              border-color: rgba( $critical, 0.3);
              .innerRiskCircle {
                border-color: rgb( $critical );
                .scoreAndLabelWrapper {
                  color: rgb( $critical );
                }
              }
            }
          }
          &.risk--high {
            background: rgb( $high--10 );
            .outerRiskCircle {
              border-color: rgba( $high, 0.3);
              .innerRiskCircle {
                border-color: rgb( $high );
                .scoreAndLabelWrapper {
                  color: rgb( $high );
                }
              }
            }
          }
          &.risk--moderate {
            background: rgb( $moderate--10 );
            .outerRiskCircle {
              border-color: rgba( $moderate, 0.3);
              .innerRiskCircle {
                border-color: rgb( $moderate );
                .scoreAndLabelWrapper {
                  color: rgb( $moderate );
                }
              }
            }
          }
          &.risk--low {
            background: rgb( $low--10 );
            .outerRiskCircle {
              border-color: rgba( $low, 0.3);
              .innerRiskCircle {
                border-color: rgb( $low );
                .scoreAndLabelWrapper {
                  color: rgb( $low );
                }
              }
            }
          }
          &.risk--minimal {
            background: rgb( $minimal--10 );
            .outerRiskCircle {
              border-color: rgba( $low, 0.3);
              .innerRiskCircle {
                border-color: rgb( $low );
                .scoreAndLabelWrapper {
                  color: rgb( $low );
                }
              }
            }
          }
          &.risk--default {
            background: rgba( $textColor-primary, 0.1 );
            .outerRiskCircle {
              border-color: rgba( $textColor-primary, 0.3);
              .innerRiskCircle {
                border-color: rgb( $textColor-primary );
                .scoreAndLabelWrapper {
                  color: rgb( $textColor-primary );
                }
              }
            }
          }
        }
      }
      &.risk_peer_percentile {
        .peerPercentileChartWrapper {
          .svgGaugeWrapper {
            width: 50vw;
          }
          .chartLabel {
            font-size: 2.5em;
            font-weight: 600;
          }
          .chartDescription {
            font-size: 2em;
          }
        }
      }
      &.risk_over_time {
        color: rgb( $textColor-primary );
        position: relative;
        overflow: hidden;
        .riskOverTimeV3Wrapper {
          display: grid;
          grid-template-rows: 2fr 1fr 1fr 2.5em;
          height: 100%;
          // area plus both bars
          &.areaHeight--50 {
            grid-template-rows: 2fr 1fr 1fr 2.5em;
          }
          // area plus one bar
          &.areaHeight--66 {
            grid-template-rows: 2fr 1fr 2.5em;
          }
          // area by itself
          &.areaHeight--100 {
            grid-template-rows: 1fr 2.5em;
          }
        }
        .riskOverTimeAreaWrapper {
          padding-top: 0.5em;
          display: grid;
          grid-template-columns: 9em 1fr;
          height: 100%;
          position: relative;
          .axisLinesContainer {
            &.yAxis {
              position: absolute;
              z-index: 1;
              padding-top: 0.5em;
              padding-left: 9.5em;
              padding-right: 3.5em;
            }
          }
          .axisContainer {
            &.yAxis {
              padding-bottom: 0;
              height: 100%;
            }
          }
          // the svg
          .areaSVG {
            // padding-bottom: 0.5em;
            padding-left: 0.5em;
            margin-left: -0.5em;
            height: 100%;
            width: calc( 100% - 3em );
            z-index: 2;
            border-bottom: 2px solid rgb( $iconColor-primary );
            &.noDash {
              border-bottom: none;
            }
          }

          .chartLegend {
            padding-left: 2em;
          }
          &.withLegend {
            grid-template-columns: 9em 1fr 14em;
            .areaSVG {
              width: 100%;
            }
            .axisLinesContainer {
              &.yAxis, &.xAxis {
                padding-right: 14.5em;
              }
            }
          }
        }
        .centerBarWrapper {
          margin-left: 8.375em;
          padding-bottom: 0.5em;
          height: 100%;
          z-index: 3;
          border-left: 2px solid rgb( $iconColor-primary );
          padding-right: 3.5em;
          position: relative;
          padding-left: 0.5em;
          svg {
            z-index: 2;
            width: 100%;
            height: 100%;
          }
          &:after {
            z-index: 1;
            content: '';
            position: absolute;
            top: calc( 50% - 3px );
            left: 0.5em;
            height: 2px;
            width: calc( 100% - 3em - 1em );
            border-bottom: 2px solid rgb( $dividerColor );
          }
          &.instances {
            padding-top: 0.5em;
          }
          &.withLegend {
            padding-right: 14.5em;
            &:after {
              width: calc( 100% - 14.5em - 1em );
            }
          }

        }

        .axisLinesContainer {
          &.xAxis {
            position: absolute;
            z-index: 1;
            width: calc( 100% - 14.5em - 9em );
            padding-top: 1em;
            padding-bottom: 2.5em;
            margin-left: 9em;
            &.fullWidth {
              width: calc( 100% - 3em - 9em );
            }
          }
        }
        .axisContainer {
          &.xAxis {
            width: calc( 100% - 14.5em - 8.5em + 2px );
            padding-top: 1em;
            margin-left: calc(8.5em - 2px );
            border-top: 2px solid rgb( $iconColor-primary );
            &.fullWidth {
              width: calc( 100% - 3em - 8.5em + 2px );
            }
          }
        }
        .patchTuesdayAndHoverIndicatorsWrapper {
          position: absolute;
          width: calc(100% - 10em);
          padding-top: 1em;
          padding-bottom: 2.25em;
          margin-left: 9em;
          height: 100%;
          z-index: 4;
          padding-right: 3em;
          padding-left: 0.5em;
          svg {
            height: calc( 100% + 0.5em );
            margin-top: -0.5em;
          }
          &.withLegend {
            padding-right: 14em;
          }
        }
        .emptyState {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
      }
      &.risk_peer_percentile_over_time {
        width: calc( 100vw - 8em );
        height: calc( 100vh - 8em );
        overflow: hidden;
        position: relative;
        .chartPlusAxis {
          display: grid;
          grid-template-columns: 6em 1fr;
          height: calc( 100% - 2em );
          padding-top: 0.5em;
          .areaPlusBarWrapper {
            width: calc( 100% - 3em );
            height: 100%;
            padding-left: 0.5em;
            border-bottom: 2px solid rgb( $iconColor-primary );
            margin-left: -0.5em;
            z-index: 2;
          }
        }
        .axisLinesContainer {
          &.yAxis, &.xAxis {
            position: absolute;
            z-index: 1;
            padding-left: 6.5em;
            padding-right: 3.5em;
            padding-bottom: 1em;
          }
          &.xAxis {
            height: calc( 100vh - 17em );
          }
        }
        .axisContainer {
          &.yAxis {
            padding-bottom: 0;
            height: 100%;
          }
          &.xAxis {
            width: calc( 100% - 3em );
            padding-top: 0.5em;
            padding-left: 6.5em;
          }
        }
      }
      &.paths_global,
      &.paths_specific {
        background: rgb( $backgroundColor );
        padding: 4em;
        border-radius: $borderRadius;
        .pathSVGContainer {
          max-height: calc( 100vh - 16em );
        }
      }
      &.compliance_current {
        .curentComparisonContentWrapper {
          height: 50vh;
          display: grid;
          grid-template-columns: 9em 1fr;
          padding-top: 0.5em;
          .axisContainer {
            &.yAxis {
              height: calc( 100% - 5.5em );
            }
          }
          .horizontalBarChartWrapper {
            position: relative;
            &:after {
              content: '';
              width: 100%;
              height: 2px;
              border-top: 2px solid rgb( $iconColor-primary );
              position: absolute;
              bottom: 5.5em;
              left: -0.5em;
            }
          }
        }
      }

      &.remediation_plans {
        .remediationChartWrapper {
          padding-top: 1em;
          .remediationPlanWrapper {
            padding-bottom: 1em;
            margin-bottom: 1em;
            border-bottom: 1px solid rgb( $dividerColor );
            color: rgb( $textColor-primary );
            &:last-of-type {
              border-bottom: 0;
              margin-bottom: 0;
            }
            label {
              font-weight: 600;
              margin-bottom: 0.5em;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              .ratingBadge {
                margin-left: 0.5em;
              }
            }
            .labelAndBarWrapper {
              display: grid;
              grid-template-columns: 3.5em 1fr;
              grid-gap: 0.5em;
              margin-bottom: 0.5em;
            }
            span {
              text-align: right;
              line-height: 1.5em;
            }
            .planCompletionWrapper {
              width: 100%;
              height: 1.5em;
              background: rgb( $backgroundColor );
              .planCompletionBar {
                height: 1.5em;
                min-width: 0.25em;
              }
            }
          }
        }
        .chartLegend {
          width: 40em;
          margin: 0 auto;
          font-size: 1em;
          ul {
            justify-content: space-around;
          }

        }
      }
      &.remediation_specific_plan {
        color: rgb( $textColor-primary );
        h2 {
          font-size: 1.125em;
          font-weight: 400;
          strong {
            font-weight: 600;
          }
        }
        .taskCount {
          margin-bottom: 1em;
        }
        .remediationChartWrapper {
          padding-top: 1em;
          .remediationTaskWrapper {
            padding-bottom: 1em;
            margin-bottom: 1em;
            border-bottom: 1px solid rgb( $dividerColor );
            color: rgb( $textColor-primary );
            &:last-of-type {
              border-bottom: 0;
              margin-bottom: 0;
            }
            label {
              font-weight: 600;
              margin-bottom: 0.5em;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              section {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                .action {
                  margin: 0 0.5em;
                  font-weight: 400;
                }
                &.owner {
                  font-weight: 400;
                  color: rgb( $textColor-secondary );
                }
              }
              .ratingBadge {
                margin-left: 0.5em;
              }
              .inlineSVG {
                width: 1.125em;
                height: 1.125em;
                margin-right: 0.5em;
                path {
                  fill: rgb( $iconColor-primary );
                }
              }
            }
            .labelAndBarWrapper {
              display: grid;
              grid-template-columns: 3.5em 1fr;
              grid-gap: 0.5em;
              margin-bottom: 0.5em;
              span {
                text-align: right;
                line-height: 1.5em;
              }
              .taskCompletionWrapper {
                width: 100%;
                height: 1.5em;
                background: rgb( $backgroundColor );
                .taskCompletionBar {
                  height: 1.5em;
                  min-width: 0.25em;
                }
              }
            }
          }
        }
        .chartLegend.horizontal {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          ul {
            justify-self: center;
            width: unset;
            li {
              margin-bottom: 0;
              &:first-child {
                margin-right: 2em;
              }
            }
          }
        }
      }
    }
    &.riskInsightDetails.section.pathsGraph {
      background: rgb( $backgroundColor );
      padding: 4em;
      border-radius: $borderRadius;
      .pathSVGContainer {
        max-height: calc( 100vh - 16em );
      }
    }
  }
  .fullScreenVisualToggleButton {
    position: absolute;
    top: 4.5em;
    right: 1em;
  }
  .fullScreenVisualPrintButton {
    position: absolute;
    top: 8em;
    right: 1em;
  }
}

#react_container {
  &.printingFullScreenVisual {
    display: none;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}