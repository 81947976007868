/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.radioGroup {
  .radioOptionWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    margin-top: 1em;
    input {
      width: auto;
      margin-right: 1em;
      margin-top: 0;
    }
    .labelWrapper {
      width: auto;
      margin-left: 0.5em;
      margin-bottom: 0;
      text-transform: none;
      color: rgb( $textColor-secondary );
      font-size: 1em;
    }
  }
  &.updated, &.invalid {
    .radioOptionWrapper {
      &:before {
        display: none;
      }
      .labelWrapper {
        padding-left: 0;

      }
    }
  };
}

.radioFieldWrapper {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  background-image: url('./../../../../../../images/icons/input/radio.svg');
  background-repeat: no-repeat;
  background-size: contain;
  &.checked {
    background-image: url('./../../../../../../images/icons/input/radio-checked.svg');
  }
  &.disabled {
    pointer-events: none;
    background-image: url('./../../../../../../images/icons/input/radio-disabled.svg');
    &.checked {
      background-image: url('./../../../../../../images/icons/input/radio-checked-disabled.svg');
    }
  }
  input {
    width: 0;
    height: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    visibility: hidden;
  }
}
