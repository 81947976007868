/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.modalContainer.twoColumn.reactSetupModal.user {
  .modalBody {
    .formWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "A A" "B C";
      .fieldGroupWrapper.selector {
        grid-area: A;
      }
      .fieldGroupWrapper.left {
        grid-area: B;
        margin: 0;
        margin-left: -3em;
        padding-left: 3em;
        padding-top: 2em;
        padding-right: 2em;
      }
      .fieldGroupWrapper.right {
        grid-area: C;
        border-left: 1px solid rgb( $iconColor-primary );
        margin: 0;
        margin-right: -3em;
        padding: 0;
        padding-right: 3em;
        padding-top: 2em;
        padding-left: 2em;
      }
    }
  }
}

.setupRecordItem.user.item.alternateLayout {
  grid-template-columns: 2fr 1fr 1fr !important;
  .userDetailsWrapper {
    .userDetailsSection {
      h3 {
        .inlineSVG {
          display: none;
        }
      }
    }
  }
  section {
    .sectionDetails {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.5em;
      }
    }
  }
}

.userMenuTrigger {
  .userDetailsWrapper {
    .avatarInitials {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
  }
}