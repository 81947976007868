/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.modalContainer.reactSetupModal.sensitive_asset_policy {
  .modalHeader {
    border-radius: $borderRadius $borderRadius 0 0;
  }
  .modalBody {
    padding: 0;
    .tableDescription {
      width: calc( 100% - 2em );
      margin: 0 1em;
      padding: 0.5em;
      text-align: left;
      font-weight: 600;
      background: $grey--background;
      border-radius: $borderRadius;
      color: $darkBlue;
    }
    .filterBuilder {
      position: relative;
      .testPolicyButton {
        @include dsButton( 'primary' );
        border-radius: $borderRadius;
        position: absolute;
        bottom: 1.75em;
        right: 1em;
      }
    }
    .formWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 1em;
      margin-bottom: 0.75em;
      border-bottom: 1px solid $grey--divider;
      .fieldGroupWrapper {
        padding: 0;
        margin: 0;
        position: relative;
        .fieldWrapper {
          margin: 0;
          &.included {
            margin-right: 0.5em;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
        .sectionHeader {
          position: absolute;
          top: -1.5em;
          width: 100%;
          text-align: center;
          left: 0;
          font-size: 0.75em;
          font-weight: 600;
          text-transform: uppercase;
        }
        &.label {
          width: 100%;
          margin-bottom: 0.5em;
          border-bottom: 1px solid $grey--divider;
          padding-bottom: 1em;
          .fieldWrapper {
            width: 50%;
            input[ type="text" ] {
              border: 1px solid $grey--icon;
              background: $grey--divider;
              border-radius: $borderRadius;
            }
          }
          .editIcon {
            margin-right: 0.75em;
            opacity: 1;
          }
        }
        &.asSentence {
          border-radius: $borderRadius;
          padding: 0.5em 0.75em 0.5em 0.75em;
          background: rgba( $filter--teal, 0.175 );
          margin-top: 1em;
          margin-bottom: 0.5em;
          margin-right: 0.5em;
          .sectionHeader {
            color: $filter--teal;
          }
          &.scope {
            background: rgba( $filter--blue, 0.175 );
            .sectionHeader {
              color: $filter--blue;
            }
          }
          &.tag {
            background: rgba( $filter--purple, 0.175 );
            .sectionHeader {
              color: $filter--purple;
            }
          }
          &.node {
            margin-right: 0;
            background: rgba( $filter--red, 0.175 );
            margin-bottom: 2.75em;
            .sectionHeader {
              color: $filter--red;
            }
          }
        }
      }
    }
    .indeterminantPagination {
      margin: 0;
      padding: 0 1em 0.75em 1em;
      .nextPreviousButtonsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .policyTestTableWrapper {
      margin: 0;
      border-top: 1px solid $grey--divider;
      .policyTestDataTable {
        .tableRow {
          grid-template-columns:
            minmax(1em, 2fr)
            minmax(1em, 2fr)
            10em;
          &.tableHeader {
            border-top: 1px solid $grey--divider;
          }
          .headerCell_node_type {
            border-right: none;
          }
        }
      }

    }
  }
}