/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.filterForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  &.horizontal {
    justify-content: flex-start;
    align-items: flex-end;
    .filterWrapper {
      flex-grow: 0;
      flex-shrink: 1;
      padding-right: 1em;
      &:last-of-type {
        padding-right: 0;
      }
      label {
        margin-bottom: 0;
        display: block;
        .labelWrapper {
          display: block;
          margin-bottom: 0.5em;
          line-height: 1.5em;
          text-transform: none;
          color: rgb( $textColor-primary );
          font-weight: 500;
        }
      }
      &.select {
        select {
          padding: 0.75em 2.5em 0.75em 1em;
        }
        .toggleWrapper.large {
          flex-direction: column;
          align-items: flex-start;
          .labelWrapper {
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }
}

.filterWrapper {
  font-family: $sans;
  font-weight: 400;
  flex-grow: 1;
  font-size: 0.875em;
  label {
    font-weight: 500;
    color: rgb( $textColor-primary );
    position: relative;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .labelWrapper {
      width: 100%;
      margin-bottom: 0.5em;
      line-height: 1.5em;
      .helpTrigger {
        height: 1em;
        margin-left: 0.5em;
        .inlineSVG {
          width: 1em;
        }
      }
    }
    select {
      padding: 0.75em  2.5em .75em 1em;
      background: rgb( $foregroundColor );
      border: 1px solid rgba( $iconColor-primary, 0.75 );
      border-radius: $borderRadius-input;
      color: rgba( $textColor-primary, 0.85 );
      box-shadow: $boxShadow-input;
      background-repeat: no-repeat;
      background-size: auto;
      background-image: url('./../../../../images/icons/input/select-arrow.svg');
      background-position: calc(100% - 0.75em) 50%;
      &:focus {
        outline: none;
        color: rgb( $textColor-primary );
      }
    }
    input[type="text"], input[type="number"] {
      padding: 0.75em 1em;
      background: rgb( $foregroundColor );
      border: 1px solid rgba( $iconColor-primary, 0.75 );
      border-radius: $borderRadius-input;
      color: rgba( $textColor-primary, 0.85 );
      box-shadow: $boxShadow-input;
      width: 100%;
      &:focus {
        outline: none;
        color: rgb( $textColor-primary );
      }
    }
    input[type="date"] {
      padding: 0.5em 0.25em 0.5em 0.5em;
      background: rgb( $backgroundColor-secondary );
      background: rgb( $foregroundColor );
      border: 1px solid rgba( $iconColor-primary, 0.75 );
      border-radius: $borderRadius-input;
      color: rgba( $textColor-primary, 0.85 );
      box-shadow: $boxShadow-input;
      &:focus {
        outline: none;
        color: rgb( $textColor-primary );
      }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgb( $textColor-secondary );
      font-weight: 400;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgb( $textColor-secondary );
      font-weight: 400;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgb( $textColor-secondary );
      font-weight: 400;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgb( $textColor-secondary );
      font-weight: 400;
    }
    .radioWrapper {
      padding: 1em 1.5em;
      background: rgb( $backgroundColor );
      border-radius: $borderRadius-full;
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        text-transform: none;
        margin-right: 1.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: auto;
        .radioFieldWrapper {
          margin-right: 0.5em;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  &.withinForm {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid rgb( $dividerColor );
    width: 100%;
  }
  &.hidden {
    width: 0;
    height: 0;
    margin: 0;
    border: none;
    padding: 0;
  }
  &.radioGroup {
    .radioOptionWrapper {
      width: 25%;
      flex-grow: 1;
      margin-bottom: 1em;
      align-items: center;
      .labelWrapper {
        width: 50%;
        flex-direction: row;
        text-transform: none;
        color: rgb( $textColor-primary );
        font-weight: 500;
        flex-grow: 1;
        margin-left: 0.75em;
        margin-bottom: 0;
      }
    }
  }
  &.checkbox {
    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      input {
        margin-right: 0.5em;
      }
      span {
        color: rgb( $textColor-primary );
        text-transform: none;
        width: auto;
        margin: 0;
        margin-left: 0.5em;
      }
    }
  }
}

.toggleWrapper {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  span.labelWrapper {
    text-align: right;
    font-size: 0.875em;
    color: rgb( $textColor-secondary );
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    .helpTrigger {
      height: 1em;
    }
  }
  .toggle {
    background: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: auto;
    button {
      background: none;
      color: rgb( $textColor-primary );
      padding: 0.5em 0em;
      font-weight: 400;
      transition: all 0.3s ease-in-out;
      text-align: center;
      cursor: pointer;
      font-size: 0.875em;
      font-weight: 600;
      text-transform: none;
      width: 9em;
      border-radius: $borderRadius-input 0 0 $borderRadius-input;
      border: 1px solid rgb( $iconColor-primary );
      border-right: none;
      &:last-of-type {
        border-radius: 0 $borderRadius-input $borderRadius-input 0;
        border-left: none;
        border-right: 1px solid rgb( $iconColor-primary );
      }
      &:focus {
        outline: none;
      }
      &:hover, &.toggled {
        color: #fff;
        background: rgb( $textColor-primary );
        border: 1px solid rgb( $textColor-primary );
      }
      &.toggled {
        cursor: default;
        pointer-events: none;

      }
    }
  }
}
//
// .filterWrapper {
//   font-family: $sans;
//   font-weight: 400;
//   flex-grow: 1;
//   label {
//     margin-bottom: .5em;
//     font-size: .875em;
//     font-weight: 600;
//     text-transform: uppercase;
//     color: rgb( $textColor-secondary );
//     position: relative;
//     margin-bottom: 0;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 100%;
//     .labelWrapper {
//       width: 100%;
//       margin-bottom: 1em;
//     }
//     select {
//       border-radius: $borderRadius;
//       margin: 0;
//       padding: 0.75em 2.5em 0.75em 1em;
//       border: 1px solid rgb( $textColor-secondary );
//       background: rgb( $foregroundColor );
//       background-repeat: no-repeat;
//       background-size: auto;
//       background-image: url('./../../../../images/icons/input/select-arrow.svg');
//       background-position: calc(100% - 0.75em) 50%;
//
//       &:focus {
//         outline: none;
//       }
//     }
//     input[type="text"] {
//       padding: 0.75em 1em;
//       border-radius: $borderRadius;
//       background: rgb( $dividerColor );
//       border: 1px solid rgb( $dividerColor );
//       border: none;
//       width: 100%;
//
//       &:focus {
//         outline: none;
//       }
//     }
//     .radioWrapper {
//       padding: 1em 1.5em;
//       background: rgb( $backgroundColor );
//       border-radius: $borderRadius-full;
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       label {
//         text-transform: none;
//         margin-right: 1.5em;
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         justify-content: flex-start;
//         width: auto;
//         .radioFieldWrapper {
//           margin-right: 0.5em;
//         }
//         &:last-of-type {
//           margin-right: 0;
//         }
//       }
//     }
//   }
// }