/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.inlineSVG {
  &.light {
    rect, line {
      stroke: #fff;
    }
    path {
      fill: #ffffff;
    }
  }
  &.dark {
    rect, line {
      stroke:  $textColor-primary;
    }
    path {
      fill: rgb( $textColor-primary );
    }
  }
  &.default {
    rect, line {
      stroke: rgb( $iconColor-primary );
    }
    path {
      fill: rgb( $iconColor-primary );
    }
  }
  &.grey {
    rect, line {
      stroke: rgb( $textColor-secondary );
    }
    path {
      fill: rgb( $textColor-secondary );
    }
  }
  &.primary {
    rect, line {
      stroke: rgb( $primary );
    }
    path {
      fill: rgb( $primary );
    }
  }
  &.status--red {
    rect, line {
      stroke: rgb( $status-alert );
    }
    path {
      fill: rgb( $status-alert );
    }
  }
  &.status--orange {
    rect, line {
      stroke: rgb( $status-moderate );
    }
    path {
      fill: rgb( $status-moderate );
    }
  }
  &.status--green {
    rect, line {
      stroke: rgb( $status-success );
    }
    path {
      fill: rgb( $status-success );
    }
  }
  &.status--blue {
    rect, line {
      stroke: rgb( $status-info );
    }
    path {
      fill: rgb( $status-info );
    }
  }
  &.low {
    rect, line {
      stroke: rgb( $low );
    }
    path {
      fill: rgb( $low );
    }
  }
  &.risk--red {
    rect, line {
      stroke: rgb( $high );
    }
    path {
      fill: rgb( $high );
    }
  }
  &.inlineLink {
    transition: all 0.1s linear;
    width: 1em !important;
    height: 1em !important;
    margin-left: 0.3em !important;
    flex-shrink: 0;

    &.darkBlue {
      color: rgb( $textColor-primary );
      path {
        fill: rgb( $textColor-primary );
      }
    }
    &.primaryBlue {
      color: rgb( $primary );
      opacity: 1 !important; // because of helpItem override
      path {
        fill: rgb( $primary );
      }
    }
    &.white {
      color: white;
      path {
        fill: white;
      }
    }
  }
}

.inlineLinkWrapper {
  display: inline-flex;
  flex-direction: row;

  .inlineLink {
    align-self: center;
    margin-left: 0.2em
  }
}