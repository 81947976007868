/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

#pageHeaderContainer.scanningDashboardHeaderWrapper {
  position: relative;
  .filterForm {
    .filterWrapper {
      .labelWrapper {
        margin-bottom: 0.5em;
        font-weight: 400;
        color: rgb( $textColor-primary );
        text-transform: none;
      }
      &.hidden {
        margin-right: 0;
        padding-right: 0;
      }
      &.scanning_mode {
        padding-top: 1.5em;
        margin-right: 3em;
        margin-left: 1em;
        align-self: center;
        font-size: 1em;
        .labelWrapper {
          display: none;
        }
      }
      &.item_count {
        position: fixed;
        right: 8em;
        top: 8em;
        label {
          .labelWrapper {
            display: none;
          }
        }
      }
    }
  }
  .showVisualToggle {
    width: 2.5em;
    height: 2.5em;
    border-radius: $borderRadius-full;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    transition: all 0.3s ease-in-out;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    background: rgb( $backgroundColor );
    position: absolute;
    right: 0em;
    top: 1.25em;
    opacity: 1;
    .inlineSVG {
      flex-shrink: 0;
      path {
        fill: rgb( $textColor-primary );
        transition: all 0.3s ease-in-out;
      }
    }
    &.visualDisabled {
      .inlineSVG {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
    }
    &:hover {
      background: rgb( $dividerColor );
      .inlineSVG {
        path {
          fill: rgb( $textColor-primary );
        }
      }
    }
  }
}

#pageContent.scanning_dashboardPageContainer {
  .riskInsightTableWrapper {
    width: 100%;
    flex-grow: 1;
    transition: all 0.15s ease-in-out;
    height: 1em;
    position: relative;
    z-index: 1;
    background: rgb( $foregroundColor );
    border-radius: $borderRadius;
  }
  .filtersHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
    border-bottom: 1px solid rgb( $dividerColor );
    color: rgb( $textColor-primary );
    h2 {
      margin-right: 1em;
    }
    .paginationContainer {
      padding: 0 1em;
      border-top: none;
      border-left: 1px solid rgb( $dividerColor );
      border-radius: 0;
      margin-left: 1em;
    }
  }
  .tableWrapper {
    padding-bottom: 3em;
    .dataTable {
      .tableRow {
        grid-template-columns:
          1fr
          2fr
          15em
          13em
          11em
          27em
          9.5em;
        .tableCell {
          &.tableCell_addresses_and_scan_logs, &.tableCell_scan_groups {
            ul {
              // flex-wrap: wrap;
              li {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                // flex-wrap: wrap;
                margin-bottom: 0.25em;
                margin-right: 0.25em;
              }
            }
            pre {
              flex-grow: 1;
              padding: 0;
              margin: 0;
              background: none;
              font-family: 'Monospace', monospace;
              font-weight: 600;
            }
          }
          &.tableCell_actions {
            align-items: center;
            justify-content: flex-end;
            .tableActionsWrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
            }
          }
          &.headerCell {
            &.headerCell_last_processed {
              border-right: none;
            }
          }
          &.tableCell_host_name, &.tableCell_name {
            a {
              color: rgb( $textColor-primary );
              text-decoration: none;
              .inlineSVG {
                path {
                  fill: rgb( $textColor-primary );
                }
              }
              &:hover {
                color: rgb( $primary );
                text-decoration: underline;
                .inlineSVG {
                  path {
                    fill: rgb( $primary );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .paginationWrapper {
    left: 5.5em;
    width: calc(100vw - 7.75em);
  }
}