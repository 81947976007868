/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.searchWithResultsWrapper.hasResults.inlineVersion {
  position: relative;
  .searchResultsList {
    top: 3em;
    padding: 0.5em;
    border: 1px solid rgb( $iconColor-primary );
    border-radius: $borderRadius-input;
    box-shadow: $boxShadow-heavy;
    ul {
      li {
        border-radius: $borderRadius;
        &:hover {
          background: rgb( $dividerColor );
        }
      }
    }
  }
}

.fieldWrapper.searchList {
  .searchFieldWrapper.inlineVersion {
    input[type="search"] {
      border: none;
      box-shadow: none;
    }
  }
  .selectListContainer {
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    .labelWrapper {
      width: 100%;
      margin-bottom: 1em;
      order: 1;
    }
    .selectedItems {
      order: 3;
      margin: 0;
      padding-left: 1em;
      width: 70%;
      flex-grow: 0;
    }
    label {
      width: 30%;
      order: 2;
    }
    &.asVertical {
      flex-direction: column;
      label {
        width: 100%;
        order: 3;
      }
      .emptyState {
        order: 2;
        font-size: 1em;
      }
      .selectedItems {
        order: 2;
        width: 100%;
        margin: 0;
        padding: 0;
        margin-bottom: 0.5em;
      }
    }
  }
  .newItemWrapper {
    padding: 0.5em;
    border-radius: $borderRadius;
    background: rgb( $backgroundColor );
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    select {
      margin-top: 0;
    }
  }
  .showMoreButton {
    width: 100%;
    padding: 0.25em 0.75em;
    color: rgb( $primary );
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.875em;
    background: rgb( $foregroundColor );
    cursor: pointer;
    border: none;
    .inlineSVG {
      margin-left: 1em;
      height: 1em;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .selectedItems {
    flex-grow: 1;
    margin-top: 1em;
    h2 {
      color: rgb( $textColor-primary );
      padding: 0;
      margin-bottom: 1em;
      font-size: 1em;
    }
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: rgb( $textColor-primary );
      text-transform: none;
      padding: 0.5em;
      position: relative;
      // border-radius: $borderRadius;
      // background: rgb( $backgroundColor );
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgb( $dividerColor );
      span {
        margin-right: 1em;
      }
      button {
        position: absolute;
        top: 0.5em;
        right: 0em;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        .inlineSVG {
          height: 1.25em;
          opacity: 0.4;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
        &.removeButton {
          right: 1.5em;
        }
      }
      .draggableHandle {
        margin-left: 1em;
        opacity: 0.5em;
        transition: 0.3s ease-in-out;
        cursor: grab;
      }
      &.isDraggable:hover {
        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        cursor: move;
        .draggableHandle {
          opacity: 1;
        }
      }
      &.isDragging {
        border: 1px solid rgb( $textColor-secondary );
        box-shadow: $boxShadow-heavy;
        border-radius: $borderRadius;
        background: rgb( $foregroundColor );
        .draggableHandle {
          opacity: 1;
        }
      }
    }
    ul {
      padding: 0.5em;
      border-radius: $borderRadius;
      &.isDragging {
        border: 1px solid rgb( $dividerColor );
        background: rgba($textColor-primary, 0.03);
      }
    }
  }
  .searchFieldWrapper.inlineVersion {
    background: rgb( $foregroundColor );
    border: 1px solid rgb( $iconColor-primary );
    border-radius: $borderRadius-input;
    padding: 0.25em 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .inlineSVG {
      position: unset;
      bottom: unset;
      left: unset;
      width: unset;
      height: unset;
      display: unset;
    }
    .searchIcon {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.25em;
      flex-shrink: 0;
    }
    .selectedItemsInline {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: -0.5em;
      width: calc( 100% - 1.75em );
      flex-grow: 1;
      flex-shrink: 1;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-transform: none;
        color: rgb( $textColor-primary );
        font-size: 0.875em;
        border-radius: 0.25em;
        background: rgb( $backgroundColor );
        padding-left: 0.5em;
        margin-right: 0.5em;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        flex-shrink: 0;
        flex-grow: 1;
        margin-bottom: 0.5em;
        font-weight: 600;
        span {
          width: calc( 100% - 1.5em );
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex-grow: 1;
        }
        .removeButton {
          flex-grow: 0;
          margin-left: 0.5em;
          background: rgb( $iconColor-primary );
          padding: 0.25em 0.5em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          border-radius: 0 $borderRadius $borderRadius 0;
          width: 2.125em;
          transition: all 0.3s ease-in-out;
          .inlineSVG {
            width: 1.125em;
            height: 1.125em;
            path {
              fill: #fff;
            }
          }
        }
        &:hover {
          background: rgb( $dividerColor );
          .removeButton {
            background: rgb( $textColor-primary );
          }
        }
      }
    }
    input {
      background: none;
      border: none;
      border-radius: unset;
      padding: 0.25em;
      margin: 0;
    }
  }
}

.searchListFieldModal {
  .modalBody {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.5em;
      margin-bottom: 2em;
      li {
        margin: 0;
        a {
          width: 100%;
          padding: 0.25em 0.75em;
          border-radius: $borderRadius;
          color: rgb( $primary );
          border: 1px solid rgb( $primary );
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: 0.875em;
          .inlineSVG {
            margin-left: 1em;
            height: 1em;
          }
          &:hover {
            background: rgb( $primary--10 );
          }
        }
      }
    }
  }
}
