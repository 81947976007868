/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.reactSetupModal.integration {
  .modalBody {
    .formWrapper {
      .fieldGroupWrapper.jira {
        padding-top: 0em;
        .contentBlock {
          margin-bottom: 0;
        }
      }
    }
  }
  .selectedProjectInformation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5em 0.875em;
    border-radius: $borderRadius;
    border: 1px solid rgb( $iconColor-primary );
    background: rgb( $backgroundColor );
    label {
      width: 100%;
      margin-bottom: 0.75em;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.875em;
      color: rgb( $textColor-secondary );
    }
    .projectAvatar {
      width: 2.5em;
      height: 2.5em;
      display: block;
      border-radius: $borderRadius-full;
      margin-right: 1em;
      &.initials {
        background: rgb( $textColor-secondary );
        color: #fff;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-transform: capitalize;
      }
    }
    .projectInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      .projectTitle {
        font-weight: 600;
      }
      .projectID {
        color: rgb( $textColor-secondary );
        font-size: 0.875em;
      }
    }
  }
  .fetchProjectsButton {
    @include dsButton( 'minimal-primary' );
  }
  .modalActions {
    justify-content: space-between;
    .secondaryButton {
      margin: 0;
      @include dsButton( 'secondary' );
    }
    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      .cancelButton {
        @include dsButton( 'minimal' );
      }
    }
  }
}
