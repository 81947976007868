/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordDetailsContainerV2 {
  display: grid;
  grid-template-columns: 36em 1fr;
  grid-gap: 1em;
  &.isCollapsed {
    grid-template-columns: 1fr;
    grid-template-rows: 5.25em 1fr;
  }
  .detailSectionDescription {
    width: 100%;
    background: rgb( $backgroundColor );
    font-size: 0.875em;
    padding: 0.5em 1em;
    border-radius: $borderRadius;
    display: block;
    margin-bottom: 1em;
    color: rgb( $textColor-primary );
    strong, span {
      display: inline;
      margin-right: 0.25em;
    }
    a {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      color: rgb( $primary );
      font-weight: 600;
      .inlineSVG {
        width: 0.75em;
        height: 0.75em;
        margin-left: 0.5em;
        path {
          fill: rgb( $primary );
        }
      }
    }
    &.withPatchesToggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      background: none;
    }
    &.darkMode {
      background: rgba( #fff, 0.2 );
    }
  }
}