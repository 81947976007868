/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.edgeContentOnPathsContainer {
  border-bottom: none !important;
  li {
    margin-bottom: 0.25em !important;
  }
  .onPathsLink {
    width: 100%;
    border-radius: $borderRadius;
    color: rgb( $textColor-primary );
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 0.5em 0.75em;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      strong {
        margin: 0 0.5em;
      }
      .inlineSVG {
        transition: all 0.3s ease-in-out;
        path {
          transition: all 0.3s ease-in-out;
          fill: rgb( $iconColor-primary );
        }
      }
    }
    &:hover {
      background: rgb( $critical--10 );
      .actions {
        .inlineSVG {
          path {
            fill: rgb( $critical );
          }
        }
      }
      &.risk-high {
        background: rgb( $high--10 );
        .actions {
          .inlineSVG {
            path {
              fill: rgb( $high );
            }
          }
        }
      }
      &.risk-moderate {
        background: rgb( $moderate--10 );
        .actions {
          .inlineSVG {
            path {
              fill: rgb( $moderate );
            }
          }
        }
      }
      &.risk-low {
        background: rgb( $low--10 );
        .actions {
          .inlineSVG {
            path {
              fill: rgb( $low );
            }
          }
        }
      }
      &.risk-minimal {
        background: rgb( $minimal--10 );
        .actions {
          .inlineSVG {
            path {
              fill: rgb( $low );
            }
          }
        }
      }
    }
  }
}
.edgeContentvulnerabilitiesSectionWrapper {
  position: relative;
  min-height: 4em;
}
.edgeContentvulnerabilityItemWrapper {
  width: 100% !important;
  align-items: center !important;
  padding: 0.5em 0.75em;
  border-radius: $borderRadius;
  margin-bottom: 0.25em !important;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  span.name {
    width: 14em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5em;
    flex-shrink: 0;
    font-weight: 600;
    white-space: nowrap;
  }
  .riskPercentageBarWrapper {
    flex-shrink: 1;
    margin-right: 0.5em;
  }
  strong {
    width: 4em;
    text-align: right;
    flex-shrink: 0;
  }
  &.selected {
    background: rgb( $dividerColor );
    &:before {
      content: "";
      width: 0.75em;
      height: 0.75em;
      background: rgb( $primary );
      border-radius: $borderRadius-full;
      flex-shrink: 0;
      margin-right: 0.5em;
    }
  }
  &:hover {
    background: rgb( $dividerColor );
  }
}
