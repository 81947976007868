/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../../styles/globals.scss" as *;

.modalContainer.remediation_plan {
  .tabWrapper.draft {
    .panel {
      overflow: auto;
    }
    .candidateFilterForm {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 1em;
      border-bottom: 1px solid rgb( $dividerColor );
      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 2em;
        .labelWrapper {
          margin-left: 0.5em;
        }
      }
    }
    ul {
      margin-bottom: 2em;
      li.taskItem.remediationTaskItem {
        padding: 0.5em 1em;
        background: rgb( $backgroundColor-secondary );
        border: 1px solid rgb( $dividerColor );
        &.selected {
          background: rgb( $backgroundColor-secondary );
          border: 1px solid rgb( $iconColor-primary ) !important;
          box-shadow: $boxShadow-light;
        }
      }
    }
  }
}