/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

// this is assumed to be displayed within a grid
.axisContainer {
  display: flex;
  // y axis is assumed to be 4em width
  &.yAxis {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: calc( 100% + 0.5em + 2px );
    border-right: 2px solid rgb( $iconColor-primary );
    margin-right: 0.5em;
    padding-bottom: calc( 0.5em + 2px );
    .axisTic {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      margin-top: -0.375em;
      color: rgb( $textColor-primary );
      font-weight: 600;
      &:after {
        content: "";
        width: 0.5em;
        height: 2px;
        background: rgb( $iconColor-primary );
        margin-left: 0.375em;
        margin-top: 0.373em;
      }
    }
    &.ticCountClass--4 {
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }
  &.xAxis {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 2.5em;
    grid-column: 2 / 3;
    width: 100%;
    border-top: 2px solid rgb( $iconColor-primary );
    margin-top: 0.5em;
    margin-left: -0.5em;
    .axisLabel {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-right: 0.25em;
      font-size: 0.75em;
      padding-top: 0.75em;
      .mainLabel {
        margin-bottom: 0.5em;
        color: rgb( $textColor-secondary );
      }
      .subLabel {
        font-weight: 600;
        font-size: 1.125em;
      }
    }
    &.ticCountClass--12 {
      .axisLabel {
        width: 8.33333%;
      }
    }
    &.ticCountClass--11 {
      .axisLabel {
        width: 9.090909%;
      }
    }
    &.ticCountClass--10 {
      .axisLabel {
        width: 10%;
      }
    }
    &.ticCountClass--9 {
      .axisLabel {
        width: 11.11111111%;
      }
    }
    &.ticCountClass--8 {
      .axisLabel {
        width: 12.5%;
      }
    }
    &.ticCountClass--7 {
      .axisLabel {
        width: 14.28571%;
      }
    }
    &.ticCountClass--6 {
      .axisLabel {
        width: 16.66666666666667%;
      }
    }
    &.ticCountClass--4 {
      .axisLabel {
        width: 25%;
      }
    }
    &.ticCountClass--3 {
      .axisLabel {
        width: 33.3333%;
      }
    }
    &.ticCountClass--2 {
      .axisLabel {
        width: 50%;
      }
    }
    &.area {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      justify-content: unset;
      height: 2.5em;
      grid-column: unset;
      width: 100%;
      border-top: none;
      margin-top: unset;
      margin-left: unset;
      position: relative;
      .axisLabel {
        width: 100%;
        display: unset;
        flex-direction: unset;
        align-items: unset;
        justify-content: unset;
        padding-right: 0;
        font-size: 1em;
        padding-top: 0;
        color: rgb( $textColor-primary );
        font-weight: 600;
        text-align: center;
        margin-left: -50%;
        margin-top: -1em;
        &.withTic {
          position: relative;
          &:before {
            content: "";
            top: -0.75em;
            left: 50%;
            height: 0.5em;
            width: 2px;
            background: rgb( $iconColor-primary );
            position: absolute;
          }
          &.above {
            &:before {
              content: "";
              top: unset;
              bottom: -0.75em;
            }
          }
        }
        &.last {
          position: absolute;
          right: calc( -50% + 0.5em );
          top: 1.5em;
        }
      }
      &.ticCountClass--5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
      &.ticCountClass--4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      &.ticCountClass--3 {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
