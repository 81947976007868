/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordRiskAndRatingWrapper {
  padding: 1em 2em;
  border-radius: $borderRadius;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  position: relative;
  margin-left: 1.5em;
  .recordStatisticWrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .riskExplanationTriggerButton {
    position: absolute;
    bottom: -2em;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    right: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
    color: rgb( $textColor-secondary );
    &:hover {
      opacity: 1;
      &.riskClass--critical, &.riskClass--Critical {
        color: rgb( $critical );
      }
      &.riskClass--high, &.riskClass--High {
        color: rgb( $high );
      }
      &.riskClass--moderate, &.riskClass--Moderate {
        color: rgb( $moderate );
      }
      &.riskClass--low, &.riskClass--Low {
        color: rgb( $low );
      }
      &.riskClass--minimal, &.riskClass--Minimal {
        color: rgb( $minimal );
      }
      &.riskClass--unknown, &.riskClass--Unknown {
        color: rgb( $textColor-secondary );
      }
      &.riskClass--primary, &.riskClass--Primary {
        color: rgb( $primary );
      }
    }
  }
  &.riskClass--critical, &.riskClass--Critical {
    border: 1px solid rgb( $critical );
    background: rgb( $critical--10 );
  }
  &.riskClass--high, &.riskClass--High {
    border: 1px solid rgb( $high );
    background: rgb( $high--10 );
  }
  &.riskClass--moderate, &.riskClass--Moderate {
    border: 1px solid rgb( $moderate );
    background: rgb( $moderate--10 );
  }
  &.riskClass--low, &.riskClass--Low {
    border: 1px solid rgb( $low );
    background: rgb( $low--10 );
  }
  &.riskClass--minimal, &.riskClass--Minimal {
    border: 1px solid rgb( $minimal );
    background: rgb( $minimal--10 );
  }
  &.riskClass--unknown, &.riskClass--Unknown {
    border: 1px solid rgb( $textColor-secondary );
    background: rgb( $backgroundColor );
  }
  &.riskClass--primary, &.riskClass--Primary {
    border: 1px solid rgb( $primary );
    background: rgb( $primary--10 );
  }
  &.horizontal {
    grid-template-columns: 1fr 1fr;
  }
  &.darkMode {
    background: none;
  }
}