/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.weeklySchedule {
  label.disabled {
    .required {
      display: none;
    }
    input[type='time'] {
      color: rgb( $iconColor-primary );
      border: 1px solid transparent;
      border-bottom: 1px solid rgb( $iconColor-primary );
    }
  }
  .timePickerWrapper {
    margin: 1em 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    label {
      width: calc(50% - 1em);
    }
  }
  .daysWrapper {
    label {
      text-transform: none;
      margin-bottom: 1em;
      font-size: 1em;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      flex-direction: row;
      span {
        margin-left: 1em;
      }
    }
  }
  &.updated, &.invalid {
    .daysWrapper, .timePickerWrapper {
      label {
        &:before {
          display: none;
        }
        .labelWrapper {
          padding-left: 0;
        }
      }
    }
  }
}
