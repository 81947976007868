/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.dropdownShade {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 1004;
  &.opaque {
    background: rgba(#000, 0.2);
  }
  &.visible {
    visibility: visible;
  }
}

.dropdownWrapper {
  position: relative;
  .dropdownTrigger {
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
  }
  &.wide {
    .dropdownMenu {
      width: 20em;
    }
  }
}

.dropdownMenu {
  visibility: hidden;
  background: rgb( $foregroundColor );
  position: absolute;
  top: 3em;
  right: 0;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  color: rgb( $textColor-primary );
  width: 12em;
  font-family: $sans;
  z-index: 99999999;
  &.riskFlyout {
    width: 28em;
    top: 4em;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    li {
      box-sizing: border-box;
      list-style: none;
      line-height: 2em;
      cursor: pointer;
      text-transform: none;
      color: rgb( $textColor-primary );
      width: 100%;
      margin-bottom: 0;
      &.noDismiss {
        cursor: default;
      }
      &:hover {
        color: rgb( $primary );
      }
      a, div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: rgb( $textColor-primary );
        text-decoration: none;
        font-family: $sans;
        font-weight: 400;
        padding: 0.5em 1em;
        width: 100%;
        display: block;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &:hover {
          color: rgb( $textColor-primary );
          background: rgb( $dividerColor );
        }
        .inlineSVG {
          width: 1.125em;
          height: 1.125em;
          margin-right: 0.5em;
        }
      }
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.5em;
      }
    }
  }
  &.visible {
    visibility: visible;
  }
  &.asSelect {
    border: 1px solid rgb( $iconColor-primary );
    overflow: hidden;
    top: 2.5em;
    padding: 0.5em;
    ul {
      border-radius: $borderRadius;
      li {
        padding: 0;
        margin-bottom: 0;
        a {
          padding: 0.5em 1em;
          text-transform: none;
          font-size: 0.875em;
          font-weight: 400;
          color: rgb( $textColor-primary );
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          &:hover {
            background: rgb( $dividerColor );
            color: rgb( $textColor-primary );
          }
          &.current {
            background: rgb( $textColor-primary );
            color: #fff;
          }
        }
        &:first-child {
          border-radius: $borderRadius $borderRadius 0 0;
          a {
            border-radius: $borderRadius $borderRadius 0 0;
          }
        }
        &:last-child {
          border-radius: 0 0 $borderRadius $borderRadius;
          a {
            border-radius: 0 0 $borderRadius $borderRadius;
          }
        }
        .selectIemWrapper {
          width: 100%;
          border-radius: none;
          background: rgb( $foregroundColor );
          text-decoration: none;
          color: rgb( $textColor-primary );
          text-align: left;
          padding: 0.375em 0.75em;
          border-radius: $borderRadius;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          // transition: all 0.3s ease-in-out;
          cursor: pointer;
          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }
          img, .inlineSVG {
            display: block;
            width: 1.375em;
            height: 1.375em;
          }
          .logoWrapper {
            width: 2.125em;
            height: 2.125em;
            border-radius: $borderRadius-full;
            margin-right: 0.5em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            background: rgb( $backgroundColor );
            img, .inlineSVG {
              margin-right: 0;
            }
          }
          &:hover {
            background: rgb( $dividerColor );
          }
          &.asDivider {
            margin-bottom: 0;
            padding: 0;
            cursor: default;
            padding-left: 1em;
            padding-top: 0.5em;
            margin-top: 0.5em;
            border-top: 1px solid rgb( $dividerColor );
            border-radius: 0;
            .dividerLabelWrapper {
              text-transform: uppercase;
              // font-weight: 600;
              color: rgb( $textColor-secondary );
              font-size: 0.875em;
            }
            &:hover {
              background: rgb( $foregroundColor );
            }
            // position: relative;
            // width: 100%;
            // padding: 0;
            // display: block;
            // &:hover {
            //   background: unset;
            // }
            // .dividerLine {
            //   width: calc( 100% - 1em );
            //   height: 1px;
            //   position: absolute;
            //   left: 0;
            //   top: 50%;
            //   background: rgb( $dividerColor );
            //   padding: 0;
            // }
            // .dividerLabelWrapper {
            //   font-size: 0.75em;
            //   cursor: default;
            //   pointer-events: none;
            //   padding: 0.5em 1em 0.5em 1.5em;
            //   color: rgb( $iconColor-primary );
            //   text-transform: uppercase;
            //   font-weight: 600;
            //   position: relative;
            //   background: rgb( $foregroundColor );
            //   z-index: 1;
            //   display: inline;
            // }
          }
          .ratingBadge.beta {
            width: 4.5em;
            justify-content: space-around;
            &:hover {
              color: rgb( $primary );
              background: rgb( $primary--10 );
            }
          }
          .ratingBadge.new {
            width: 4.5em;
            justify-content: space-around;
            &:hover {
              color: rgb( $status-success );
              background: rgb( $status-success--10 );
            }
          }
        }
      }
    }
  }
}

.dropdownTrigger {
  &.asSelect {
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url('./../../../../images/icons/input/select-arrow.svg');
    background-position: calc(100% - 0.75em) 50%;
    padding: 0.5em 2.5em 0.5em 1.125em;
    border: 1px solid rgba( $iconColor-primary, 0.75 );
    color: rgba( $textColor-primary, 0.85 );
    box-shadow: $boxShadow-input;
    border-radius: $borderRadius-input;
    margin: 0;
    width: 100%;
    font-size: 1.125em;
    span {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      &.placeholder {
        color: rgb( $textColor-secondary );
      }
    }
    img {
      display: block;
      width: 1.375em;
    }
    .logoWrapper {
      width: 2.125em;
      height: 2.125em;
      border-radius: $borderRadius-full;
      margin-right: 0.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background: rgb( $backgroundColor );
    }
    .inlineSVG {
      width: 1.375em;
      height: 1.375em;
    }
  }
}
