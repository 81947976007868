/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

#pageHeaderContainer.sensitiveAssetsManualHeaderWrapper {
  .filterForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .filterWrapper {
      width: unset;
      margin: 0;
      padding: 0;
      border-bottom: none;
      flex-grow: 0;
      margin-right: 1em;
      label {
        width: unset;
        flex-direction: column;
      }
      &.ignore {
        label {
          flex-direction: row;
          margin-top: 1.75em;
        }
      }
    }
  }
}

#pageContent.sensitive_assets_manualPageContainer {
  .riskInsightTableWrapper {
    width: 100%;
    flex-grow: 1;
    transition: all 0.15s ease-in-out;
    height: 1em;
    position: relative;
    z-index: 1;
    background: rgb( $foregroundColor );
    border-radius: $borderRadius;
    margin-bottom: 5em;
  }
  .tableActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-radius: $borderRadius $borderRadius 0 0;
    .multipleSelectedActions {
      flex-grow: 0;
      button {
        flex-grow: 0;
        width: 14em;
        @include dsButton('minimal-primary');
        border-radius: $borderRadius;
      }
    }
  }
  .riskInsightTableWrapper {
    .sensitiveAssetsDataTable {
      .tableRow {
        grid-template-columns:
          4em
          1fr
          2fr
          16em
          12em
          4em;
        &.tableHeader {
          border-top: 1px solid rgb( $dividerColor );
        }
        .tableCell {
          &.tableCell_name {
            .nodeLink {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              color: rgb( $textColor-primary );
              text-decoration: none;
              .nodeIconWrapper {
                width: 2em;
                height: 2em;
                border-radius: 9999em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                background: rgb( $backgroundColor );
                margin: 0;
                padding: 0;
                margin-right: 0.5em;
                .recordTypeHeaderIcon {
                  width: 1.25em;
                  height: 1.25em;
                }
              }
              &:hover {
                font-weight: 600;
                text-decoration: underline;
              }
            }
          }
        }
        .headerCell_impact  {
          border-right: 1px solid #fff;
        }
        &:hover {
          .tableCell_name {
            .nodeLink {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
};

.paginationContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 2em;
  margin-left: 2em;
  border-left: 1px solid rgb( $dividerColor );
  flex-grow: 1;
  button {
    @include dsButton('minimal-dark');
    border-radius: $borderRadius;
    &.next {
      margin-left: 1em;
    }
    &:disabled {
      @include dsButton( 'minimal-default' );
      border-radius: $borderRadius;
      cursor: default;
      pointer-events: none;
      opacity: 0.6;
      color: rgb( $textColor-secondary ) !important;
      .inlineSVG {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
    }
  }
  .nextPreviousButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
.sensitiveAssetsModal {
  .inputWrapper {
    .errorMessage {
      margin-top: 0.5em;
      color: rgb( $status-alert );
    }
    input {
      padding: 0.5em 1em;
      border-radius: $borderRadius;
      margin-top: 0.5em;
      border: 1px solid rgb( $iconColor-primary );
    }
  }
}