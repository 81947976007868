/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.remediation_specific_plan {
  color: rgb( $textColor-primary );
  h2 {
    font-size: 1.125em;
    font-weight: 400;
    strong {
      font-weight: 600;
    }
  }
  .taskCount {
    margin-bottom: 1em;
  }
  .remediationChartWrapper {
    padding-top: 1em;
    .remediationTaskWrapper {
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid rgb( $dividerColor );
      color: rgb( $textColor-primary );
      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }
      label {
        font-weight: 600;
        margin-bottom: 0.5em;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        section {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .action {
            margin: 0 0.5em;
            font-weight: 400;
          }
          &.owner {
            font-weight: 400;
            color: rgb( $textColor-secondary );
            margin-right: 5.5em;
          }
        }
        .ratingBadge {
          margin-right: 0.5em;
        }
        .inlineSVG {
          width: 1.125em;
          height: 1.125em;
          margin-right: 0.5em;
          path {
            fill: rgb( $iconColor-primary );
          }
        }
      }
      .labelAndBarWrapper {
        display: grid;
        grid-template-columns: 3.5em 1fr 5em;
        grid-gap: 0.5em;
        margin-bottom: 0.5em;
        span {
          text-align: right;
          line-height: 1.5em;
          &.categoryLabelWrapper {
            text-align: left;
          }
        }
        .taskCompletionWrapper {
          width: 100%;
          height: 1.5em;
          background: rgb( $backgroundColor );
          .taskCompletionBar {
            height: 1.5em;
            min-width: 0.25em;
          }
        }
      }
    }
  }
  .chartLegend.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    ul {
      justify-self: center;
      width: unset;
      li {
        margin-bottom: 0;
        &:first-child {
          margin-right: 2em;
        }
      }
    }
  }
}