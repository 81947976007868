/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.categoriesBreakdownChart {
  color: rgb( $textColor-primary );
  rect {
    transition: all 0.3s ease-in-out;
    &.isClickable {
      cursor: pointer;
    }
  }
}