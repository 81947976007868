/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../styles/globals.scss" as *;

.exploreModal {
  &.segment {
    padding-bottom: 4em;
    background: none;
    box-shadow: none;
    .modalHeader {
      padding: 0;
      position: relative;
      h2 {
        display: none;
      }
      button {
        position: absolute;
        top: 4.25em;
        right: 2em;
      }
    }
    .modalBody {
      padding: 0;
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      overflow: hidden;
    }
    h2 {
      font-size: 1.125em;
      padding-bottom: 0;
      margin-bottom: 1.5em;
      grid-column: 1 / 3;
    }
    .stepDirections {
      background: rgb( $backgroundColor );
      padding: 1em 1.5em;
      border-radius: $borderRadius;
      grid-column: 1 / 3;
      margin-bottom: 1.75em;
      display: block;
    }
    .formWrapper {
      margin-top: 0;
    }
    .tabWrapper {
      background: rgb( $foregroundColor );
      margin: 0;
      border-radius: 0 $borderRadius 0 0;
      border-top: none;
      padding: 2em !important;
      padding-bottom: 4.5em !important;
      height: calc( 95vh - 3em ) !important;
      .loadingWrapperPlaceholder {
        position: absolute;
        z-index: 20;
        width: 100%;
        height: 100%;
      }
      &.existing {
        display: grid;
        grid-template-columns: 30em 1fr;
        grid-column-gap: 2em;
        .existingVulnerabilitiesColumn {
          border-right: 1px solid rgb( $dividerColor );
          padding-right: 2em;
          label {
            margin: 1em;
            font-size: 1em;
          }
          ul {
            width: 100%;
            overflow: auto;
            height: calc( 95vh - 21em );
            .escalationItem {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              color: rgb( $textColor-primary );
              text-decoration: none;
              transition: all 0.3s ease-in-out;
              margin: 0;
              padding: 0.375em 0.75em;
              border-radius: 0.25em;
              position: relative;
              cursor: pointer;
              .name {
                width: 9em;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 0.5em;
                flex-shrink: 0;
                font-weight: 600;
                white-space: nowrap;
              }
              .riskPercentageBarWrapper {
                flex-shrink: 1;
                margin-right: 0.5em;
              }
              strong {
                width: 4em;
                text-align: right;
                flex-shrink: 0;
                margin-right: 1em;
              }
              button {
                margin: 0;
                padding: 0;
                opacity: 0.6;
                border: none;
                background: none;
                cursor: pointer;
                display: block;
                transition: all 0.3s ease-in-out;
                margin-left: 0.5em;
                &:focus {
                  outline: none;
                }
                &:hover {
                  opacity: 1;
                  background: rgb( $dividerColor );
                }
                .inlineSVG {
                  display: block;
                  width: 1.5em;
                  height: 1.5em;
                  &.light {
                    display: none;
                    height: 0;
                    width: 0;
                  }
                }
              }
              &:hover {
                background: rgb( $dividerColor );
              }
              &.selected {
                background: rgb( $dividerColor );
                padding-left: 2em;
                &:before {
                  content: "";
                  flex-shrink: 0;
                  height: 0.75em;
                  width: 0.75em;
                  border-radius: $borderRadius-full;
                  background: rgb( $primary );
                  position: absolute;
                  top: 50%;
                  margin-top: calc( 0.75em / -2 );
                  left: 0.75em;
                }
                &.updated {
                  padding-left: 3.5em;
                  background: rgb( $dividerColor );
                  &:after {
                    content: "";
                    flex-shrink: 0;
                    height: 0.75em;
                    width: 0.75em;
                    border-radius: $borderRadius-full;
                    background: rgb( $status-warning );
                    position: absolute;
                    top: 50%;
                    margin-top: calc( 0.75em / -2 );
                    left: 2em;
                  }
                }
              }
              &.updated {
                padding-left: 2em;
                background: rgb( $backgroundColor );
                &:before {
                  content: "";
                  flex-shrink: 0;
                  height: 0.75em;
                  width: 0.75em;
                  border-radius: $borderRadius-full;
                  background: rgb( $status-warning );
                  position: absolute;
                  top: 50%;
                  margin-top: calc( 0.75em / -2 );
                  left: 0.75em;
                }
                &.selected {
                  padding-left: 3.5em;
                  background: rgb( $dividerColor );
                  &:before {
                    content: "";
                    flex-shrink: 0;
                    height: 0.75em;
                    width: 0.75em;
                    border-radius: $borderRadius-full;
                    background: rgb( $primary );
                    position: absolute;
                    top: 50%;
                    margin-top: calc( 0.75em / -2 );
                    left: 0.75em;
                  }
                  &:after {
                    content: "";
                    flex-shrink: 0;
                    height: 0.75em;
                    width: 0.75em;
                    border-radius: $borderRadius-full;
                    background: rgb( $status-warning );
                    position: absolute;
                    top: 50%;
                    margin-top: calc( 0.75em / -2 );
                    left: 2em;
                  }
                }
              }
              &.added {
                padding-left: 2em;
                background: rgb( $backgroundColor );
                &:before {
                  content: "";
                  flex-shrink: 0;
                  height: 0.75em;
                  width: 0.75em;
                  border-radius: $borderRadius-full;
                  background: rgb( $status-success );
                  position: absolute;
                  top: 50%;
                  margin-top: calc( 0.75em / -2 );
                  left: 0.75em;
                }
                &.selected {
                  background: rgb( $dividerColor );
                  &:before {
                    content: "";
                    flex-shrink: 0;
                    height: 0.75em;
                    width: 0.75em;
                    border-radius: $borderRadius-full;
                    background: rgb( $primary );
                    position: absolute;
                    top: 50%;
                    margin-top: calc( 0.75em / -2 );
                    left: 0.75em;
                  }
                }
                &.updated {
                  padding-left: 3.5em;
                  &:after {
                    content: "";
                    flex-shrink: 0;
                    height: 0.75em;
                    width: 0.75em;
                    border-radius: $borderRadius-full;
                    background: rgb( $status-warning );
                    position: absolute;
                    top: 50%;
                    margin-top: calc( 0.75em / -2 );
                    left: 2em;
                  }
                }
              }
            }
          }
        }
        .formColumnWrapper {
          position: relative;
          overflow: auto;
          .vulnerabilityEditBanner {
            button {
              @include dsButton('secondary');
              position: absolute;
              top: 1em;
              right: calc( 2em + 2px);
            }
          }
          .formWrapper {
            display: grid;
            grid-template-areas: 'A A' 'B C';
            grid-gap: 1em;
            grid-auto-flow: column;
            grid-template-columns: 1fr 1fr;
            .vulnerability {
              grid-area: A;
              padding: 1em;
              border-radius: $borderRadius;
              // border: 1px solid rgb( $iconColor-primary );
              background: rgb( $backgroundColor );
              width: calc( 100% - 1em);
              .fieldWrapper {
                margin-bottom: 1em;
                textArea {
                  resize: none;
                  height: 10em;
                  &:disabled {
                    background: rgb( $backgroundColor );
                    color: rgb( $iconColor-primary );
                  }
                }
              }
            }
            .exploitLeft {
              grid-area: B;
            }
            .exploitRight {
              grid-area: C;
              margin-top: 3em;
              width: calc( 100% - 1em);
            }
          }
          &.editDisabled {
            .formWrapper {
              .vulnerability {
                .fieldWrapper {
                  pointer-events: none;
                  cursor: not-allowed;
                  label, textarea, select, .selectFieldWrapper {
                    pointer-events: none;
                    cursor: not-allowed;
                    color: rgb( $textColor-secondary );
                  }
                }
              }
            }
          }
          &.editEnabled {
            .vulnerabilityEditBanner {
              background: rgb( $status-alert );
              border-radius: $borderRadius $borderRadius 0 0;
              padding: 0.5em 0.75em;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              width: calc( 100% - 1em );
              h2 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                color: #fff;
                font-size: 1em;
                font-weight: 400;
                .inlineSVG {
                  width: 1.5em;
                  height: 1.5em;
                  margin-right: 0.5em;
                }
                strong {
                  margin-right: 0.25em;
                }
              }
              button {
                @include dsButton('status-alert');
                top: 3.5em;
              }
            }
            .formWrapper {
              .vulnerability {
                background: rgb( $status-alert--25 );
                border-radius: 0 0 $borderRadius $borderRadius;
                .fieldWrapper {
                  pointer-events: all;
                  cursor: default;
                  label, textarea, select, .selectFieldWrapper {
                    pointer-events: all;
                    cursor: default;
                    color: rgb( $textColor-primary );
                  }
                }
              }
            }
          }
        }
      }
      // tab 2, add new vulns
      &.new {
        .formWrapper {
          padding: 0 1em;
          grid-column: 1 / 3;
          .fieldWrapper#identifier_text_fieldWrapper {
            position: relative;
            &:before {
              content: 'CUSTOM -';
              position: absolute;
              top: 2.75em;
            }
            input {
              margin-left: 5.5em;
              padding: 0.5em;
            }
          }
        }
        .stepDirections {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .panels {
          display: grid;
          grid-template-columns: 30em 1fr;
          grid-column-gap: 2em;
          width: 100%;
          grid-column: 1 / 3;
          .panel {
            padding: 0;
            &.results {
              padding-right: 2em;
              flex-shrink: 0;
              label {
                margin: 0 0 1em 1em;
                font-size: 1em;
              }
              ul {
                width: 100%;
                height: calc( 95vh - 27.75em );
                overflow: auto;
                .vulnerabilityResult {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  color: rgb( $textColor-primary );
                  text-decoration: none;
                  transition: all 0.3s ease-in-out;
                  margin: 0;
                  padding: 0.375em 0.75em;
                  border-radius: 0.25em;
                  position: relative;
                  cursor: pointer;
                  .name {
                    width: 9em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 0.5em;
                    flex-shrink: 0;
                    font-weight: 600;
                    white-space: nowrap;
                  }
                  .riskPercentageBarWrapper {
                    flex-shrink: 1;
                    margin-right: 0.5em;
                  }
                  strong {
                    width: 4em;
                    text-align: right;
                    flex-shrink: 0;
                    margin-right: 1em;
                  }
                  button {
                    margin: 0;
                    padding: 0;
                    opacity: 0.6;
                    border: none;
                    background: none;
                    cursor: pointer;
                    display: block;
                    transition: all 0.3s ease-in-out;
                    margin-left: 0.5em;
                    &:focus {
                      outline: none;
                    }
                    &:hover {
                      opacity: 1;
                      background: rgb( $dividerColor );
                    }
                    .inlineSVG {
                      display: block;
                      width: 1.5em;
                      height: 1.5em;
                      &.light {
                        display: none;
                        height: 0;
                        width: 0;
                      }
                    }
                  }
                  &:hover {
                    background: rgb( $dividerColor );
                  }
                  &.selected {
                    background: rgb( $dividerColor );
                    padding-left: 2em;
                    &:before {
                      content: "";
                      flex-shrink: 0;
                      height: 0.75em;
                      width: 0.75em;
                      border-radius: $borderRadius-full;
                      background: rgb( $primary );
                      position: absolute;
                      top: 50%;
                      margin-top: calc( 0.75em / -2 );
                      left: 0.75em;
                    }
                  }
                }
              }
            }
            &.vulnerabilityDetails {
              pre {
                margin-top: 0;
              }
              strong {
                display: block;
                margin-bottom: 1em;
              }
            }
          }
        }
      }
    }
  }
}
