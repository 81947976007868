/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordListItem {
  padding-bottom: 0;
  margin-bottom: 0.125em;
  position: relative;
  a, .notLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgb( $textColor-primary );
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin: 0;
    padding: 0.375em 0.75em;
    border-radius: 0.25em;
    cursor: pointer;
    .itemContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: calc( 100% - 2em );
      strong {
        margin-right: 0.5em;
      }
    }
    &.withPercentageBar {
      justify-content: flex-start;
      .itemContent {
        width: 100%;
        display: grid;
        grid-template-columns: 14em 1fr 4em;
        grid-gap: 0.5em;
        padding-right: 1em;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 0.5em;
          flex-shrink: 0;
          font-weight: 600;
          white-space: nowrap;
          &.vulnerability {
            width: 9em;
          }
        }
        .itemPercentageBarWrapper {
          flex-shrink: 1;
          margin-right: 0.5em;
        }
        strong {
          width: 100%;
          display: block;
          text-align: right;
          &.risk-critical {
            color: rgb( $critical );
          }
          &.risk-high, &.risk-weaponized {
            color: rgb( $high );
          }
          &.risk-moderate, &.published_details, &.details_published {
            color: rgb( $moderate );
          }
          &.risk-low, &.poc {
            color: rgb( $low );
          }
          &.risk-minimal {
            color: rgb( $minimal );
          }
          &.risk-primary {
            color: rgb( $primary );
          }
          &.risk-unknown, &.risk-default, &.risk-private {
            color: rgb( $textColor-secondary );
          }
          &.risk-darkBlue {
            color: rgb( $textColor-primary );
          }
        }
      }
    }
    .inlineSVG {
      transition: all 0.3s ease-in-out;
      width: 1em;
      height: 1em;
      opacity: 0.3;
      margin-right: 0.5em;
    }
    &:visited {
      color: rgb( $textColor-primary );
    }
    &:hover, &.isSelected {
      color: rgb( $primary );
      .inlineSVG {
        opacity: 1;
        path {
          fill: rgb( $primary );
        }
      }
      &.fullHover {
        text-decoration: none;
        color: rgb( $textColor-primary );
        background: rgb( $primary--10 );
        &.risk-critical {
          background: rgb( $critical--10 );
          .inlineSVG {
            path {
              fill: rgb( $critical );
            }
          }
        }
        &.risk-high, &.risk-weaponized {
          background: rgb( $high--10 );
          .inlineSVG {
            path {
              fill: rgb( $high );
            }
          }
        }
        &.risk-moderate, &.published_details, &.details_published {
          background: rgb( $moderate--10 );
          .inlineSVG {
            path {
              fill: rgb( $moderate );
            }
          }
        }
        &.risk-low, &.poc {
          background: rgb( $low--10 );
          .inlineSVG {
            path {
              fill: rgb( $low );
            }
          }
        }
        &.risk-minimal {
          background: rgb( $minimal--10 );
          .inlineSVG {
            path {
              fill: rgb( $minimal );
            }
          }
        }
        &.risk-primary {
          background: rgb( $primary--10 );
          .inlineSVG {
            path {
              fill: rgb( $primary );
            }
          }
        }
        &.risk-unknown, &.risk-default, &.risk-private {
          background: rgb( $dividerColor );
          .inlineSVG {
            path {
              fill: rgb( $textColor-secondary );
            }
          }
        }
        &.risk-darkBlue {
          background: rgb( $dividerColor );
          .inlineSVG {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
      }
    }
    &.isSelected {
      &.fullHover {
        padding-left: 2em;
        &:before {
          content: "";
          position: absolute;
          height: 0.75em;
          width: 0.75em;
          border-radius: $borderRadius-full;
          background: rgb( $primary );
          top: 50%;
          margin-top: -0.375em;
          left: 0.75em;
        }
        &.risk-critical {
          &:before {
            background: rgb( $critical );
          }
        }
        &.risk-high, &.risk-weaponized {
          &:before {
            background: rgb( $high );
          }
        }
        &.risk-moderate, &.published_details, &.details_published {
          &:before {
            background: rgb( $moderate );
          }
        }
        &.risk-low, &.poc {
          &:before {
            background: rgb( $low );
          }
        }
        &.risk-minimal {
          &:before {
            background: rgb( $minimal );
          }
        }
        &.risk-primary {
          &:before {
            background: rgb( $primary );
          }
        }
        &.risk-unknown, &.risk-default, &.risk-private {
          &:before {
            background: rgb( $textColor-secondary );
          }
        }
        &.risk-darkBlue {
          &:before {
            background: rgb( $textColor-primary );
          }
        }
      }
    }
  }
  .relatedSignatureButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    padding: 0.375em 0.75em;
    border-radius: $borderRadius;
    margin: 0;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    color: rgb( $textColor-primary );
    width: 100%;
    .labelWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img {
        display: block;
        height: 1.5em;
        margin-right: 0.5em;
      }
      strong {
        font-weight: 600;
        margin-right: 0.25em;
      }
      span {
        font-weight: 400;
      }
    }
    .timestamp {
      font-weight: 400;
      color: rgb( $textColor-secondary );
    }
    &:hover {
      background: rgb( $dividerColor );
    }
  }
  .additionalActionLink {
    background: rgb( $high--10 );
    padding: 0.5em 0.5em 0.5em 1em;
    color: rgb( $critical );
    .inlineSVG {
      path {
        fill: rgb( $critical );
      }
    }
    &:hover {
      background: rgb( $high--10 );
    }
  }
  .disableClicks {
    cursor: default;
    pointer-events: none;
  }
  &.darkMode {
    a, .notLink {
      &:hover, &.isSelected {
        color: rgba( #000, 0.75 ) !important;
      }
    }
  }
}