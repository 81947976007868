/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.duration {
  label {
    flex-direction: row;
    flex-wrap: wrap;
  }
  input[type='number'], select {
    width: auto;
    flex-shrink: 0;
    flex-grow: 1;
  }
  input[type='number'] {
    margin-right: 1em;
  }
  .selectFieldWrapper {
    select {
      margin-top: 0 !important;
      border-radius: $borderRadius-input;
      padding-right: 2.5em;
    }
  }
}
