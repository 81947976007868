/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.paths_specific,
.dashboardWidgetWrapper.paths_global {
  .widgetContent {
    .pathsWidgetWrapper {
      background: rgb( $backgroundColor );
      border-radius: $borderRadius;
      padding: 1em;
      width: 100%;
      min-height: 100%;
      max-height: 100%;
      .pathSVGContainer {
        height: 100%;
        .graphActions {
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  &.withDescription {
    .widgetContent {
      .pathsWidgetWrapper {
        min-height: calc( 100% - 4em );
        max-height: calc( 100% - 4em );
      }
    }
  }
}