/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_priority,
.dashboardWidgetWrapper.patches_priority,
.dashboardWidgetWrapper.vulnerabilities_priority,
.dashboardWidgetWrapper.users_priority {
  .widgetDescriptionContainer {
    order: 2;
  }
  .widgetContent {
    overflow: auto;
  }
  .recordsCountHeader {
    color: rgb( $textColor-primary );
    font-size: 1.125em;
    line-height: 1.125em;
    order: 1;
    margin-bottom: 0.5em;
  }
  &.list {
    .recordList {
      order: 3;
    }
  }
  // bar chart version
  &.barchart {
    .widgetContent {
      overflow: hidden;
    }
    .priorityChartAndAxisWrapper {
      display: grid;
      grid-template-columns: 5em 1fr;
      height: calc( 100% - 2em );
      padding-top: 0.5em;
      .axisContainer.yAxis {
        margin-right: 0;
        height: calc(100% + 0.5em );
      }
      .svgBarWrapper {
        height: calc(100% + 0.5em );
        padding-left: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 2px solid rgb( $iconColor-primary );
      }
    }
    &.withDescription {
      .widgetContent {
        .priorityChartAndAxisWrapper {
          height: calc( 100% - 5em );
        }
      }
    }
    &.widgetHeight-4 {
      .widgetContent {
        .priorityChartAndAxisWrapper {
          .svgBarWrapper,
          .axisContainer.yAxis {
            height: calc( 100% - 5.5em );
          }
        }
      }
      &.withDescription {
        .widgetContent {
          .priorityChartAndAxisWrapper {
            .svgBarWrapper,
            .axisContainer.yAxis {
              height: calc( 100% - 8.5em );
            }
          }
        }
      }
    }
    &.widgetHeight-5 {
      .widgetContent {
        .priorityChartAndAxisWrapper {
          .svgBarWrapper,
          .axisContainer.yAxis {
            height: calc( 100% - 1.5em );
          }
        }
      }
      &.withDescription {
        .widgetContent {
          .priorityChartAndAxisWrapper {
            .svgBarWrapper,
            .axisContainer.yAxis {
              height: calc( 100% - 4.5em );
            }
          }
        }
      }
    }
    &.widgetHeight-6 {
      &.withDescription {
        .widgetContent {
          .priorityChartAndAxisWrapper {
            .svgBarWrapper,
            .axisContainer.yAxis {
              height: calc( 100% - 0.5em );
            }
          }
        }
      }
    }
  }
  // table version
  &.table {
    .tableWidgetWrapper {
      margin-top: 1em;
      .tableRow {
        margin: 0;
        padding: 0;
        width: 100%;
        &.tableHeader {
          border-left: 1px solid rgb( $dividerColor );
          border-right: 1px solid rgb( $dividerColor );
          border-top: 1px solid rgb( $dividerColor );
          .headerCell:first-of-type {
            margin: 0;
          }
        }
        .tableCell {
          padding: 0.5em 1em;
          &.tableCell_description,
          &.tableCell_name,
          &.tableCell_product_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
          }
          &.tableCell_risk {
            .ratingBadge {
              margin-right: 0.5em;
            }
          }
          &.tableCell_name {
            a {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              color: rgb( $textColor-primary );
              transition: all 0.3s ease-in-out;
              cursor: pointer;
              text-decoration: none;
              font-weight: 600;
              .inlineSVG {
                width: 1em;
                height: 1em;
                margin-left: 0.5em;
                flex-shrink: 0;
                path {
                  transition: all 0.3s ease-in-out;
                  fill: $iconColor-primary
                }
              }
              &:hover {
                color: rgb( $primary );
                text-decoration: underline;
                .inlineSVG {
                  path {
                    fill: rgb( $primary );
                  }
                }
              }
            }
          }
        }
      }
      &.hosts {
        .tableRow {
          grid-template-columns: 13em 1fr 10em;
          .tableCell {
            &.headerCell_host_type,
            &.headerCell_vulnerabilities,
            &.tableCell_host_type,
            &.tableCell_vulnerabilities {
              text-align: center;
              justify-content: space-around;
            }
            &.headerCell_vulnerabilities {
              border-right: none;
            }
          }
        }
        // risk with a user
        &.userClass--has_user {
          .tableRow {
            grid-template-columns: 13em 1fr 12em 10em;
          }
          // risk with a user and a host type
          &.hostTypeClass--has_host_type {
            .tableRow {
              grid-template-columns: 13em 1fr 12em 10em 10em;
            }
          }
        }
        // risk with a host type
        &.hostTypeClass--has_host_type {
          .tableRow {
            grid-template-columns: 13em 1fr 12em 10em;
          }
          // risk with a user and a host type
          &.userClass--has_user {
            .tableRow {
              grid-template-columns: 13em 1fr 12em 10em 10em;
            }
          }
        }
        // no risk
        &.riskClass--no_risk {
          .tableRow {
            grid-template-columns: 1fr 10em;
          }
          // no risk with a user
          &.userClass--has_user {
            .tableRow {
              grid-template-columns: 1fr 10em 10em;
            }
            // no risk with a user and a host type
            &.hostTypeClass--has_host_type {
              .tableRow {
                grid-template-columns: 1fr 12em 10em 10em;
              }
            }
          }
          // no risk with a host type
          &.hostTypeClass--has_host_type {
            .tableRow {
              grid-template-columns: 1fr 10em 10em;
            }
            // no risk with a host type and a user
            &.userClass--has_user {
              .tableRow {
                grid-template-columns: 1fr 12em 10em 10em;
              }
            }
          }
        }
      }
      &.patches {
        .tableRow {
          grid-template-columns: 13em 1fr 8em 8em 2fr;
          .tableCell {
            &.headerCell_hosts,
            &.headerCell_vulnerabilities,
            &.tableCell_hosts,
            &.tableCell_vulnerabilities {
              text-align: center;
              justify-content: space-around;
            }
            &.headerCell_description {
              border-right: none;
            }
          }
        }
        &.riskClass--no_risk {
          .tableRow {
            grid-template-columns: 1fr 8em 8em 2fr;
          }
        }
      }
      &.vulnerabilities {
        .tableRow {
          grid-template-columns: 13em 1fr 8em 8em 2fr;
          .tableCell {
            &.headerCell_hosts,
            &.tableCell_hosts {
              text-align: center;
              justify-content: space-around;
            }
            &.headerCell_description {
              border-right: none;
            }
          }
        }
        &.cvssClass--has_cvss {
          .tableRow {
            grid-template-columns: 13em 13em 1fr 8em 8em 2fr;
          }
          &.riskClass--no_risk {
            .tableRow {
              grid-template-columns: 13em 1fr 8em 8em 2fr;
            }
          }
        }
        &.riskClass--no_risk {
          .tableRow {
            grid-template-columns: 1fr 8em 8em 2fr;
          }
          &.cvssClass--has_cvss {
            .tableRow {
              grid-template-columns: 13em 1fr 8em 8em 2fr;
            }
          }
        }
      }
      &.users {
        .tableRow {
          grid-template-columns: 13em 1fr 10em 10em;
          .tableCell {
            &.headerCell_recently_accessed_hosts,
            &.tableCell_recently_accessed_hosts,
            &.headerCell_domain_groups,
            &.tableCell_domain_groups {
              text-align: center;
              justify-content: space-around;
            }
            &.headerCell_domain_groups {
              border-right: none;
            }
          }
        }
      }
    }
    .tableWidgetWrapper {
      order: 3;
    }
  }
}
