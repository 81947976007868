/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.apiCredentials {
  .apiCredWrapper {
    text-transform: none;
    color: rgb( $textColor-primary );
    width: 100%;
    margin-top: 1em;
    border-bottom: 1px solid rgb( $dividerColor );
    font-size: 1.125em;
    .generateButton {
      @include dsButton('secondary');
    }
  }
}
