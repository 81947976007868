/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.dashboardWidgetWrapper.hosts_comparison,
.dashboardWidgetWrapper.patches_comparison,
.dashboardWidgetWrapper.vulnerabilities_comparison,
.dashboardWidgetWrapper.vulnerability_instances_comparison,
.dashboardWidgetWrapper.risk_comparison,
.dashboardWidgetWrapper.sensitive_assets_comparison,
.dashboardWidgetWrapper.compliance_comparison,
.dashboardWidgetWrapper.compliance_current {
  color: rgb( $textColor-primary );
  &.number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .widgetHeaderContainer {
      display: none;
    }
    .widgetContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .overtimeNumberWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      h2 {
        font-size: 1.125em;
        font-weight: 400;
        margin-bottom: 1em;
        color: rgb( $textColor-primary );
        strong {
          font-weight: 600;
        }
      }
      .countWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1em;
        .inlineSVG {
          width: 2.5em;
          height: 2.5em;
          margin-right: 0.75em;
          path {
            fill: rgb( $iconColor-primary );
          }
        }
        strong {
          font-size: 3.5em;
        }
        &.risk {
          border-radius: 1em;
          background: rgb( $backgroundColor );
          padding: 1.5em 2.5em;
          strong {
            font-weight: 500;
          }
          &.riskClass--critical {
            background: rgb( $critical--10 );
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
            strong {
              color: rgb( $critical );
            }
          }
          &.riskClass--high {
            background: rgb( $high--10 );
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
            strong {
              color: rgb( $high );
            }
          }
          &.riskClass--moderate {
            background: rgb( $moderate--10 );
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
            strong {
              color: rgb( $moderate );
            }
          }
          &.riskClass--low {
            background: rgb( $low--10 );
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
            strong {
              color: rgb( $low );
            }
          }
          &.riskClass--minimal {
            background: rgb( $minimal--10 );
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
            strong {
              color: rgb( $minimal );
            }
          }
          &.riskClass--primary {
            background: rgb( $primary--10 );
            .inlineSVG {
              path {
                fill: rgb( $primary );
              }
            }
            strong {
              color: rgb( $primary );
            }
          }
        }
      }
      .trendWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1em;
        .trendIconWrapper {
          width: 1.5em;
          height: 1.5em;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          background: rgb( $backgroundColor );
          border-radius: $borderRadius-full;
          margin-right: 0.5em;
          .inlineSVG {
            width: 1em;
            height: 1em;
            path {
              fill: rgb( $textColor-secondary );
            }

          }
          span {
            font-weight: 600;
            color: rgb( $textColor-secondary );
            line-height: 1.25em;
          }
          &.up {
            background: rgb( $status-alert--10 );
            .inlineSVG {
              path {
                fill: rgb( $status-alert );
              }
            }
            &.compliance {
              background: rgb( $status-success--10 );
              .inlineSVG {
                path {
                  fill: rgb( $status-success );
                }
              }
            }
          }
          &.down {
            background: rgb( $status-success--10 );
            .inlineSVG {
              path {
                fill: rgb( $status-success );
              }
            }
            &.compliance {
              background: rgb( $status-alert--10 );
              .inlineSVG {
                path {
                  fill: rgb( $status-alert );
                }
              }
            }
          }
        }
      }
    }
  }
  &.barchart {
    .overtimeBarWrapper, .overtimeMultiBarWrapper {
      padding-top: 0.5em;
      display: grid;
      grid-template-columns: 8em 1fr 16em;
      height: 100%;
      max-height: 100%;
      .axisContainer {
        &.yAxis {
          height: 100%;
          max-height: 100%;
        }
      }
      .svgBarWrapper, .svgMultiBarWrapper {
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        border-bottom: 2px solid rgb( $iconColor-primary );
        margin-left: -0.5em;
        max-height: 100%;
      }
      .chartLegend {
        padding-left: 2em;
      }
      .svgMultiBarWrapper {
        height: 100%;
        max-height: 100%;
        .multiBarChart {
          width: 100%;
          height: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .curentComparisonContentWrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 9em 1fr;
    padding-top: 0.5em;
    .axisContainer {
      &.yAxis {
        height: calc( 100% - 5.5em );
      }
    }
    .horizontalBarChartWrapper {
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        border-top: 2px solid rgb( $iconColor-primary );
        position: absolute;
        bottom: 5.5em;
        left: -0.5em;
      }
    }
  }
  .emptyState {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}