/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.recordDetailsInformationPanel {
  width: 100%;
  border-radius: $borderRadius;
  padding: 1em;
  background: rgb( $foregroundColor );
  color: rgb( $textColor-primary );
  .recordDetails__InformationPanel__Header {
    .headerRow {
      display: grid;
      grid-template-columns: 1fr 6em;
      grid-gap: 1em;
    }
    .left, .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .left {
      .recordIconWrapper {
        width: 2.25em;
        height: 2.25em;
        border-radius: $borderRadius-full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: rgb( $backgroundColor );
        margin: 0;
        padding: 0;
        margin-right: 0.5em;
        flex-shrink: 0;
        .inlineSVG {
          width: 1.5em;
          height: 1.5em;
          margin: 0;
          padding: 0;
          path {
            fill: rgb( $textColor-secondary );
          }
        }
        &.critical, &.Critical {
          background: rgb( $critical--10 );
          .inlineSVG {
            path {
              fill: rgb( $critical );
            }
          }
        }
        &.high, &.High {
          background: rgb( $high--10 );
          .inlineSVG {
            path {
              fill: rgb( $high );
            }
          }
        }
        &.moderate, &.Moderate {
          background: rgb( $moderate--10 );
          .inlineSVG {
            path {
              fill: rgb( $moderate );
            }
          }
        }
        &.low, &.Low {
          background: rgb( $low--10 );
          .inlineSVG {
            path {
              fill: rgb( $low );
            }
          }
        }
        &.minimal, &.Minimal {
          background: rgb( $minimal--10 );
          .inlineSVG {
            path {
              fill: rgb( $minimal );
            }
          }
        }
        &.primary, &.Primary {
          background: rgb( $primary--10 );
          .inlineSVG {
            path {
              fill: rgb( $primary );
            }
          }
        }
        &.unknown, &.Unknown {
          background: rgb( $backgroundColor );
          .inlineSVG {
            path {
              fill: rgb( $textColor-secondary );
            }
          }
        }
      }
      h2 {
        font-size: 1.25em;
        line-height: 1.25em;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
    }
    .right {
      justify-content: flex-end;
    }
    .recordDetails__InformationPanel__Header__dividerBar {
      width: 100%;
      height: 0.25em;
      border-radius: 0.125em;
      background: rgb( $textColor-secondary );
      margin: 0.75em 0;
      &.critical, &.Critical {
        background: rgb( $critical );
      }
      &.high, &.High {
        background: rgb( $high );
      }
      &.moderate, &.Moderate {
        background: rgb( $moderate );
      }
      &.low, &.Low {
        background: rgb( $low );
      }
      &.minimal, &.Minimal {
        background: rgb( $minimal );
      }
      &.primary, &.Primary {
        background: rgb( $primary );
      }
      &.unknown, &.Unknown {
        background: rgb( $textColor-secondary );
      }
    }
    &.path {
      .headerRow {
        &:nth-of-type(1) {
          grid-template-columns: 1fr;
          .recordIconWrapper {
            width: 2em;
            height: 2em;
            .inlineSVG {
              width: 1.125em;
              height: 1.125em;
            }
          }
          .left {
            // flex-wrap: wrap;
            h2 {
              font-size: 1.125em;
              .pathLabelWrapper {
                flex-wrap: wrap;
              }
            }
          }
          .right {
            display: none;
          }
        }
      }
    }
    // collapsed variant is very different and has some elements from the body included
    &.collapsed {
      .recordDetails__InformationPanel__Header__dividerBar {
        margin-top: 0;
      }
      .headerRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .left {
        .assetTagListWrapper {
          margin-left: 1em;
        }
      }
      .right {
        .ratingBadge {
          margin-right: 0.5em;
        }
        .recordDetails__InformationPanel__Body_Section.statistics {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-right: 2em;
          .recordStatisticWrapper {
            margin-bottom: 0;
            margin-left: 2em;
            padding-left: 2em;
            border-left: 1px solid rgb( $dividerColor );
            &:first-of-type {
              margin-left: 0;
              padding-left: 0;
              border-left: none;
            }
            .statisticLabel {
              display: none;
            }
            .exploitStatusWrapper {
              margin: 0;
              .statusTitle {
                display: none;
              }
            }
          }
        }
      }
      &.path {
        .headerRow {
          .recordIconWrapper {
            width: 2.25em;
            height: 2.25em;
            .inlineSVG {
              width: 1.25em;
              height: 1.25em;
            }
          }
          .left {
            h2 {
              font-size: 1.25em;
            }
          }
        }
      }
    }
  }
  .recordDetails__InformationPanel__Body {
    .instancesBreakdownWrapper {
      h3 {
        font-size: 0.875em;
      }
    }
    .recordDetails__InformationPanel__Body_Section {
      padding-top: 1em;
      .bodySectionItem {
        font-size: 0.875em;
        margin-bottom: 1em;
        color: rgb( $textColor-primary );
        label {
          margin-bottom: 0.5em;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: rgb( $textColor-primary );
        }
        .inlineLink {
          margin-bottom: 0.5em;
          &.mitreLink {
            background: rgb( $status-moderate--10 );
            .cveLogo {
              height: 1em;
              width: 3em;
              margin-right: 0.5em;
            }
            span {
              color: rgb( $textColor-primary );
            }
            .inlineSVG {
              path {
                fill: rgb( $status-moderate );
              }
            }
            &:hover {
              span {
                text-decoration-color: rgb( $textColor-primary ) !important;
                color: rgb( $textColor-primary );
              }
            }
            &.darkMode {
              background: none;
              border: 1px solid rgb( $status-moderate );
              color: rgb( $status-moderate );
              &:hover {
                background: rgb( $status-moderate );
                color: rgb( $textColor-secondary );
              }
            }
          }
        }
        .nodeDetailItem {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 0.5em;
          .inlineSVG {
            width: 1.25em;
            height: 1.25em;
            margin-right: 0.5em;
            path {
              fill: rgb( $status-alert );
            }
          }
        }
      }
      &.details {
        &.host {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1em;
          border-top: 1px solid rgb( $dividerColor );
          border-bottom: 1px solid rgb( $dividerColor );
          .bodySectionItem {
            text-align: center;
            color: rgb( $textColor-primary );
            label {
              justify-content: space-around;
              color: rgb( $textColor-primary );
            }
            &:nth-of-type( 9 ) {
              grid-column: 1 / 3;
            }
          }
        }
        &.patch {
          border-bottom: none;
        }
      }
      &.statistics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        border-top: 1px solid rgb( $dividerColor );
        border-bottom: 1px solid rgb( $dividerColor );
        &.patch {
          border-top: none;
        }
        &.vulnerability, &.user {
          border-bottom: none;
        }
        &.path {
          border-bottom: none;
          border-top: none;
          .recordRiskAndRatingWrapper {
            grid-column: 1 / 3;
            .recordStatisticWrapper {
              .statisticLabel {
                font-size: 1em;
              }
            }
            .riskExplanationTriggerButton {
              top: unset;
              bottom: -2em;
            }
          }
        }
      }
      &.cvssBreakdown {
        .chartAndLegendWrapper {
          display: grid;
          grid-template-columns: 1fr 12.5em;
          grid-template-areas: "A B" "C C";
          grid-column-gap: 1em;
          margin-bottom: 1em;
          .cvssBarChartWrapper {
            grid-area: A;
            grid-template-columns: 7.5em 1fr;
          }
          .chartLegend {
            grid-area: B;
            align-self: center;
          }
          .axisContainer.xAxis {
            grid-area: C;
            margin-left: 7em;
            margin-right: 13em;
            width: calc( 100% - 7em - 13.5em );
          }
        }
      }
    }
  }
}