/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.notScannedEmptyState {
  width: 100%;
  padding: 2em;
  background: rgb( $status-alert--10 );
  border-radius: $borderRadius;
  align-items: center;
  margin: 2em 0;
  .inlineSVG {
    width: 2em;
    height: 2em;
    margin-bottom: 2em;
    path {
      fill: rgb( $status-alert );
    }
  }
  p {
    font-size: 1.25em;
    color: rgb( $textColor-primary );
    a {
      font-weight: 600;
      color: rgb( $status-alert );
    }
  }
}