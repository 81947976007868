/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../../styles/globals.scss" as *;

.modalContainer.remediation_plan {
  .tabWrapper.review {
    .stepDirections {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .activePlanActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        flex-grow: 1;
        .stepChangeButton {
          @include dsButton('minimal-dark');
          margin-right: 1em;
        }
        .exportMenuTrigger {
          @include dsButton('dark');
          width: unset;
          color: #fff;
          .inlineSVG {
            height: 1.125em;
            width: 1.125em;
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .panel.review.remediationPanel {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    overflow: hidden;
    max-height: 95vh;
    background: rgb( $foregroundColor );
    padding: 1em;
    width: 100%;
    .activePlanOverView {
      display: flex;
      flex-direction: column;
      background: rgb( $backgroundColor-secondary );
      padding: 1em;
      border-radius: $borderRadius;
      border: 1px solid rgb( $iconColor-primary );
      width: 18em;
      margin-right: 1em;
      flex-shrink: 0;
      .column {
        h2 {
          font-size: 1.25em;
          padding-bottom: 1em;
          border-bottom:  1px solid rgb( $dividerColor );
          margin-bottom: 1em;
        }
        .recordAttribute {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          margin-bottom: 0.5em;
          &:nth-of-type(2) {
            padding-bottom: 1em;
            margin-bottom: 1em;
            border-bottom: 1px solid rgb( $dividerColor );
          }
        }
      }
      .completionWrapper {
        position: relative;
        width: 100%;
        padding-top: 1em;
        label {
          margin-bottom: 0.5em;
          text-transform: uppercase;
          color: rgb( $textColor-secondary );
          font-weight: 600;
          font-size: 0.875em;
        }
        .completionBarWrapper {
          width: calc( 100% - 3em );
          height: 2em;
          position: relative;
          background: rgb( $dividerColor );
          margin-left: 3em;
          .completionLabel {
            position: absolute;
            left: -3em;
            width: 3em;
            height: 1.75em;
            font-size: 1.125em;
            line-height: 1.75em;
            text-align: right;
            font-weight: 600;
            padding-right: 0.5em;
          }
          .completionBar {
            position: absolute;
            left: 0;
            top: 0;
            height: 2em;
            background: rgb( $filter-teal );
            min-width: 0.25em;
            &.instances {
              background: rgb( $filter-blue );
            }
          }
        }
        &.risk {
          grid-area: E;
        }
        &.instances {
          grid-area: F;
        }
      }
      .stepChangeButton {
        @include dsButton('minimal-primary');
        margin-top: 1em;
        text-align: center;
        justify-content: space-around;
        // padding: 0.5em 0.75em;
      }
    }
    section {
      flex-grow: 1;
      width: 100%;
      overflow: auto;
      margin-top: -1em;
      margin-right: -1em;
      padding-top: 1em;
      padding-right: 1em;
      max-height: calc( 95vh - 17em );
      .taskList {
        li {
          .addItemButton {
            display: none;
          }
        }
      }
    }

  }

}