/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.mainPanelTabsContainer, .setupTabsContainer {
  background: rgb( $foregroundColor );
  border-radius: $borderRadius-input $borderRadius-input 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  padding: 1em;
  padding-bottom: 0;
  width: 19em;
  .mainPanelTab, .setupTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-left: 1px solid rgba( $iconColor-primary, 0.5 );
    &:nth-of-type( 1 ) {
      border-left: none;
    }
    .iconLabelAndStatusWrapper, .setupTabContentWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      color: rgb( $textColor-secondary );
      padding: 0.5em 1em;
      border-radius: $borderRadius;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      span {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
      div {
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
      .inlineSVG {
        cursor: pointer;
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.5em;
        transition: all 0.3s ease-in-out;
        path {
          transition: all 0.3s ease-in-out;
          fill: rgb( $iconColor-primary );
        }
        .statusDot {
          fill: rgb( $textColor-primary );
        }
      }
      &:hover {
        background: rgb( $dividerColor );
        color: rgb( $textColor-primary );
        .inlineSVG {
          path, rect {
            fill: rgb( $iconColor-primary );
            &.darkFill {
              fill: rgb( $primary );
            }
          }
        }
      }
    }
    .setupTabContentWrapper {
      span {
        align-items: center;
      }
      .tabStep {
        width: 1.75em;
        height: 1.75em;
        border-radius: $borderRadius-full;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background: rgb( $iconColor-primary );
        margin-right: 0.75em;
        color: #fff;
        font-size: 0.75em;
      }
      .inlineSVG {
        path, rect {
          fill: rgb( $dividerColor );
          &.darkFill {
            fill: rgb( $iconColor-primary );
          }
        }
      }
    }
    &.isCurrent {
      .iconLabelAndStatusWrapper, .setupTabContentWrapper {
        background: rgb( $dividerColor );
        color: rgb( $textColor-primary );
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary );
          }
        }
      }
      .setupTabContentWrapper {
        .tabStep {
          background: rgb( $textColor-primary );
        }
        .inlineSVG {
          opacity: 1;
          path, rect {
            fill: rgb( $iconColor-primary );
            &.darkFill {
              fill: rgb( $primary );
            }
          }
        }
      }
      &.risk_and_remediation, &.path_analysis {
        &.tabClass--critical {
          .iconLabelAndStatusWrapper {
            background: rgb( $critical--10 );
            .inlineSVG {
              path {
                fill: rgb( $critical );
              }
            }
          }
        }
        &.tabClass--high {
          .iconLabelAndStatusWrapper {
            background: rgb( $high--10 );
            .inlineSVG {
              path {
                fill: rgb( $high );
              }
            }
          }
        }
        &.tabClass--moderate {
          .iconLabelAndStatusWrapper {
            background: rgb( $moderate--10 );
            .inlineSVG {
              path {
                fill: rgb( $moderate );
              }
            }
          }
        }
        &.tabClass--low {
          .iconLabelAndStatusWrapper {
            background: rgb( $low--10 );
            .inlineSVG {
              path {
                fill: rgb( $low );
              }
            }
          }
        }
        &.tabClass--minimal {
          .iconLabelAndStatusWrapper {
            background: rgb( $minimal--10 );
            .inlineSVG {
              path {
                fill: rgb( $minimal );
              }
            }
          }
        }
      }
    }
    &.isDisabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.375;
    }

    // risk-like classes
    &.tabClass--Critical,
    &.tabClass--critical {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $critical );
          }
        }
      }
    }
    &.tabClass--high,
    &.tabClass--High,
    &.tabClass--important,
    &.tabClass--Important {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $high );
          }
        }
      }
    }
    &.tabClass--moderate,
    &.tabClass--Moderate,
    &.tabClass--medium,
    &.tabClass--Medium {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $moderate );
          }
        }
      }
    }
    &.tabClass--low,
    &.tabClass--Low {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $low );
          }
        }
      }
    }
    &.tabClass--minimal,
    &.tabClass--Minimal {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $minimal );
          }
        }
      }
    }

    // status-like classes
    &.tabClass--red {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $status-alert );
          }
        }
      }
    }
    &.tabClass--orange {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $status-moderate );
          }
        }
      }
    }
    &.tabClass--yellow {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $status-warning );
          }
        }
      }
    }
    &.tabClass--green {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $status-success );
          }
        }
      }
    }
    &.tabClass--blue {
      .iconLabelAndStatusWrapper {
        .inlineSVG {
          .statusDot {
            fill: rgb( $status-info );
          }
        }
      }
    }
  }
  &.tabCount--2 {
    grid-template-columns: 1fr 1fr;
    width: 38em;
    .mainPanelTab, .setupTab {
      border-left: none;
    }
  }
  &.tabCount--3 {
    grid-template-columns: 1fr 1fr 1fr;
    width: 57em;
    &.selectedTabIndex--0 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 1 ),
        &:nth-of-type( 2 ) {
          border-left: none;
        }
      }
    }
    &.selectedTabIndex--1 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 2 ),
        &:nth-of-type( 3 ) {
          border-left: none;
        }
      }
    }
    &.selectedTabIndex--2 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 3 ) {
          border-left: none;
        }
      }
    }
  }
  &.tabCount--4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 76em;
    &.selectedTabIndex--0 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 1 ),
        &:nth-of-type( 2 ) {
          border-left: none;
        }
      }
    }
    &.selectedTabIndex--1 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 2 ),
        &:nth-of-type( 3 ) {
          border-left: none;
        }
      }
    }
    &.selectedTabIndex--2 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 3 ),
        &:nth-of-type( 4 ) {
          border-left: none;
        }
      }
    }
    &.selectedTabIndex--3 {
      .setupTab, .mainPanelTab {
        &:nth-of-type( 4 ) {
          border-left: none;
        }
      }
    }
  }
  &.darkMode {
    .mainPanelTab, .setupTab {
      .iconLabelAndStatusWrapper, .setupTabContentWrapper{
        color: rgb( $textColor-primary ) !important;
      }
      &.isCurrent {
        color: rgb( $textColor-secondary ) !important;
        .iconLabelAndStatusWrapper {
          background: rgba( #fff, 0.2 ) !important;
          .inlineSVG {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
        &.risk_and_remediation, &.path_analysis {
          color: rgb( $textColor-secondary ) !important;
          &.tabClass--critical {
            .iconLabelAndStatusWrapper {
              color: rgb( $textColor-secondary );
              .inlineSVG {
                path {
                  fill: rgb( $critical );
                }
              }
            }
          }
          &.tabClass--high {
            .iconLabelAndStatusWrapper {
              color: rgb( $textColor-secondary );
              .inlineSVG {
                path {
                  fill: rgb( $high );
                }
              }
            }
          }
          &.tabClass--moderate {
            .iconLabelAndStatusWrapper {
              color: rgb( $textColor-secondary );
              .inlineSVG {
                path {
                  fill: rgb( $moderate );
                }
              }
            }
          }
          &.tabClass--low {
            .iconLabelAndStatusWrapper {
              color: rgb( $textColor-secondary );
              .inlineSVG {
                path {
                  fill: rgb( $low );
                }
              }
            }
          }
          &.tabClass--minimal {
            .iconLabelAndStatusWrapper {
              color: rgb( $textColor-secondary );
              .inlineSVG {
                path {
                  fill: rgb( $minimal );
                }
              }
            }
          }
        }
      }
    }
  }
}