/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.pathSVGContainer {
  width: 100%;
  position: relative;
  svg {
    width: 100%;
    .scopeGroup {
      .riskRatingCircle {
        fill: rgb( $textColor-secondary );
        opacity: 0.3;
      }
      rect {
        -webkit-filter: drop-shadow( 1px 1px 3px rgba(0, 0, 0, .1));
        filter: drop-shadow( 1px 1px 3px rgba(0, 0, 0, .1));
      }
      &.isParentScope {
        rect {
          -webkit-filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, .1));
          filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, .1));
        }
      }
      &.selected {
        rect {
          stroke: rgb( $critical--75 );
          fill: rgb( $critical--10 );
          -webkit-filter: drop-shadow( 0 0 2px $critical--100 );
          filter: drop-shadow( 0 0 2px $critical--100 );
        }
        &.high {
          rect {
            stroke: rgb( $high--75 );
            fill: rgb( $high--10 );
            -webkit-filter: drop-shadow( 0 0 2px $high--100 );
            filter: drop-shadow( 0 0 2px $high--100 );
          }
        }
        &.moderate {
          rect {
            stroke: rgb( $moderate--75 );
            fill: rgb( $moderate--10 );
            -webkit-filter: drop-shadow( 0 0 2px $moderate--100 );
            filter: drop-shadow( 0 0 2px $moderate--100 );
          }
        }
        &.low {
          rect {
            stroke: rgb( $low--75 );
            fill: rgb( $low--10 );
            -webkit-filter: drop-shadow( 0 0 2px $low--100 );
            filter: drop-shadow( 0 0 2px $low--100 );
          }
        }
        &.minimal {
          rect {
            stroke: rgb( $minimal--75 );
            fill: rgb( $minimal--10 );
            -webkit-filter: drop-shadow( 0 0 2px $minimal--100 );
            filter: drop-shadow( 0 0 2px $minimal--100 );
          }
        }
        &.default {
          rect {
            stroke: rgb( $high );
            fill: rgb( $backgroundColor );
            -webkit-filter: drop-shadow( 0 0 2px $textColor-primary );
            filter: drop-shadow( 0 0 2px $textColor-primary );
          }
        }
      }
      &.darkMode {
        rect {
          fill: rgba( #fff, 0.2 );
        }
        &.selected {
          rect {
            stroke: rgb( $critical );
            stroke-width: 3;
            fill: rgba( $critical, 0.2 );
            -webkit-filter: drop-shadow( 0 0 2px $critical );
            filter: drop-shadow( 0 0 2px $critical );
          }
          &.critical {
            rect {
              stroke: rgb( $critical );
              fill: rgba( $critical, 0.2 );
              -webkit-filter: drop-shadow( 0 0 2px $critical );
              filter: drop-shadow( 0 0 2px $critical );
            }
          }
          &.high {
            rect {
              stroke: rgb( $high );
              fill: rgba( $high, 0.2 );
              -webkit-filter: drop-shadow( 0 0 2px $high );
              filter: drop-shadow( 0 0 2px $high );
            }
          }
          &.moderate {
            rect {
              stroke: rgb( $moderate );
              fill: rgba( $moderate, 0.2 );
              -webkit-filter: drop-shadow( 0 0 2px $moderate );
              filter: drop-shadow( 0 0 2px $moderate );
            }
          }
          &.low {
            rect {
              stroke: rgb( $low );
              fill: rgba( $low, 0.2 );
              -webkit-filter: drop-shadow( 0 0 2px $low );
              filter: drop-shadow( 0 0 2px $low );
            }
          }
          &.minimal {
            rect {
              stroke: rgb( $minimal );
              fill: rgba( $minimal, 0.2 );
              -webkit-filter: drop-shadow( 0 0 2px $minimal );
              filter: drop-shadow( 0 0 2px $minimal );
            }
          }
          &.default {
            rect {
              stroke: rgb( $textColor-primary );
              -webkit-filter: drop-shadow( 0 0 2px $textColor-primary );
              filter: drop-shadow( 0 0 2px $textColor-primary );
            }
          }
        }
      }
    }
    .edgeGroup {
      .edgePath, .edgeCount, .edgeArrow, .edgeCountCircle, .edgeHoverPath {
        cursor: pointer;
      }
      .edgePath {
        transition: unset;
      }
      .edgeArrowDirection {
        cursor: pointer;
        transform-origin: center;
        &.n {
          transform: rotate(-90deg);
        }
        &.ne {
          transform: rotate(-45deg);
        }
        &.e {
          transform: rotate(0deg);
        }
        &.se {
          transform: rotate(45deg);
        }
        &.s {
          transform: rotate(90deg);
        }
        &.sw {
          transform: rotate(135deg);
        }
        &.w {
          transform: rotate(180deg);
        }
        &.nw {
          transform: rotate(225deg);
        }
      }
      &.hovered {
        .edgeHoverPath {
          opacity: .15;
        }
      }
    }
    .nodeGroup {
      .nodeHoverCircle {
        cursor: pointer;
      }
      &.default {
        .nodeIconCircle {
          fill: rgb( $textColor-secondary );
        }
        .nodeHoverCircle {
          fill: rgb( $textColor-secondary );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $textColor-secondary );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $textColor-secondary );
          }
          .nodeHoverCircle {
            fill: rgb( $textColor-secondary );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $textColor-secondary );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.high {
        .nodeIconCircle {
          fill: rgb( $high--100 );
        }
        .nodeHoverCircle {
          fill: rgb( $high--100 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $high--100 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $high--75 );
          }
          .nodeHoverCircle {
            fill: rgb( $high--75 );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $high--75 );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.moderate {
        .nodeIconCircle {
          fill: rgb( $moderate--100 );
        }
        .nodeHoverCircle {
          fill: rgb( $moderate--100 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $moderate--100 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $moderate--75 );
          }
          .nodeHoverCircle {
            fill: rgb( $moderate--75 );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $moderate--75 );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.low {
        .nodeIconCircle {
          fill: rgb( $low--100 );
        }
        .nodeHoverCircle {
          fill: rgb( $low--100 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $low--100 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $low--75 );
          }
          .nodeHoverCircle {
            fill: rgb( $low--75 );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $low--75 );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.minimal {
        .nodeIconCircle {
          fill: rgb( $minimal--100 );
        }
        .nodeHoverCircle {
          fill: rgb( $minimal--100 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $minimal--100 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $minimal--75 );
          }
          .nodeHoverCircle {
            fill: rgb( $minimal--75 );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $minimal--75 );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.primary {
        .nodeIconCircle {
          fill: rgb( $primary--100 );
        }
        .nodeHoverCircle {
          fill: rgb( $primary--100 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $primary--100 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $primary--75 );
          }
          .nodeHoverCircle {
            fill: rgb( $primary--75 );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $primary--75 );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.sensitive {
        .nodeIndicatorDot {
          fill: rgb( $status-alert );
        }
        .nodeIconCircle {
          fill: rgb( $primary--75 );
        }
        .nodeHoverCircle {
          fill: rgb( $primary--75 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $primary--75 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $primary );
          }
          .nodeHoverCircle {
            fill: rgb( $primary );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $primary );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.isAdversary {
        .nodeIconCircle {
          fill: rgb( $high--100 );
        }
        .nodeHoverCircle {
          fill: rgb( $high--100 );
          fill-opacity: 0.2;
        }
        svg {
          path {
            fill: rgb( $high--100 );
            &.svgNodeIconOutline {
              fill: #fff
            }
          }
        }
        &.highlight {
          .nodeIconCircle {
            fill: rgb( $high--75 );
          }
          .nodeHoverCircle {
            fill: rgb( $high--75 );
            fill-opacity: 0.2;
          }
          svg {
            path {
              fill: rgb( $high--75 );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
        }
      }
      &.darkMode {
        &.default {
          .nodeIconCircle {
            fill: rgb( $backgroundColor-secondary );
          }
          svg {
            path {
              fill: rgb( $backgroundColor-secondary );
              &.svgNodeIconOutline {
                fill: #fff
              }
            }
          }
          &.highlight {
            .nodeIconCircle {
              fill: rgb( $backgroundColor-secondary );
            }
            .nodeHoverCircle {
              fill: rgb( $backgroundColor-secondary );
              fill-opacity: 0.2;
            }
            svg {
              path {
                fill: rgb( $backgroundColor-secondary );
                &.svgNodeIconOutline {
                  fill: #fff
                }
              }
            }
          }
          &.isAdversary {
            .nodeIconCircle {
              fill: rgb( $critical );
            }
            .nodeHoverCircle {
              fill: rgb( $critical );
              fill-opacity: 0.2;
            }
            svg {
              path {
                fill: rgb( $critical );
                &.svgNodeIconOutline {
                  fill: #fff
                }
              }
            }
            &.highlight {
              .nodeIconCircle {
                fill: rgb( $critical--75 );
              }
              .nodeHoverCircle {
                fill: rgb( $critical--75 );
                fill-opacity: 0.2;
              }
              svg {
                path {
                  fill: rgb( $critical--75 );
                  &.svgNodeIconOutline {
                    fill: #fff
                  }
                }
              }
            }
          }
          &.sensitive {
            .nodeIndicatorDot {
              fill: rgb( $status-alert );
            }
            .nodeIconCircle {
              fill: rgb( $primary );
            }
            .nodeHoverCircle {
              fill: rgb( $primary );
              fill-opacity: 0.2;
            }
            svg {
              path {
                fill: rgb( $primary );
                &.svgNodeIconOutline {
                  fill: #fff
                }
              }
            }
            &.highlight {
              .nodeIconCircle {
                fill: rgb( $primary );
              }
              .nodeHoverCircle {
                fill: rgb( $primary );
                fill-opacity: 0.2;
              }
              svg {
                path {
                  fill: rgb( $primary );
                  &.svgNodeIconOutline {
                    fill: #fff
                  }
                }
              }
            }
          }
        }

      }
    }
  }
  #panZoomWrapper {
    transition: all 0.3s ease-in-out;
  }
  .graphActions {
    position: absolute;
    bottom: 1em;
    right: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    button {
      @include dsButton( 'secondary' );
      &.revertZoomButton {
        margin-right: 1em;
      }
      &.fullscreenButton {
        padding: 0.375em;
        .inlineSVG {
          margin: 0;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.pathsGraphFullscreenModal {
  padding-bottom: 0;
  .modalBody {
    padding: 2em;
    margin: 2em;
    border-radius: $borderRadius;
    background-color: rgb( $backgroundColor );
    width: calc( 100vw - 4em );
    max-height: unset;
    position: relative;
    .pathSVGContainer {
      position: static;
      .graphActions {
        bottom: 1em;
        right: 1em;
      }
    }
  }
}