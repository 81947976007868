/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

#resetPasswordContainer {
  width: 100vw;
  height: 100vh;
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($textColor-primary, 0.8);
  .resetFormWrapper {
    background: rgb( $foregroundColor );
    padding: 2em;
    padding-top: 1em;
    border-radius: $borderRadius;
    color: rgb( $textColor-primary );
    width: 50vw;
    min-width: 40em;
    max-width: 60em;
    margin: 0 auto;
    margin-top: 4em;
    .resetPasswordButton {
      @include dsButton( 'primary' );
    }
  }
}