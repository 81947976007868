/***************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.filterWrapper.buttons {
  color: rgb( $textColor-primary );
  .labelWrapper {
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 0.5em;
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    button {
      @include dsButton( 'minimal--dark' );
      border-radius: $borderRadius-input;
      background: rgb( $backgroundColor );
      color: rgb( $iconColor-primary );
      margin-right: 0.75em;
      text-transform: uppercase;
      padding: 0.625em 1.5em;
      &:hover {
        background: rgb( $iconColor-primary );
        color: rgb( $textColor-primary );
      }
      &:last-of-type {
        margin-right: 0;
      }
      &.selected {
        background: rgb( $iconColor-primary );
        color: rgb( $textColor-primary );
      }
      &.high {
        &:hover, &.selected {
          background: rgb( $high--10 );
          color: rgb( $high );
        }
      }
      &.medium {
        &:hover, &.selected {
          background: rgb( $moderate--10 );
          color: rgb( $moderate );
        }
      }
      &.low {
        &:hover, &.selected {
          background: rgb( $low--10 );
          color: rgb( $low );
        }
      }
    }
  }
}
