/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.peerPercentileChartWrapper {
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: rgb( $textColor-primary );
  .svgGaugeWrapper {
    width: 16em;
    margin-bottom: 1em;
  }
  h3 {
    font-weight: 400;
    width: 100%;
    text-align: center;
    font-size: 1.5em;
  }
  p {
    font-size: 1.125em;
    text-align: center;
    strong {
      color: rgb( $primary );
    }
  }
}