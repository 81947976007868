/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../styles/globals.scss" as *;

.addToPlanMenuWrapper {
  z-index: 999999;
  font-family: $sans;
  position: fixed;
  top: 2.25em;
  right: 8em;
  &.asButtonWithCount {
    position: relative;
    top: unset;
    right: unset;
  }
  &.topBarMenu {
    opacity: 1;
    top: 1.75em;
    right: 9em;
    .addToPlanMenuTriggerWrapper {
      background: none;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      // opacity: 0.5;
      width: 3em;
      height: 3em;
      border-radius: $borderRadius-full;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 0;
      margin: 0;
      .inlineSVG {
        flex-shrink: 0;
        width: 2em;
        height: 2em;
      }
      .notificationCount {
        background: rgb( $primary );
        top: -0.125em;
        right: -0.125em;
        width: 2.125em;
        height: 2.125em;
        line-height: 2.125em;
        font-size: 0.625em;
      }
      &:hover {
        background: rgb( $backgroundColor );
        opacity: 1;
      }
    }
  }
  .addToPlanMenuTriggerWrapper {
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
    .inlineSVG {
      transition: all 0.3s ease-in-out;
      opacity: 0.5;
      width: 2.25em;
      height: 2.25em;
    }
    .notificationCount {
      background: rgb( $textColor-primary );
      top: -1.25em;
      right: -1.25em;
    }
    &:hover {
      .inlineSVG {
        opacity: 1;
      }
    }
    &.asButtonWithCount {
      border: none;
      padding: 0;
      button {
        @include dsButton('minimal-primary');
        border-radius: $borderRadius;
        position: relative;
        span {
          margin: 0;
          margin-left: 0.5em;
          margin-right: 0.75em;
          &.notificationCount {
            margin: 0;
            margin-left: 0;
            line-height: 2.25em;
            top: -0.75em;
            right: -0.75em;
            background-color: rgb( $primary );
          }
        }
      }

    }
    &.asMinimalButton {
      button {
        background: none;
        border-radius: 0;
        border: none;
        padding: 0;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        .inlineSVG {
          opacity: .8;
          width: 2.25em;
          height: 2.25em;
          transition: all 0.3s ease-in-out;
          path {
            fill: rgb( $primary );
            &.whiteFill {
              fill: #fff;
            }
          }
          circle {
            fill: rgb( $primary );
          }
        }
        span {
          &.notificationCount {
            display: none;
          }
        }
        &:hover {
          background: none;
          opacity: 0.5;
        }
      }
    }
  }
  .notificationCount {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    width: 2.25em;
    height: 2.25em;
    line-height: 2.25em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    background: rgb( $primary );
    border-radius: $borderRadius-full;
    font-size: 0.75em;
    font-weight: 600;
    white-space: nowrap;
    margin: 0;
    margin-left: 0;
  }
}

.addToPlanShade {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999998;
}

.addToPlanMenu {
  width: 32em;
  height: unset;
  max-height: 33em;
  display: none;
  position: absolute;
  background: rgb( $foregroundColor );
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  width: 32em;
  overflow: hidden;
  padding: 0em;
  padding-bottom: 4.5em;
  font-family: $sans;
  z-index: 99999999;
  .flashMessage.asNotification {
      margin: 1em;
      width: calc( 100% - 2em );
    }
  button.primary {
    @include dsButton( 'minimal-primary' );
    text-align: center;
    justify-content: space-around;
    border-radius: $borderRadius;
  }
  button.secondary {
    @include dsButton( 'minimal' );
    border-radius: $borderRadius;
  }
  .emptyState {
    font-size: 1.25em;
    font-weight: 400;
  }
  .remediationWorkflowHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.75em 1em;
    border-bottom: 1px solid rgb( $iconColor-primary );
    color: rgb( $textColor-primary );
    background: rgb( $backgroundColor-secondary );
    .titleWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.5em;
        path {
          fill: rgb( $iconColor-primary );
        }
      }
      h2 {
        font-size: 1em;
        margin: 0;
        padding: 0;
      }
    }
    button.closeButton {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      margin: 0;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-left: 1em;
      .inlineSVG {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  strong.itemsCount {
    display: block;
    color: rgb( $textColor-secondary );
    padding: 0.5em 1em;
  }
  .accordionWrapper {
    .accordionBody {
      max-height: 11em;
      background: rgb( $backgroundColor );
      overflow: auto;
      ul {
        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 0.875em;
          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .ratingBadge {
              margin-right: 0.75em;
            }
          }
        }
      }
    }
    .accordionHeader {
      border-bottom: 1px solid rgb( $dividerColor );
      h3 {
        font-size: 0.875em;
        .inlineSVG {
          height: 1.25em;
          width: 1.25em;
          margin-right: 0.5em;
          path {
            fill: rgb( $iconColor-primary );
          }
        }
      }
      button {
        .inlineSVG {
          height: 1em;
          width: 1em;
        }
      }
    }
  }
  .formButtonsWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: rgb( $foregroundColor );
    padding: 1em;
    border-top: 1px solid rgb( $dividerColor );
    border-radius: 0 0 $borderRadius $borderRadius;
    .cancelButton {
      @include dsButton( 'minimal' );
      border-radius: $borderRadius;
    }
    .submitButton {
      @include dsButton( 'minimal-primary' );
      border-radius: $borderRadius;
      margin-left: 1em;
      flex-grow: 1;
      justify-content: space-around;
      text-align: center;
    }
  }
  .goToPlanButton {
    @include dsButton('minimal-primary');
    border-radius: $borderRadius;
    position: absolute;
    bottom: 1em;
    left: 1em;
    width: calc( 100% - 2em );
    justify-content: space-around;
    span {
      margin-left: 1.5em;
    }
    .inlineSVG {
      margin: 0;
      margin-left: 0.5em;
    }
  }
  .afterSaveWrapper {
    strong {
      text-align: center;
      padding: 1em;
      font-size: 1.5em;
    }
    .successImageWrapper {
      width: 100%;
      margin-bottom: 1em;
      opacity: 0.4;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 2em;
      .inlineSVG {
        width: 4em;
        height: 4em;
      }
      .successIconWrapper {
        position: absolute;
        width: 3em;
        height: 3em;
        border-radius: $borderRadius-full;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        left: 50%;
        top: 1em;
        background-color: rgb( $textColor-primary );
        .inlineSVG {
          width: 2em;
          height: 2em;
        }
      }
    }
    .actions {
      width: 100%;
      padding: 0 1em 1em 1em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
      margin-bottom: -4.5em;
      button {
        margin: 0;
        position: static;
        top: unset;
        left: unset;
        bottom: unset;
        top: unset;
        width: unset;
        border-radius: $borderRadius;
        &.cancelButton {
          @include dsButton('minimal-default');
          border-radius: $borderRadius;
        }
      }
    }
    // .cancelButton {
    //   @include dsButton('secondary');
    //   margin: 1em 1em 1em 1em;
    // }
  }
  &.contextChooser {
    padding: 1em;
    h3 {
      text-align: center;
      color: rgb( $textColor-primary );
      font-weight: 600;
      font-size: 1.25em;
      margin-top: 1em;
    }
    .contextOptions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
      margin: 1em 0;
      .addToMenuButton {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: none;
        border: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border-radius: $borderRadius;
        opacity: 0.5;
        color: rgb( $textColor-primary );
        font-weight: 600;
        padding: 2em 1em;
        .inlineSVG {
          width: 2.25em;
          height: 2.25em;
          margin-bottom: 1.5em;
        }
        &:hover {
          background: rgb( $backgroundColor );
          opacity: 1;
          .inlineSVG {
            path {
              fill: rgb( $primary );
            }
          }
        }
      }
    }
  }
  &.remediationMenu {
    .createModeWrapper {
      .formWrapper {
        padding: 1em;
      }
      .formActionsWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 1em;
        button {
          flex-grow: 0;
          &:nth-of-type(2) {
            flex-grow: 1;
            margin-left: 1em;
          }
        }
      }
    }
    .planNameWrapper {
      display: flex;
      justify-content:flex-start;
      align-items: center;
      flex-direction: row;
      padding-bottom: 1em;
      padding-top: 1em;
      margin: 0 1em;
      border-bottom: 1px solid rgb( $dividerColor );
      strong {
        display: block;
        white-space: nowrap;
        flex-shrink: 0;
        line-height: 1.5em;
        color: rgb( $textColor-secondary );
        margin-right: 0.25em;
      }
      .dropdownWrapper {
        position: relative;
        top: unset;
        right: unset;
        flex-grow: 1
      }
      .dropdownTrigger {
        @include dsButton('minimal-primary');
        border-radius: $borderRadius;
        justify-content: space-between;
        span {
          text-align: left;
          display: block;
          width: 22em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &.acceptedRiskMenu {
    max-height: 43.5em;
    padding-bottom: 4em;
    .formWrapper {
      .fieldGroupWrapper {
        .fieldWrapper {
          margin: 0;
          padding: 0.75em 1em;
          border-bottom: 1px solid rgb( $dividerColor );
          label {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            .labelWrapper {
              font-size: 1em;
              width: unset;
              margin: 0;
              margin-right: 0.5em;
              text-transform: none;
              padding-top: 0.625em;
            }
            input, textarea, .selectFieldWrapper, .textFieldWrapper {
              flex-grow: 1;
              margin: 0;
              &[type="text"] {
                border: 1px solid rgb( $iconColor-primary );
                // background: rgb( $dividerColor );
                border-radius: $borderRadius;
              }
            }
            textarea {
              resize: none;
            }
            .fieldErrors {
              display: none;
              width: 100%;
            }
            &:before {
              top: 0.75em;
            }
          }
          .editIcon {
            margin-right: 0.5em;
            top: 0.5em;
            opacity: 1;
          }
        }
      }
    }
    .itemsContainer {
      .accordionWrapper {
        max-height: 18em;
        .accordionHeader {
          h3 {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 1em;
            span.itemsCount {
              font-weight: 400;
              margin-left: 0.25em;
            }
          }
        }
        .accordionBody {
          .selectAllButton {
            padding: 0;
            margin: 0;
            margin-bottom: 0.5em;
            cursor: pointer;
            background: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border: none;
            .inlineSVG {
              transition: all 0.3s ease-in-out;
              opacity: 0.6;
              margin-right: 0.5em;
            }
            &:hover {
              .inlineSVG {
                opacity: 1;
              }
            }
          }
          label {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 0.75em;
            cursor: pointer;
            .labelWrapper {
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
  &.visible {
    display: block;
  }
}

.dropdownMenu.workingDraftPicker {
  width: 25.75em !important;
  top: 2.5em;
  border: 1px solid rgb( $iconColor-primary );
  max-height: 13em;
  overflow: auto;
  ul {
    width: 100%;
    li {
      width: 100%;
      div {
        padding-bottom: 0;
        span {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;
          border-bottom: 1px solid rgb( $dividerColor );
          padding-bottom: 0.5em;
          &.current {
            padding-left: 1.5em;
            &:before {
              content: "";
              height: 0.75em;
              width: 0.75em;
              border-radius: $borderRadius-full;
              background-color: rgb( $primary );
              left: 0;
              position: absolute;
              top: 50%;
              margin-top: -0.5em;
            }
          }
        }
      }
      button {
        @include dsButton('minimal-primary');
        border-radius: $borderRadius;
        margin: 1em;
        width: calc( 100% - 2em );
        text-align: center;
        justify-content: space-around;
        transition: none;
      }
    }
  }
}