/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.attackScenarioCountLink {
  text-decoration: none !important;
  color: rgb( $textColor-primary ) !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 3px !important;
  border-radius: $borderRadius-input !important;
  background: rgb( $textColor-primary ) !important;
  margin-bottom: 0.5em !important;
  transition: all 300ms ease-in-out !important;
  .iconAndCountWrapper {
    background: rgba( #fff, 0.9 );
    border-radius: $borderRadius;
    padding: 0.5em 0.75em 0.5em 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0.5em;
    flex-shrink: 0;
    font-weight: 600;
    .inlineSVG {
      margin-right: 0.5em;
      width: 1.125em;
      height: 1.125em;
      opacity: 0.75 !important;
      transition: all 300ms ease-in-out !important;
      path {
        transition: all 300ms ease-in-out !important;
        fill: rgb( $textColor-primary ) !important;
      }
    }
  }
  .labelAndLinkWrapper {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding-right: 0.5em;
    .inlineSVG {
      margin-left: 0.5em;
      width: 1em;
      height: 1em;
      opacity: 0.75;
      transition: all 300ms ease-in-out !important;
      path {
        transition: all 300ms ease-in-out !important;
        fill: rgb( $textColor-primary );
      }
    }
  }
  &.lpe {
    background: rgba( $attackScenario-lpe--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-lpe ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $attackScenario-lpe ) !important;
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-lpe ) !important;
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-lpe--30 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.remote_client {
    background: rgba( $attackScenario-rce--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-rce ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $attackScenario-rce ) !important;
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-rce ) !important;
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-rce--30 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.remote_service {
    background: rgba( $attackScenario-clientSideAttack--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-clientSideAttack ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $attackScenario-clientSideAttack ) !important;
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-clientSideAttack ) !important;
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-clientSideAttack--30 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.mitm {
    background: rgba( $attackScenario-mim--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-mim ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $attackScenario-mim ) !important;
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-mim ) !important;
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-mim--30 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.cred_theft {
    background: rgba( $attackScenario-credentialTheft--30, 0.5 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-credentialTheft ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $attackScenario-credentialTheft ) !important;
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-credentialTheft ) !important;
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-credentialTheft--30 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.exploitable_vulns_at_risk_count {
    background: rgba( $status-alert, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $status-alert ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $status-alert ) !important;
      .inlineSVG {
        path {
          fill: rgb( $status-alert ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $status-alert, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.critical, &.Critical {
    background: rgba( $critical, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $critical ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $critical ) !important;
      .inlineSVG {
        path {
          fill: rgb( $critical ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $critical, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.high, &.High {
    background: rgba( $high, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $high ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $high ) !important;
      .inlineSVG {
        path {
          fill: rgb( $high ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $high, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.moderate, &.Moderate {
    background: rgba( $moderate, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $moderate ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $moderate ) !important;
      .inlineSVG {
        path {
          fill: rgb( $moderate ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $moderate, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.low, &.Low {
    background: rgba( $low, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $low ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $low ) !important;
      .inlineSVG {
        path {
          fill: rgb( $low ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $low, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.minimal, &.Minimal {
    background: rgba( $minimal, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $minimal ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $minimal ) !important;
      .inlineSVG {
        path {
          fill: rgb( $minimal ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $minimal, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.unknown, &.Unknown, &.default, &.Default {
    background: rgba( $textColor-primary, 0.2 ) !important;
    .iconAndCountWrapper {
      .inlineSVG {
        path {
          fill: rgb( $textColor-primary ) !important;
        }
      }
    }
    .labelAndLinkWrapper {
      color: rgb( $textColor-primary ) !important;
      .inlineSVG {
        path {
          fill: rgb( $textColor-primary ) !important;
        }
      }
    }
    &:hover {
      background: rgba( $textColor-primary, 0.4 ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          opacity: 1 !important;
        }
      }
    }
  }
  &.user {
    .iconAndCountWrapper {
      justify-content: space-around;
      padding: 0.5em;
      .inlineSVG {
        margin-right: 0em;
      }
    }
  }
  &.darkMode {
    .iconAndCountWrapper {
      background: rgba( #fff, 0.6 );
      color: rgb( $textColor-secondary );
    }
    &.lpe {
      background: rgb( $attackScenario-lpe ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-lpe ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $attackScenario-lpe, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.remote_client {
      background: rgb( $attackScenario-rce ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-rce ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $attackScenario-rce, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.remote_service {
      background: rgb( $attackScenario-clientSideAttack ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-clientSideAttack ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $attackScenario-clientSideAttack, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.mitm {
      background: rgb( $attackScenario-mim ) !important;
      .iconAndCountWrapper {
        color: rgb( $textColor-secondary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-mim ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-secondary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-secondary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $attackScenario-mim, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.cred_theft {
      background: rgb( $attackScenario-credentialTheft ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-credentialTheft ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $attackScenario-credentialTheft, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.exploitable_vulns_at_risk_count {
      background: rgb( $status-alert ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $status-alert ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $status-alert, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.critical, &.Critical {
      background: rgb( $critical ) !important;

      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $critical ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $critical, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.high, &.High {
      background: rgb( $high ) !important;
      color: rgb( $textColor-primary );
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $high ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $high, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.moderate, &.Moderate {
      background: rgb( $moderate ) !important;
      color: rgb( $textColor-secondary );
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $moderate ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $moderate, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.low, &.Low {
      background: rgb( $low ) !important;
      color: rgb( $textColor-secondary );
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $low ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $low, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.minimal, &.Minimal {
      background: rgb( $minimal ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $minimal ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $minimal, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
    &.unknown, &.Unknown, &.default, &.Default {
      background: rgb( $textColor-primary ) !important;
      .iconAndCountWrapper {
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      .labelAndLinkWrapper {
        color: rgb( $textColor-primary ) !important;
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary ) !important;
          }
        }
      }
      &:hover {
        background: rgba( $textColor-primary, 0.5 ) !important;
        .iconAndCountWrapper {
          .inlineSVG {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}