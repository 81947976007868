/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;

.fieldWrapper.collection {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  &.included {
    display: flex;
  }
  .addedItems {
    padding: 0.75em 1em;
    border: 1px solid rgb( $dividerColor );
    background: rgb( $backgroundColor-secondary );
    border-radius: $borderRadius;
  }
  .specialWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
    width: 100%;
    &:last-of-type {
      margin-bottom: 0;
    }
    ul.invalidErrorMessage {
      width: 100%;
    }
    .inputWithIconWrapper {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 1em;
      position: relative;
      &:first-of-type {
        flex-grow: 1;
      }
    }
    input.invalid {
      border-color: rgb( $status-alert );
      color: rgb( $status-alert );
      position: relative;
    }
    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.3s ease-in-out;
      margin-top: 0.5em;
      svg {
        width: 1.75em;
        height: 1.75em;
      }
      &:hover {
        opacity: 1;
      }
      &.disabled, &:disabled {
        pointer-events: none;
        cursor: default;
        svg {
          path {
            fill: rgb( $iconColor-primary );
          }
        }
      }
    }
    &.isNew {
      margin-top: 1em;
    }
    &.invalid {
      input[type='text'], input[type='number'] {
        border-radius: $borderRadius;
        border-color: $status-alert
      }
      .editIcon {
        margin-right: 1em !important;
      }
    }
    &.rootFocus {
      .inputWithIconWrapper {
        .editIcon {
          margin-right: 0;
        }
        &:first-of-type {
          .editIcon {
            margin-right: 1em;
          }
        }
      }
    }
    &.portFocus {
      .inputWithIconWrapper {
        .editIcon {
          margin-right: 0;
        }
        &:last-of-type {
          .editIcon {
            margin-right: 2.5em;
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 0.5em;
    .addNewItemButton {
      @include dsButton('minimal-primary');
      border-radius: $borderRadius;
      .inlineSVG {
        path {
          fill: rgb( $primary );
        }
      }
      &.disabled {
        color: rgb( $primary );
        opacity: 0.4;
      }
    }
  }
}
