/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.httpStatusHandlerContainer {
  width: 100%;
  font-family: $sans;
  text-align: center;
  padding: 4em;
  color: rgb( $textColor-primary );
  border-radius: $borderRadius-input;
  background: rgb( $foregroundColor );
  h2 {
    font-size: 3em;
    margin-bottom: 1em;
    strong {
      margin-right: 0.25em;
    }
  }
  p {
    font-size: 1.5em;
  }
 }