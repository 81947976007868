/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.fieldWrapper.file {
  textarea {
    margin-top: 0.5em;
    min-height: 16em;
    resize: none;
  }
  .previewLabel {
    margin-top: 1em;
    font-weight: 500;
  }
  pre {
    margin-top: 0.5em;
  }
  input[type="file"]{
    &::file-selector-button {
      @include dsButton( 'minimal-minimal');
      display: inline-flex;
      margin-right: 0.5em;
    }
  }
}