/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.recordStatisticWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  margin-bottom: 1em;
  .statisticLabel {
    color: rgb( $textColor-primary );
    font-size: 0.875em;
    margin-bottom: 0.5em;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease-in-out;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-left: 0.5em;
      transition: all 0.3s ease-in-out;
      path {
        transition: all 0.3s ease-in-out;
        fill: rgb( $iconColor-primary );
        &.whiteFill {
          fill: #fff;
        }
      }
    }
  }
  .statisticValue {
    font-weight: 600;
    color: rgb( $textColor-primary );
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transition: all 0.3s ease-in-out;
    font-size: 1.25em;
    transition: all 0.3s ease-in-out;
    .inlineSVG {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      transition: all 0.3s ease-in-out;
      path {
        transition: all 0.3s ease-in-out;
        fill: rgb( $iconColor-primary );
        &.whiteFill {
          fill: #fff;
        }
      }
    }
    .exploitStatusWrapper {
      margin-top: 0.25em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .inlineSVG {
        opacity: 1;
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.625em;
        path {
          fill: #fff;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .statusTitle {
        margin-top: 0.5em;
        font-size: 0.75em;
        text-align: center;
        .inlineSVG {
          width: 1.25em;
          height: 1.25em;
          path {
            fill: rgb( $iconColor-primary );
          }
          &:hover {
            path {
              fill: rgb( $textColor-primary );
            }
          }
        }
      }
    }
  }
  &.asLink {
    padding: 1em 2em;
    border-radius: $borderRadius;
    &:hover {
      text-decoration: none;
      background: rgb( $primary--10 );
      .statisticLabel {
        color: rgb( $primary );
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
      .statisticValue {
        color: rgb( $primary );
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
  }
  &.exploitable_vulns_at_risk_count {
    background: rgb( $status-alert--10 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: rgb( $status-alert );
        }
      }
    }
    &:hover {
      background: rgb( $status-alert--20 );
      color: rgb( $status-alert );
      .statisticLabel{
        color: rgb( $status-alert );
      }
      .statisticValue {
        color: rgb( $status-alert );
        .inlineSVG {
          path {
            fill: rgb( $status-alert );
          }
        }
      }
    }
  }
  &.lpe {
    background: rgba( $attackScenario-lpe--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-lpe );
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-lpe--30 );
      color: rgb( $attackScenario-lpe );
      .statisticLabel{
        color: rgb( $attackScenario-lpe );
      }
      .statisticValue {
        color: rgb( $attackScenario-lpe );
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-lpe );
          }
        }
      }
    }
  }
  &.remote_client {
    background: rgba( $attackScenario-rce--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-rce );
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-rce--30 );
      color: rgb( $attackScenario-rce );
      .statisticLabel{
        color: rgb( $attackScenario-rce );
      }
      .statisticValue {
        color: rgb( $attackScenario-rce );
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-rce );
          }
        }
      }
    }
  }
  &.remote_service {
    background: rgba( $attackScenario-clientSideAttack--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-clientSideAttack );
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-clientSideAttack--30 );
      color: rgb( $attackScenario-clientSideAttack );
      .statisticLabel{
        color: rgb( $attackScenario-clientSideAttack );
      }
      .statisticValue {
        color: rgb( $attackScenario-clientSideAttack );
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-clientSideAttack );
          }
        }
      }
    }
  }
  &.mitm {
    background: rgba( $attackScenario-mim--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-mim );
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-mim--30 );
      color: rgb( $attackScenario-mim );
      .statisticLabel{
        color: rgb( $attackScenario-mim );
      }
      .statisticValue {
        color: rgb( $attackScenario-mim );
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-mim );
          }
        }
      }
    }
  }
  &.cred_theft {
    background: rgba( $attackScenario-credentialTheft--30, 0.5 );
    .statisticValue {
      .inlineSVG {
        path {
          fill: rgb( $attackScenario-credentialTheft );
        }
      }
    }
    &:hover {
      background: rgb( $attackScenario-credentialTheft--30 );
      color: rgb( $attackScenario-credentialTheft );
      .statisticLabel{
        color: rgb( $attackScenario-credentialTheft );
      }
      .statisticValue {
        color: rgb( $attackScenario-credentialTheft );
        .inlineSVG {
          path {
            fill: rgb( $attackScenario-credentialTheft );
          }
        }
      }
    }
  }
}