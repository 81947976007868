/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.graphModelScopeGroup {
  cursor: pointer;
  .draggableIcon {
    path {
      fill: rgb( $iconColor-primary );
    }
  }
  .graphModelScopeRectangle {
    -webkit-filter: drop-shadow( 2px 2px 6px rgba(0, 0, 0, .1));
    filter: drop-shadow( 2px 2px 6px rgba(0, 0, 0, .1));
    &.isParentScope {
      -webkit-filter: drop-shadow( 2px 2px 12px rgba(0, 0, 0, .1));
      filter: drop-shadow( 2px 2px 12px rgba(0, 0, 0, .1));
    }
  }
  &:hover {
    .draggableIcon {
      path {
        fill: rgb( $primary );
      }
    }
  }
  &.selected {
    .graphModelScopeRectangle {
      stroke: rgb( $textColor-primary );
      fill: rgb( $backgroundColor-secondary );
    }
    .scopeIcon {
      path {
        fill: rgb( $textColor-secondary );
      }
    }
  }
  &.externallyHovered {
    .graphModelScopeRectangle {
      stroke: rgb( $textColor-primary );
      fill: rgb( $backgroundColor );
    }
    &.risk-critical {
      .graphModelScopeRectangle {
        stroke: rgb( $critical );
        fill: rgb( $critical--10 );
      }
      .scopeIcon {
        path {
          fill: rgb( $critical );
        }
      }
    }
    &.risk-high {
      .graphModelScopeRectangle {
        stroke: rgb( $high );
        fill: rgb( $high--10 );
      }
      .scopeIcon {
        path {
          fill: rgb( $high );
        }
      }
    }
    &.risk-moderate {
      .graphModelScopeRectangle {
        stroke: rgb( $moderate );
        fill: rgb( $moderate--10 );
      }
      .scopeIcon {
        path {
          fill: rgb( $moderate );
        }
      }
    }
    &.risk-low {
      .graphModelScopeRectangle {
        stroke: rgb( $low );
        fill: rgb( $low--10 );
      }
      .scopeIcon {
        path {
          fill: rgb( $low );
        }
      }
    }
    &.risk-minimal {
      .graphModelScopeRectangle {
        stroke: rgb( $low );
        fill: rgb( $minimal--10 );
      }
      .scopeIcon {
        path {
          fill: rgb( $low );
        }
      }
    }
  }
}
