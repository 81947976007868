/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.errorFallbackWrapper {
  width: calc( 100vw - 4em );
  position: absolute;
  left: 2em;
  top: 2em;
  font-family: $sans;
  text-align: center;
  padding: 4em;
  color: rgb( $textColor-primary );
  background: rgb( $foregroundColor );
  border-radius: $borderRadius;
  h1 {
    font-size: 3em;
    margin-bottom: 1em;
  }
  pre {
    font-size: 1.5em;
    margin-bottom: 2em;
    margin-top: 0;
  }
  button {
    @include dsButton('primary');
    color: #fff !important;
    width: 20em;
    margin: 0 auto;
    font-size: 1.25em;
  }
}