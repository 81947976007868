/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../../styles/globals.scss" as *;

#react-network-connectivity-container {
  width: 100%;
  .setupFormContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    .cliquesSection {
      grid-column: 1 / 3;
    }
  }
  .networkConnectivitySection {
    border-radius: $borderRadius;
    background: rgb( $foregroundColor );
    &.subnetSection {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
      flex-grow: 1;
      box-shadow: none;
      padding: 1em 2em;
      border-radius: $borderRadius;
      background: rgb( $foregroundColor );
      .sectionHeader {
        font-weight: 600;
        font-size: 1.125em;
        margin-bottom: 1em;
        width: 100%;
      }
      h2 {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-bottom: 1em;
        font-size: 1em;
      }
      .buttonContainer {
        button {
          @include dsButton( 'minimal-primary' );
          .inlineSVG {
            path {
              fill: rgb( $primary );
            }
            &.excludeSelected {
              margin-right: 0;
              margin-left: 0.25em;
            }
          }
        }
      }
      .subnetListWrapper {
        width: calc( 50% - 1.5em);
        h2 {
          width: 100%;
          margin-bottom: 1em;
          font-weight: 400;
        }
        select {
          -webkit-appearance: none;
          appearance: none;
          border: none;
          background: none;
          padding: 0.5em;
          height: 20em;
          border: 1px solid rgb( $iconColor-primary );
          border-radius: $borderRadius;
          margin-bottom: 1em;
          &:focus {
            outline: none;
          }
          option {
            padding: 0.5em 1em;
            cursor: pointer;
            &:focus,
            &:active,
            &:checked {
              background-image: $backgroundColor;
            }
            &:first-child {
              border-radius: $borderRadius $borderRadius 0 0;
            }
            &:last-child {
              border-radius: 0 0 $borderRadius $borderRadius;
            }
          }
        }
      }
    }
  }
  .cliquesSection {
    .sectionHeader {
      // padding: 1em 2em;
      border-radius: $borderRadius;
      // background: rgb( $foregroundColor );
      margin-bottom: 1em;
      margin-top: 2em;
    }
    .setupRecordItem.item.connectivity_clique {
      grid-template-columns: 1fr 3fr 6em;
    }
  }
}
