/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.filterWrapper.inequality {
  .selectFieldWrapper {
    width: unset !important;
  }
  .inputsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    input[ type="number" ] {
      margin: 0 0.5em;
      max-width: 12em;
      height: 3.25em;
    }
    span.subLabel {
      color: rgb( $textColor-primary );
      text-transform: none;
      font-weight: 400;
      flex-shrink: 0;
    }
  }
}