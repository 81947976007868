/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

.pageSelectorShade {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.pageSelectorWrapper {
  position: relative;
  font-family: $sans;
  z-index: 99999;
}

.pageSelectorTrigger {
  padding: 0.5em 1em 0.5em 1em;
  border-radius: $borderRadius-input;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 20em;
  z-index: 59;
  color: rgb( $textColor-secondary );
  background: rgb( $textColor-primary );
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .labelWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    .menuIcon {
      margin-right: 1em;
      path {
        fill: rgb( $primary );
      }
    }
    .ratingBadge.beta {
      background: rgb( $primary );
      margin-left: 1em;
      color: #fff;
    }
    .ratingBadge.new {
      background: rgb( $status-success );
      margin-left: 1em;
      color: #fff;
    }
    .parentLabel {
      margin-right: 0.5em;
    }
    .newCustomReportIcon {
      display: none;
    }
    .advancedSettingsIcon {
      width: 1.25em;
      height: 1.25em;
      margin-right: 0.5em;
      path {
        fill: rgb( $iconColor-primary );
      }
    }
  }
  .carretIcon {
    width: 1.25em;
    height: 1.25em;
    transition: all 0.3s ease-in-out;
    flex-shrink: 0;
    margin-left: 1em;
    path {
      fill: #fff;
    }
  }
  &.open {
    box-shadow: $boxShadow;
    .carretIcon {
      transform: rotate( 180deg );
    }
  }
  &:hover {
    box-shadow: $boxShadow;
  }
  &.darkMode {
    background: rgba( $primary, 0.85 );
    color: rgb( $textColor-secondary );
    .labelWrapper {
      .menuIcon {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
    }
    .carretIcon {
      path {
        fill: rgb( $textColor-secondary );
      }
    }
  }
}

.pageSelectorMenu {
  position: absolute;
  top: 3em;
  background: rgb( $foregroundColor );
  border: 1px solid rgb( $iconColor-primary );
  border-radius: $borderRadius-input;
  box-shadow: $boxShadow-heavy;
  width: 100%;
  ul {
    padding: 0.5em;
  }
  li.divider {
    border-top: 1px solid rgb( $dividerColor );
    margin-bottom: 0;
    margin-top: 0.5em;
    padding: 0.5em 1em;
    label {
      text-transform: uppercase;
      // // font-weight: 600;
      color: rgb( $textColor-secondary );
      font-size: 0.75em;
    }
    &:first-child {
      margin-top: 0;
      border-top: none;
    }
  }
  .subNavigationItem {
    width: 100%;
    margin-bottom: 0;
    a, .notLink {
      width: 100%;
      padding: 0.5em 1em 0.5em 2em;
      border-radius: none;
      background: rgb( $foregroundColor );
      text-decoration: none;
      color: rgb( $textColor-primary );
      text-align: left;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      transition: all 0.3s ease-in-out;
    }
    a {
      &:hover {
        background: rgb( $dividerColor );
      }
    }
    .notLink {
      background-color: rgb( $textColor-primary );
      color: #fff;
      position: relative;
      padding-left: 2em;
      &:before {
        content: '';
        position: absolute;
        top: calc( 50% - ( 0.75em / 2 ) );
        left: 0.75em;
        width: 0.75em;
        height: 0.75em;
        background: rgb( $primary );
        border-radius: $borderRadius-full;
      }
      .ratingBadge.beta {
        background: rgb( $primary );
        color: #fff;
      }
      .ratingBadge.new {
        background: rgb( $status-success );
        color: #fff;
      }
      &.withAction {
        background: none;
        color: rgb( $textColor-primary );
        padding-left: 1em;
        cursor: pointer;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
        .ratingBadge {
          margin-left: 1em;
        }
        &:before {
          display: none;
        }
        &:hover {
          background: rgb( $dividerColor );
        }
      }
    }
  }
}