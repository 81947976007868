/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../styles/globals.scss" as *;

strong.riskReduction {
  color: rgb( $critical );
  &.high {
    color: rgb( $high );
  }
  &.moderate {
    color: rgb( $moderate );
  }
  &.low {
    color: rgb( $low );
  }
  &.minimal {
    color: rgb( $minimal );
  }
  &.negligible {
    color: rgb( $primary );
  }
  &.none {
    color: rgb( $textColor-secondary );
  }
  &.unknown {
    color: rgb( $iconColor-primary );
  }
}