/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../../../styles/globals.scss" as *;

.inputValueWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: 3em;
  label {
    margin-right: 1em;
  }
}

.searchWithResultsWrapper {
  position: relative;
  width: 100%;
  margin-top: 0;
  &.hasResults {
    border-radius: $borderRadius-input;
    .searchFieldWrapper {
      input[type='search'] {
        border-radius: $borderRadius-input;
      }
    }
    .searchResultsList {
      border: 1px solid rgb( $iconColor-primary );
    }
  }
  .labelWrapper {
    width: 100%;
    margin-bottom: 0.5em;
    font-weight: 600;
    color: rgba($textColor-primary, 0.75);
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
}
.selectedSearchResult {
  background: rgb( $backgroundColor );
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  padding: 0.75em 1.5em;
  width: 100%;
  margin-top: 0;
  border: 1px solid rgba( $iconColor-primary, 0.75 );
  border-radius: $borderRadius-input;
  color: rgba( $textColor-primary, 0.85 );
  box-shadow: $boxShadow-input;
  button {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    right: 0.5em;
    top: 0.625em;
    .inlineSVG {
      width: 1.25em;
      height: 1.25em;
      opacity: 0.6;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      .inlineSVG {
        opacity: 1;
      }
    }
  }
}
.searchResultsListShade {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999998;
}
.searchResultsList {
  max-height: 40vh;
  overflow: auto;
  background: rgb( $foregroundColor );
  margin: 0;
  padding: 0.5em;
  border-radius: $borderRadius-input;
  border: 1px solid rgb( $iconColor-primary );
  box-shadow: $boxShadow;
  position: fixed;
  top: 0;
  left: 0;
  width: 32em;
  z-index: 99999999;
  font-family: $sans;
  .searchResultItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    margin: 0;
    list-style: none;
    padding: 0.5em 1em !important;
    border-bottom: none !important;
    transition: all 0.3s ease-in-out;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .labelWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      // width: 100%;
      label {
        font-weight: 600;
        margin: 0 0.5em;
        color: rgb( $textColor-primary );
        cursor: pointer;
        max-width: 16em;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;
      }
    }
    .riskReduction {
      flex-shrink: 0;
    }
    &:hover {
      background: rgb( $dividerColor );
      color: rgb( $textColor-primary );
    }
  }
  &.loading {
    min-height: 14em;
  }
  &.empty {
    border: 1px solid rgb( $iconColor-primary );
  }
}

.searchFieldWrapper {
  position: relative;
  flex-grow: 1;
  .inlineSVG {
    position: absolute;
    bottom: 0.875em;
    left: .75em;
    width: 1.5em;
    height: 1.5em;
    display: block;
  }
  input[type='search'] {
    padding: .75em 1.5em .75em 2.5em;
    font-weight: 400;
    background: rgb( $foregroundColor );
    border: 1px solid rgba( $iconColor-primary, 0.75 );
    border-radius: $borderRadius-input;
    color: rgba( $textColor-primary, 0.85 );
    box-shadow: $boxShadow-input;
    transition: all 0.3s ease-in-out;
    margin-top: 0em;

    &:focus {
      border: 1px solid rgb( $iconColor-primary );
      color: rgb( $textColor-primary );
      outline: none;
    }
  }
  ::placeholder {
    color: rgb( $textColor-secondary );
  }
  ::-moz-placeholder {
    color: rgb( $textColor-secondary );
  }
}
