/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.emptyInstances {
  width: 100%;
  height: 2em;
  border-radius: $borderRadius;
  background: rgb( $backgroundColor );
}

.instancesBreakdownWrapper {
  color: rgb( $textColor-primary );
  width: 100%;
  h3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    margin: 0;
    padding: 1em 0;
    strong {
      span {
        font-weight: 400;
        &.totalValue {
          font-weight: 600;
        }
      }
    }
  }
}