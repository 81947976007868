/***************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
@use "../../../../styles/globals.scss" as *;

.paginationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: calc( 100vw - 4.25em );
  background: rgb( $foregroundColor );
  padding: 0.5em;
  position: fixed;
  bottom: 0em;
  left: 4.25em;
  z-index: 999;
  ul {
    opacity: 0;
    position: absolute;
    z-index: -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    li {
      border: 1px solid rgb( $iconColor-primary );
      border-radius: $borderRadius;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb( $textColor-primary );
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      margin-left: 0.5em;
      font-weight: 600;
      width: 2.5em;
      height: 2.5em;
      margin-bottom: 0;
      flex-shrink: 0;;
      .inlineSVG {
        height: 1.25em;
        width: 1.25em;
      }
    }
    &.actualPages {
      opacity: 1;
      visibility: visible;
      position: static;
      z-index: 1;
      li {
        &:hover {
          border: 1px solid rgb( $textColor-primary );
          color: rgb( $textColor-primary );
        }
        &.disabled {
          opacity: 0.3;
          cursor: default;
          &:hover {
            border: 1px solid rgb( $iconColor-primary );
            color: rgb( $iconColor-primary );
          }
        }
        &.current {
          border: 1px solid rgb( $textColor-primary );
          background: rgb( $textColor-primary );
          color: #fff;
          cursor: default;
          &:hover {
            border: 1px solid rgb( $textColor-primary );
            background: rgb( $textColor-primary );
            color: #fff;
          }
        }
        &.placeholder {
          border: none;
          cursor: default;
          &:hover {
            border: none;
          }
        }
      }
    }
  }
}

.indeterminantPagination {
  flex-grow: unset;
  padding: 1em;
  border-left: none;
  flex-grow: 0 !important;

  &.sensitiveAssetsPagination {
    padding: 0em;
    flex-grow: 1 !important;
    padding-left: 1em;
    margin-left: 1em;
    border-left: 1px solid rgb( $dividerColor );
  }
}

.fullPaginationWrapper {
  // padding is handled with inline styles to keep all the math in one place
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid rgb( $dividerColor );
  .totalRecordsCountWrapper {
    text-align: right;
    color: rgb( $textColor-primary );
    font-size: 1em;
  }
  .allPaginationButtons {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .pageButtons {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

    }
    .pageButton {
      @include dsButton( 'minimal' );
      padding-left: 0;
      padding-right: 0;
      // border: 1px solid rgb( $textColor-secondary );
      transition: all 300ms ease-in-out;
      .inlineSVG {
        transition: all 300ms ease-in-out;
      }
      &.selected, &:hover {
        background: rgb( $textColor-primary );
        color: #fff;
        opacity: 1;
        .inlineSVG {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &.darkMode {
    .allPaginationButtons {
      .pageButton {
        &.selected, &:hover {
          background: rgb( $primary );
          color: rgb( $textColor-secondary );
          .inlineSVG {
            path {
              fill: rgb( $textColor-secondary );
            }
          }
        }
      }
    }
  }
}