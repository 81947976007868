/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

@use "../../../../../../../styles/globals.scss" as *;

.taskItem.remediationTaskItem {
  background: rgb( $backgroundColor );
  border-radius: $borderRadius;
  display: grid;
  grid-column-gap: 0;
  position: relative;
  grid-template-columns: 1fr 4em;
  .remediationPlanInformation {
    order: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    padding-right: 0;
    border-right: none;
    h3, .actions {
      margin: 0;
      padding: 0;
    }
    h3 {
      border-right: 1px solid rgb( $dividerColor );
      padding-right: 1em;
      margin-right: 1em;
      font-size: 1em;
      line-height: 1em;
      .riskReduction {
        margin-right: 1em;
      }
    }
  }
  .itemActions {
    padding-left: 1.5em;
    order: 2;
    .addItemButton {
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      width: 2.5em;
      height: 2.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-radius: $borderRadius-full;
      cursor: pointer;
      .inlineSVG {
        path {
          fill: rgb( $textColor-secondary );
        }
      }
      &:hover {
        background: rgb( $dividerColor );
        .inlineSVG {
          path {
            fill: rgb( $textColor-primary );
          }
        }
      }
    }
  }
  .remediationPlanProgress {
    width: 100%;
    padding-right: 0;
    order: 2;
    .completionWrapper {
      .completionBarWrapper {
        width: 100% !important;
      }
    }
  }

  &.active {
    background: none;
    grid-template-columns: 40em 1fr;
    padding-bottom: 1em;
    border-bottom: 1px solid rgb( $dividerColor );
    .remediationPlanInformation {
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding-right: 1em;
      border-right: 1px solid rgb( $dividerColor );
      order: 1;
      h3 {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 0.5em;
      }
    }
    &.hasExternalTicket {
      grid-template-columns: 40em 10.75em 1fr;
      .remediationPlanProgress {
        order: 3;
      }
      .externalTicketLink {
        order: 2;
      }
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.taniumBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25em 0.75em 0.25em 0.375em;
  border-radius: $borderRadius-full;
  border: 1px solid rgb( $textColor-secondary );
  background: rgb( $backgroundColor-secondary );
  margin-left: 0.5em;
  .inlineSVG {
    width: 0.875em;
    height: 0.875em;
    margin-right: 0.5em;
  }
  strong {
    font-size: 0.875em;
  }
}

.externalLinkPlaceholder {
  order: 2;
}
.externalTicketLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgb( $iconColor-primary );
  padding: 0.25em 0.75em;
  border-radius: $borderRadius;
  color: rgb( $textColor-primary );
  text-decoration: none;
  margin-left: 1em;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 3.5em;
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0.75em;
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &.id {
        color: rgb( $primary );
        margin-bottom: 0.25em;
        .inlineSVG {
          width: 1em;
          height: 1em;
          transition: all 0.2s ease-in-out;
          path {
            transition: all 0.2s ease-in-out;
            fill: rgb( $iconColor-primary );
          }
        }
      }
      &.status {
        justify-content: flex-start;
        color: rgb( $textColor-primary );
        font-size: 0.875em;
        label {
          cursor: pointer;
          font-weight: 600;
          margin-right: 0.25em;
        }
      }
    }
  }
  &:hover {
    text-decoration: none;
    background: rgb( $backgroundColor-secondary );
    box-shadow: $boxShadow-light;
    .text {
      .row.id {
        .inlineSVG {
          path {
            fill: rgb( $primary );
          }
        }
      }
    }
  }
}