/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../styles/globals.scss" as *;

.riskInsightVisualWrapper {
  display: flex;
  flex-direction: row;
  max-height: 30em;
  min-height: 20em;
  padding: 1em;
  width: 100%;
  background: rgb( $foregroundColor );
  color: rgb( $textColor-primary );
  z-index: 4;
  transition: all 0.15s ease-in-out;
  position: relative;
  border-radius: $borderRadius $borderRadius 0 0;
  &.host, &.patch, &.vulnerability, &.path, &.user {
    h3 {
      font-size: 1.125em;
      font-weight: 400;
    }
    .widgetContent {
      padding-top: 0.5em;
      .svgBarWrapper {
        height: 19.5em;
      }
    }
  }
  &.instance {
    padding-top: 1em;
    .roundGlyphButton {
      display: none;
    }
    .vulnerabilityInstancesVisualWrapper {
      h3 {
        font-size: 1.125em;
      }
      .excludeNotScannedToggle {
        top: unset;
        right: unset;
        left: 0;
        bottom: 0;
      }
    }
  }
  &.collapsed {
    height: 0;
    min-height: 0;
    padding: 0;
    margin-bottom: 0em;
    .dashboardWidgetWrapper,
    .widgetContent,
    .svgBarWrapper,
    .visualizationWrapper,
    .instancesWidgetsWrapper,
    .vulnerabilityInstancesVisualWrapper,
    .excludeNotScannedToggle,
    .emptyState,
    #chartWrapper {
      padding: 0;
      height: 0;
      overflow: hidden;
    }
  }
  .visualizationWrapper {
    height: 100%;
    position: relative;
  }
  .dashboardWidgetWrapper {
    width: 100%;
    padding: 0;
    height: 20em;
    margin: 0;
    .widgetContent {
      width: 100%;
      display: grid;
      grid-template-columns: 10em 1fr 14em;
      height: 18em;
      .axisContainer {
        &.yAxis {
          height: 100%;
          max-height: 100%;
        }
      }
      .svgBarWrapper {
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        border-bottom: 2px solid rgb( $iconColor-primary );
        margin-left: -0.5em;
        height: 17.5em;
        svg {
          height: 100%;
          width: 100%;
        }
      }
      .chartLegend {
        padding-left: 2em;
        margin-top: -2em;
      }
    }
    &.bar {
      .axisContainer.xAxis {
        width: calc( 100% - 7em );
        margin-left: 6.5em;
      }
    }
  }
  .showFullScreenButton {
    position: absolute;
    bottom: 0em;
    right: 0em;
    display: flex;
  }
  .emptyState {
    grid-column: 1 / 3;
    width: 100%;
  }
}