/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "../../../../../../../styles/globals.scss" as *;
.riskGradeWidgetWrapper {
  background: rgb( $foregroundColor );
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 3em;
  height: 100%;
  .riskGradeContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .scoreCircle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 10em;
      height: 10em;
      border-radius: $borderRadius-full;
      margin-bottom: 1.5em;
      .gradeWrapper {
        font-size: 4.5em;
      }
    }
    .chartLabel {
      color: rgb( $textColor-primary );
      font-size: 1.25em;
    }
  }
  &.risk--critical {
    .scoreCircle {
      background: rgb( $critical--10 );
      .gradeWrapper {
        color: rgb( $critical );
      }
    }
  }
  &.risk--high {
    .scoreCircle {
      background: rgb( $high--10 );
      .gradeWrapper {
        color: rgb( $high );
      }
    }
  }
  &.risk--moderate {
    .scoreCircle {
      background: rgb( $moderate--10 );
      .gradeWrapper {
        color: rgb( $moderate );
      }
    }
  }
  &.risk--low {
    .scoreCircle {
      background: rgb( $low--10 );
      .gradeWrapper {
        color: rgb( $low );
      }
    }
  }
  &.risk--minimal {
    .scoreCircle {
      background: rgb( $minimal--10 );
      .gradeWrapper {
        color: rgb( $minimal );
      }
    }
  }
  &.risk--primary, &.risk--primaryBlue {
    .scoreCircle {
      background: rgb( $primary--10 );
      .gradeWrapper {
        color: rgb( $primary );
      }
    }
  }
  &.risk--default {
    .scoreCircle {
      background: rgba( $textColor-primary, 0.1 );
      .gradeWrapper {
        color: rgb( $textColor-primary );
      }
    }
  }
}

.dashboardWidgetWrapper.risk_grade {
  padding: 0;
  overflow: hidden;
  border: none;
  &.widgetHeight-4 {
    .widgetContent {
      height: 17em;
    }
  }
  &.widgetHeight-5 {
    .widgetContent {
      height: 21em;
    }
  }
  &.widgetHeight-6 {
    .widgetContent {
      height: 25em;
    }
  }
}
